import { Grid } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BenefitColumn, { IconTypes } from '../layout/columns/Benefit.column';
import FadeSlide from '../layout/container/FadeSlide.container';
import Row from '../layout/rows/Row';
import Header from '../text/Header';
import SubHeader from '../text/SubHeader';

interface BenefitsSectionPropsType {
  darkTheme?: boolean;
}

const BenefitsSection: FC<BenefitsSectionPropsType> = ({ darkTheme }) => {
  const { t, i18n } = useTranslation();
  return (
    <Row
      sx={{
        mt: { xs: 0, md: 3, lg: 5 },
        mb: '2rem',
      }}
      rowSpacing={8}
    >
      <FadeSlide rowSpacing={2} sx={{ textAlign: 'center' }} direction={'up'} once>
        <Grid item xs={12}>
          <Header text={t('HOME.SECTIONS.BENEFITS.TITLE')} darkTheme={darkTheme} />
        </Grid>
        <Grid item xs={12}>
          <SubHeader text={t('HOME.SECTIONS.BENEFITS.SUBTITLE')} darkTheme={darkTheme} />
        </Grid>
      </FadeSlide>
      <FadeSlide direction={'up'} once>
        <Grid container item columnSpacing={2} rowSpacing={4}>
          <BenefitColumn
            darkTheme={darkTheme}
            title={t('HOME.SECTIONS.BENEFITS.EFFICIENCY.TITLE')}
            text={t('HOME.SECTIONS.BENEFITS.EFFICIENCY.TEXT')}
            tag={t('HOME.SECTIONS.BENEFITS.EFFICIENCY.TAG')}
            type={IconTypes.Time}
            index={0}
          />
          <BenefitColumn
            darkTheme={darkTheme}
            title={t('HOME.SECTIONS.BENEFITS.COST.TITLE')}
            text={t('HOME.SECTIONS.BENEFITS.COST.TEXT')}
            tag={t('HOME.SECTIONS.BENEFITS.COST.TAG')}
            type={IconTypes.Money}
            index={1}
          />
          <BenefitColumn
            darkTheme={darkTheme}
            title={t('HOME.SECTIONS.BENEFITS.ESG.TITLE')}
            text={t('HOME.SECTIONS.BENEFITS.ESG.TEXT')}
            tag={t('HOME.SECTIONS.BENEFITS.ESG.TAG')}
            index={2}
          />
        </Grid>
      </FadeSlide>
    </Row>
  );
};

export default BenefitsSection;
