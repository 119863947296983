import { FC } from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as Gesture } from '/assets/images/icons/gesture.svg';
import { ReactComponent as AutoFix } from '/assets/images/icons/auto-fix.svg';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../../text/SubHeader';

interface BeforeAndAfterPropsType {
  darkTheme: boolean;
  item: {
    before_fides: string[];
    after_fides: string[];
  };
}

const BeforeAndAfterSection: FC<BeforeAndAfterPropsType> = ({ darkTheme, item }) => {
  const { t } = useTranslation();

  return (
    <Grid container item xs={12} pb={4}>
      <Grid item container xs={12} md={6} alignItems="center" height="fit-content" rowSpacing={3}>
        <Grid item width="30px" pr={1.5}>
          <Gesture color="#17496A" />
        </Grid>
        <Grid item>
          <SubHeader text={t('TESTIMONIALS.BEFORE_FIDES')} opacity darkTheme={darkTheme} />
        </Grid>
        <Grid item xs={12}>
          <ul>
            {item.before_fides.map((item) => {
              return (
                <li style={{ paddingBottom: '10px' }} key={item}>
                  {item}
                </li>
              );
            })}
          </ul>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={6} alignItems="center" height="fit-content" rowSpacing={3}>
        <Grid item width="30px" pr={1.5} color="blue">
          <AutoFix color="#17496A" />
        </Grid>
        <Grid item>
          <SubHeader text={t('TESTIMONIALS.AFTER_FIDES')} opacity darkTheme={darkTheme} />
        </Grid>
        <Grid item xs={12}>
          <ul>
            {item.after_fides.map((item) => {
              return (
                <li style={{ paddingBottom: '10px' }} key={item}>
                  {item}
                </li>
              );
            })}
          </ul>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BeforeAndAfterSection;
