import { Layer } from '@fides-jam/front/components/Layer';
import TruncatedText from '@fides-jam/front/components/textWrappers/TruncatedText';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { Grid, SxProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedSlide from '../../layout/container/AnimatedSlide.container';
interface EventRowPropsType {
  sx?: SxProps;
  name: string;
  position: string;
  day: string;
  month: string;
  time: string;
  ipAddress: string;
  line: boolean;
  index: number;
  fadeGradient?: boolean;
  animate?: boolean;
}

const EventRow: FC<EventRowPropsType> = ({
  sx,
  name,
  position,
  day,
  month,
  time,
  ipAddress,
  line,
  index,
  fadeGradient,
  animate,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <AnimatedSlide animate={animate} direction={'up'} transitionDelay={100 * index}>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={{
          pl: 0,
          px: 0,
          py: 0.8,
          borderBottom: line ? '2px solid rgba(0,0,0,0.05)' : 'unset',
          position: 'relative',
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          background: '#FFF',
          borderRadius: {
            xs: '18px',
            md: '22px',
          },
          p: 2,
          mb: 1,
        }}
      >
        <Grid
          container
          sx={{
            height: '70px',
            width: '85px',
            mr: 1,
            position: 'relative',
            textAlign: 'right',
          }}
        >
          <Grid
            item
            container
            sx={{
              flex: 1,
              fontSize: '22px',
              fontWeight: '700',
              pr: 1,
              mt: 0.5,
            }}
          >
            <Grid
              item
              sx={{
                flex: 2,
              }}
            >
              {day}
            </Grid>
            <Grid
              item
              sx={{
                flex: 1,
                mt: -1.5,
                opacity: 0.4,
                fontSize: '12px',
                lineHeight: 1,
              }}
            >
              {month}
            </Grid>
            <Grid
              item
              sx={{
                flex: 1,
                mt: -1,
                opacity: 0.4,
                fontSize: '12px',
                lineHeight: 1,
              }}
            >
              {time}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              ml: 1,
              background: AppSignUpColors.PRIMARY_LIGHTER,
              height: '100%',
              width: '4px',
              borderRadius: '2px',
            }}
          />
        </Grid>
        <Grid
          container
          item
          direction={'column'}
          sx={{
            flex: 1,
            px: 1,
            py: 1,
          }}
        >
          <Grid
            item
            sx={{
              fontSize: '16px',
              fontWeight: '700',
              //mb:-0.5,
            }}
          >
            <TruncatedText maxLines={1}>{name}</TruncatedText>
          </Grid>
          <Grid
            item
            sx={{
              fontSize: '12px',
              opacity: 0.4,
            }}
          >
            <TruncatedText maxLines={1}>{position}</TruncatedText>
          </Grid>
          <Grid
            item
            sx={{
              fontSize: '12px',
              opacity: 0.4,
            }}
          >
            <TruncatedText maxLines={1}>
              {t('AUDITTRAILCELL.IP')}: <strong>{ipAddress}</strong>
            </TruncatedText>
          </Grid>
        </Grid>
        <Layer
          sx={{
            right: 60,
            left: 60,
            background: {
              xs: fadeGradient
                ? 'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)'
                : 'unset',
              sm: fadeGradient
                ? 'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)'
                : 'unset',
            },
          }}
        />
      </Grid>
    </AnimatedSlide>
  );
};

export default EventRow;
