import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import Row from '../../components/layout/rows/Row';
import Footer from '../../components/views/Footer';
import { DarkThemeContext } from '../../components/Context/ThemeContext';

const DemoBookingPage: React.FC = () => {
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(true);
  }, [toggleDarkTheme]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        transition: 'background 0.6s ease-in-out',
        background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
        maxWidth: '100vw',
        overflow: 'hidden',
      }}
    >
      <Row
        sx={{
          mt: 10,
          p: {
            xs: 2,
            sm: 5,
            md: 6,
            lg: 7,
          },
        }}
      >
        <Footer />
      </Row>
    </Grid>
  );
};

export default React.memo(DemoBookingPage);
