import CSS from 'csstype';

const containerStyle: CSS.Properties = {
	transitionDuration: '0.5s',
}

const hoverStyle: { [P in CSS.SimplePseudos]?: CSS.Properties } = {
  ':hover': {
		transform: 'scale(1.02)',
	  boxShadow: '0px 0px 0px 5px rgba(230,230,242,0.7)',
  },
};

export default {
	containerStyle,
	hoverStyle
};