import Menu from '@fides-jam/front/components/Menu';
import { IconButton, ListItemIcon, ListItemText, MenuItem, SxProps } from '@mui/material';
import { FC, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CountryBubble from '@fides-jam/front/components/CountryBubble';
import { useNavigate, useLocation } from 'react-router-dom';

interface LanguageMenuProps {
  onSelectLng?: (lng: string) => void;
  darkTheme?: boolean;
  sx?: SxProps;
}

const LanguageMenu: FC<LanguageMenuProps> = ({ onSelectLng, darkTheme, sx }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleCloseMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const handleClickMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  return (
    <>
      <IconButton onClick={handleClickMenu} sx={{ ...sx }}>
        <CountryBubble
          size={16}
          jurisdiction={i18n.language === 'de' ? 'Germany' : 'United States'}
          sx={{
            boxShadow: '0px 0px 0px 3px rgba(230,230,242,0.25)',
          }}
        />
      </IconButton>
      <Menu
        key="details_menu_1"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        disableAutoFocusItem
        darkTheme={darkTheme}
      >
        <MenuItem
          onClick={(event) => {
            onSelectLng?.('de');
            i18n.changeLanguage('de');
            handleCloseMenu(event);
            navigate(location.pathname.replace('/en/', '/de/'));
          }}
        >
          <ListItemIcon>
            <CountryBubble
              size={20}
              jurisdiction={'Germany'}
              sx={{
                boxShadow: '0px 0px 0px 3px rgba(230,230,242,0.25)',
              }}
            />
          </ListItemIcon>
          <ListItemText primary={t('GENERAL.LANGUAGES.GERMAN')} />
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            onSelectLng?.('en');
            i18n.changeLanguage('en');
            handleCloseMenu(event);
            navigate(location.pathname.replace('/de/', '/en/'));
          }}
        >
          <ListItemIcon>
            <CountryBubble
              size={20}
              jurisdiction={'United States'}
              sx={{
                boxShadow: '0px 0px 0px 3px rgba(230,230,242,0.25)',
              }}
            />
          </ListItemIcon>
          <ListItemText>{t('GENERAL.LANGUAGES.ENGLISH')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageMenu;
