import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Title from '../../components/text/Title';
import { Section } from '../layout';

interface CompaniesThatUseFidesPropsType {
  title: string;
  type: string;
  darkTheme?: boolean;
}

const CompaniesThatUseFides: React.FC<CompaniesThatUseFidesPropsType> = ({
  title,
  type,
  darkTheme,
}) => {
  const allClients = [
    {
      img: '/assets/images/testimonials/companies-that-use-fides/faber-castell.jpg',
      alt: 'Logo Faber Castell',
    },
    {
      img: '/assets/images/testimonials/companies-that-use-fides/simon-kucher.jpg',
      alt: 'Logo Simon Kucher',
    },
    {
      img: '/assets/images/testimonials/companies-that-use-fides/aareon.jpg',
      alt: 'Logo Aareon',
    },
    {
      img: '/assets/images/testimonials/companies-that-use-fides/all4labels.jpg',
      alt: 'Logo All4Labels',
    },
    {
      img: '/assets/images/testimonials/companies-that-use-fides/materna.jpg',
      alt: 'Logo Materna',
    },
    {
      img: '/assets/images/testimonials/companies-that-use-fides/dle.jpg',
      alt: 'Logo DLE',
    },
    {
      img: '/assets/images/testimonials/companies-that-use-fides/solarisbank.jpg',
      alt: 'Logo Solarisbank',
    },
  ];

  const counselClients = [
    {
      img: '/assets/images/testimonials/companies-that-use-fides/faber-castell.jpg',
      alt: 'Logo Faber Castell',
    },
    {
      img: '/assets/images/testimonials/companies-that-use-fides/simon-kucher.jpg',
      alt: 'Logo Simon Kucher',
    },
    {
      img: '/assets/images/testimonials/companies-that-use-fides/aareon.jpg',
      alt: 'Logo Aareon',
    },
    {
      img: '/assets/images/testimonials/companies-that-use-fides/all4labels.jpg',
      alt: 'Logo All4Labels',
    },
  ];

  let clients = allClients;
  let size = { xs: 5, md: 1.7 };

  switch (type) {
    case 'all':
      clients = allClients;
      size = { xs: 5, md: 1.7 };
      break;
    case 'counsel':
      clients = counselClients;
      size = { xs: 5, md: 2.1 };
      break;
    default:
      clients = allClients;
      size = { xs: 5, md: 1.7 };
      break;
  }

  return (
    <Section fullWidth>
      <Grid item md={11}>
        <Title text={title} darkTheme={darkTheme} center />
      </Grid>

      <Grid container item justifyContent="center" xs={12} md={11} spacing={7} alignItems="center">
        {clients.map((client) => (
          <Grid item xs={size.xs} md={size.md} key={client.alt}>
            <img
              src={client.img}
              alt={client.alt}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                maxHeight: '90px',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default CompaniesThatUseFides;
