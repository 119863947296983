import { FC } from 'react';
import { Grid } from '@mui/material';
import { Section } from '../layout';
import Title from '../../components/text/Title';
import { DetailCard } from '../Cards/Feature.card';

interface FeatureOverviewPropsType {
  title: string;
  features: { title: string; text: string; icon: JSX.Element }[];
  size?: 'medium' | 'big';
}

const FeatureOverview: FC<FeatureOverviewPropsType> = ({ title, features, size }) => {
  return (
    <Section fullWidth bgcolor="#101828">
      <Grid container item justifyContent="center" alignItems="center" mb={4}>
        <Grid item xs={12} md={10}>
          <Title text={title} darkTheme={true} center />
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" alignItems="stretch" spacing={3} color="white">
        {features.map((feature) => (
          <DetailCard feature={feature} size={size} darkTheme={true} key={feature.title} />
        ))}
      </Grid>
    </Section>
  );
};

export default FeatureOverview;
