import { useContext, useEffect } from 'react';
import RowSeperator from '@fides-jam/front/components/separators/HorizontalSeparator';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RouteNames } from '../../../Routes/routeNames';
import { DarkThemeContext } from '../../../components/Context/ThemeContext';
import Column from '../../../components/layout/columns/index.column';
import FadeSlide from '../../../components/layout/container/FadeSlide.container';
import ScreenShotSlideContainer from '../../../components/layout/container/ScreenShotSlide.container';
import Row from '../../../components/layout/rows/Row';
import Header from '../../../components/text/Header';
import Label from '../../../components/text/Label';
import LinkText from '../../../components/text/Link';
import PlainText from '../../../components/text/PlainText';
import SubTitle from '../../../components/text/SubTitle';
import Title from '../../../components/text/Title';
import Footer from '../../../components/views/Footer';
import { PageWrapper } from '../../../components/layout';

const EntityManagementPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(true);
  }, [toggleDarkTheme]);

  return (
    <PageWrapper>
      <Grid
        container
        item
        rowSpacing={isSmall ? 8 : 10}
        justifyContent="center"
        alignItems="center"
      >
        <Row
          sx={{
            mt: {
              xs: 0,
              md: 3,
              lg: 5,
            },
            mb: {
              xs: -3,
              sm: 'unset',
            },
          }}
          rowSpacing={0}
        >
          <FadeSlide
            rowSpacing={2}
            sx={{
              textAlign: 'center',
              py: {
                xs: 0,
                sm: 10,
              },
              px: {
                xs: 0,
                sm: 5,
              },
              mt: 10,
              justifyContent: 'center',
            }}
            direction={'up'}
            once
          >
            <Grid item xs={12} lg={10}>
              <Title
                text={t('ENTITY_MANAGEMENT.TITLE')}
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 45,
                    lg: 55,
                    xl: 68,
                  },
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mt: 4 }}>
              <SubTitle
                text={t('ENTITY_MANAGEMENT.SUBTITLE')}
                darkTheme={darkTheme}
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                  opacity: 0.5,
                }}
              />
            </Grid>

            <Grid item xs={12} lg={10} sx={{ mt: 2 }}>
              <RowSeperator sx={{ width: '100%', m: 0 }} darkTheme={darkTheme} />
            </Grid>
          </FadeSlide>
        </Row>

        <Row columnSpacing={isSmall ? 0 : 10} flexDirection={'row-reverse'}>
          <Column rowSpacing={3}>
            <FadeSlide direction={'up'} rowSpacing={0} once>
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.DASHBOARD.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
            </FadeSlide>
            <ScreenShotSlideContainer
              direction={'right'}
              darkTheme={darkTheme}
              imageUrl={`/assets/images/product/entity-management/dashboard/${i18n.language}.jpg`}
            />
          </Column>
          <Column>
            <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.DASHBOARD.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item xs={12}>
                <Header
                  text={t('ENTITY_MANAGEMENT.SECTIONS.DASHBOARD.TITLE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('ENTITY_MANAGEMENT.SECTIONS.DASHBOARD.TEXT')}
                />
              </Grid>
            </FadeSlide>
          </Column>
        </Row>
        <Row columnSpacing={isSmall ? 0 : 10}>
          <Column rowSpacing={3}>
            <FadeSlide direction={'up'} rowSpacing={0} once>
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.STRUCTURE_CHART.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
            </FadeSlide>
            <ScreenShotSlideContainer
              direction={'left'}
              darkTheme={darkTheme}
              imageUrl={`/assets/images/product/entity-management/organisation/${i18n.language}.jpg`}
            />
          </Column>
          <Column>
            <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.STRUCTURE_CHART.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item xs={12}>
                <Header
                  text={t('ENTITY_MANAGEMENT.SECTIONS.STRUCTURE_CHART.TITLE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('ENTITY_MANAGEMENT.SECTIONS.STRUCTURE_CHART.TEXT')}
                />
              </Grid>
            </FadeSlide>
          </Column>
        </Row>

        <Row columnSpacing={isSmall ? 0 : 10} flexDirection={'row-reverse'}>
          <Column rowSpacing={3}>
            <FadeSlide direction={'up'} rowSpacing={0} once>
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.ENTITIES_LIST.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
            </FadeSlide>
            <ScreenShotSlideContainer
              direction={'right'}
              darkTheme={darkTheme}
              imageUrl={`/assets/images/product/entity-management/entities/${i18n.language}.jpg`}
            />
          </Column>
          <Column>
            <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.ENTITIES_LIST.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item xs={12}>
                <Header
                  text={t('ENTITY_MANAGEMENT.SECTIONS.ENTITIES_LIST.TITLE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('ENTITY_MANAGEMENT.SECTIONS.ENTITIES_LIST.TEXT')}
                />
              </Grid>
            </FadeSlide>
          </Column>
        </Row>

        <Row columnSpacing={isSmall ? 0 : 10}>
          <Column rowSpacing={3}>
            <FadeSlide direction={'up'} rowSpacing={0} once>
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.CAP_TABLE.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
            </FadeSlide>
            <ScreenShotSlideContainer
              direction={'left'}
              darkTheme={darkTheme}
              imageUrl={`/assets/images/product/entity-management/cap-table/${i18n.language}.jpg`}
            />
          </Column>
          <Column>
            <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.CAP_TABLE.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item xs={12}>
                <Header
                  text={t('ENTITY_MANAGEMENT.SECTIONS.CAP_TABLE.TITLE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('ENTITY_MANAGEMENT.SECTIONS.CAP_TABLE.TEXT')}
                />
              </Grid>
              <Grid item>
                <LinkText
                  text={t('GENERAL.LEARN_MORE')}
                  darkTheme={darkTheme}
                  href={RouteNames.capTable}
                />
              </Grid>
            </FadeSlide>
          </Column>
        </Row>

        <Row columnSpacing={isSmall ? 0 : 10} flexDirection={'row-reverse'}>
          <Column rowSpacing={3}>
            <FadeSlide direction={'up'} rowSpacing={0} once>
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.COMMITTEE_MANAGEMENT.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
            </FadeSlide>
            <ScreenShotSlideContainer
              direction={'right'}
              darkTheme={darkTheme}
              imageUrl={`/assets/images/product/entity-management/committees/${i18n.language}.jpg`}
            />
          </Column>
          <Column>
            <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Label
                  text={t('ENTITY_MANAGEMENT.SECTIONS.COMMITTEE_MANAGEMENT.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item xs={12}>
                <Header
                  text={t('ENTITY_MANAGEMENT.SECTIONS.COMMITTEE_MANAGEMENT.TITLE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('ENTITY_MANAGEMENT.SECTIONS.COMMITTEE_MANAGEMENT.TEXT')}
                />
              </Grid>
              <Grid item>
                <LinkText
                  text={t('GENERAL.LEARN_MORE')}
                  darkTheme={darkTheme}
                  href={RouteNames.committees}
                />
              </Grid>
            </FadeSlide>
          </Column>
        </Row>

        <Row sx={{ mt: 3 }}>
          <Footer />
        </Row>
      </Grid>
    </PageWrapper>
  );
};

export default EntityManagementPage;
