import { FC } from 'react';
import FadeSlide from '../layout/container/FadeSlide.container';
import TestimonialColumn from '../layout/columns/Testimonial.column';
import Row from '../layout/rows/Row';
import { Section } from '../layout';

interface TestimonialsSectionPropsType {
  darkTheme?: boolean;
}

const TestimonialsSection: FC<TestimonialsSectionPropsType> = ({ darkTheme }) => {
  return (
    <Section>
      <TestimonialColumn
        index={0}
        backgroundImage={'url("/assets/images/testimonials/1.webp")'}
        darkTheme={darkTheme}
      />
      <TestimonialColumn
        index={1}
        backgroundImage={'url("/assets/images/testimonials/2.webp")'}
        darkTheme={darkTheme}
      />
      <TestimonialColumn
        index={2}
        backgroundImage={'url("/assets/images/testimonials/3.png")'}
        darkTheme={darkTheme}
      />
      <TestimonialColumn
        index={3}
        backgroundImage={'url("/assets/images/testimonials/4.png")'}
        darkTheme={darkTheme}
      />
      <TestimonialColumn
        index={4}
        backgroundImage={'url("/assets/images/testimonials/7.webp")'}
        darkTheme={darkTheme}
      />
      <TestimonialColumn
        index={5}
        backgroundImage={'url("/assets/images/testimonials/6.webp")'}
        darkTheme={darkTheme}
      />
    </Section>
  );
};

export default TestimonialsSection;
