import { Grid } from '@mui/material';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { useContext } from 'react';
import { DarkThemeContext } from '../../../components/Context/ThemeContext';

type PageWrapperPropsType = {
  darkTheme?: boolean;
  centerContent?: boolean;
  children: React.ReactNode;
};

export default function PageWrapper({ centerContent = true, children }: PageWrapperPropsType) {
  const { darkTheme } = useContext(DarkThemeContext);

  return (
    <Grid
      container
      justifyContent={centerContent ? 'center' : 'flex-start'}
      alignItems={centerContent ? 'center' : 'flex-start'}
      sx={{
        transition: 'background 0.6s ease-in-out',
        background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
        overflow: 'hidden',
      }}
    >
      {children}
    </Grid>
  );
}
