import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface LinkComponentPropsType {
  id?: string;
  darkTheme?: boolean;
  size?: 'large' | 'small' | 'medium';
  href?: string;
  localized: boolean;
  fullWidth?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  sx?: any;
}

export default function LinkComponent({
  id,
  href,
  localized,
  fullWidth,
  children,
  onMouseEnter,
  onMouseLeave,
  onClick,
  ...props
}: LinkComponentPropsType) {
  const { i18n } = useTranslation();

  return localized ? (
    <Link
      to={'/' + i18n.language + href}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      id={id}
      style={{
        textDecoration: 'none',
        width: fullWidth ? '100%' : 'unset',
        cursor: 'pointer',
      }}
      {...props}
    >
      {children}
    </Link>
  ) : (
    <a
      id={id}
      href={href}
      style={{
        textDecoration: 'none',
        width: fullWidth ? '100%' : 'unset',
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </a>
  );
}
