import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Row from '../../components/layout/rows/Row';
import PlainText from '../../components/text/PlainText';
import SubTitle from '../../components/text/SubTitle';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import { ThemeColors } from '../../utils/colors';
import { PageWrapper } from '../../components/layout';
import { DarkThemeContext } from '../../components/Context/ThemeContext';

const PrivacyPolicyPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  return (
    <PageWrapper>
      <Grid container item rowSpacing={10} justifyContent="center" alignItems="center">
        <Row
          sx={{
            mt: {
              xs: 0,
              md: 3,
              lg: 5,
            },
          }}
          rowSpacing={0}
        >
          <FadeSlide
            rowSpacing={2}
            sx={{
              textAlign: 'center',
              py: 10,
              px: {
                xs: 2,
                sm: 5,
              },
              mt: 10,
              justifyContent: 'center',
            }}
            direction={'up'}
          >
            <Grid item xs={12} lg={10}>
              <Title
                text={t('PRIVACY.TITLE')}
                mainTitle
                darkTheme={false}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 45,
                    lg: 55,
                    xl: 68,
                  },
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mt: 4 }}>
              <SubTitle
                text={t('PRIVACY.SUBTITLE')}
                darkTheme={false}
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                  opacity: 0.5,
                }}
              />
            </Grid>
          </FadeSlide>
          <FadeSlide
            rowSpacing={2}
            transitionDelay={200}
            direction={'up'}
            sx={{
              justifyContent: 'center',
            }}
          ></FadeSlide>
        </Row>
        <Row columnSpacing={isSmall ? 0 : 10}>
          <FadeSlide direction={'up'} rowSpacing={3}>
            <PlainText darkTheme={darkTheme}>
              {' '}
              <p>
                We (Fides, “us”, “our”) take data protection very serious. This Privacy Policy
                describes how we as controller or processor within the meaning of the General Data
                Protection Regulation ("GDPR") collect and further process your personal data when
                you (“user”, “users” or “you”) use the website www.fides.technology (“Website”)
                and/or the platform available via the Website or the iPad App (“Services” or "Platform").
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.NAME.TITLE')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>

            <Grid>
              <PlainText darkTheme={darkTheme} text={t('PRIVACY.SECTIONS.NAME.TEXT')} />
              <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.DETAILS.ADDRESS')} />
              <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.DETAILS.PHONE')} />
              <PlainText darkTheme={darkTheme}>
                <p>
                  <a href="mailto:hello@fides.technology" target="_blank">
                    hello@fides.technology
                  </a>
                </p>
              </PlainText>

              <PlainText>
                {' '}
                <p></p>
              </PlainText>
              <PlainText darkTheme={darkTheme} text={t('PRIVACY.SECTIONS.DPO.TEXT')} />
              <PlainText darkTheme={darkTheme} text={t('PRIVACY.SECTIONS.DPO.CONTACT')} />
              <PlainText darkTheme={darkTheme} text={t('PRIVACY.SECTIONS.DPO.PHONE')} />
              <PlainText darkTheme={darkTheme}>
                <p>
                  <a href="https://www.isico-datenschutz.de" target="_blank">
                    www.isico-datenschutz.de
                  </a>
                </p>
              </PlainText>
              <PlainText darkTheme={darkTheme} text={t('PRIVACY.SECTIONS.DPO.INFO')} />
            </Grid>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.GENERALINFORMATION.TEXT')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText
                darkTheme={darkTheme}
                text={t('PRIVACY.SECTIONS.GENERALINFORMATION.SCOPE')}
              />
            </Grid>
            <PlainText darkTheme={darkTheme}>
              <p>
                In general, we only process the personal data of our users to the extent necessary
                in order to provide a functioning website with our content and services. The
                processing of personal data regularly only takes place with the consent of the user.
                Exceptions include cases where prior consent technically cannot be obtained and
                where the processing of the data is permitted by law.
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText
                text={t('PRIVACY.SECTIONS.GENERALINFORMATION.RESPONSIBILITY')}
                darkTheme={darkTheme}
              />
            </Grid>
            <PlainText>
              <p>
                When you visit our Website we collect and process data as the{' '}
                <strong>data controller</strong>.<br />
                <br /> When you use our Service we collect data as the{' '}
                <strong>data processor</strong>. We collect data that is either provided by you or
                an entity that uses our Service.
              </p>
            </PlainText>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText
                text={t('PRIVACY.SECTIONS.GENERALINFORMATION.LEGALBASIS')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid>
              <PlainText darkTheme={darkTheme}>
                <p>
                  <strong>Art. 6 (1) (a) GDPR</strong> serves as the legal basis to obtain the
                  consent of the data subject for the processing of their data.
                </p>
                <p>
                  As for the processing of personal data required for the performance of a contract
                  of which the data subject is party, <strong>Art. 6 (1) (b) GDPR</strong> serves as
                  the legal basis. This also applies to processing operations required to carry out
                  pre-contractual activities.
                </p>
                <p>
                  {' '}
                  When it is necessary to process personal data in order to fulfil a legal
                  obligation to which our company is subject, <strong>Art. 6 (1) (c) GDPR </strong>
                  serves as the legal basis.
                </p>
                <p>
                  {' '}
                  If vital interests of the data subject or another natural person require the
                  processing of personal data, <strong>Art. 6 (1) (d) GDPR</strong> serves as the
                  legal basis.
                </p>

                <p>
                  {' '}
                  If the processing of data is necessary to safeguard the legitimate interests of
                  our company or that of a third party, and the fundamental rights and freedoms of
                  the data subject do not outweigh the interest of the former,{' '}
                  <strong>Art. 6 (1) (f) GDPR</strong> will serve as the legal basis for the
                  processing of data.
                </p>
              </PlainText>
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText
                text={t('PRIVACY.SECTIONS.GENERALINFORMATION.DEVICES')}
                darkTheme={darkTheme}
              />
            </Grid>
            <PlainText darkTheme={darkTheme}>
              <p>
                The legal basis for the use of technically necessary cookies and related data
                processing is <strong>§ 25 para. 2 TTDSG</strong> in conjunction with{' '}
                <strong>Art. 6 (1) (f) GDPR</strong>. The processing serves to facilitate your use
                of our website and to be able to offer you our services as desired. Some functions
                of our website also do not work without the use of these cookies and could therefore
                not be offered. Our legitimate interest in processing the cookies results from the
                aforementioned purposes. The cookies are deleted after the session ends (e.g.
                logging out or closing the browser) or after the expiration of a specified duration.
              </p>
              <p>
                The legal basis for the use of technically unnecessary cookies is your consent,
                which you give us via the cookie banner in accordance with{' '}
                <strong>Section 25 (1) TTDSG</strong> in conjunction with{' '}
                <strong>Art. 6 (1) (a) GDPR</strong>. For these services, you can revoke your
                consent at any time with effect for the future or subsequently grant it again by
                accessing your cookie and privacy settings online via our privacy information and
                configuring them accordingly. Alternatively, you can prevent the storage of cookies
                by selecting the appropriate settings on your browser software. Please note that the
                browser settings you make only affect the browser you are using. For further
                detailed information, please refer to the following descriptions.
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText
                text={t('PRIVACY.SECTIONS.GENERALINFORMATION.DATAREMOVAL')}
                darkTheme={darkTheme}
              />
            </Grid>
            <PlainText darkTheme={darkTheme}>
              <p>
                {' '}
                Your personal data will be erased or restricted as soon as the purpose of its
                storage has been accomplished. Additional storage may occur if it was provided for
                by the European or national legislator within the EU regulations, law, or other
                relevant regulations to which we are subject.
                <br />
                <br />
                Restriction or erasure of the data also takes place when the storage period
                stipulated by the aforementioned standards expires, unless there is a need to
                prolong the storage of the data for the purpose of concluding or fulfilling the
                respective contract.
              </p>
            </PlainText>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.DATATYPES')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>
            <Grid>
              <PlainText darkTheme={darkTheme}>
                <p>
                  {' '}
                  We collect data only where necessary. This is a list of types of data we collect:
                </p>
              </PlainText>
            </Grid>
            <table
              style={{
                border: '3px solid grey',
                borderCollapse: 'collapse',
                textAlign: 'left',
                width: '100%',
                tableLayout: 'fixed',
              }}
            >
              <tr>
                <th style={{ border: '1px solid grey', textAlign: 'left', fontSize: '20px' }}>
                  {' '}
                  <PlainText>Types of data </PlainText>
                </th>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    Contact details{' '}
                    <ul>
                      <li>Name</li>
                      <li>Email-address</li>
                      <li>Phone number</li>
                      <li>Work address</li>
                    </ul>
                  </PlainText>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    Individual data{' '}
                    <ul>
                      <li>Gender</li>
                      <li>Appointment dates</li>
                      <li>Profile Picture</li>
                      <li>Birthday</li>

                    </ul>
                  </PlainText>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText> Data on how you use our Website and our Services</PlainText>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    {' '}
                    Data that identifies you
                    <ul>
                      <li>IP-Address</li>
                      <li>Login information</li>
                      <li>Browser type and version</li>
                      <li>Time zone</li>
                      <li>Operating system used</li>
                      <li>Referrer URL</li>
                      <li>Host name of the accessing computer</li>
                      <li>Date and time of server request</li>
                    </ul>
                  </PlainText>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    Company specific data{' '}
                    <ul>
                      <li>Role in company</li>

                    </ul>
                  </PlainText>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    {' '}
                    Shareholder and committee specific data{' '}
                    <ul>
                      <li> Public shareholder information</li>
                      <li>Any personal information you or a user adds about you on the Platform</li>
                    </ul>
                  </PlainText>
                </td>
              </tr>
            </table>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.COLLECT')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>

            <table
              style={{
                border: '3px solid grey',
                borderCollapse: 'collapse',
                textAlign: 'left',
                width: '100%',
                tableLayout: 'fixed',
              }}
            >
              <tr>
                <th style={{ border: '1px solid grey' }}>
                  {' '}
                  <PlainText>Visiting our website </PlainText>
                </th>

                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    Data that identifies you <br />
                    Data on how you use the Website <br /> If you book a demo, download a whitepaper
                    or watch a webinar we additionally collect: Contact details
                  </PlainText>
                </td>
              </tr>

              <tr>
                <th style={{ border: '1px solid grey' }}>
                  {' '}
                  <PlainText>Job applications</PlainText>
                </th>

                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    Contact details
                    <br />
                    Details you share with us
                    <br />
                    Details about your job profile
                  </PlainText>
                </td>
              </tr>

              <tr>
                <th style={{ border: '1px solid grey' }}>
                  {' '}
                  <PlainText>General use of the Platform </PlainText>
                </th>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    Contact details
                    <br />
                    Individual data
                    <br />
                    Company specific data
                    <br />
                    Data that identifies you
                    <br />
                    Any personal data that a user or you adds to the Service
                    <br />
                    Data on how you use the platform{' '}
                  </PlainText>
                </td>
              </tr>

              <tr>
                <th style={{ border: '1px solid grey' }}>
                  {' '}
                  <PlainText>In addition: Shareholder specific use of the Platform </PlainText>
                </th>

                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    Public shareholder information
                    <br />
                    Any personal data that a user or you adds to the Service
                  </PlainText>
                </td>
              </tr>
            </table>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.HOSTING')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>

            <PlainText>
              <p>
                {' '}
                Our servers are hosted by <strong>AWS EMEA</strong> and are located in the AWS
                Region Frankfurt.
              </p>
            </PlainText>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.THIRDPARTIES.TITLE')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>
            <Grid>
              <PlainText darkTheme={darkTheme}>
                <p>
                  {' '}
                  Tech businesses often use third parties to help them host their application,
                  communicate with customers, power their emails etc. We partner with third parties
                  who we believe are the best in their field at what they do.
                </p>
                <p>
                  When we do this, sometimes it is necessary for us to share your data with them in
                  order to get these services to work well. Your data is shared only when strictly
                  necessary and according to the safeguards and good practices detailed in this
                  Privacy Policy.
                </p>
                <p>
                  Where personal data is transferred to a third party in a country outside the UK or
                  European Economic Area (EEA) that is not considered to offer an adequate level of
                  protection for personal data, we ensure that we have in place a binding contract
                  with them containing the current version of the standard contractual clauses with
                  them. Generally, we use the European Commission Standard Contractual Clauses (EU
                  2021/964). We continually monitor this transfer mechanism.
                </p>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.THIRDPARTIES.WEBSITE')}
                  darkTheme={darkTheme}
                />
              </Grid>

              <table
                style={{
                  border: '3px solid grey',
                  borderCollapse: 'collapse',
                  textAlign: 'left',
                  width: '100%',
                  tableLayout: 'fixed',
                }}
              >
                <tr>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Third party provider</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Business seat (transfer to countries outside EU)</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Purpose of data processing</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Legal basis</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Information</PlainText>
                  </th>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Hubspot Ireland Limited</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Ireland</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Book demos, access whitepapers and webinars</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://legal.hubspot.com/privacy-policy?hubs_content=www.hubspot.com/&hubs_content-cta=Privacy%20Policy">
                        Privacy Policy
                      </a>
                      <br />
                      <a href="https://legal.hubspot.com/security">Business safety</a>
                    </PlainText>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Google Ireland Ltd.</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Ireland</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Analytics</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://policies.google.com/privacy?gl=DE&hl=de">Privacy Policy</a>
                      <br />
                      <a href="https://business.safety.google/gdpr/">Business safety</a>
                    </PlainText>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Usercentrics GmbH</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Germany</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Consent Management</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://usercentrics.com/de/datenschutzerklaerung/">
                        Privacy Policy
                      </a>
                    </PlainText>
                  </td>
                </tr>
              </table>

              <Grid>
                <PlainText>
                  <br />
                </PlainText>
              </Grid>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.THIRDPARTIES.PLATFORM')}
                  darkTheme={darkTheme}
                />
              </Grid>

              <table
                style={{
                  border: '3px solid grey',
                  borderCollapse: 'collapse',
                  textAlign: 'left',
                  width: '100%',
                  tableLayout: 'fixed',
                }}
              >
                <tr>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Third party provider</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Business seat (transfer to countries outside EU)</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Purpose of data processing</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Legal basis</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Information</PlainText>
                  </th>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>auth0 Inc.</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>USA</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Login authentication</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://auth0.com/docs/secure/data-privacy-and-compliance#gdpr">
                        Privacy Policy
                      </a>
                    </PlainText>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>AWS EMEA SARL</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Germany</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Cloud Provider</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://aws.amazon.com/de/compliance/data-privacy/">
                        Privacy Policy
                      </a>
                      <br />
                      <a href="https://aws.amazon.com/de/security/">Business safety</a>
                    </PlainText>
                  </td>
                </tr>

                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Docusign International Limited</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Ireland</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Providing e-signing feature </PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://www.docusign.com/privacy">Privacy Policy</a>
                      <br />
                      <a href="https://www.docusign.com/trust">Business safety</a>
                    </PlainText>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Microsoft Ireland Operations Limited</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Ireland</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Selfhosted server for openAi model </PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://privacy.microsoft.com/en-gb/privacystatement">
                        Privacy Policy
                      </a>
                    </PlainText>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Mixpanel Inc.</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>USA</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Analytics</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://mixpanel.com/legal/privacy-policy">Privacy Policy</a>
                      <br />
                      <a href="https://mixpanel.com/legal/security-overview">Business safety</a>
                    </PlainText>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Pinecone Systems Limited</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>USA</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Cloud Provider for embedded vectors</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://www.pinecone.io/privacy/">Privacy Policy</a>
                      <br />
                      <a href="https://www.pinecone.io/security/">Business safety</a>
                    </PlainText>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Productfruits s.r.o.</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Czech republic</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Product tours</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://productfruits.com/policies/privacy">Privacy Policy</a>
                    </PlainText>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Sendgrid - Twilio Ireland Limited</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Ireland</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Sending Email-Notifications</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://www.twilio.com/en-us/legal/privacy">Privacy Policy</a>
                      <br />
                      <a href="https://sendgrid.com/policies/security/">Business safety</a>
                    </PlainText>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Segment - Twilio Ireland Limited </PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Ireland</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Data warehouse</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://www.twilio.com/en-us/legal/privacy">Privacy Policy</a>
                      <br />
                      <a href="https://segment.com/security/">Business safety</a>
                    </PlainText>
                  </td>
                </tr>

                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Twilio Ireland Limited</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Ireland</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Sending Textmessages and Email-Notifications</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://www.twilio.com/en-us/legal/privacy">Privacy Policy</a>
                      <br />
                      <a href="https://www.twilio.com/de-de/security">Business safety</a>
                    </PlainText>
                  </td>
                </tr>
              </table>
              <Grid>
                <PlainText>
                  <br />
                </PlainText>
              </Grid>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.THIRDPARTIES.APPLICATION')}
                  darkTheme={darkTheme}
                />
              </Grid>

              <table
                style={{
                  border: '3px solid grey',
                  borderCollapse: 'collapse',
                  textAlign: 'left',
                  width: '100%',
                  tableLayout: 'fixed',
                }}
              >
                <tr>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Third party provider</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Business seat (transfer to countries outside EU)</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Purpose of data processing</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Legal basis</PlainText>
                  </th>
                  <th style={{ border: '1px solid grey' }}>
                    <PlainText>Information</PlainText>
                  </th>
                </tr>
                <tr>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Personio GmbH</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Germany</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Processing your application</PlainText>
                  </td>
                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                  </td>

                  <td style={{ border: '1px solid grey' }}>
                    <PlainText>
                      <a href="https://www.personio.com/privacy-policy/">Privacy Policy</a>
                      <br />
                      <a href="https://support.personio.de/hc/de/articles/360002838118-Datensicherheit-in-Personio">
                        Business safety
                      </a>
                    </PlainText>
                  </td>
                </tr>
              </table>
            </Grid>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.COOKIES')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.GENERAL.SCOPE')} darkTheme={darkTheme} />
            </Grid>
            <PlainText darkTheme={darkTheme}>
              <p>
                {' '}
                Our Website and our Services use cookies. Cookies are text files that are stored in
                the internet browser or the internet browser on the user’s computer system. If a
                user calls up a website, a cookie can be stored on the user’s operating system.
                These cookies contain a string of characters that allows the browser to be uniquely
                identified when the website is reopened. We use cookies to make our website more
                user-friendly. Some elements of our website require the calling browser to be
                identified even after a page break. The following data is stored and transmitted in
                the cookies:
              </p>
              <ol>
                <li>Search terms entered</li>
                <li>Frequency of page views</li>
                <li>Use of website functions</li>
              </ol>
              <p>
                {' '}
                The user data collected in this manner is pseudonymised by technical measures. It is
                therefore not possible to assign the data to the user accessing the site. The data
                is not stored together with other personal data of the users.
              </p>
            </PlainText>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.GENERAL.PURPOSE')} darkTheme={darkTheme} />
            </Grid>
            <PlainText>
              <p>
                {' '}
                The purpose of using technical cookies is to simplify the use of websites for users.
                Some functions of our website cannot be offered without the use of cookies. These
                require that the browser is recognized even after a page change. We require cookies
                for the following applications:
              </p>
              <ul>
                <li>Adaptation of the display to the device and operating system.</li>
              </ul>
              <p>
                {' '}
                The user data collected by technical cookies are not used to create user profiles.
                The analysis cookies are used for the purpose of improving the quality of our
                website and its content. Through the analysis cookies, we learn how the website is
                used and thus can constantly optimize our offer.
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.GENERAL.LEGALBASIS')} darkTheme={darkTheme} />
            </Grid>
            <PlainText>
              <p>
                {' '}
                The legal basis for the processing of personal data using non-technical cookies and
                plugins is <strong>Art. 6 (1) (b) GDPR</strong>. The legal basis for the processing
                of personal data using technical cookies is <strong>Art. 6 (1) (f) GDPR</strong>,
                legitimate interests.
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.GENERAL.COOKIE')} darkTheme={darkTheme} />
            </Grid>
            <PlainText>
              <p>
                Cookies are stored on the user's device and transmitted to our site by the user.
                Therefore, you as a user also have full control over the use of cookies. You can
                deactivate or restrict the transmission of cookies by changing the settings in your
                Internet browser. Cookies that have already been saved can be deleted at any time.
                This can also be done automatically. If cookies are deactivated for our website, it
                is possible that not all functions of the website can be used to their full extent.
                <br />
                If you use the Safari browser version 12.1 or higher, cookies will be automatically
                deleted after seven days. This also applies to opt-out cookies, which are used to
                prevent the use of tracking mechanisms.
              </p>
            </PlainText>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.PLUGINS.TITLE')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>
            <PlainText darkTheme={darkTheme}>
              <p>
                {' '}
                We use plugins in various places to provide the best experience and service to you.
                We partner with third parties who we believe are the best in their field at what
                they do.
              </p>
              <p>
                When we do this, sometimes it is necessary for us to share your data with them in
                order to get these services to work well. Your data is shared only when strictly
                necessary and according to the safeguards and good practices detailed in this
                Privacy Policy.
              </p>
              <p>
                Where personal data is transferred to a third party in a country outside the UK or
                European Economic Area (EEA) that is not considered to offer an adequate level of
                protection for personal data, we ensure that we have in place a binding contract
                with them containing the current version of the standard contractual clauses with
                them. Generally, we use the European Commission Standard Contractual Clauses (EU
                2021/964). We continually monitor this transfer mechanism.
              </p>
              <p>
                {' '}
                Your personal information will be stored for as long as is necessary to fulfil the
                purpose described in this Privacy Policy or as required by law.
              </p>
              <p>
                You have the right to withdraw your declaration of consent under data protection law
                at any time. The withdrawal of the consent does not affect the lawfulness of the
                processing carried out on the basis of the consent up to the withdrawal. You can
                prevent the collection as well as the processing of your personal data by the
                respective providers by preventing the storage of third-party cookies on your
                computer, using the "Do Not Track" function of a supporting browser, disabling the
                execution of script code in your browser or installing a script blocker such as
                NoScript or Ghostery in your browser. We reserve the right to make changes to this
                privacy policy at any time. The privacy policy will be updated regularly and any
                changes will be automatically posted on our website.
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.PLUGINS.WEBSITE')} darkTheme={darkTheme} />
            </Grid>
            <table
              style={{
                border: '3px solid grey',
                borderCollapse: 'collapse',
                textAlign: 'left',
                width: '100%',
                tableLayout: 'fixed',
              }}
            >
              <tr>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Third party provider</PlainText>
                </th>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Business seat (transfer to countries outside EU)</PlainText>
                </th>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Purpose of data processing</PlainText>
                </th>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Legal basis</PlainText>
                </th>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Information</PlainText>
                </th>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>HubSpot Ireland Limited</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Ireland</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Book demos, access whitepapers and webinars</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                </td>

                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    <a href="https://legal.hubspot.com/privacy-policy?hubs_content=www.hubspot.com/&hubs_content-cta=Privacy%20Policy">
                      Privacy Policy
                    </a>
                  </PlainText>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Usercentrics GmbH</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Ireland</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Manage user consent</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Art. 6 (1) (f) GDPR</PlainText>
                </td>

                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    <a href="https://usercentrics.com/privacy-policy/#:~:text=Usercentrics%20will%20only%20release%20specific,legally%20prohibited%20from%20doing%20so.">
                      Privacy Policy
                    </a>
                  </PlainText>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText> YouTube LLC </PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>USA</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Embed videos to provide meaningful information</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                </td>

                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    <a href="https://www.youtube.com/howyoutubeworks/our-commitments/protecting-user-data/">
                      Privacy Policy
                    </a>
                  </PlainText>
                </td>
              </tr>
            </table>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.PLUGINS.PLATFORM')} darkTheme={darkTheme} />
            </Grid>
            <table
              style={{
                border: '3px solid grey',
                borderCollapse: 'collapse',
                textAlign: 'left',
                width: '100%',
                tableLayout: 'fixed',
              }}
            >
              <tr>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Third party provider</PlainText>
                </th>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Business seat (transfer to countries outside EU)</PlainText>
                </th>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Purpose of data processing</PlainText>
                </th>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Legal basis</PlainText>
                </th>
                <th style={{ border: '1px solid grey' }}>
                  <PlainText>Information</PlainText>
                </th>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>HubSpot Ireland Limited</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>No</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Customer support</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Art. 6 (1) (a) GDPR</PlainText>
                </td>

                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    <a href="https://legal.hubspot.com/privacy-policy?hubs_content=www.hubspot.com/&hubs_content-cta=Privacy%20Policy">
                      Privacy Policy
                    </a>
                  </PlainText>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Product fruits s.r.o.</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>No</PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>User onboarding </PlainText>
                </td>
                <td style={{ border: '1px solid grey' }}>
                  <PlainText>Art. 6 (1) (b) GDPR</PlainText>
                </td>

                <td style={{ border: '1px solid grey' }}>
                  <PlainText>
                    <a href="https://productfruits.com/policies/privacy">Privacy Policy</a>
                  </PlainText>
                </td>
              </tr>
            </table>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.TITLE')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>
            <Grid>
              <PlainText darkTheme={darkTheme}>
                <p>
                  {' '}
                  When your personal data is processed, you are subsequently a data subject in the
                  sense of the GDPR and have the following rights:
                </p>
              </PlainText>

              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.INFORMATION')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText darkTheme={darkTheme}>
                <p>
                  You may request from the data controller to confirm whether your personal data is
                  processed by them. If such processing is the case, you can request the following
                  information from the data controller:
                </p>
                <ul>
                  <li> The purpose for which the personal data is processed;</li>
                  <li> The categories of personal data being processed;</li>
                  <li>
                    {' '}
                    The recipients or categories of recipients to whom the personal data relating to
                    you have been disclosed or are still being disclosed;
                  </li>
                  <li>
                    The planned duration of the storage of your personal data or, if specific
                    information is not available, criteria for determining the duration of storage;
                  </li>
                  <li>
                    The existence of a right to rectification or erasure of personal data concerning
                    you, a right to restriction of processing by the data controller or a right to
                    object to such processing;
                  </li>
                  <li>The existence of a right of appeal to a supervisory authority;</li>
                  <li>
                    {' '}
                    All available information on the source of the data if the personal data is not
                    collected from the data subject;
                  </li>
                  <li>
                    {' '}
                    The existence of automated decision-making including profiling under{' '}
                    <strong>Art. 22 GDPR</strong> and, in certain cases, meaningful information
                    about the data processing system involved, and the scope and intended result of
                    such processing on the data subject.
                  </li>
                </ul>
                <p>
                  {' '}
                  You have the right to request information on whether your personal data will be
                  transmitted to a third country or an international organisation. In this context,
                  you can then request for the appropriate guarantees in accordance with{' '}
                  <strong>Art. 46 GDPR</strong> in connection with the transfer.
                </p>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.RECTIFICATION')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText darkTheme={darkTheme}>
                <p>
                  {' '}
                  You have a right to rectification and/or completion of the data controller, if
                  your processed personal data is incorrect or incomplete. The data controller must
                  correct the data without delay.
                </p>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.RESTRICTION')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText darkTheme={darkTheme}>
                <p>
                  You may request the restriction of the processing of your personal data under the
                  following conditions:
                </p>
                <ul>
                  <li>
                    If you challenge the correctness of your personal data for a period of time that
                    enables the data controller to verify the accuracy of your personal data;
                  </li>
                  <li>
                    The processing is unlawful, and you refuse the erasure of the personal data and
                    instead demand the restriction of the use of the personal data;
                  </li>
                  <li>
                    The representative no longer needs the personal data for the purpose of
                    processing, but you need it to assert, exercise or defend legal claims; or
                  </li>
                  <li>
                    If you have objected to the processing pursuant to{' '}
                    <strong>Art. 21 (1) GDPR</strong> and it is not yet certain whether the
                    legitimate reasons of the data controller outweigh your reasons.
                  </li>
                </ul>
                <p>
                  If the processing of personal data concerning you has been restricted, this data
                  may with the exception of data storage only be used with your consent or for the
                  purpose of asserting, exercising or defending legal claims or protecting the
                  rights of another natural or legal person or for reasons of important public
                  interest, interest to the Union, or a Member State.
                </p>
                <p>
                  {' '}
                  If the processing has been restricted according to the beforementioned conditions,
                  you will be informed by the data controller before the restriction is lifted.
                </p>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.ERASURE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText darkTheme={darkTheme}>
                <p>a) Obligation to erase</p>
                <p>
                  If you request from the data controller to delete your personal data with
                  immediate effect, they are required to do so immediately given that one of the
                  following applies:
                </p>
                <ul>
                  <li>
                    Personal data concerning you is no longer necessary for the purposes for which
                    they were collected or processed.
                  </li>
                  <li>
                    You revoke your consent, to which the processing is allowed pursuant to{' '}
                    <strong>Art. 6 (1) (a) GDPR</strong> or <strong>Art. 9 (2) (a) GDPR </strong>and
                    there is no other legal basis for processing the data
                  </li>
                  <li>
                    According to <strong>Art. 21 (1) GDPR</strong> you object to the processing of
                    the data given that the processing of the data is justified by a legitimate
                    interest, or you object pursuant to Art. 21 (2) GDPR.
                  </li>
                  <li>Your personal data has been processed unlawfully.</li>
                  <li>
                    The act of deleting your personal data will invoke a legal obligation under the
                    Union law or the law of the Member States to which the data controller is
                    subject.
                  </li>
                  <li>
                    Your personal data was collected in relation to information business services
                    offered pursuant to <strong>Art. 8 (1) GDPR</strong>.
                  </li>
                </ul>
                <p>b) Information to third parties</p>
                <p>
                  If the data controller has made your personal data public and has to delete the
                  data pursuant to <strong>Art. 17 (1) GDPR</strong>, they shall take appropriate
                  measures, including technical means, to inform data processors who process the
                  personal data, that a request has been made to delete all links to such personal
                  data or copies or replications of the personal data, taking into account available
                  technology and implementation costs to execute the process.
                </p>

                <p>c) Exceptions</p>
                <p>The right to deletion does not exist if the processing is necessary</p>
                <ul>
                  <li>to exercise the right to freedom of speech and information;</li>
                  <li>
                    to fulfill a legal obligation required by the law of the Union or of the Member
                    States to which the representative is subject, or to perform a task of public
                    interest or in the exercise of public authority delegated to the representative;
                  </li>
                  <li>
                    for reasons of public interest in the field of public health pursuant to{' '}
                    <strong>Art. 9 (2) (h) and (i)</strong> and <strong></strong>Art. 9 (3) GDPR;
                  </li>
                  <li>
                    {' '}
                    for archival purposes of public interest, scientific or historical research
                    purposes or for statistical purposes pursuant to{' '}
                    <strong>Art. 89 (1) GDPR</strong>, to the extent that the law referred to in
                    subparagraph (a) is likely to render impossible or seriously affect the
                    achievement of the objectives of that processing, or
                  </li>

                  <li>to enforce, exercise or defend legal claims.</li>
                </ul>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.RIGHTINFORMATION')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText>
                <p>
                  If you have the right of rectification, erasure or restriction of processing over
                  the data controller, they are obliged to notify all recipients to whom your
                  personal data have been disclosed of the correction or erasure of the data or
                  restriction of processing, unless this proves to be impossible or involves a
                  disproportionate effort. You reserve the right to be informed about the recipients
                  of your data by the data controller.
                </p>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.DATAPORTABILITY')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText>
                <p>
                  You have the right to receive your personal data given to the data controller in a
                  structured, standard and machine-readable format. In addition, you have the right
                  to transfer this data to another person without hindrance by the data controller
                  who was initially given the data, given{' '}
                </p>
                <ol>
                  <li>
                    that the processing is based on a consent in accordance with{' '}
                    <strong>Art. 6 (1) (a) GDPR</strong> or <strong>Art. 9 (2) (a) GDPR</strong> or
                    on a contract in accordance with <strong>Art. 6 (1) (b) GDPR</strong>
                  </li>
                  <li> and the processing is done by automated means.</li>
                </ol>
                <p>
                  In exercising this right, you also have the right to maintain that your personal
                  data relating to you are transmitted directly from one person to another, insofar
                  as this is technically feasible. Freedoms and rights of other persons shall not be
                  affected. The right to data portability does not apply to the processing of
                  personal data necessary for the performance of a task in the public interest or in
                  the exercise of official authority delegated to the data controller.
                </p>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.OBJECT')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText>
                <p>
                  Subjective to your situation, you have, at any time, the right to object against
                  the processing of your personal data pursuant to{' '}
                  <strong>Art. 6 (1) (e) or (f) GDPR</strong>; this also applies to profiling based
                  on these provisions. The data controller will no longer process the personal data
                  concerning you unless he can demonstrate compelling legitimate grounds for
                  processing that outweigh your interests, rights and freedoms, or the processing is
                  for the purpose of enforcing, exercising or defending legal claims. If the
                  personal data relating to you are processed for direct marketing purposes, you
                  have the right to object at any time to the processing of your personal data in
                  regard to such advertising; this also applies to profiling insofar as it is
                  associated with direct mail. If you object to processing for direct marketing
                  purposes, your personal data will no longer be processed for these purpose. You
                  have the possibility, in connection with the use of information society services -
                  notwithstanding Directive 2002/58/EC - to exercise your right to object by means
                  of automated procedures using technical specifications.
                </p>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.REVOKE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText>
                <p>
                  You have the right to withdraw your consent at any time. The revocation of consent
                  does not affect the legality of the processing carried out on the basis of the
                  consent until the revocation.
                </p>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.PROFILING')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText>
                <p>
                  You have the right not to subject to a decision based solely on automated
                  processing including profiling that will have legal effect or affect you in a
                  similar manner. This does not apply if the decision
                </p>
                <ul>
                  <li>
                    {' '}
                    is required for the conclusion or execution of a contract between you and the
                    data controller,
                  </li>
                  <li>
                    {' '}
                    is permitted by the Union or Member State legislation to which the data
                    controller is subject, and where such legislation contains appropriate measures
                    to safeguard your rights and freedoms and legitimate interests, or
                  </li>
                  <li> with your expressed consent.</li>
                </ul>

                <p>
                  {' '}
                  However, these decisions must not be based on special categories of personal data
                  under <strong>Art. 9 (1) GDPR</strong>, unless{' '}
                  <strong>Art. 9 (2) (a) or (g) GDPR</strong> applies and reasonable measures have
                  been taken to protect the rights and freedoms as well as your legitimate
                  interests. With regard to the cases referred to in (1) and (3), the data
                  controller shall take appropriate measures to uphold your rights and freedoms as
                  well as your legitimate interests, including the right to obtain assistance from
                  the data controller or their representative, to express your opinion on the
                  matter, and to contest the decision.
                </p>
              </PlainText>
              <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                <PlainText
                  text={t('PRIVACY.SECTIONS.RIGHTSOFSUBJECT.COMPLAIN')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <PlainText>
                <p>
                  {' '}
                  Without prejudice to any other administrative or judicial remedy, you shall have
                  the right to complain to a supervisory authority, in the Member State of their
                  residence, place of work or place of alleged infringement, if you believe that the
                  processing of the personal data concerning you violates the GDPR. The supervisory
                  authority to which the complaint has been submitted shall inform the complainant
                  of the status and results of the complaint, including the possibility of a
                  judicial remedy pursuant to <strong>Art. 78 GDPR</strong>.
                </p>
              </PlainText>
            </Grid>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.PRESENCEMEDIA')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>

            <PlainText>
              <p>
                {' '}
                <strong>Instagram:</strong> Instagram, Part of Facebook Ireland Ltd., 4 Grand Canal
                Square Grand Canal Harbour, Dublin 2 Ireland
              </p>

              <p>
                On our company website we provide information and offer Instagram users the
                possibility of communication. If you carry out an action on our Instagram company
                website (e.g. comments, contributions, likes etc.), you may make personal data (e.g.
                clear name or photo of your user profile) public. However, as we generally or to a
                large extent have no influence on the processing of your personal data by Instagram,
                the company jointly responsible for our company appearance, we cannot make any
                binding statements regarding the purpose and scope of the processing of your data.
                Our corporate presence in social networks is used for communication and information
                exchange with (potential) customers. In particular, we use the company’s presence
                for information about our products and services. Publications on the company
                appearance can contain the following content:
              </p>
              <ol>
                {' '}
                <li>Information about products</li>
                <li>Information about services</li>
                <li>Advertisement</li>
                <li>Customer contact</li>
              </ol>

              <p>
                Every user is free to publish personal data through activities. The legal basis for
                data processing is <strong>Art. 6 (1) (a) GDPR</strong>.
              </p>
              <p>
                The data generated on the company appearance are not stored in our own systems. You
                can object at any time to the processing of your personal data that we collect
                within the framework of your use of our Instagram corporate web presence and assert
                your rights as a data subject mentioned under IV. of this privacy policy. Please
                send us an informal Email to hello@fides.technology. For further information on the
                processing of your personal data by Instagram and the corresponding objection
                options, please click{' '}
                <a href="https://help.instagram.com/519522125107875" target="_blank">
                  here
                </a>
                .
              </p>
              <p>
                {' '}
                <strong>YouTube:</strong>YouTube LLC, 901 Cherry Ave, San Bruno, CA 94066, United
                States.
              </p>

              <p>
                On our company profile we provide information and offer YouTube users the
                possibility of communication. If you carry out an action on our YouTube company
                profile (e.g., comments, contributions, likes etc.), you may make personal data
                (e.g., clear name or photo of your user profile) public.
              </p>
              <p>
                In order to ensure appropriate guarantees for the protection of the transfer and
                processing of personal data outside the EU, the transfer of data to and processing
                of data by YouTube is carried out on the basis of appropriate guarantees according
                to Art. 46 ff DSGVO, in particular by concluding so-called standard data protection
                clauses according to Art. 46 para. 2 lit. c DSGVO.
              </p>

              <p>
                Our corporate presence in social networks is used for communication and information
                exchange with (potential) customers. In particular, we use the company presence for:
                Information and updates about the product and the company, its employees, its
                founders and investors themselves, industry news in the area of governance and
                compliance, announcements of webinars, events, material and blogposts. In this
                regard, publications about the corporate presence may include the following content:
              </p>
              <ol>
                <li>Information about products</li>
                <li>Information about services</li>
                <li> Advertising</li>
                <li>Customer contact</li>
              </ol>

              <p>
                In this context, each user is free to publish personal data through activities. The
                legal basis for data processing is <strong>Art. 6 (1) (f) GDPR</strong>. The data
                generated by the company presence is not stored in our own systems. You can object
                at any time to the processing of your personal data that we collect in the course of
                your use of our YouTube corporate presence and assert your data subject rights
                mentioned under IV. of this privacy policy. To do so, send us an informal email to
                hello@fides.technology. You can object at any time to the processing of your
                personal data that we collect within the framework of your use of our YouTube
                corporate web profile and assert your rights as a data subject mentioned under IV.
                of this privacy policy. For further information on the processing of your personal
                data by YouTube and the corresponding objection options, please click here:
                https://policies.google.com/privacy?gl=DE&hl=en
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.NETWORKS')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>

            <PlainText>
              <p>
                {' '}
                <strong>1. Presence in professional oriented networks</strong>
              </p>
              <p>
                We use corporate presences on professionally oriented networks. We maintain a
                corporate presence on the following professionally oriented networks:
              </p>
              <p>
                LinkedIn: LinkedIn, Unlimited Company Wilton Place, Dublin 2, Irleand
                <br />
                On our site we provide information and offer users the possibility of communication.
                <br />
                The corporate presence is used for job applications, information/PR and active
                sourcing.
                <br />
                We do not have any information on the processing of your personal data by the
                companies jointly responsible for the corporate presence. Further information can be
                found in the privacy policy of:
                <a
                  href="https://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv"
                  target="_blank"
                >
                  LinkedIn
                </a>
                .
              </p>

              <p>
                If you carry out an action on our company website (e.g. comments, posts, likes,
                etc.), it is possible that you make personal data (e.g. clear name or photo of your
                user profile) public.
              </p>

              <p>
                <strong>2. Legal basis for data processing</strong>
              </p>
              <p>
                The legal basis for the processing of your data in connection with the use of our
                corporate web presence is <strong>Art. 6 (1) (f) GDPR</strong>.
              </p>

              <p>
                If you carry out an action on our company website (e.g. comments, posts, likes,
                etc.), it is possible that you make personal data (e.g. clear name or photo of your
                user profile) public.
              </p>

              <p>
                <strong>3. Purpose of the data processing</strong>
              </p>
              <p>
                {' '}
                Our corporate web presence serves to inform users about our services. Every user is
                free to publish personal data through activities.
              </p>

              <p>
                <strong>4. Duration of storage</strong>
              </p>
              <p>
                We store your activities and personal data published via our corporate web presence
                until you revoke your consent. In addition, we comply with the statutory retention
                periods.
              </p>

              <p>
                <strong>5. Objection and removal</strong>
              </p>
              <p>
                You can object at any time to the processing of your personal data which we collect
                within the scope of your use of our corporate web presence and assert your rights as
                a data subject mentioned under IV. of this privacy policy. To do so, send us an
                informal email to the email address stated in this data protection declaration.
                Further information on this provided by LinkedIn can be found{' '}
                <a href="https://de.linkedin.com/legal/l/dpa" target="_blank">
                  here
                </a>
                .
              </p>
            </PlainText>

            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <SubTitle
                text={t('PRIVACY.SECTIONS.CONTENTDELIVERY')}
                darkTheme={darkTheme}
                sx={{ color: darkTheme ? '#FFF' : ThemeColors.light.TITLE }}
              />
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.GENERAL.SCOPE')} darkTheme={darkTheme} />
            </Grid>
            <PlainText>
              <p>
                On our website we use functions of the content delivery network CloudFlare of
                CloudFlare Germany GmbH, Rosental 7, 80331 Munich, Germany (hereinafter referred to
                as CloudFlare). A Content Delivery Network (CDN) is a network of regionally
                distributed servers connected via the Internet to deliver content, especially large
                media files such as videos. CloudFlare offers web optimization and security services
                that we use to improve the load times of our website and to protect it from misuse.
                When you visit our website you will be connected to the servers of CloudFlare, e.g.
                to retrieve content. This allows personal data to be stored and evaluated in server
                log files, the user's activity (e.g. which pages have been visited) and device and
                browser information (e.g. IP address and operating system). Further information on
                the collection and storage of data by CloudFlare can be found{' '}
                <a href="https://www.cloudflare.com/en-gb/privacypolicy/" target="_blank">
                  here
                </a>
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.GENERAL.PURPOSE')} darkTheme={darkTheme} />
            </Grid>
            <PlainText>
              <p>
                {' '}
                The use of CloudFlare's features serves to deliver and accelerate online
                applications and content.
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.GENERAL.LEGALBASIS')} darkTheme={darkTheme} />
            </Grid>
            <PlainText>
              <p>
                {' '}
                The data is collected on the basis of <strong>Art. 6 (1) (f) GDPR</strong>. The
                website operator has a legitimate interest in the technically error-free
                presentation and optimization of his website -and the server log files are therefore
                recorded.
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.GENERAL.STORAGE')} darkTheme={darkTheme} />
            </Grid>
            <PlainText>
              <p>
                {' '}
                Your personal information will be retained for as long as necessary to fulfil the
                purposes described in this Privacy Policy or as required by law.
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRIVACY.SECTIONS.GENERAL.OBJECTION')} darkTheme={darkTheme} />
            </Grid>
            <PlainText>
              <p>
                {' '}
                Information about objection and removal options regarding CloudFlare can be found{' '}
                <a href="https://www.cloudflare.com/en-gb/privacypolicy/" target="_blank">
                  here
                </a>{' '}
              </p>
            </PlainText>
          </FadeSlide>
        </Row>

        <Row sx={{ mt: 3 }}>
          <Footer />
        </Row>
      </Grid>
    </PageWrapper>
  );
};

export default PrivacyPolicyPage;
