import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { PageWrapper, Section } from '../../components/layout';
import Row from '../../components/layout/rows/Row';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import SubTitle from '../../components/text/SubTitle';
import PlainText from '../../components/text/PlainText';
import HButton from '../../components/buttons/Highlight.button';
import CallToAction from '../../components/sections/cta/CallToAction.section';
import TestimonialCard from '../../components/Cards/Testimonial.card';
import CompaniesThatUseFides from '../../components/sections/CompaniesThatUseFides.section';
import FeatureHighlight from '../../components/sections/FeatureHighlight.section';
import FeatureOverview from '../../components/sections/FeatureOverview.section';
import FeatureScreenshotsSection from '../../components/sections/feature-screenshots/FeatureScreenshots.section';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';
import { ReactComponent as AccelerateProcesses } from '/assets/images/icons/accelerate-processes.svg';
import { ReactComponent as IncreaseLegalCertainty } from '/assets/images/icons/increase-legal-certainty.svg';
import { ReactComponent as CreateTransparency } from '/assets/images/icons/create-transparency.svg';
import { ReactComponent as Route } from '/assets/images/icons/route.svg';
import { ReactComponent as Flag } from '/assets/images/icons/flag.svg';
import { ReactComponent as Gathering } from '/assets/images/icons/gathering.svg';
import { ReactComponent as CheckmarkPerson } from '/assets/images/icons/checkmark-person.svg';
import { ReactComponent as Calendar } from '/assets/images/icons/calendar.svg';
import { ReactComponent as FindInPage } from '/assets/images/icons/find-in-page.svg';

const Mittelstand: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);

  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);

  const masterChallanges = [
    {
      title: t('MITTELSTAND.MASTER_CHALLANGES.SECTIONS.ACCELERATE_PROCESSES.LABEL'),
      text: t('MITTELSTAND.MASTER_CHALLANGES.SECTIONS.ACCELERATE_PROCESSES.TEXT'),
      icon: <AccelerateProcesses />,
    },
    {
      title: t('MITTELSTAND.MASTER_CHALLANGES.SECTIONS.INCREASE_LEGAL_CERTAINTY.LABEL'),
      text: t('MITTELSTAND.MASTER_CHALLANGES.SECTIONS.INCREASE_LEGAL_CERTAINTY.TEXT'),
      icon: <IncreaseLegalCertainty />,
    },
    {
      title: t('MITTELSTAND.MASTER_CHALLANGES.SECTIONS.CREATE_TRANSPARENCY.LABEL'),
      text: t('MITTELSTAND.MASTER_CHALLANGES.SECTIONS.CREATE_TRANSPARENCY.TEXT'),
      icon: <CreateTransparency />,
    },
  ];

  // const testimonials = [
  //   {
  //     company: {
  //       img: '/assets/images/mittelstand/testimonials/lotto24.png',
  //       industry_en: 'eCommerce',
  //       industry_de: 'eCommerce',
  //     },
  //     person: {
  //       name: 'Carsten Muth',
  //       img: '/assets/images/mittelstand/testimonials/carsten-muth.png',
  //       position_en: 'Chief Legal Officer, Regulation, Compliance & HR',
  //       position_de: 'Vorstand Recht, Regulierung, Compliance & HR',
  //     },
  //     testimonial_en:
  //       '"Any support that is provided by tools helps a lot; especially with meeting invitations, meeting documentation, taking minutes of committee discussions and meetings."',
  //     testimonial_de:
  //       '"Jede Unterstützung, die toolseitig erfolgt, hilft sehr; gerade bei Sitzungseinladungen, Sitzungsdokumentation, Protokollierung von Gremiendiskussionen und Sitzungen.”',
  //     change: {
  //       before_fides_en: [
  //         'Manual preparation and follow-up of board meetings.',
  //         'Enormous time commitment for distributing and filing information and documents.',
  //       ],
  //       before_fides_de: [
  //         'Manuelle Vor- und Nachbereitung von Aufsichtsratssitzungen',
  //         'Enormer Zeitaufwand durch die Distribution und Ablage von Informationen und Unterlagen',
  //       ],
  //       after_fides_en: [
  //         'Faster processes for board meetings.',
  //         'Enhanced communication with board members, ensuring reliable document distribution and filing.',
  //       ],
  //       after_fides_de: [
  //         'Beschleunigte Abläufe rund um Aufsichtsratssitzungen',
  //         'Erleichterter Austausch mit Aufsichtsratsmitgliedern, zuverlässige Distribution und Ablage von Unterlagen',
  //       ],
  //     },
  //   },
  // ];

  const testimonials = [
    {
      company: {
        img: '/assets/images/testimonials/simon-kucher/simon-kucher.webp',
        industry_en: 'Consulting Firm',
        industry_de: 'Unternehmensberatung',
      },
      person: {
        name: 'Dirk Sievert',
        img: '/assets/images/testimonials/simon-kucher/dirk-sievert.webp',
        position_en: 'General Counsel & Head of Compliance',
        position_de: 'General Counsel & Head of Compliance',
      },
      testimonial_en:
        '"Working together has never been easier. Our entire team, including senior members of our company, can access the same up-to-date information in real-time, regardless of their location. This has enhanced our communication, reduced the risk of errors, and improved our governance and transparency."',
      testimonial_de:
        '"Kollaboration war noch nie so einfach. Mit Fides können unser gesamtes Team und alle anderen leitenden Mitarbeiter unserer Firma in Echtzeit auf dieselben aktuellen Informationen zugreifen, egal wo sie sich befinden. Das hat unsere Kommunikation verbessert, das Fehlerrisiko verringert und unsere Governance und Transparenz verbessert.”',
      change: {
        before_fides_en: [
          'Decentralized storage of corporate data and documents',
          'Time-consuming coordination of resolutions with +180 shareholders',
        ],
        before_fides_de: [
          'Dezentrale Ablage von Unternehmensdaten und -Dokumenten',
          'Enormer Zeitaufwand durch die Distribution und Ablage von Informationen und Unterlagen',
        ],
        after_fides_en: [
          'Centralized storage and maintenance of data from global subsidiaries',
          'Efficiently implement and document decisions made by all global partners',
        ],
        after_fides_de: [
          'Zentrale Ablage und Pflege von Daten der globalen Tochtergesellschaften',
          'Effiziente Durchführung & Dokumentation von Entscheidungen aller Partner',
        ],
      },
    },
  ];

  const features = [
    {
      title: t('MITTELSTAND.FEATURES.SUBSIDARY_INFORMATION.TITLE'),
      text: t('MITTELSTAND.FEATURES.SUBSIDARY_INFORMATION.TEXT'),
      icon: <FindInPage />,
    },
    {
      title: t('MITTELSTAND.FEATURES.CALENDAR_REMINDERS.TITLE'),
      text: t('MITTELSTAND.FEATURES.CALENDAR_REMINDERS.TEXT'),
      icon: <Calendar />,
    },
    {
      title: t('MITTELSTAND.FEATURES.POA_PROCURATION.TITLE'),
      text: t('MITTELSTAND.FEATURES.POA_PROCURATION.TEXT'),
      icon: <CheckmarkPerson />,
    },
    {
      title: t('MITTELSTAND.FEATURES.HOLD_MEETINGS.TITLE'),
      text: t('MITTELSTAND.FEATURES.HOLD_MEETINGS.TEXT'),
      icon: <Gathering />,
    },
    {
      title: t('MITTELSTAND.FEATURES.PASS_RESOLUTIONS.TITLE'),
      text: t('MITTELSTAND.FEATURES.PASS_RESOLUTIONS.TEXT'),
      icon: <Flag />,
    },
    {
      title: t('MITTELSTAND.FEATURES.COLLABORATION.TITLE'),
      text: t('MITTELSTAND.FEATURES.COLLABORATION.TEXT'),
      icon: <Route />,
    },
  ];

  const featureScreenshots = [
    {
      id: 'INFORMATION_MANAGEMENT',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.INFORMATION_MANAGEMENT.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.INFORMATION_MANAGEMENT.DESCRIPTION'),
      active: true,
      img:
        '/assets/images/mittelstand/feature-screenshots/information-management/' +
        i18n.language +
        '.webp',
    },
    {
      id: 'BOARD_COMMITTEE_WORK',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.BOARD_COMMITTEE_WORK.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.BOARD_COMMITTEE_WORK.DESCRIPTION'),
      active: false,
      img:
        '/assets/images/mittelstand/feature-screenshots/board-committee-work/' +
        i18n.language +
        '.webp',
    },
    {
      id: 'LEGAL_CERTAINTY',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.LEGAL_CERTAINTY.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.LEGAL_CERTAINTY.DESCRIPTION'),
      active: false,
      img:
        '/assets/images/mittelstand/feature-screenshots/legal-certainty/' + i18n.language + '.webp',
    },
    {
      id: 'COLLABORATION',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.COLLABORATION.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.COLLABORATION.DESCRIPTION'),
      active: false,
      img:
        '/assets/images/mittelstand/feature-screenshots/collaboration/' + i18n.language + '.webp',
    },
    {
      id: 'AUDIT_TRAIL_FOR_DOCUMENTATION',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.AUDIT_TRAIL_FOR_DOCUMENTATION.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.AUDIT_TRAIL_FOR_DOCUMENTATION.DESCRIPTION'),
      active: false,
      img:
        '/assets/images/mittelstand/feature-screenshots/audit-trail-for-documentation/' +
        i18n.language +
        '.webp',
    },
  ];

  const titleStyle = {
    fontSize: {
      xs: 30,
      sm: 38,
      lg: 48,
    },
    textAlign: 'center',
  };

  return (
    <PageWrapper>
      <Section flexDirection="row-reverse" expanded>
        <Grid item xs={12} md={6}>
          <img
            src={'/assets/images/mittelstand/hero/' + i18n.language + '.webp'}
            alt="Hero Mittelstand"
            style={{
              width: '100%',
              maxHeight: '500px',
              objectFit: 'contain',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item container rowSpacing={3}>
            <Title
              text={t('MITTELSTAND.HERO.TITLE')}
              mainTitle
              darkTheme={darkTheme}
              sx={{
                mb: 2,
              }}
            />
            <SubTitle
              text={t('MITTELSTAND.HERO.SUBTITLE')}
              sx={{
                fontSize: {
                  xs: 30,
                  sm: 30,
                  lg: 30,
                  xl: 38,
                },
                mb: 1,
                pr: 2,
              }}
              darkTheme={darkTheme}
            />
            <PlainText text={t('MITTELSTAND.HERO.TEXT')} darkTheme={darkTheme} sx={{ pr: 6 }} />
            <Grid item xs={12} onClick={() => setShowContactPopup(true)}>
              <HButton size="large" sx={{ px: 5 }} darkTheme={true}>
                {t('GENERAL.BOOK_MEETING')}
              </HButton>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <FeatureHighlight
        title={t('MITTELSTAND.MASTER_CHALLANGES.TITLE')}
        features={masterChallanges}
        darkTheme={darkTheme}
      />

      <CompaniesThatUseFides
        title={t('MITTELSTAND.INDUSTRY_LEADERS.TITLE')}
        type="all"
        darkTheme={darkTheme}
      />

      <TestimonialCard
        testimonials={testimonials}
        darkTheme={darkTheme}
        bgcolorWrapper="#F3F3F3"
        bgcolor="white"
      />

      <Section>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          onClick={() => setShowContactPopup(true)}
        >
          <HButton size="large" sx={{ px: 5 }} darkTheme={true}>
            {t('GENERAL.BOOK_PERSONAL_MEETING')}
          </HButton>
        </Grid>
      </Section>

      <FeatureOverview title={t('MITTELSTAND.FEATURES.TITLE')} features={features} />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        pt={18}
        overflow="hidden"
        zIndex={1}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          paddingTop="100px"
          height="70%"
          width="80%"
          position="absolute"
          maxWidth="1200px"
          maxHeight="800px"
        >
          <div
            style={{
              background: 'rgb(0, 109, 165)',
              borderRadius: '50%',
              WebkitFilter: 'blur(60px)',
              filter: 'blur(60px)',
              opacity: 0.05,
              overflow: 'visible',
              width: '100%',
              height: '100%',
            }}
          />
        </Grid>

        <Grid container item xs={7} justifyContent="center" mb={10}>
          <Grid item>
            <Title
              text={t('MITTELSTAND.FEATURE_SCREENSHOTS.TITLE')}
              darkTheme={false}
              sx={{
                fontSize: {
                  xs: 45,
                },
                textAlign: {
                  xs: 'center',
                  sm: 'center',
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} justifyContent="center" py={3} px={1}>
          <Grid container display={{ xs: 'none', md: 'flex' }} maxWidth="1400px">
            <FeatureScreenshotsSection featureScreenshots={featureScreenshots} type="desktop" />
          </Grid>
          <Grid container display={{ xs: 'flex', md: 'none' }} justifyContent="center">
            <FeatureScreenshotsSection featureScreenshots={featureScreenshots} type="mobile" />
          </Grid>
        </Grid>
      </Grid>

      <CallToAction
        header={t('MITTELSTAND.CTA.TITLE')}
        subHeader={t('MITTELSTAND.CTA.SUBTITLE')}
        link={() => setShowContactPopup(true)}
        buttonText={t('GENERAL.BOOK_MEETING')}
      />

      {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}

      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </PageWrapper>
  );
};

export default Mittelstand;
