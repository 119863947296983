import { Grid } from '@mui/material';
import { FC } from 'react';
import Header from '../text/Header';
import PlainText from '../text/PlainText';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';

interface DetailCardPropsType {
  darkTheme: boolean;
  size?: 'medium' | 'big';
  feature: {
    title: string;
    text: string;
    icon: JSX.Element;
  };
}

interface TitleOnlyCardPropsType {
  darkTheme: boolean;
  feature: {
    title: string;
    icon: JSX.Element;
  };
}

export const DetailCard: FC<DetailCardPropsType> = ({ feature, size = 'medium' }) => {
  const gridSize = {
    desktop: size === 'big' ? 6 : 4,
    tablet: size === 'big' ? 8 : 6,
  };

  return (
    <Grid item container xs={12} sm={gridSize.tablet} md={gridSize.desktop}>
      <Grid
        item
        container
        bgcolor="#212B40"
        width="100%"
        p={4}
        textAlign="center"
        rowGap={2}
        justifyContent="center"
        borderRadius="10px"
        alignContent="center"
        minHeight="270px"
      >
        <Grid item xs={2.25} maxHeight="50px">
          {feature.icon}
        </Grid>
        <Grid item xs={12}>
          <PlainText opacity bold text={feature.title} darkTheme={true} />
        </Grid>
        <Grid item xs={12}>
          <PlainText text={feature.text} darkTheme={true} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const TitleOnlyCard: FC<TitleOnlyCardPropsType> = ({ feature }) => {
  return (
    <Grid item container xs={12} sm={6} md={4}>
      <Grid
        item
        container
        bgcolor={AppSignUpColors.PRIMARY_DARKER}
        border={AppSignUpColors.PRIMARY_DARKER_BORDER}
        borderRadius="20px"
        width="100%"
        p={4}
        textAlign="center"
        rowGap={5}
        justifyContent="center"
        alignContent="center"
        minHeight="270px"
      >
        <Grid item xs={2.25} maxHeight="50px">
          {feature.icon}
        </Grid>
        <Grid item xs={12}>
          <Header
            text={feature.title}
            darkTheme={true}
            sx={{
              fontSize: {
                xs: 22,
                sm: 27,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
