import { Layer } from '@fides-jam/front/components/Layer';
import { Grid, SxProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedSlide from '../../layout/container/AnimatedSlide.container';
import MeetingRow from './meeting.row';
interface MeetingsPropsType {
  sx?: SxProps;
  animate?: boolean;
}

const Meetings: FC<MeetingsPropsType> = ({ sx, animate }) => {
  const { t, i18n } = useTranslation();
  return (
    <Layer
      sx={{
        overflow: 'visible',
        top: {
          xs: '-115px',
          sm: '30px',
          md: '10px',
          lg: '40px',
        },
        bottom: 'unset',
        left: {
          xs: '-115px',
          sm: '-50px',
          md: '-75px',
          lg: '-40px',
        },
        right: 'unset',
      }}
    >
      <AnimatedSlide
        animate={animate}
        transitionDelay={600}
        direction={'right'}
        sx={{
          py: 3,
          px: 2.5,
          position: 'relative',
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          //boxShadow: `0px 0px 34px 5px ${ThemeColors.light.PRIMARY}`,
          background: '#FFF',
          borderRadius: {
            xs: '20px',
            md: '25px',
          },
          width: '450px',
          height: '290px',
        }}
      >
        <Grid
          container
          sx={{
            opacity: {
              xs: 0.5,
              sm: 0.8,
            },
            fontWeight: '600',
            fontSize: '17px',
            mb: 0.8,
            ml: 1,
          }}
        >
          <Grid sx={{ opacity: 0.4, mb: 1, pl: 0.5 }}> {t('RESOLUTIONCELL.MEETINGS')}</Grid>
          <MeetingRow
            animate={animate}
            name={'Quaterly Shareholder Meeting'}
            position={'Shareholder Committee'}
            day={'02.'}
            month={t('RESOLUTIONCELL.JANURARY')}
            line={false}
            index={0}
          />
          <MeetingRow
            animate={animate}
            name={'Investment Strategy'}
            position={'Investment Committee'}
            day={'15.'}
            month={'August'}
            line={false}
            index={1}
          />
          <MeetingRow
            animate={animate}
            name={'Technology Forecast'}
            position={'Technology Committee'}
            day={'28.'}
            month={t('RESOLUTIONCELL.OCTOBER')}
            line={false}
            index={2}
          />
          <Layer
            sx={{
              right: 60,
              left: 60,
              background: {
                xs: 'unset',
                sm: 'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)',
              },
            }}
          />
        </Grid>
      </AnimatedSlide>
    </Layer>
  );
};

export default Meetings;
