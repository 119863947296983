import { FC, useMemo } from 'react';
import { Box, Grid, SxProps } from '@mui/material';
import * as React from 'react';
import Label from '../../text/Label';
import PlainText from '../../text/PlainText';
import { ThemeColors } from '../../../utils/colors';
import { iOSColors } from '@fides-jam/utils/theme/colors';

import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GroupsIcon from '@mui/icons-material/Groups';

export enum IconTypes {
  Warning = 'WARNING',
  Money = 'MONEY',
  Time = 'TIME',
  Collaboration = 'COLLABORATION',
}

interface BenefitColumnPropsType {
  darkTheme?: boolean;
  title: string;
  text: string;
  tag?: string;
  index?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  small?: boolean;
  type?: IconTypes;
  sx?: SxProps;
}

const BenefitColumn: FC<BenefitColumnPropsType> = ({
  darkTheme,
  title,
  text,
  tag,
  index,
  xs,
  sm,
  md,
  lg,
  xl,
  small,
  type,
  children,
  sx,
}) => {
  const bGColor = useMemo(
    () => (darkTheme ? ThemeColors.dark.PRIMARY : ThemeColors.light.PRIMARY),
    [darkTheme]
  );
  const color = useMemo(() => (darkTheme ? '#000' : '#FFF'), [darkTheme]);
  return (
    <Grid
      container
      item
      xs={xs || 12}
      sm={sm || 6}
      md={md || 4}
      lg={lg || 4}
      xl={xl || 4}
      sx={{
        mt: 5,
      }}
    >
      <Grid sx={{ position: 'relative' }}>
        <Grid
          item
          container
          flexDirection={'column'}
          justifyContent="center"
          alignItems="center"
          sx={{
            transition: 'background 0.5s ease-in-out',
            background: darkTheme ? 'rgba(255,255,255,0.03)' : 'rgba(0,100,255,0.04)',
            borderRadius: '8px',
            textAlign: 'center',
            px: 5,
            py: 8,
            height: '100%',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              transition: 'background 700ms ease-in-out',
              WebkitTransition: 'background 700ms ease-in-out',
              MsTransition: 'background 700ms ease-in-out',
              MozTransition: 'background 700ms ease-in-out',
              OTransition: 'background 700ms ease-in-out',
              transitionDuration: '700ms',
              WebkitTransitionDuration: '700ms',
              MsTransitionDuration: '700ms',
              MozTransitionDuration: '700ms',
              OTransitionDuration: '700ms',
              background: darkTheme ? 'rgba(255,255,255,0.03)' : 'rgba(0,100,255,0.04)',
              height: '80px',
              width: '80px',
              borderRadius: '50%',
              top: '-40px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {type === IconTypes.Warning && (
              <ErrorIcon
                sx={{
                  transition: 'color 0.5s ease-in-out',
                  color: iOSColors.red,
                  fontSize: '45px',
                }}
              />
            )}

            {type === IconTypes.Money && (
              <MonetizationOnIcon
                sx={{
                  transition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  WebkitTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  MsTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  MozTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  OTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  transitionDuration: '500ms',
                  WebkitTransitionDuration: '500ms',
                  MsTransitionDuration: '500ms',
                  MozTransitionDuration: '500ms',
                  OTransitionDuration: '500ms',
                  color: darkTheme ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.60)', //!darkTheme?iOSColors.green:ThemeColors.dark.PRIMARY,
                  fontSize: '45px',
                  background: darkTheme ? ThemeColors.dark.PRIMARY : ThemeColors.light.PRIMARY,
                  borderRadius: '50%',
                }}
              />
            )}

            {type === IconTypes.Time && (
              <AccessTimeFilledIcon
                sx={{
                  transition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  WebkitTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  MsTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  MozTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  OTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  transitionDuration: '500ms',
                  WebkitTransitionDuration: '500ms',
                  MsTransitionDuration: '500ms',
                  MozTransitionDuration: '500ms',
                  OTransitionDuration: '500ms',
                  color: darkTheme ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.60)', //!darkTheme?iOSColors.green:ThemeColors.dark.PRIMARY,
                  fontSize: '45px',
                  background: darkTheme ? ThemeColors.dark.PRIMARY : ThemeColors.light.PRIMARY,
                  borderRadius: '50%',
                }}
              />
            )}

            {type === IconTypes.Collaboration && (
              <GroupsIcon
                sx={{
                  transition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  WebkitTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  MsTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  MozTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  OTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  transitionDuration: '500ms',
                  WebkitTransitionDuration: '500ms',
                  MsTransitionDuration: '500ms',
                  MozTransitionDuration: '500ms',
                  OTransitionDuration: '500ms',
                  color: darkTheme ? ThemeColors.dark.PRIMARY : ThemeColors.light.PRIMARY,
                  fontSize: '45px',
                  borderRadius: '50%',
                }}
              />
            )}

            {!type && (
              <CheckCircleIcon
                sx={{
                  transition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  WebkitTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  MsTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  MozTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  OTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                  transitionDuration: '500ms',
                  WebkitTransitionDuration: '500ms',
                  MsTransitionDuration: '500ms',
                  MozTransitionDuration: '500ms',
                  OTransitionDuration: '500ms',
                  color: darkTheme ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.60)', //!darkTheme?iOSColors.green:ThemeColors.dark.PRIMARY,
                  fontSize: '45px',
                  background: darkTheme ? ThemeColors.dark.PRIMARY : ThemeColors.light.PRIMARY,
                  borderRadius: '50%',
                }}
              />
            )}
          </Box>
          <Label text={title} darkTheme={darkTheme} highlighted />
          <PlainText darkTheme={darkTheme} sx={{ mt: 2, lineHeight: small ? 1.3 : 'unset' }}>
            {small ? <small>{text}</small> : text}
          </PlainText>
          {!!tag && (
            <Box
              sx={{
                transition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                WebkitTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                MsTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                MozTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                OTransition: 'color 500ms ease-in-out, background 500ms ease-in-out',
                transitionDuration: '500ms',
                WebkitTransitionDuration: '500ms',
                MsTransitionDuration: '500ms',
                MozTransitionDuration: '500ms',
                OTransitionDuration: '500ms',
                position: 'absolute',
                bottom: '20px',
                mt: 3,
                background: bGColor,
                px: 2,
                pt: 0.6,
                pb: 0.7,
                lineHeight: 0.8,
                borderRadius: '14px',
                color: color,
                minWidth: '180px',
                fontWeight: '600',
                textTransform: 'uppercase',
              }}
            >
              <small>{tag}</small>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BenefitColumn;
