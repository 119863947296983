import React, { memo } from 'react'
import {Box, SxProps} from "@mui/material";

type Props = {
  id?: string;
  sx?: SxProps;
  children?: any;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?:() => void;
}
export const Layer = memo(
  ({
     id,
     sx,
     children,
     onMouseEnter,
     onMouseLeave,
     onClick,
  }: Props) => (
    <Box
      id={id}
      sx={{
        position:'absolute',
        top:0,
        right:0,
        left:0,
        bottom:0,
        overflow:'auto',
        padding:'16px',
        ...sx,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </Box>
  )
)
