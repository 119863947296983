import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Row from '../../components/layout/rows/Row';
import SubHeader from '../../components/text/SubHeader';
import Title from '../../components/text/Title';
import Header from '../../components/text/Header';
import Footer from '../../components/views/Footer';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import HighlightCard from '../../components/views/KnowledgeHub/Highlight.card';
import FilterCard from '../../components/views/KnowledgeHub/Filter.card';
import { PageWrapper, Section } from '../../components/layout';
import { RouteNames } from '../../Routes/routeNames';
import { builder, Builder, BuilderContent } from '@builder.io/react';
import PostView from '../../components/views/Posts/Post';
import { Spinner } from '../../components/Spinner';
import {
  getAllContentInPreview,
  getAllContentInLive,
} from '../../components/views/KnowledgeHub/utils/utils';
import PostCard from '../../components/views/KnowledgeHub/Post.card';
import { PostType } from '../../../types/builder-io-types';

const KnowledgeHubPage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const [filter, setFilter] = useState('all');
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const [posts, setPosts] = useState<PostType[]>([]);
  const [highlightContent, setHighlightContent] = useState<PostType | undefined>(undefined);
  const navigate = useNavigate();
  const selectPost = useCallback(
    (id: string) => {
      navigate(`${id}`);
    },
    [navigate]
  );

  const { id } = useParams();

  useEffect(() => {
    async function fetchBuilderKnowledgeHub() {
      try {
        const kh = await builder.get('knowledge-hub', {
          options: {
            locale: language,
          },
          userAttributes: {
            locale: language,
          },
        });

        const [highlightContent, allContentFilteredAndSorted] =
          Builder.isPreviewing || Builder.isEditing
            ? await getAllContentInPreview(kh.data)
            : await getAllContentInLive(kh.data);

        const onlyVisiblePostsInLiveMode =
          Builder.isPreviewing || Builder.isEditing
            ? allContentFilteredAndSorted
            : allContentFilteredAndSorted.filter(
                (p: PostType) => !p?.invisible && p?.languages.includes(language)
              );

        setHighlightContent(highlightContent);
        setPosts(onlyVisiblePostsInLiveMode);
      } catch (e) {
        console.error(e);
      }
    }

    fetchBuilderKnowledgeHub();
  }, [language, Builder.isPreviewing, Builder.isEditing]);

  if (id && posts.length > 0) {
    const post = posts.find((p) => p.slug === id);

    if (!post) {
      return (
        <PageWrapper>
          <Section
            sx={{
              minHeight: '100vh',
              alignContent: 'flex-start',
            }}
          >
            <Grid item xs={12} textAlign="center">
              <Header
                text={t('KNOWLEDGE_HUB.ERROR_POST_NOT_FOUND.TITLE')}
                darkTheme={false}
                sx={{
                  mb: 2,
                }}
              />

              <SubHeader
                text={t('KNOWLEDGE_HUB.ERROR_POST_NOT_FOUND.SUBTITLE')}
                darkTheme={false}
              />
              <p>
                <Button href={language + RouteNames.knowledgeHub}>
                  {t('KNOWLEDGE_HUB.ERROR_POST_NOT_FOUND.BUTTON_TEXT')}
                </Button>
              </p>
            </Grid>
          </Section>
        </PageWrapper>
      );
    }

    if (post.type === 'blog-article') {
      return <PostView post={post} />;
    }

    return (
      <BuilderContent
        model={post.type}
        options={{
          locale: language,
          query: {
            id: post.id,
          },
        }}
      >
        {(p, loading, meta) => {
          if (loading) {
            return (
              <PageWrapper>
                <Section
                  sx={{
                    minHeight: '100vh',
                    alignContent: 'flex-start',
                  }}
                ></Section>
              </PageWrapper>
            );
          }

          p.type = post.type;
          p.typeLabel = post.typeLabel;
          return <PostView post={p} />;
        }}
      </BuilderContent>
    );
  }

  // if (language === 'en') {
  //   return (
  //     <PageWrapper>
  //       <Section
  //         sx={{
  //           minHeight: '100vh',
  //           alignContent: 'flex-start',
  //         }}
  //       >
  //         <Grid item xs={12} textAlign="center">
  //           <Header
  //             text="Sorry!"
  //             darkTheme={darkTheme}
  //             sx={{
  //               mb: 2,
  //             }}
  //           />

  //           <SubHeader
  //             text="The Knowledge-Hub is currently only available in German."
  //             darkTheme={darkTheme}
  //           />
  //           <p>
  //             <Button href={'de' + RouteNames.knowledgeHub}>
  //               Click here to go to the German Knowledge-Hub.
  //             </Button>
  //           </p>
  //         </Grid>
  //       </Section>
  //     </PageWrapper>
  //   );
  // }

  return (
    <PageWrapper>
      <Section
        sx={{
          textAlign: 'center',
        }}
        spacing="sm"
      >
        <Grid item xs={12}>
          <Title
            text={t('POSTS.TITLE')}
            mainTitle
            darkTheme={false}
            sx={{
              fontSize: {
                xs: 30,
                sm: 45,
                lg: 55,
                xl: 68,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SubHeader text={t('POSTS.SUBTITLE')} darkTheme={false} />
        </Grid>
      </Section>
      <Section>
        {highlightContent ? (
          <Grid item xs={12}>
            <HighlightCard
              post={highlightContent}
              onClick={() => selectPost(highlightContent?.slug as string)}
            />
          </Grid>
        ) : (
          <Spinner darkTheme={darkTheme} />
        )}
        <Grid item xs={12} zIndex={1}>
          <FilterCard darkTheme={darkTheme} setFilter={setFilter} filter={filter} />
        </Grid>
        <Grid container item xs={12} justifyContent="center" spacing={6}>
          {!posts && (
            <Grid item xs={12}>
              <Spinner darkTheme={!darkTheme} />
            </Grid>
          )}
          {posts?.map((post: PostType, index: number) => {
            if (filter !== 'all' && post.type !== filter) {
              return null;
            }

            return (
              <Grid
                key={index}
                item
                container
                xs={12}
                sm={10}
                md={11}
                lg={6}
                xl={4}
                sx={{
                  minWidth: {
                    xs: 'unset',
                    sm: '400px',
                  },
                }}
              >
                <PostCard post={post} onClick={() => selectPost(post.slug)} />
              </Grid>
            );
          })}
        </Grid>
      </Section>
      <Row sx={{ mt: 3 }}>
        <Footer />
      </Row>
    </PageWrapper>
  );
};

export default KnowledgeHubPage;
