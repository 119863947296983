import DetailsView from '@fides-jam/front/components/DetailsView';
import {Box, SxProps} from '@mui/material';
import { FC } from 'react';
import styles from './styles';
import {TrackingSuffix} from "@fides-jam/types/analytics";

interface ModalProps {
  id?: string;
  open: boolean;
  onClose?: () => void;
  title?: string;
  renderBottomChildren?: () => void;
  sx?: SxProps;
  customZIndex?: number;
  hideBackdrop?: boolean;
}

const ScrollableModal: FC<ModalProps> = ({
  id,
  open,
  onClose,
  title,
  children,
  sx,
  customZIndex,
  hideBackdrop,
}) => {
  const { containerStyle } = styles;
  return (
    <Box
      id={id}
      sx={{
        ...containerStyle,
        zIndex: customZIndex || 10000,
        display: open?'flex':'none',
        background: !hideBackdrop
          ? 'rgba(255,255,255,.6)'
          : 'unset',
        backdropFilter: !hideBackdrop
          ? 'blur(20px)'
          : 'unset',
        WebkitBackdropFilter: !hideBackdrop
          ? 'blur(20px)'
          : 'unset',
      }}
    >
      <div>
        <DetailsView
          id={id?.replace(TrackingSuffix.VIEW, '')}
          title={title}
          onClose={onClose}
          variant={'close'}
          contentStyleProps={{p: 0}}
          sx={{
            maxHeight: { xs: '95vh', sm: '80vh' },
            width: '100%',
            maxWidth: '500px',
            position: 'relative',
            ...sx,
          }}
        >
          {children}
        </DetailsView>
      </div>
    </Box>
  );
};

export default ScrollableModal;
