import { FC } from 'react';
import { Box, SxProps } from '@mui/material';
import { ThemeColors } from '../../utils/colors';

interface TitlePropsType {
  text?: string;
  mainTitle?: boolean;
  darkTheme?: boolean;
  large?: boolean;
  center?: boolean;
  sx?: SxProps;
}

const Title: FC<TitlePropsType> = ({ text, darkTheme, sx, large, center, mainTitle, children }) => {
  return (
    <Box
      component={mainTitle ? 'h1' : 'h2'}
      sx={{
        fontSize: large
          ? {
              xs: 35,
              sm: 45,
              lg: 55,
              xl: 68,
            }
          : {
              xs: 30,
              sm: 38,
              lg: 48,
            },
        fontWeight: '700',
        lineHeight: 1.2,
        transition: 'color 500ms ease-in-out',
        WebkitTransition: 'color 500ms ease-in-out',
        MsTransition: 'color 500ms ease-in-out',
        MozTransition: 'color 500ms ease-in-out',
        OTransition: 'color 500ms ease-in-out',
        transitionDuration: '500ms',
        WebkitTransitionDuration: '500ms',
        MsTransitionDuration: '500ms',
        MozTransitionDuration: '500ms',
        OTransitionDuration: '500ms',
        //color:darkTheme?'#FFF':'#000',
        color: darkTheme ? '#FFF' : ThemeColors.light.TITLE,
        textAlign: center ? 'center' : 'inherit',
        ...sx,
      }}
    >
      {text}
      {children}
    </Box>
  );
};

export default Title;
