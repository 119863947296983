import { styled } from "@mui/material/styles";
import { Menu } from "@mui/material";
import { MouseEvent } from "react";

interface StyledTabProps {
  open: boolean;
  anchorEl?: HTMLElement | null;
  onClose: (event: MouseEvent<HTMLElement>) => void;
  darkTheme?: boolean;
  disableAutoFocusItem?: boolean;
}

const CustomMenu = styled((props: StyledTabProps) => {
  let newProps = {...props};
  delete newProps.darkTheme;
  return <Menu {...newProps} />;
})(
  ({ theme, ...props }) => ({
    background:props.darkTheme?'rgba(2, 33, 90,0.3)':'rgba(2, 33, 90,0.15)',
    '& .MuiPaper-root': {
        borderRadius: 10,
        minWidth: 180,
        border:'none',
        padding:'20px 0px 20px 0px',
        background:props.darkTheme?'rgb(2, 33, 90)':'#FFF',
        boxShadow:'0px 0px 58px 4px rgba(22, 33, 90, 0.2)',
        '& .MuiMenu-list': {
          padding: '0px 0',
        },
        '& .MuiMenuItem-root:last-child ': {
          borderBottom:props.darkTheme?'1px solid rgba(255,255,255,0.1)':'1px solid rgba(0,0,0,0.05)',
        },
        '& .MuiMenuItem-root': {
          padding:'8px 40px 8px 40px',
          borderTop:props.darkTheme?'1px solid rgba(255,255,255,0.1)':'1px solid rgba(0,0,0,0.05)',
          '& .MuiListItemText-root': {
            '& .MuiTypography-root': {
              fontSize:'15px',
              fontWeight:'400',
              color:props.darkTheme?'rgba(255,255,255,0.9)':'default',
            }
          },
          '& .MuiSvgIcon-root': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
          },
          '& .MuiListItemIcon-root': {
            marginLeft:'-10px',
          },
          '&:active': {
            backgroundColor: props.darkTheme?'rgba(255,255,255,0.18)':'rgba(230,230,242,1)',
          },
          '&:hover': {
            backgroundColor: props.darkTheme?'rgba(255,255,255,0.08)':'rgba(230,230,242,0.4)',
          },
        },
      },
  }),
);

export default CustomMenu;
