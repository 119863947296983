import RowSeperator from '@fides-jam/front/components/separators/HorizontalSeparator';
import { Box, Grid, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RouteNames } from '../../../Routes/routeNames';
import Logo from '../../logos/Logo';
import Header from '../../text/Header';
import FooterAnchor from './FooterAnchor';
import { useContext } from 'react';
import { DarkThemeContext } from '../../Context/ThemeContext';
import SubHeader from '../../text/SubHeader';
import { BuilderContent } from '@builder.io/react';
import PostsSection from '../../sections/Posts.section';
import BookDemoHubspot from '../../Hubspot/BookDemo.hubspot';
interface FooterPropsType {
  sx?: SxProps;
  hideCalendar?: boolean;
  showPostsSection?: boolean;
}

const Footer: React.FC<FooterPropsType> = ({ sx, hideCalendar, showPostsSection }) => {
  const { t, i18n } = useTranslation();
  const { darkTheme } = useContext(DarkThemeContext);

  return (
    <Grid
      container
      justifyContent="center"
      rowSpacing={6}
      columnSpacing={4}
      color={darkTheme ? '#FFF' : '#000'}
      sx={{
        ...sx,
      }}
    >
      <Grid
        item
        container
        sx={{
          transition: 'background 0.6s ease-in-out',
        }}
      >
        {!hideCalendar && (
          <>
            <Grid
              container
              rowSpacing={2}
              justifyContent="center"
              sx={{
                textAlign: 'center',
                mb: {
                  xs: 5,
                  md: 4,
                },
              }}
            >
              <Grid item xs={12}>
                <Header text={t('HOME.SECTIONS.DEMO.TITLE')} darkTheme={darkTheme} />
              </Grid>
              <Grid item xs={12} md={10} lg={8} xl={8}>
                <SubHeader text={t('HOME.SECTIONS.DEMO.SUBTITLE')} darkTheme={darkTheme} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <BookDemoHubspot />
            </Grid>
          </>
        )}
      </Grid>
      {showPostsSection && i18n.language === 'de' && (
        <PostsSection darkTheme={darkTheme} key={i18n.language} />
      )}
      <Grid item container xs={12} px={3}>
        <RowSeperator sx={{ width: '100%' }} darkTheme={darkTheme} />
      </Grid>
      <Grid item container xs={12} sm={10} md={2.75} flexDirection="column">
        <Grid item container sx={{ mb: 1.5 }}>
          <strong>
            <big>{t('FOOTER.CONTACT.TITLE')}</big>
          </strong>
        </Grid>
        <Grid item container>
          {t('FOOTER.CONTACT.ADDRESS_1')}
        </Grid>
        <Grid item container>
          {t('FOOTER.CONTACT.ADDRESS_2')}
        </Grid>
        <Box height="5px" />
        <Grid item container sx={{ userSelect: 'none' }}>
          {t('FOOTER.CONTACT.EMAIL')}
        </Grid>
        <Box height="5px" />
        <Grid item container sx={{ userSelect: 'none' }}>
          <a href="tel:+49 89 12085471" style={{ all: 'unset', cursor: 'pointer' }}>
            {t('FOOTER.CONTACT.PHONE')}
          </a>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={10} md={2.75} flexDirection="column" gap={2}>
        <BuilderContent
          model="footer"
          options={{
            locale: i18n.language,
          }}
          key={i18n.language}
        >
          {(content, loading, meta) => {
            if (loading) {
              return null;
            }

            return (
              <Grid container item xs={12} flexDirection="column">
                {content.sections.map(
                  (section: {
                    title: string;
                    links: { text: string; url: string; visibleOn: 'en' | 'de' }[];
                  }) => {
                    if (section.links.every((link) => !link.visibleOn.includes(i18n.language))) {
                      return null;
                    }

                    return (
                      <Grid container mb={3} key={section.title}>
                        <Grid item container mb={1.5}>
                          <strong>
                            <big>{section.title}</big>
                          </strong>
                        </Grid>
                        {section.links.map(
                          (link: { text: string; url: string; visibleOn: 'en' | 'de' }) => {
                            if (!link.visibleOn.includes(i18n.language)) {
                              return null;
                            }
                            return (
                              <Grid item container key={link.text}>
                                <FooterAnchor
                                  text={link.text}
                                  darkTheme={darkTheme}
                                  href={link.url}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    );
                  }
                )}
              </Grid>
            );
          }}
        </BuilderContent>
      </Grid>
      <Grid item xs={12} sm={10} md={2.25} flexDirection="column">
        <Grid item container sx={{ mb: 1.5 }}>
          <strong>
            <big>{t('FOOTER.COMPANY.TITLE')}</big>
          </strong>
        </Grid>
        <Grid item container>
          <FooterAnchor
            text={t('FOOTER.COMPANY.ABOUT')}
            darkTheme={darkTheme}
            href={RouteNames.about}
          />
        </Grid>
        <Grid item container>
          <FooterAnchor
            text={t('FOOTER.COMPANY.JOBS')}
            darkTheme={darkTheme}
            href={RouteNames.jobs}
            localized={false}
          />
        </Grid>
        <Grid item container>
          <FooterAnchor
            text={t('FOOTER.COMPANY.LEGAL')}
            darkTheme={darkTheme}
            href={RouteNames.legalNotice}
          />
        </Grid>
        <Grid item container>
          <FooterAnchor
            text={t('FOOTER.COMPANY.PRIVACY')}
            darkTheme={darkTheme}
            href={RouteNames.privacyPolicy}
          />
        </Grid>
        <Grid item container>
          <FooterAnchor
            text={t('FOOTER.COMPANY.PRESS')}
            darkTheme={darkTheme}
            href={RouteNames.media}
          />
        </Grid>
        <Grid item container>
          <FooterAnchor
            text={t('FOOTER.COMPANY.REPORT_WRONGDOING')}
            darkTheme={darkTheme}
            href={RouteNames.reportWrongdoing}
            localized={false}
          />
        </Grid>
        <Grid item container>
          <FooterAnchor
            text={t('FOOTER.COMPANY.MERCH')}
            darkTheme={darkTheme}
            href={RouteNames.merch}
            localized={false}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={10} md={4.25}>
        <Grid item xs={12} container justifyContent="center" gap={1}>
          <img
            src="/assets/images/footer/wiwo_award.webp"
            alt="WiWo Award"
            style={{
              width: 'auto',
              height: '180px',
              maxWidth: '100%',
              paddingTop: '2px',
            }}
          />
          <img
            src="/assets/images/footer/kununu_award.webp"
            alt="Kununu Award"
            style={{
              width: 'auto',
              height: '180px',
              maxWidth: '100%',
            }}
          />
          <img
            src="/assets/images/footer/iso27001.webp"
            alt="ISO 27001"
            style={{
              width: 'auto',
              height: '150px',
              maxWidth: '100%',
              backgroundColor: 'white',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #e0e0e0',
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <RowSeperator sx={{ width: '100%' }} darkTheme={darkTheme} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={10}
      >
        <Grid textAlign="center">
          <Logo darkTheme={darkTheme} />
        </Grid>
        <Grid textAlign="center">
          <small>© 2024 Fides Technology GmbH - All rights reserved.</small>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
