import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import slugify from 'slugify';
import Row from '../../components/layout/rows/Row';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import SubTitle from '../../components/text/SubTitle';
import Header from '../../components/text/Header';
import PlainText from '../../components/text/PlainText';
import Logo from '../../components/logos/Logo';
import HButton from '../../components/buttons/Highlight.button';
import UpcomingEventsView from '../../components/views/CircleOfCounsels/upcomingEvents.view';
import ImageScroll from '../../components/views/CircleOfCounsels/imageScroll.row';
import PastEventsCarousel from '../../components/views/CircleOfCounsels/pastEventsCarousel.row';
import LogoWall from '../../components/LogoWall/LogoWall.view';
import { BuilderContent, builder } from '@builder.io/react';
import HubspotForm from 'react-hubspot-form';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { PageWrapper, Section } from '../../components/layout';
import { useLocation } from 'react-router-dom';
import { useHubspotFormConversionTracking } from '../../hooks/useHubspotConversionTracking';
import CircleOfCounselsDetailPage from './CircleOfCounsels.detail.page';
import { RouteNames } from '../../Routes/routeNames';
import { UpcomingCocEvent } from 'apps/speedy/src/types/builder-io-types';

const CircleOfCounselsPage: FC = () => {
  const { t, i18n } = useTranslation();
  const [showAllUpcomingEvents, setShowAllUpcomingEvents] = useState<boolean>(false);
  const [upcomingEvents, setUpcomingEvents] = useState<any[]>([]);
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { location } = useParams();

  useEffect(() => {
    toggleDarkTheme(true);
  }, [toggleDarkTheme]);

  useEffect(() => {
    async function fetchUpcomingEvents() {
      try {
        const ue = await builder.get('co-c-events', {
          options: {
            locale: i18n.language,
          },
          userAttributes: {
            locale: i18n.language,
          },
        });

        setUpcomingEvents(ue.data.upcomingEvents);
      } catch (e) {
        console.error(e);
      }
    }

    fetchUpcomingEvents();
  }, []);

  const formId =
    i18n.language === 'de'
      ? '6c6b01ed-ef0f-4486-abde-2e2c3e8c537f'
      : 'dd573f7f-954d-4863-ba4e-55f6f070387b';

  useHubspotFormConversionTracking(formId, 'AW-10855235169/w1bfCL2Iu50ZEOH8lrgo');

  if (location) {
    console.log(location);
    console.log(slugify(location, { lower: true }));

    const slugifySettings = {
      replacement: '-',
      remove: /ä|ö|ü/g,
      lower: true,
      strict: true,
      locale: 'de',
      trim: true,
    };

    const cocEvent = upcomingEvents.find(
      (item) => slugify(item.cityName, slugifySettings) === slugify(location, slugifySettings)
    );

    if (cocEvent) {
      return <CircleOfCounselsDetailPage cocEvent={cocEvent} />;
    } else {
      navigate(RouteNames.circleOfCounsels);
    }
  }

  const logoWallImages: string[] = [
    '/assets/images/events/coc/logo-wall/bayer.webp',
    '/assets/images/events/coc/logo-wall/deutsche-bahn.webp',
    '/assets/images/events/coc/logo-wall/deutsche-bank.webp',
    '/assets/images/events/coc/logo-wall/hello-fresh.webp',
    '/assets/images/events/coc/logo-wall/ing-diba.webp',
    '/assets/images/events/coc/logo-wall/mahle.webp',
    '/assets/images/events/coc/logo-wall/mann-hummel.webp',
    '/assets/images/events/coc/logo-wall/otto-group.webp',
    '/assets/images/events/coc/logo-wall/pro7sat1.webp',
    '/assets/images/events/coc/logo-wall/simon-kucher.webp',
    '/assets/images/events/coc/logo-wall/tesla.webp',
    '/assets/images/events/coc/logo-wall/trade-republic.webp',
  ];

  const impressionsImages: { src: string; description?: string }[] = [
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_1-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_2-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_3-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_4-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_5-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_6-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_7-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_8-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_9-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_10-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_11-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_12-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_13-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_14-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_15-min.webp',
    },
    {
      src: '/assets/images/events/coc/impressions/coc_impressions_16-min.webp',
    },
  ];

  const titleStyle = {
    fontSize: {
      xs: 30,
      sm: 38,
      lg: 48,
    },
    textAlign: 'center',
  };

  return (
    <PageWrapper>
      <Section>
        <Grid item xs={12} md={6}>
          <Grid item>
            <Title
              text={t('CIRCLE_OF_COUNSELS.JOIN.TITLE')}
              mainTitle
              darkTheme={darkTheme}
              sx={{
                fontSize: {
                  xs: 30,
                  sm: 30,
                  lg: 40,
                  xl: 48,
                },
                mb: 2,
              }}
            />
            <SubTitle
              text={t('CIRCLE_OF_COUNSELS.JOIN.SUBTITLE')}
              sx={{
                fontSize: {
                  xs: 30,
                  sm: 30,
                  lg: 30,
                  xl: 38,
                },
                mb: 1,
              }}
              darkTheme={darkTheme}
            />

            <Grid container alignItems="center" gap={3} sx={{ opacity: '0.6' }} mt={1}>
              <SubTitle
                text={t('CIRCLE_OF_COUNSELS.JOIN.HOSTED_BY')}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 30,
                    lg: 30,
                    xl: 38,
                  },
                }}
                darkTheme={darkTheme}
              />
              <Logo darkTheme={darkTheme} height="50px" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <img
            src="/assets/images/events/coc/impressions/coc_impressions_1-min.webp"
            alt="Cirlce of Counsels"
            style={{
              width: '100%',
              height: '500px',
              objectFit: 'cover',
              borderRadius: '10px',
              boxShadow: '0px 0px 60px 0px rgba(0,0,0,0.09)',
              border: '5px solid rgba(240,245,255,1)',
            }}
          />
        </Grid>
      </Section>

      <Section>
        <Grid item md={10}>
          <Title
            text={t('CIRCLE_OF_COUNSELS.PAST_ATTENDEES.TITLE')}
            darkTheme={darkTheme}
            sx={titleStyle}
          />
        </Grid>

        <Grid container item justifyContent="center">
          <LogoWall logos={logoWallImages} />
        </Grid>
      </Section>

      <Section>
        <Grid item>
          <Title text={t('CIRCLE_OF_COUNSELS.ABOUT.TITLE')} darkTheme={darkTheme} sx={titleStyle} />
        </Grid>

        <Grid container item spacing={5} alignItems="center">
          <Grid item xs={12} md={6}>
            <Grid
              container
              item
              sx={{
                background: 'black',
                borderRadius: '30px',
                border: '1px solid white',
                overflow: 'hidden',
                height: {
                  xs: '450px',
                  md: '680px',
                },
              }}
              minWidth="300px"
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'relative',
                }}
              >
                <source src="/assets/videos/coc-event.webm" type="video/webm" />
                <source src="/assets/videos/coc-event.mp4" type="video/mp4" />
              </video>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={6} rowSpacing={4}>
            <Grid item>
              <PlainText darkTheme={darkTheme} text={t('CIRCLE_OF_COUNSELS.ABOUT.TEXT_1')} />
            </Grid>
            <Grid item>
              <PlainText darkTheme={darkTheme} text={t('CIRCLE_OF_COUNSELS.ABOUT.TEXT_2')} />
            </Grid>
            <Grid item>
              <PlainText darkTheme={darkTheme} text={t('CIRCLE_OF_COUNSELS.ABOUT.TEXT_3')} />
            </Grid>

            <Grid item xs={12}>
              <PlainText darkTheme={darkTheme} text={t('CIRCLE_OF_COUNSELS.ABOUT.TEXT_4')} />
            </Grid>
            <Grid item xs={12}>
              <HButton
                size="large"
                fullWidth
                sx={{ px: 5 }}
                darkTheme={true}
                href={pathname + '#signup'}
                localized={false}
              >
                {t('CIRCLE_OF_COUNSELS.ABOUT.BUTTON_TEXT')}
              </HButton>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <BuilderContent
        model="co-c-events"
        options={{
          locale: i18n.language,
        }}
        key={i18n.language}
      >
        {(cocEvents, loading, meta) => {
          if (loading) {
            return null;
          }

          const upcomingEvents = cocEvents?.upcomingEvents;
          const pastEvents = cocEvents?.pastEvents;

          return (
            <>
              <Section fullWidth>
                <Grid item>
                  <Title
                    text={t('CIRCLE_OF_COUNSELS.UPCOMING_EVENTS.TITLE')}
                    darkTheme={darkTheme}
                    sx={titleStyle}
                  />
                </Grid>
                <Grid container item rowSpacing={3} columnSpacing={3} justifyContent="center">
                  {upcomingEvents
                    .slice(0, showAllUpcomingEvents ? upcomingEvents.length : 4)
                    .map((event: UpcomingCocEvent) => (
                      <UpcomingEventsView
                        event={event}
                        lang={i18n.language}
                        key={event.title}
                        pathname={pathname}
                      />
                    ))}
                </Grid>
                <Grid container item justifyContent="center" mt={4}>
                  {upcomingEvents.length > 4 &&
                    (!showAllUpcomingEvents ? (
                      <Box onClick={() => setShowAllUpcomingEvents(true)}>
                        <SubTitle
                          darkTheme={darkTheme}
                          text={t('CIRCLE_OF_COUNSELS.UPCOMING_EVENTS.SEE_MORE_BUTTON_TEXT')}
                          sx={{
                            cursor: 'pointer',
                          }}
                        />
                      </Box>
                    ) : (
                      <Box onClick={() => setShowAllUpcomingEvents(false)}>
                        <SubTitle
                          darkTheme={darkTheme}
                          text={t('CIRCLE_OF_COUNSELS.UPCOMING_EVENTS.SEE_LESS_BUTTON_TEXT')}
                          sx={{
                            cursor: 'pointer',
                          }}
                        />
                      </Box>
                    ))}
                </Grid>
              </Section>
              <Section fullWidth>
                <Grid item>
                  <Title
                    text={t('CIRCLE_OF_COUNSELS.PAST_EVENTS.TITLE')}
                    darkTheme={darkTheme}
                    sx={titleStyle}
                  />
                </Grid>
                <Grid container item justifyContent="center">
                  <PastEventsCarousel pastEvents={pastEvents} />
                </Grid>
              </Section>
            </>
          );
        }}
      </BuilderContent>

      <Section>
        <Grid item>
          <Title
            text={t('CIRCLE_OF_COUNSELS.EXCLUSIVE_VIDEO.TITLE')}
            darkTheme={darkTheme}
            sx={titleStyle}
          />
        </Grid>

        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          spacing={5}
          flexDirection="row-reverse"
        >
          <Grid item xs={12} md={3.5}>
            <FadeSlide direction="up" transitionDelay={400} once>
              <Grid item container rowGap={2} justifyContent="center">
                <Grid item xs={8} md={12} mb={3} justifyContent="center">
                  <img
                    src="/assets/images/events/coc/exclusive-video/faber-castell-logo.png"
                    alt="Faber-Castell"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                      position: 'relative',
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <PlainText
                    darkTheme={darkTheme}
                    text={t('CIRCLE_OF_COUNSELS.EXCLUSIVE_VIDEO.TEXT_1')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PlainText
                    darkTheme={darkTheme}
                    text={t('CIRCLE_OF_COUNSELS.EXCLUSIVE_VIDEO.TEXT_2')}
                  />
                </Grid>
              </Grid>
            </FadeSlide>
          </Grid>
          <Grid item xs={12} md={8.5}>
            <FadeSlide transitionDelay={900} once>
              <Grid
                container
                item
                sx={{
                  background: 'black',
                  borderRadius: '30px',
                  border: '1px solid white',
                  overflow: 'hidden',
                  height: {
                    xs: '250px',
                    md: '600px',
                  },
                }}
                minWidth="300px"
              >
                <iframe
                  style={{ border: 'none' }}
                  src="https://www.youtube-nocookie.com/embed/9CuCzOwyTJ0?vq=hd1080&autoplay=0&modestbranding=1&rel=0&color=white&mute=1&showinfo=0"
                  width="100%"
                  height="100%"
                  title="Wie Faber-Castell seine Rechtsabteilung digitalisiert | Circle of Counsels by Fides"
                  allowFullScreen
                ></iframe>
              </Grid>
            </FadeSlide>
          </Grid>
        </Grid>
      </Section>

      <Section fullWidth>
        <Grid item>
          <Title
            text={t('CIRCLE_OF_COUNSELS.IMPRESSIONS.TITLE')}
            darkTheme={darkTheme}
            sx={titleStyle}
          />
        </Grid>

        <Grid container item justifyContent="center">
          <ImageScroll images={impressionsImages} />
        </Grid>
      </Section>

      <Section id="signup">
        <Grid container item justifyContent="center">
          <Grid
            container
            item
            bgcolor="white"
            borderRadius={4}
            border="1px solid #EDEDED"
            xs={12}
            sx={{
              px: {
                xs: 5,
                md: 8,
              },
              py: {
                xs: 5,
                md: 8,
              },
            }}
          >
            <Grid item container spacing={6}>
              <Grid item xs={12} md={5}>
                <Header
                  sx={{
                    mb: '20px',
                  }}
                  text={t('CIRCLE_OF_COUNSELS.SIGN_UP_FORM.TITLE')}
                />

                <PlainText
                  sx={{
                    mb: '10px',
                  }}
                  text={t('CIRCLE_OF_COUNSELS.SIGN_UP_FORM.TEXT_1')}
                />

                <PlainText text={t('CIRCLE_OF_COUNSELS.SIGN_UP_FORM.TEXT_2')} />
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid container sx={{ display: 'block' }}>
                  <Grid
                    item
                    sx={{
                      maxWidth: {
                        xl: '1000px',
                      },
                    }}
                  >
                    <HubspotForm
                      key={`hubspot_${i18n.language}`}
                      portalId="25457524"
                      formId={
                        i18n.language === 'de'
                          ? '5a4cb89a-1ebd-47cf-a561-26f254d10bdd'
                          : '51984fe0-6d69-4fec-8fa2-e3de158dc819'
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </PageWrapper>
  );
};

export default CircleOfCounselsPage;
