import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutUsPage from '../pages/main/AboutUs.page';
import DataInformationPage from '../pages/main/DataInformation.page';
import DemoPage from '../pages/main/Demo.page';
import DemoBookingPage from '../pages/main/DemoBooking.page';
import DemoBookingConfirmationPage from '../pages/main/DemoBookingConfirmation.page';
import Home from '../pages/main/Home.page';
import LegalNoticePage from '../pages/main/LegalNotice.page';
import KnowledgeHubPage from '../pages/main/KnowledgeHub.page';
import WhitepaperPage from '../pages/main/Whitepaper.page';
import MediaPage from '../pages/main/Media.page';
import PressReleasePage from '../pages/main/PressRelease.page';
import PrivacyPolicyPage from '../pages/main/PrivacyPolicy.page';
import ProductEntityManagementPage from '../pages/product/featureGroups/EntityManagement.page';
import ProductAuditTrailPage from '../pages/product/featureGroups/ProductAudittrail.page';
import ProductWorkflowManagementPage from '../pages/product/featureGroups/ProductWorkflowManagementPage';
import ApprovalsPage from '../pages/product/featuresDetails/Approvals.page';
import CapTablePage from '../pages/product/featuresDetails/CapTable.page';
import CommitteesPage from '../pages/product/featuresDetails/Committees.page';
import EntityManagementPage from '../pages/product/featuresDetails/EntityManagement.page';
import MeetingsPage from '../pages/product/featuresDetails/Meetings.page';
import ResolutionsPage from '../pages/product/featuresDetails/Resolutions.page';
import TasksPage from '../pages/product/featuresDetails/Tasks.page';
import DocumentBuilderPage from '../pages/product/featuresDetails/DocumentBuilder.page';
import DataVaultPage from '../pages/product/featuresDetails/DataVault.page';
import CircleOfCounselsPage from '../pages/main/CircleOfCounsels.page';
import MittelstadPage from '../pages/main/Mittelstand.page';
import WebinarSignUpPage from '../pages/main/WebinarSignUp.page';
import FidesForCounselsPage from '../pages/main/FidesForCounsels.page';
import SimonKucherSucessStoryPage from '../pages/main/SimonKucherSucessStory.page';
import FidesVsComeptitorPage from '../pages/main/FidesVsComeptitors';
import AiPage from '../pages/main/Ai.page';
import SecurityPage from '../pages/main/Security.page';
import BuyersGuidePage from '../pages/main/BuyersGuide.page';
import EnterprisePage from '../pages/main/Enterprise.page';
import BoardManagementPage from '../pages/product/featureGroups/BoardManagement.page';
import { RouteNames } from './routeNames';
import LanguageRedirect from './LanguageRedirect';
import { useTranslation } from 'react-i18next';
import SupervisoryBoardPage from '../pages/main/SupervisoryBoard.page';

const AppRoutes: FC = () => {
  const { i18n } = useTranslation();
  const langSlug = '/' + i18n.language;

  return (
    <LanguageRedirect>
      <Routes>
        <Route
          path={langSlug + RouteNames.home}
          element={
            <Suspense fallback={<></>}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.product}
          element={
            <Suspense fallback={<></>}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.about}
          element={
            <Suspense fallback={<></>}>
              <AboutUsPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.demoBooking}
          element={
            <Suspense fallback={<></>}>
              <DemoBookingPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.demoBookingDummyConfirmation}
          element={
            <Suspense fallback={<></>}>
              <DemoBookingPage />
            </Suspense>
          }
        />

        <Route
          path={langSlug + RouteNames.demoBookingConfirmation}
          element={
            <Suspense fallback={<></>}>
              <DemoBookingConfirmationPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.knowledgeHub}
          element={
            <Suspense fallback={<></>}>
              <KnowledgeHubPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.knowledgeHubArticles}
          element={
            <Suspense fallback={<></>}>
              <KnowledgeHubPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.productEntityManagement}
          element={
            <Suspense fallback={<></>}>
              <ProductEntityManagementPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.productWorkflows}
          element={
            <Suspense fallback={<></>}>
              <ProductWorkflowManagementPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.productAuditTrail}
          element={
            <Suspense fallback={<></>}>
              <ProductAuditTrailPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.approvals}
          element={
            <Suspense fallback={<></>}>
              <ApprovalsPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.capTable}
          element={
            <Suspense fallback={<></>}>
              <CapTablePage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.committees}
          element={
            <Suspense fallback={<></>}>
              <CommitteesPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.entityManagement}
          element={
            <Suspense fallback={<></>}>
              <EntityManagementPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.resolutions}
          element={
            <Suspense fallback={<></>}>
              <ResolutionsPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.tasks}
          element={
            <Suspense fallback={<></>}>
              <TasksPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.documentManagement}
          element={
            <Suspense fallback={<></>}>
              <DocumentBuilderPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.dataVault}
          element={
            <Suspense fallback={<></>}>
              <DataVaultPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.pressRelease}
          element={
            <Suspense fallback={<></>}>
              <PressReleasePage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.legalNotice}
          element={
            <Suspense fallback={<></>}>
              <LegalNoticePage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.privacyPolicy}
          element={
            <Suspense fallback={<></>}>
              <PrivacyPolicyPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.dataInformation}
          element={
            <Suspense fallback={<></>}>
              <DataInformationPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.media}
          element={
            <Suspense fallback={<></>}>
              <MediaPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.demo}
          element={
            <Suspense fallback={<></>}>
              <DemoPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.meetings}
          element={
            <Suspense fallback={<></>}>
              <MeetingsPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.webinar}
          element={
            <Suspense fallback={<></>}>
              <WebinarSignUpPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.whitepaper}
          element={
            <Suspense fallback={<></>}>
              <WhitepaperPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.circleOfCounsels}
          element={
            <Suspense fallback={<></>}>
              <CircleOfCounselsPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.circleOfCounselsDetail}
          element={
            <Suspense fallback={<></>}>
              <CircleOfCounselsPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.mittelstand}
          element={
            <Suspense fallback={<></>}>
              <MittelstadPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.fidesForCounsels}
          element={
            <Suspense fallback={<></>}>
              <FidesForCounselsPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.simonKucherSuccessStory}
          element={
            <Suspense fallback={<></>}>
              <SimonKucherSucessStoryPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.FidesVsComeptitors}
          element={
            <Suspense fallback={<></>}>
              <FidesVsComeptitorPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.FidesVsComeptitor}
          element={
            <Suspense fallback={<></>}>
              <FidesVsComeptitorPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.ai}
          element={
            <Suspense fallback={<></>}>
              <AiPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.security}
          element={
            <Suspense fallback={<></>}>
              <SecurityPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.buyersGuide}
          element={
            <Suspense fallback={<></>}>
              <BuyersGuidePage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.enterprise}
          element={
            <Suspense fallback={<></>}>
              <EnterprisePage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.boardManagement}
          element={
            <Suspense fallback={<></>}>
              <BoardManagementPage />
            </Suspense>
          }
        />
        <Route
          path={langSlug + RouteNames.supervisoryBoard}
          element={
            <Suspense fallback={<></>}>
              <SupervisoryBoardPage />
            </Suspense>
          }
        />
      </Routes>
    </LanguageRedirect>
  );
};

export default AppRoutes;
