import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import PlainText from '../../text/PlainText';
import { FC } from 'react';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import HButton from '../../buttons/Highlight.button';
import { RouteNames } from '../../../Routes/routeNames';
import { UpcomingCocEvent } from 'apps/speedy/src/types/builder-io-types';
interface UpcomingEventsViewPropsType {
  event: UpcomingCocEvent;
  lang: string;
  pathname: string;
}

const UpcomingEventsView: FC<UpcomingEventsViewPropsType> = ({ event, pathname }) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language === 'de' ? 'de-DE' : 'en-GB';
  const date = new Date(event.dateTime).toLocaleDateString(locale, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <Grid container item xs={12} sm={9} lg={6}>
      <Grid item container bgcolor="white" borderRadius={4} alignItems="center" p={1}>
        <Grid
          item
          container
          sx={{
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
          xs={12}
          md={5}
        >
          <img
            src={event.image}
            alt={event.title}
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
          />
        </Grid>
        <Grid container item flexDirection="column" gap={1} px={3} py={2} xs={12} md={7}>
          <Grid item>
            <PlainText
              sx={{
                fontSize: 20,
                fontWeight: 'bold',
              }}
              opacity
              text={`Circle of Counsels in ${event.cityName}`}
            />
          </Grid>
          <Grid item>
            <PlainText opacity text={event.title} />
          </Grid>
          <Grid item>
            <PlainText text={event.description} />
          </Grid>
          <Grid container my={1}>
            <Grid item container alignItems="center" flexBasis="fit-content" mr={2}>
              <EventIcon sx={{ fontSize: 16, marginRight: 0.75 }} />
              <PlainText opacity text={event.customDateText || date} />
            </Grid>
            <Grid container item alignItems="center" flexBasis="fit-content">
              <PlaceIcon sx={{ fontSize: 16, marginRight: 0.6 }} />
              <PlainText opacity text={event.location} />
            </Grid>
          </Grid>
          <Grid item>
            <HButton
              size="large"
              fullWidth
              sx={{ px: 5 }}
              darkTheme={true}
              href={pathname + '#signup'}
              localized={false}
            >
              {t('CIRCLE_OF_COUNSELS.UPCOMING_EVENTS.BUTTON_TEXT')}
            </HButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpcomingEventsView;
