import { FC } from 'react';
import DetailsView from './DetailsView';
import { Grid, Box, useTheme } from '@mui/material';
import Header from '../../text/Header';
import SubHeader from '../../text/SubHeader';
import Tag from '@fides-jam/front/components/Tag';
import VideoPost from './PostContent/Video.post';
import WebinarPost from './PostContent/Webinar.post';
import WhitepaperPost from './PostContent/Whitepaper.post';
import BlogArticle from './PostContent/Blog.post';
import { PageWrapper, Section } from '../../layout';
import { useTranslation } from 'react-i18next';

interface PostViewPropsType {
  post: any;
}

const PostView: FC<PostViewPropsType> = ({ post }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const theme = useTheme();
  const locale = language === 'de' ? 'de-DE' : 'en-GB';

  const date = new Date(post?.date).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  if (!post)
    return (
      <PageWrapper>
        <Section
          sx={{
            minHeight: '100vh',
            alignContent: 'flex-start',
          }}
        >
          <Grid item xs={12} textAlign="center">
            <Header
              text="Sorry!"
              darkTheme={true}
              sx={{
                mb: 2,
              }}
            />

            <SubHeader text="The requested post could not be found." darkTheme={true} />
          </Grid>
        </Section>
      </PageWrapper>
    );

  const contentType = post.type;
  const PostContent = () => {
    switch (contentType) {
      case 'video':
        return <VideoPost post={post} />;
      case 'webinar':
        return <WebinarPost post={post} />;
      case 'whitepaper':
        return <WhitepaperPost post={post} />;
      case 'blog-article':
        return <BlogArticle post={post} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        py: {
          xs: '0px',
          sm: '20px',
        },
        px: {
          xs: '0px',
          sm: '20px',
          lg: '100px',
          xl: '275px',
        },
        pt: {
          xs: '10px',
          sm: '10px',
        },
        maxHeight: '100vh',
        overflow: 'hidden',
        background: 'rgba(255,255,255,0.7)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)',
      }}
    >
      <DetailsView
        title={post?.title}
        sx={{
          height: '100%',
          maxHeight: 'calc(100vh - 75px)',
          minHeight: 'calc(100vh - 75px)',
          overflow: 'auto',
        }}
        contentStyleProps={{
          p: 0,
        }}
      >
        {post.type !== 'video' ? (
          <Grid
            container
            item
            sx={{
              paddingBottom: '22%',
              minHeight: post.type === 'webinar' ? '350px' : '240px',
              background: 'rgba(230,230,242,0.2)',
              backgroundImage: `url(${post?.thumbnail})`,
              backgroundSize: post.type === 'webinar' ? 'contain' : 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          />
        ) : (
          <iframe
            style={{ border: 'none' }}
            src={`https://www.youtube-nocookie.com/embed/${post.videoId}?vq=hd1080&autoplay=0&modestbranding=1&rel=0&color=white&mute=1&showinfo=0`}
            width="100%"
            height="500px"
            title={post?.title}
            allowFullScreen
          ></iframe>
        )}

        <Grid
          container
          item
          sx={{
            px: {
              xs: 4,
              sm: 6,
              md: 6,
              lg: 10,
              xl: 14,
            },
            py: {
              xs: 6,
              xl: 8,
            },
            maxWidth: '100%',
          }}
        >
          <Grid container>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid container item mb={1} xs="auto">
                <SubHeader text={post.typeLabel} darkTheme={false} />
              </Grid>
              <Grid item container xs="auto">
                {post?.tags.map((t: string) => (
                  <Tag key={t} text={t} color="#DEE4F7" textColor="#000" />
                ))}
              </Grid>
            </Grid>

            <Grid container item mb={1}>
              <Header text={post.title} />
            </Grid>
            <strong>
              <small style={{ color: theme.palette.grey[500] }}>{date}</small>
            </strong>
          </Grid>

          <PostContent />
        </Grid>
      </DetailsView>
    </Box>
  );
};

export default PostView;
