// Variations https://coolors.co/33ff99
export const AppColors = {
  PRIMARY: '#33FF99',
  PRIMARY_DARK: '#00DB6D',
  SECONDARY: '#16215A',
  SECONDARY_LIGHT: '#CCCCCC',
  SECONDARY_MEDIUM: '#E4E7EC',
  BACKGROUND_DEFAULT: '#FFFFFF',
  BACKGROUND_PAPER: '#F9FAFB',
  TEXT_PRIMARY: '#999999',
  TEXT_SECONDARY: '#000000',
  ERROR: '#FF4975',
  INFO: '#0093E5',
  SUCCESS: '#53D3AB',
  LIGHT_BLUE: '#0094B7',
  DARK_BLUE: '#006DA5',
  LIGHT_GREEN: '#E9FEEB',
};

export const AppSignUpColors = {
  WHITE: '#FFFFFF',
  FIDES_GREEN: '#33ff99',
  PRIMARY: '#16215A',
  PRIMARY_DARK: '#273B9F',
  PRIMARY_LIGHT: '#9CA9E7',
  PRIMARY_LIGHT_BORDER: '1px solid #d9d9d9',
  PRIMARY_MEDIUM: '#F4EBFF',
  PRIMARY_LIGHTER: '#DEE4F7',
  PRIMARY_LIGHTEST: '#EFF1FB',
  PRIMARY_DARKER: '#101828',
  PRIMARY_DARKER_BORDER: '1px solid #525b6d',
  SECONDARY: '#667085',
  SECONDARY_DARK: '#344054',
  SECONDARY_MEDIUM: '#E4E7EC',
  GRAY_200: '#F2F4F7',
  SECONDARY_LIGHT: '#CCCCCC',
  BACKGROUND_DEFAULT: '#FFFFFF',
  BACKGROUND_PAPER: '#F9FAFB',
  BACKGROUND_SECTION: '#FAFCFF',
  BACKGROUND_PAPER_LIGHT: '#F9FAFB',
  BACKGROUND_HEADER_BOX: '#E6F9FC',
  SHADOW_BLUE: '#036CB5',
  ERROR_DARK: '#B42318',
  ERROR_500: '#F04438',
  ERROR_100: '#facccc',
  SUCCESS: '#4CE2A3',
  SUCCESS_LIGHT: '#c1ffdf',
  WARNING: '#B54708',
  BLUE_OCEAN: '#EBFFF5',
};

export const iOSColors = {
  red: '#ff2d55',
  green: '#4cd964',
  blue: '#007aff',
  yellow: '#ffcc00',
  orange: '#ff9500',
  purple: '#5856d6',
  lightBlue: '#5ac8fa',
  grey: '#666666',
  lightGrey: 'rgba(230,230,242,0.7)', //'#D3D3D3',
};

export function hexToRgb(h: string) {
  const hex = h.substring(1);
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return r + ',' + g + ',' + b;
}

export function colouredHex(hex: string, opacity?: number) {
  return `rgba(${hexToRgb(hex)}, ${opacity ?? 1})`;
}

export const ChartColors = {
  BACKGROUND: {
    0: '#16215A',
    1: '#7DFBA2',
    2: '#58b086',
    3: '#f97dfb',
    4: '#ae58b0',
    5: '#fbd47d',
    6: '#fb957d',
    7: '#e3fb7d',
    8: '#7d9ffb',
    9: '#586fb0',
    10: '#8d90fb',
    11: '#392F5A',
    12: '#41658A',
    13: '#9C528B',
    14: '#1D1E2C',
    15: '#A9DEF9',
    16: '#D0F4DE',
    17: '#FCF6BD',
    18: '#E4C1F9',
    19: '#FAB2EA',
    20: '#4A051C',
    21: '#830A48',
    22: '#384E77',
  },
  TEXT: {
    0: '#FFFFFF',
    1: '#000000',
    2: '#000000',
    3: '#FFFFFF',
    4: '#FFFFFF',
    5: '#000000',
    6: '#000000',
    7: '#000000',
    8: '#000000',
    9: '#FFFFFF',
    10: '#000000',
    11: '#FFFFFF',
    12: '#FFFFFF',
    13: '#FFFFFF',
    14: '#FFFFFF',
    15: '#000000',
    16: '#000000',
    17: '#000000',
    18: '#000000',
    19: '#000000',
    20: '#FFFFFF',
    21: '#FFFFFF',
    22: '#FFFFFF',
  },
};
