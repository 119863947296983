import { Grid, Box } from '@mui/material';
import PlainText from '../../text/PlainText';
import SubHeader from '../../text/SubHeader';

interface QuoteCardPropsType {
  darkTheme?: boolean;
  quote: {
    text: string;
    name: string;
    position: string;
    image: string;
    companyImage: string;
  };
}

const QuoteCard: React.FC<QuoteCardPropsType> = ({ quote, darkTheme }) => {
  return (
    <Grid container item columnSpacing={14} rowSpacing={4} justifyContent="center">
      <Grid container item xs={12} sm={8} md={6} lg={3}>
        <img
          src={quote.image}
          alt={quote.name}
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '350px',
            objectFit: 'cover',
            borderRadius: '20px',
          }}
        />
      </Grid>
      <Grid container item xs={12} md={6} lg={8}>
        <Grid container alignContent="center" spacing={4} position="relative">
          <Box position="absolute" top={-20} left={-20}>
            <img
              src="/assets/images/icons/quote.svg"
              alt="quote"
              style={{
                width: 'auto',
                height: '50px',
                maxHeight: '100%',
                minHeight: '80px',
                opacity: 0.7,
              }}
            />
          </Box>
          <Grid item xs={12} pb={2}>
            <SubHeader
              text={quote.text}
              darkTheme={darkTheme}
              sx={{
                fontSize: {
                  xs: 16,
                  sm: 20,
                },

                borderBottom: '1px solid #54596350',
                pb: 3,
                mb: -1,
                opacity: 0.8,
              }}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <PlainText
                text={quote.name}
                darkTheme={darkTheme}
                sx={{
                  fontWeight: 'bold',
                }}
              />
              <PlainText text={quote.position} darkTheme={darkTheme} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              justifyContent={{
                xs: 'flex-start',
                md: 'flex-end',
              }}
            >
              <img
                src={quote.companyImage}
                alt={quote.name}
                style={{
                  width: 'auto',
                  height: 'auto',
                  maxHeight: '50px',
                  objectFit: 'contain',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuoteCard;
