import { Box, SxProps, useTheme } from '@mui/material';
import { FC } from 'react';
import TruncatedText from '../textWrappers/TruncatedText';

interface TagProps {
  text?: string;
  color?: string;
  textColor?: string;
  sx?: SxProps;
}

const Tag: FC<TagProps> = ({ text, color, textColor, sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: color || theme.palette.primary.dark,
        color: textColor || '#fff',
        px: 1.8,
        py: 0,
        borderRadius: 4,
        paddingBottom: 0.5,
        lineHeight: 1,
        mr: 1,
        mb: 1,
        ...sx,
      }}
    >
      <TruncatedText maxLines={1}>
        <small>
          <small>
            <small>
              <strong>
                {text}
              </strong>
            </small>
          </small>
        </small>
      </TruncatedText>
    </Box>
  );
};

export default Tag;
