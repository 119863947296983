import React, { FC, useEffect, useState } from "react";

interface RowProps {
  size:number;
  percentage:number|undefined;
  rotation?:number;
  color:string;
  sx?:any;
  darkTheme?:boolean;
}

const CircularSegment: FC<RowProps> = ({
  size,
  percentage,
  rotation,
  color,
  darkTheme,
  sx,
}) => {

  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    setProgress(percentage||0);
  }, [percentage]);

  const strokeWidth = size/2;
  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <svg
      key={`${rotation}_circ`}
      width={size}
      height={size}
      viewBox={viewBox}
      display={'block'}
      style={{
        minWidth:size, minHeight:size,
        //filter:'drop-shadow( 0px 0px 4px rgba(0, 0, 0, 0.2))',
        ...sx
    }}
    >
      <circle
        key={`${rotation}_circ_inner`}
        fill="none"
        stroke={color}
        cx={size/2}
        cy={size/2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-${90-(rotation||0)} ${size/2} ${size/2})`}
        strokeDasharray={`${dash}, ${circumference - dash}`}
        style={{transition:'transform 0.5s ease-in-out'}}
      />
    </svg>
  );
};

export default CircularSegment;
