import { FC, useEffect, useMemo, useState } from 'react';
import { Grid, SxProps } from '@mui/material';
import { Layer } from '@fides-jam/front/components/Layer';
import { useTranslation } from 'react-i18next';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { RouteNames } from '../../../Routes/routeNames';
import { ThemeColors } from '../../../utils/colors';
import SolutionsMenuItem from './components/SolutionsMenu.item';
import { ReactComponent as Group } from '/assets/images/icons/group.svg';
import { ReactComponent as MediumSize } from '/assets/images/icons/medium-size.svg';
import { ReactComponent as StartUp } from '/assets/images/icons/start-up.svg';
import { ReactComponent as CFO } from '/assets/images/icons/cfo.svg';
import { ReactComponent as Counsel } from '/assets/images/icons/counsel.svg';
import { ReactComponent as ExecutiveBoard } from '/assets/images/icons/executive-board.svg';
import { ReactComponent as SupervisoryBoard } from '/assets/images/icons/supervisory-board.svg';

interface SolutionsMenuPropsType {
  darkTheme: boolean;
  sx?: SxProps;
  open?: boolean;
  closeMenu: () => void;
  backdrop?: boolean;
}

const SolutionsMenu: FC<SolutionsMenuPropsType> = ({
  darkTheme,
  open,
  sx,
  closeMenu,
  backdrop,
}) => {
  const { t } = useTranslation();

  const titleColor = useMemo(
    () =>
      darkTheme
        ? hover
          ? ThemeColors.dark.PRIMARY_COMPLEMENT
          : ThemeColors.dark.PRIMARY
        : ThemeColors.light.PRIMARY,
    [darkTheme]
  );

  const [container_1, setContainer_1] = useState<boolean>(false);
  const [container_2, setContainer_2] = useState<boolean>(false);

  const hover = useMemo(() => container_1 || container_2, [container_1, container_2]);

  useEffect(() => {
    if (!hover) closeMenu();
  }, [hover]);

  return (
    <>
      {backdrop && (
        <Layer
          sx={{
            display: {
              xs: open ? 'block' : 'none',
              lg: open ? 'block' : 'none',
            },
            transition: 'background 700ms ease-in-out',
            background: darkTheme ? 'rgba(16, 24, 40, 0.7)' : 'rgba(255, 255, 255, 0.7)',
            top: '60px',
            backdropFilter: 'blur(10px)',
          }}
        />
      )}
      <Layer
        sx={{
          display: {
            xs: open ? 'block' : 'none',
            lg: open ? 'block' : 'none',
          },
          background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
          transition: 'background 700ms ease-in-out',
          top: '70px',
          left: 'unset',
          bottom: 'unset',
          minWidth: '200px',
          minHeight: '20px',
          borderRadius: '20px',
          p: 0,
          border: `1px solid ${darkTheme ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'}`,
          overflow: 'visible',
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          ...sx,
        }}
        onMouseEnter={() => setContainer_2(true)}
        onMouseLeave={() => setContainer_2(false)}
      >
        <Layer
          sx={{
            top: '-75px',
            height: '75px',
            bottom: '100px',
            right: '140px',
            left: '140px',
            maxWidth: '120px',
            cursor: 'pointer',
            flexDirection: 'row',
            display: 'flex',
          }}
          onMouseEnter={() => setContainer_1(true)}
          onMouseLeave={() => setContainer_1(false)}
        />
        <Grid
          container
          maxHeight="calc(100svh - 70px)"
          width="100%"
          maxWidth={{
            xs: 'unset',
            lg: '600px',
          }}
          p={3.5}
        >
          <Grid container item xs={12} justifyContent="space-between" spacing={3}>
            <Grid item container xs={12} md={5.5} spacing={2} alignContent="flex-start">
              <Grid
                item
                sx={{
                  transition: 'color 300ms ease-in-out',
                  color: titleColor,
                  fontSize: '1.2rem',
                }}
              >
                <strong>{t('NAVBAR.SOLUTIONS.COMPANY_SIZE')}</strong>
              </Grid>
              <Grid container item spacing={1.75}>
                <Grid item xs={12}>
                  <SolutionsMenuItem
                    title={t('NAVBAR.SOLUTIONS.ENTERPRISE')}
                    Icon={Group}
                    darkTheme={darkTheme}
                    href={RouteNames.enterprise}
                    onClick={closeMenu}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SolutionsMenuItem
                    title={t('NAVBAR.SOLUTIONS.MEDIUM_SIZED_COMPANY')}
                    Icon={MediumSize}
                    darkTheme={darkTheme}
                    href={RouteNames.mittelstand}
                    onClick={closeMenu}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                   <SolutionsMenuItem
                     title={t('NAVBAR.SOLUTIONS.START_UP')}
                     icon={<StartUp />}
                     darkTheme={darkTheme}
                     href={RouteNames.about}
                   />
                 </Grid> */}
              </Grid>
            </Grid>
            <Grid item container xs={12} md="auto" justifyContent="center">
              <Grid
                item
                xs={12}
                bgcolor={titleColor}
                sx={{ opacity: 0.2 }}
                height={{
                  xs: '2px',
                  md: 'unset',
                }}
                width={{
                  xs: 'unset',
                  md: '2px',
                }}
                borderRadius="10px"
              ></Grid>
            </Grid>
            <Grid item container xs={12} md={5.5} spacing={2} alignContent="flex-start">
              <Grid
                item
                sx={{
                  transition: 'color 300ms ease-in-out',
                  color: titleColor,
                  fontSize: '1.2rem',
                }}
              >
                <strong> {t('NAVBAR.SOLUTIONS.ROLE')}</strong>
              </Grid>
              <Grid container item spacing={1.75} xs={12}>
                <Grid item xs={12}>
                  <SolutionsMenuItem
                    title={t('NAVBAR.SOLUTIONS.COUNSEL')}
                    Icon={MediumSize}
                    darkTheme={darkTheme}
                    href={RouteNames.fidesForCounsels}
                    onClick={closeMenu}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <SolutionsMenuItem
                    title={t('NAVBAR.SOLUTIONS.CFO')}
                    icon={<CFO />}
                    darkTheme={darkTheme}
                    href={about}
                    onClick={closeMenu}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SolutionsMenuItem
                    title={t('NAVBAR.SOLUTIONS.EXECUTIVE_BOARD')}
                    icon={<ExecutiveBoard />}
                    darkTheme={darkTheme}
                    href={}
                    onClick={closeMenu}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <SolutionsMenuItem
                    title={t('NAVBAR.SOLUTIONS.SUPERVISORY_BOARD')}
                    Icon={SupervisoryBoard}
                    darkTheme={darkTheme}
                    href={RouteNames.supervisoryBoard}
                    onClick={closeMenu}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Layer>
    </>
  );
};

export default SolutionsMenu;
