import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as PhoneIcon } from '/assets/images/icons/phone.svg';
import PlainText from '../text/PlainText';
import { ThemeColors } from '../../utils/colors';

interface PhoneNumberCardPropsType {
  darkTheme: boolean;
}

const PhoneNumberCard: React.FC<PhoneNumberCardPropsType> = ({ darkTheme }) => {
  const [isEurope, setIsEurope] = useState<boolean>(true);
  const [polling, setPolling] = useState<boolean>(true);

  useEffect(() => {
    const checkCountry = () => {
      const storedCountry = sessionStorage.getItem('uc_user_country');
      if (storedCountry) {
        const country = JSON.parse(storedCountry);
        const europeanUnion = [
          'AT',
          'BE',
          'BG',
          'CY',
          'CZ',
          'DE',
          'DK',
          'EE',
          'ES',
          'FI',
          'FR',
          'GR',
          'HR',
          'HU',
          'IE',
          'IT',
          'LT',
          'LU',
          'LV',
          'MT',
          'NL',
          'PL',
          'PT',
          'RO',
          'SE',
          'SI',
          'SK',
        ];

        if (country && europeanUnion.includes(country.countryCode)) {
          setIsEurope(true);
        } else {
          setIsEurope(false);
        }
        setPolling(false);
      }
    };

    let intervalId: number;
    if (polling) {
      intervalId = window.setInterval(checkCountry, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [polling]);

  return (
    <a
      href={isEurope ? 'tel:+498912085471' : 'tel:+19292553972'}
      style={{
        textDecoration: 'none',
        position: 'absolute',
        width: '100%',
        top: '10px',
      }}
    >
      <Grid
        display={{ xs: 'none', lg: 'flex' }}
        container
        position="absolute"
        height="auto"
        width="auto"
        maxWidth={300}
        bgcolor={darkTheme ? ThemeColors.dark.PRIMARY : ThemeColors.light.PRIMARY}
        gap={1.25}
        px={2}
        py={0.75}
        borderRadius={4}
        boxShadow={
          darkTheme ? '0px 0px 4px rgba(0, 0, 0, 0.25)' : '0px 0px 4px rgba(0, 0, 0, 0.25)'
        }
        border={darkTheme ? '2px solid #31dc86' : '2px solid #4395bf'}
        right={{ lg: '45px', xl: '110px' }}
      >
        <Grid item container xs="auto" alignItems="center">
          <PhoneIcon
            height="17px"
            width="17px"
            fill={darkTheme ? 'black' : 'white'}
            style={{ transition: 'fill 0.6s ease-in-out' }}
          />
        </Grid>
        <Grid item container xs="auto" minWidth="max-content">
          <PlainText
            darkTheme={!darkTheme}
            text={isEurope ? '+49 89 12085471' : '+1 929 255 3972'}
            opacity
            sx={{ fontWeight: 600, fontSize: '14px' }}
          />
        </Grid>
      </Grid>
    </a>
  );
};
export default PhoneNumberCard;
