import { Grid, Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FadeSlide from '../layout/container/FadeSlide.container';
import PlainText from '../text/PlainText';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import SubTitle from '../text/SubTitle';

interface QuoteCardPropsType {
  darkTheme?: boolean;
  quote: {
    text: string;
    name: string;
    position: string;
    image: string;
  };
}

const QuoteCard: FC<QuoteCardPropsType> = ({ quote, darkTheme }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      bgcolor={AppSignUpColors.PRIMARY_DARKER}
      borderRadius="20px"
      rowSpacing={8}
      p={{
        xs: 4,
        sm: 5,
        md: 6,
        lg: 7,
      }}
    >
      <FadeSlide
        rowSpacing={2}
        sx={{
          textAlign: 'left',
          padding: {
            xs: 0,
            md: '2rem 4rem ',
          },
        }}
        direction="up"
        once
      >
        <Grid container columnSpacing={14} rowSpacing={4}>
          <Grid container item xs={12} md={6} lg={8}>
            <Grid container alignContent="flex-start" spacing={4} position="relative">
              <Box position="absolute" top={-20} left={-20}>
                <img
                  src="/assets/images/icons/quote.svg"
                  alt="quote"
                  style={{
                    width: 'auto',
                    height: '50px',
                    maxHeight: '100%',
                    minHeight: '80px',
                    opacity: 0.7,
                  }}
                />
              </Box>
              <Grid item xs={12}>
                <SubTitle
                  text={quote.text}
                  darkTheme={darkTheme}
                  sx={{
                    fontSize: {
                      xs: 16,
                      md: 30,
                    },
                    opacity: 0.8,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PlainText
                  text={quote.name}
                  darkTheme={darkTheme}
                  sx={{
                    fontWeight: 'bold',
                  }}
                />
                <PlainText text={quote.position} darkTheme={darkTheme} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={4}>
            <img
              src={quote.image}
              alt={quote.name}
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '400px',
                objectFit: 'cover',
                borderRadius: '20px',
              }}
            />
          </Grid>
        </Grid>
      </FadeSlide>
    </Grid>
  );
};

export default QuoteCard;
