import { Grid } from '@mui/material';
import PlainText from '../text/PlainText';

interface ListPropsType {
  darkTheme: boolean;
  list: string[];
}

const List: React.FC<ListPropsType> = ({ list, darkTheme }) => {
  return (
    <Grid container>
      <ul
        style={{
          marginTop: 0,
        }}
      >
        {list.map((item) => (
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-start"
            alignItems="center"
            pb={1}
            key={item}
          >
            <Grid item xs={12}>
              <li
                style={{
                  color: darkTheme ? 'white' : 'black',
                }}
              >
                <PlainText text={item} darkTheme={darkTheme} opacity />
              </li>
            </Grid>
          </Grid>
        ))}
      </ul>
    </Grid>
  );
};

export default List;
