import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { useTranslation } from 'react-i18next';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Row from '../../components/layout/rows/Row';
import NavBar from '../../components/Navbar';
import PlainText from '../../components/text/PlainText';
import SubTitle from '../../components/text/SubTitle';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';

const LegalNoticePage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        transition: 'background 0.6s ease-in-out',
        background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
      }}
    >
      <Grid container item xs={12} rowSpacing={10} justifyContent="center" alignItems="center">
        <Row
          sx={{
            mt: {
              xs: 0,
              md: 3,
              lg: 10,
            },
          }}
          rowSpacing={0}
        >
          <FadeSlide
            rowSpacing={2}
            sx={{
              textAlign: 'center',
              py: 10,
              px: {
                xs: 1,
                sm: 5,
              },
              mt: 10,
              justifyContent: 'center',
            }}
            direction={'up'}
          >
            <Grid item xs={12} lg={10}>
              <Title
                text={t('IMPRINT.TITLE')}
                mainTitle
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 45,
                    lg: 55,
                    xl: 68,
                  },
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                }}
              />
            </Grid>
          </FadeSlide>
        </Row>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={11}
        md={11}
        lg={10}
        xl={10}
        rowSpacing={10}
        justifyContent="center"
        alignItems="center"
      >
        <Row
          columnSpacing={isSmall ? 0 : 10}
          flexDirection={'row-reverse'}
          sx={{
            mt: {
              xs: -5,
              sm: 0,
            },
          }}
        >
          <FadeSlide direction={'up'} rowSpacing={3} transitionDelay={400} once>
            <Grid item sx={{ display: { xs: 'block', md: 'block' } }}>
              <SubTitle text={t('IMPRINT.SECTIONS.DETAILS.LABEL')} darkTheme={darkTheme} />
              <Grid>
                <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.DETAILS.ADDRESS')} />
              </Grid>
              <Grid>
                <PlainText darkTheme={darkTheme}>
                  <p>
                    <a href="mailto:hello@fides.technology">hello@fides.technology</a>
                    <br />
                    <a href="tel:+49 89 12085471">+49 89 12085471</a>
                  </p>
                </PlainText>
              </Grid>
              <Grid>
                <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.DETAILS.REGISTER')} />
              </Grid>
              <Grid>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('IMPRINT.SECTIONS.DETAILS.REPRESENTATIVES')}
                />
              </Grid>
              <Grid>
                <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.DETAILS.VAT')} />
              </Grid>
            </Grid>
            <Grid item sx={{ display: { xs: 'block', md: 'block' } }}>
              <SubTitle text={t('IMPRINT.SECTIONS.REFERENCE.LABEL')} darkTheme={darkTheme} />
              <Grid item>
                <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.REFERENCE.TEXT')} />
              </Grid>
            </Grid>
            <Grid item sx={{ display: { xs: 'block', md: 'block' } }}>
              <SubTitle text={t('IMPRINT.SECTIONS.CONTENT.LABEL')} darkTheme={darkTheme} />
              <Grid>
                <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.DETAILS.ADDRESS')} />
              </Grid>
              <Grid>
                <PlainText>
                  {' '}
                  <p>
                    {' '}
                    {t('IMPRINT.SECTIONS.DETAILS.CONTACT')}{' '}
                    <a href="mailto:hello@fides.technology">hello@fides.technology</a>
                  </p>
                </PlainText>
              </Grid>
            </Grid>
            <Grid item sx={{ display: { xs: 'block', md: 'block' } }}>
              <SubTitle text={t('IMPRINT.SECTIONS.COPYRIGHT.LABEL')} darkTheme={darkTheme} />
              <Grid item>
                <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.COPYRIGHT.TEXT')} />
              </Grid>
            </Grid>
            <Grid item sx={{ display: { xs: 'block', md: 'block' } }}>
              <SubTitle text={t('IMPRINT.SECTIONS.LEGALNOTICE.LABEL')} darkTheme={darkTheme} />
              <Grid item>
                <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.LEGALNOTICE.TEXT')} />
              </Grid>
            </Grid>
            <Grid item sx={{ display: { xs: 'block', md: 'block' } }}>
              <SubTitle text={t('IMPRINT.SECTIONS.ACCOUNTABLE.LABEL')} darkTheme={darkTheme} />
              <Grid item>
                <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.ACCOUNTABLE.TEXT')} />
              </Grid>
            </Grid>
          </FadeSlide>
        </Row>

        <Row sx={{ mt: 3 }}>
          <Footer />
        </Row>
      </Grid>
    </Grid>
  );
};

export default LegalNoticePage;
