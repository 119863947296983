import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { AppColors } from '@fides-jam/utils/theme/colors';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { useNavigate } from 'react-router-dom';
import Row from '../../components/layout/rows/Row';
import Footer from '../../components/views/Footer';
import Header from '../../components/text/Header';
import Label from '../../components/text/Label';
import PlainText from '../../components/text/PlainText';
import CircleOfCounselsSection from '../../components/sections/CircleOfCounsels.section';
import Title from '../../components/text/Title';
import { PageWrapper, Section } from '../../components/layout';
import { HubspotFormWrapperWithTracking } from '../../components/HubspotFormWrapperWithTracking';

const BuyersGuidePage: React.FC = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  if (language !== 'en') {
    navigate(`/en/buyers-guide`, { replace: true });
    window.location.reload();
  }

  return (
    <PageWrapper>
      <Section fullWidth spacing="lg" fadeIn>
        <Grid item container xs={12} md={5} rowSpacing={1}>
          <Grid item xs={12}>
            <Label text={t('BUYERS_GUIDE.LABEL')} highlighted darkTheme={darkTheme} />
          </Grid>
          <Grid item xs={12}>
            <Header text={t('BUYERS_GUIDE.TITLE')} darkTheme={darkTheme} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              mt: 3,
            }}
          >
            <PlainText darkTheme={darkTheme} text={t('BUYERS_GUIDE.DESCRIPTION.1')} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: 3,
            }}
          >
            <PlainText darkTheme={darkTheme} text={t('BUYERS_GUIDE.DESCRIPTION.2')} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: 3,
            }}
          >
            <PlainText darkTheme={darkTheme} text={t('BUYERS_GUIDE.DESCRIPTION.3')} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            item
            container
            sx={{
              background: '#F7F7F7',
              p: 5,
            }}
            justifyContent="center"
            borderRadius="10px"
          >
            <Grid item xs={12}>
              <img
                src="/assets/images/buyers-guide/buyers-guide.webp"
                style={{
                  display: 'block',
                  width: '100%',
                  borderRadius: '20px',
                }}
                alt="Fides Technology Buyers Guide"
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <HubspotFormWrapperWithTracking
                key={language}
                portalId="25457524"
                formId="954c1495-e317-4c65-9926-526fbed94a10"
                gtagEventId="AW-10855235169/sVriCJ2HxJ0ZEOH8lrgo"
              />
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <Section bgcolor={AppSignUpColors.PRIMARY_DARKER}>
        <Grid item textAlign="center">
          <Title text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.INTRO.TITLE_1')} darkTheme={true} large />
          <Title
            text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.INTRO.TITLE_2')}
            sx={{
              color: AppColors.PRIMARY,
            }}
            large
          />
        </Grid>
      </Section>

      <CircleOfCounselsSection />

      <Row sx={{ mt: 3 }}>
        <Footer />
      </Row>
    </PageWrapper>
  );
};

export default BuyersGuidePage;
