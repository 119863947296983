import { Grid } from '@mui/material';
import { FC } from 'react';

interface SpinnerPropsType {
  darkTheme?: boolean;
  center?: boolean;
}

export const Spinner: FC<SpinnerPropsType> = ({ darkTheme, center = true }) => {
  const spinner = (
    <span
      className="loader"
      style={{
        border: darkTheme ? '5px solid black' : '5px solid white',
      }}
    ></span>
  );

  return center ? (
    <Grid container justifyContent="center" alignItems="center">
      {spinner}
    </Grid>
  ) : (
    spinner
  );
};
