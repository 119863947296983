import { FC } from 'react';
import { Box, Grid, SxProps } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TruncatedText from '@fides-jam/front/components/textWrappers/TruncatedText';
import AnimatedSlide from '../../layout/container/AnimatedSlide.container';

interface UserRowPropsType {
  sx?: SxProps;
  name: string;
  position: string;
  imageUrl: string;
  vote: boolean;
  line: boolean;
  index: number;
  animate?: boolean;
}

const UserRow: FC<UserRowPropsType> = ({
  sx,
  name,
  position,
  imageUrl,
  vote,
  line,
  index,
  animate,
}) => {
  return (
    <AnimatedSlide animate={animate} direction={'up'} transitionDelay={100 * index}>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={{
          pl: 0,
          px: 0,
          py: 1.5,
          borderBottom: line ? '2px solid rgba(0,0,0,0.05)' : 'unset',
        }}
      >
        <Box
          sx={{
            background: 'rgba(0,0,0,0.05)',
            height: '60px',
            width: '60px',
            borderRadius: '50%',
            mr: 1.5,
            position: 'relative',
            backgroundSize: 'cover',
            backgroundImage: `url("${imageUrl}")`,
            backgroundPositionX: 'center',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: '-8px',
              bottom: '-8px',
              background: '#FFF',
              borderRadius: '50%',
            }}
          >
            {vote ? (
              <CheckCircleIcon
                fontSize={'medium'}
                color={'success'}
                sx={{
                  display: 'block',
                }}
              />
            ) : (
              <CancelIcon
                fontSize={'medium'}
                color={'warning'}
                sx={{
                  display: 'block',
                }}
              />
            )}
          </Box>
        </Box>
        <Grid
          container
          item
          direction={'column'}
          sx={{
            flex: 1,
            px: 1,
            py: 1,
          }}
        >
          <Grid
            item
            sx={{
              fontSize: '18px',
              fontWeight: '700',
              lineHeight: 1,
            }}
          >
            <TruncatedText maxLines={1}>{name}</TruncatedText>
          </Grid>
          <Grid
            item
            sx={{
              opacity: 0.4,
            }}
          >
            <TruncatedText maxLines={1}>{position}</TruncatedText>
          </Grid>
        </Grid>
      </Grid>
    </AnimatedSlide>
  );
};

export default UserRow;
