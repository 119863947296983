import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery, useTheme, Box } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import Row from '../../components/layout/rows/Row';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import Header from '../../components/text/Header';
import Label from '../../components/text/Label';
import PlainText from '../../components/text/PlainText';
import ImageScroll from '../../components/views/CircleOfCounsels/imageScroll.row';
import HButton from '../../components/buttons/Highlight.button';
import { RouteNames } from '../../Routes/routeNames';
import { ThemeColors } from '../../utils/colors';
import { PageWrapper, Section } from '../../components/layout';

const AboutUsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const [selectedFounder, setSelectedFounder] = useState<string>('');

  const impressionsImages: { src: string; description?: string }[] = [
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_1.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_1')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_2.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_2')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_3.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_3')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_4.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_4')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_5.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_5')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_6.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_6')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_7.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_7')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_8.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_8')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_9.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_9')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_10.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_10')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_11.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_11')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_12.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_12')}`,
    },
    {
      src: '/assets/images/about-us/impressions/about_us_impressions_13.webp',
      description: `${t('ABOUT.SECTIONS.IMPRESSIONS.IMAGES.IMG_13')}`,
    },
  ];

  const founderObj: {
    name: string;
    title: string;
    short_title: string;
    image: string;
    description: string;
  }[] = [
    {
      name: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.LISA.TITLE')}`,
      title: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.LISA.LABEL')}`,
      short_title: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.LISA.SHORT_LABEL')}`,
      image: '/assets/images/founders/lisa.webp',
      description: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.LISA.TEXT')}`,
    },
    {
      name: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.PHILIPPA.TITLE')}`,
      title: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.PHILIPPA.LABEL')}`,
      short_title: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.PHILIPPA.SHORT_LABEL')}`,
      image: '/assets/images/founders/philippa.webp',
      description: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.PHILIPPA.TEXT')}`,
    },
    {
      name: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.VINCENT.TITLE')}`,
      title: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.VINCENT.LABEL')}`,
      short_title: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.VINCENT.SHORT_LABEL')}`,
      image: '/assets/images/founders/vincent.webp',
      description: `${t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.FOUNDER_DETAILS.VINCENT.TEXT')}`,
    },
  ];

  return (
    <PageWrapper>
      <Section bgcolor="#E9FEEB">
        <Grid item xs={12} md={6.5}>
          <Grid container item spacing={2} xs={12} md={12}>
            <Grid item xs={12}>
              <Label text={t('ABOUT.LABEL')} darkTheme={darkTheme} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Title
                text={t('ABOUT.TITLE')}
                darkTheme={darkTheme}
                mainTitle
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 30,
                    lg: 40,
                    xl: 48,
                  },
                  mb: 2,
                }}
              />
            </Grid>
            <Grid container item spacing={4} xs={12} md={11.5} textAlign="justify">
              <Grid item>
                <PlainText text={t('ABOUT.TEXT_1')} />
              </Grid>

              <Grid item>
                <PlainText text={t('ABOUT.TEXT_2')} sx={{ display: 'inline' }} />
                {isSmall ? ' ' : <br />}
                <PlainText
                  text={t('ABOUT.TEXT_3')}
                  sx={{ fontWeight: 'bold', display: 'inline' }}
                />
              </Grid>
              <Grid item>
                <PlainText text={t('ABOUT.TEXT_4')} sx={{ fontWeight: 'bold' }} />
              </Grid>
              <Grid item>
                <PlainText text={t('ABOUT.TEXT_5')} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5.5}>
          <img
            src="/assets/images/about-us/title_img_about_us.webp"
            alt="About Us"
            style={{
              width: '100%',
              height: isSmall ? '400px' : '700px',
              objectFit: 'cover',
              borderRadius: '10px',
              boxShadow: '0px 0px 60px 0px rgba(0,0,0,0.09)',
            }}
          />
        </Grid>
      </Section>

      <Section>
        <Grid item textAlign="center">
          <Title
            text={t('ABOUT.SECTIONS.IMPRESSIONS.TITLE')}
            sx={{
              fontSize: {
                xs: 30,
                sm: 30,
                lg: 40,
                xl: 48,
              },
            }}
          />
        </Grid>
        <Grid container item justifyContent="center">
          <ImageScroll images={impressionsImages} />
        </Grid>
        <Grid container item justifyContent="center">
          <Grid item>
            <a href={RouteNames.jobs} style={{ textDecoration: 'none' }}>
              <HButton size="large" fullWidth sx={{ px: 5 }} darkTheme={true}>
                {t('ABOUT.SECTIONS.IMPRESSIONS.BUTTON_TEXT')}
              </HButton>
            </a>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid item xs={12} md={6} lg={5}>
          <Grid item>
            <Title
              text={t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.TITLE')}
              darkTheme={darkTheme}
              sx={{
                fontSize: {
                  xs: 30,
                  sm: 30,
                  lg: 40,
                  xl: 48,
                },
                mb: 4,
                mt: -0.45,
              }}
            />

            <Grid container item spacing={4} xs={12} textAlign="justify">
              <Grid item>
                <PlainText text={t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.TEXT_1')} />
              </Grid>
              <Grid item>
                <PlainText text={t('ABOUT.SECTIONS.MEET_OUR_FOUNDERS.TEXT_2')} />
              </Grid>
              <Grid container item spacing={2} mt={1.5}>
                {founderObj.map((founder) => (
                  <Grid item xs={12} key={founder.name}>
                    <Box
                      onClick={() =>
                        setSelectedFounder(
                          `${selectedFounder !== founder.name ? founder.name : ''}`
                        )
                      }
                    >
                      <HButton
                        size="large"
                        darkTheme={true}
                        fullWidth
                        sx={{
                          backgroundColor:
                            selectedFounder === founder.name
                              ? ThemeColors.dark.PRIMARY_COMPLEMENT
                              : '',
                          color: selectedFounder === founder.name ? '#FFF' : '',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {founder.name}, {founder.short_title}
                      </HButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={5} mt="0.4rem">
          {selectedFounder ? (
            founderObj
              .filter((founder) => founder.name === selectedFounder)
              .map((founder) => (
                <FadeSlide direction="up" key={founder.name}>
                  <Grid container item spacing={2} xs={12} textAlign="justify">
                    <Grid
                      container
                      item
                      spacing={5}
                      xs={12}
                      alignItems="center"
                      mb={2}
                      justifyContent="center"
                    >
                      <Grid item xs={6}>
                        <img
                          src={founder.image}
                          alt={founder.name}
                          style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover',
                            borderRadius: '10px',
                            boxShadow: '0px 0px 60px 0px rgba(0,0,0,0.09)',
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Header
                          text={founder.name}
                          darkTheme={darkTheme}
                          sx={{
                            fontSize: {
                              xs: 30,
                              sm: 30,
                              lg: 40,
                              xl: 48,
                            },
                            mb: 1,
                          }}
                        />
                        <Label text={founder.title} highlighted darkTheme={darkTheme} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <PlainText text={founder.description} />
                    </Grid>
                  </Grid>
                </FadeSlide>
              ))
          ) : (
            <img
              src="/assets/images/about-us/founder_team.webp"
              alt="Fides Founder Team"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
                boxShadow: '0px 0px 60px 0px rgba(0,0,0,0.09)',
                objectPosition: '38% center',
              }}
            />
          )}
        </Grid>
      </Section>

      <Section bgcolor="#101828" fullWidth>
        <Grid item textAlign="center">
          <Title
            text={t('ABOUT.SECTIONS.INVESTORS.TITLE')}
            sx={{
              fontSize: {
                xs: 30,
                sm: 30,
                lg: 40,
                xl: 48,
              },
              color: '#4BFF9D',
            }}
          />
        </Grid>

        <Grid container item>
          <Grid container alignContent="center" alignItems="center" spacing={5} mb={1}>
            <Grid item>
              <Label
                text="VCs"
                sx={{
                  color: '#4BFF9D',
                }}
                darkTheme={true}
              />
            </Grid>
          </Grid>
          <Grid container alignContent="center" alignItems="center" spacing={10} mb={5}>
            <Grid
              item
              xs={6}
              md={3}
              sx={{
                filter: 'saturate(0)',
                '&:hover': { filter: 'saturate(1)' },
                transition: 'filter 0.3s ease-in-out',
              }}
            >
              <a href="https://www.lafamiglia.vc/" target="_blank" style={{ width: '100%' }}>
                <Grid container bgcolor="white" borderRadius={2}>
                  <img
                    src="/assets/images/about-us/vcs/la-famiglia-vc.webp"
                    alt="La Famiglia VC Logo"
                    style={{
                      width: '100%',
                      height: '100px',
                      objectFit: 'contain',
                      borderRadius: '10px',
                      padding: '15px',
                    }}
                  />
                </Grid>
              </a>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              sx={{
                filter: 'saturate(0)',
                '&:hover': { filter: 'saturate(1)' },
                transition: 'filter 0.3s ease-in-out',
              }}
            >
              <a href="https://www.sequoiacap.com/" target="_blank" style={{ width: '100%' }}>
                <Grid container bgcolor="white" borderRadius={2}>
                  <img
                    src="/assets/images/about-us/vcs/sequoia-capital.webp"
                    alt="Sequoia Capital Logo"
                    style={{
                      width: '100%',
                      height: '100px',
                      objectFit: 'contain',
                      borderRadius: '10px',
                      padding: '15px',
                    }}
                  />
                </Grid>
              </a>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              sx={{
                filter: 'saturate(0)',
                '&:hover': { filter: 'saturate(1)' },
                transition: 'filter 0.3s ease-in-out',
              }}
            >
              <a href="https://auxxo.de/" target="_blank" style={{ width: '100%' }}>
                <Grid
                  container
                  bgcolor="white"
                  borderRadius={2}
                  sx={{
                    filter: 'saturate(0)',
                    '&:hover': { filter: 'saturate(1)' },
                    transition: 'filter 0.3s ease-in-out',
                  }}
                >
                  <img
                    src="/assets/images/about-us/vcs/auxxo.webp"
                    alt="Auxxo Logo"
                    style={{
                      width: '100%',
                      height: '100px',
                      objectFit: 'contain',
                      borderRadius: '10px',
                      padding: '15px',
                    }}
                  />
                </Grid>
              </a>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              sx={{
                filter: 'saturate(0)',
                '&:hover': { filter: 'saturate(1)' },
                transition: 'filter 0.3s ease-in-out',
              }}
            >
              <a href="https://www.angelinvest.ventures/" target="_blank" style={{ width: '100%' }}>
                <Grid container bgcolor="white" borderRadius={2}>
                  <img
                    src="/assets/images/about-us/vcs/angel-invest.webp"
                    alt="Angel Invest Logo"
                    style={{
                      width: '100%',
                      height: '100px',
                      objectFit: 'contain',
                      borderRadius: '10px',
                      padding: '3px',
                    }}
                  />
                </Grid>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid container alignContent="center" alignItems="center" spacing={5} mb={1}>
            <Grid item>
              <Label
                text="Angels"
                sx={{
                  color: '#4BFF9D',
                }}
                darkTheme={true}
              />
            </Grid>
          </Grid>
          <Grid container alignContent="center" alignItems="center" spacing={10} mb={5}>
            <Grid item xs={12} md={6} lg={3}>
              <Grid container bgcolor="white" borderRadius={3} overflow="hidden">
                <Grid
                  container
                  spacing={1.5}
                  alignItems="center"
                  minHeight="180px"
                  sx={{
                    filter: 'saturate(0)',
                    '&:hover': { filter: 'saturate(1)' },
                    transition: 'filter 0.3s ease-in-out',
                  }}
                >
                  <Grid container item xs={5} height="100%">
                    <img
                      src="/assets/images/about-us/angels/people/russ-heddleston.webp"
                      alt="Russ Heddleston"
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  </Grid>
                  <Grid item container xs={7} pr={2} my={2}>
                    <Grid item mb={1.5}>
                      <PlainText
                        text={t('ABOUT.SECTIONS.INVESTORS.ANGELS.RUSS_HEDDLESTON')}
                        sx={{ fontSize: '0.9rem' }}
                      />
                    </Grid>
                    <Grid container item alignItems="center">
                      <Grid item container xs={10}>
                        <img
                          src="/assets/images/about-us/angels/companies/dropbox.webp"
                          alt="Dropbox Logo"
                          style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain',
                            borderRadius: '10px',
                            padding: '4px 5px',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid container bgcolor="white" borderRadius={3} overflow="hidden">
                <Grid
                  container
                  spacing={1.5}
                  alignItems="center"
                  minHeight="180px"
                  sx={{
                    filter: 'saturate(0)',
                    '&:hover': { filter: 'saturate(1)' },
                    transition: 'filter 0.3s ease-in-out',
                  }}
                >
                  <Grid container item xs={5} height="100%">
                    <img
                      src="/assets/images/about-us/angels/people/christa-davies.webp"
                      alt="Christa Davies"
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  </Grid>
                  <Grid item container xs={7} pr={2} my={2}>
                    <Grid item mb={1.5}>
                      <PlainText
                        text={t('ABOUT.SECTIONS.INVESTORS.ANGELS.CHRISTA_DAVIES')}
                        sx={{ fontSize: '0.9rem' }}
                      />
                    </Grid>
                    <Grid container item alignItems="center">
                      <Grid item container xs={4}>
                        <img
                          src="/assets/images/about-us/angels/companies/aon.webp"
                          alt="AON Logo"
                          style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain',

                            padding: '4px 5px',
                          }}
                        />
                      </Grid>
                      <Grid item container xs={4}>
                        <img
                          src="/assets/images/about-us/angels/companies/stripe.webp"
                          alt="Stripe Logo"
                          style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain',

                            padding: '8px 5px 4px 5px',
                          }}
                        />
                      </Grid>
                      <Grid item container xs={4}>
                        <img
                          src="/assets/images/about-us/angels/companies/workday.webp"
                          alt="Workday Logo"
                          style={{
                            width: '50%',
                            height: 'auto',
                            objectFit: 'contain',

                            padding: '4px 5px',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid container bgcolor="white" borderRadius={3} overflow="hidden">
                <Grid
                  container
                  spacing={1.5}
                  alignItems="center"
                  minHeight="180px"
                  sx={{
                    filter: 'saturate(0)',
                    '&:hover': { filter: 'saturate(1)' },
                    transition: 'filter 0.3s ease-in-out',
                  }}
                >
                  <Grid container item xs={5} height="100%">
                    <img
                      src="/assets/images/about-us/angels/people/cristina-stenbeck.webp"
                      alt="Cristina Stenbeck"
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  </Grid>
                  <Grid item container xs={7} pr={2} my={2}>
                    <Grid item mb={1.5}>
                      <PlainText
                        text={t('ABOUT.SECTIONS.INVESTORS.ANGELS.CHRISTINA_STENBECK')}
                        sx={{ fontSize: '0.9rem' }}
                      />
                    </Grid>
                    <Grid container item alignItems="center">
                      <Grid item container xs={10}>
                        <img
                          src="/assets/images/about-us/angels/companies/kinnevik.webp"
                          alt="Dropbox Logo"
                          style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain',
                            borderRadius: '10px',
                            padding: '4px 5px',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid container bgcolor="white" borderRadius={3} overflow="hidden">
                <Grid
                  container
                  spacing={1.5}
                  alignItems="center"
                  minHeight="180px"
                  sx={{
                    filter: 'saturate(0)',
                    '&:hover': { filter: 'saturate(1)' },
                    transition: 'filter 0.3s ease-in-out',
                  }}
                >
                  <Grid container item xs={5} height="100%">
                    <img
                      src="/assets/images/about-us/angels/people/hannes-ametsreiter.webp"
                      alt="Hannes Ametsreiter"
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  </Grid>
                  <Grid item container xs={7} pr={2} my={2}>
                    <Grid item mb={1.5}>
                      <PlainText
                        text={t('ABOUT.SECTIONS.INVESTORS.ANGELS.HANNES_AMETSREITER')}
                        sx={{ fontSize: '0.9rem' }}
                      />
                    </Grid>

                    <Grid container item alignItems="center">
                      <Grid item container xs={10}>
                        <img
                          src="/assets/images/about-us/angels/companies/vodafone.webp"
                          alt="Dropbox Logo"
                          style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain',

                            padding: '4px 5px',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <Section bgcolor="#F1E6FA" flexDirection="row-reverse">
        <Grid item textAlign="center" xs={12} md={5}>
          <Grid
            container
            item
            sx={{
              background: 'black',
              borderRadius: '30px',
              border: '1px solid white',
              overflow: 'hidden',
              height: {
                xs: '450px',
                md: '580px',
              },
            }}
            minWidth="300px"
          >
            <video
              autoPlay
              loop
              playsInline
              muted
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'relative',
              }}
            >
              <source src="/assets/videos/coc-event.webm" type="video/webm" />
              <source src="/assets/videos/coc-event.mp4" type="video/mp4" />
            </video>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid item xs={12} md={11} mb={4}>
            <Title
              text={t('ABOUT.SECTIONS.CIRCLE_OF_COUNSELS.TITLE')}
              sx={{
                fontSize: {
                  xs: 30,
                  sm: 30,
                  lg: 40,
                  xl: 48,
                },
              }}
              darkTheme={false}
            />
          </Grid>
          <Grid container item spacing={4} xs={12} md={9} textAlign="justify">
            <Grid item>
              <PlainText text={t('ABOUT.SECTIONS.CIRCLE_OF_COUNSELS.TEXT_1')} />
            </Grid>
            <Grid item>
              <PlainText text={t('ABOUT.SECTIONS.CIRCLE_OF_COUNSELS.TEXT_2')} />
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <PlainText
                  text={t('ABOUT.SECTIONS.CIRCLE_OF_COUNSELS.TEXT_3')}
                  sx={{ fontWeight: 'bold' }}
                />
              </Grid>
              <Grid item xs={12}>
                <HButton
                  size="large"
                  fullWidth
                  sx={{ px: 5 }}
                  darkTheme={true}
                  href={RouteNames.circleOfCounsels}
                >
                  {t('ABOUT.SECTIONS.CIRCLE_OF_COUNSELS.BUTTON_TEXT')}
                </HButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <Row sx={{ mt: 3 }}>
        <Footer />
      </Row>
    </PageWrapper>
  );
};

export default AboutUsPage;
