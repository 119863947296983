import { FC } from 'react';
import { RouteNames } from '../../Routes/routeNames';
import { Grid } from '@mui/material';
import LinkComponent from '../Link/Link';

interface LogoPropsType {
  height?: string;
  width?: string;
  darkTheme?: boolean;
}

const SvgComponent = ({
  color,
  height,
  width,
}: {
  color: string;
  height: string;
  width: string;
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1682 566"
    height={height}
    width={width}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`.st0{fill:#FFFFFF;}
	.st1{fill:#33FF99;}`}
    </style>
    <g>
      <g>
        <path
          fill={color}
          d="M516.06134,103.00002h228.5379v40.956H561.5675v113.76282h164.32513v40.95599H561.5675v158.25668h-45.50616V103.00002z"
        />
        <path
          fill={color}
          d="M772.40851,103.00002h45.50366v53.59658h-45.50366V103.00002z M772.40851,204.12473h45.50366v252.80679h-45.50366
        V204.12473z"
        />
        <path
          fill={color}
          d="M1085.37659,103.00002v353.93149h-45.50366V420.022C1021.16394,448.84354,993.86072,463,959.98547,463
        c-67.24695,0-116.29346-50.05624-116.29346-132.47189c0-79.88751,46.51837-132.47185,114.77509-132.47185
        c35.39362,0,62.19067,12.13446,81.40582,38.93399V103.00002H1085.37659z M1041.38879,331.03424
        c0-47.022-10.61853-96.57211-72.3031-96.57211c-56.62836,0-75.3374,35.39117-75.3374,96.06598
        c0,59.15649,15.1687,96.57211,74.32764,96.57211C1016.61377,427.10022,1041.38879,394.74081,1041.38879,331.03424z"
        />
        <path
          fill={color}
          d="M1350.82642,344.18094h-191.6283c1.00977,55.61612,24.26892,82.41318,71.79712,82.41318
        c42.97546,0,66.23462-18.20047,72.3031-56.62836l43.98779,3.54034C1336.16138,432.6626,1295.71399,463,1231.50134,463
        c-38.93408,0-69.27148-11.63083-91.01221-36.90955c-21.23474-23.76529-30.84363-56.6308-30.84363-96.06845
        c0-37.41565,10.61865-70.28116,31.85583-94.04399c22.24695-25.28117,53.08801-37.92175,89.4939-37.92175
        c39.94373,0,77.86304,17.1907,98.59399,50.05623C1344.75806,272.38141,1352.84851,308.28119,1350.82642,344.18094z
         M1171.8363,262.26895c-9.10034,15.1687-12.13452,32.35941-12.13452,45h144.60632c-0.5061-13.65036-5.55981-32.86307-13.65039-45
        c-13.14661-19.21271-31.85327-27.30318-59.15637-27.30318C1203.18591,234.96577,1181.94873,245.58437,1171.8363,262.26895z"
        />
        <path
          fill={color}
          d="M1367.50854,374.01224l41.45972-4.04648c-0.50366,35.39365,25.28113,57.13446,64.71875,57.13446
        c41.46216,0,62.19067-14.15646,62.19067-40.95352c0-28.82153-46.51587-32.35941-81.40332-41.4621
        c-40.95349-10.61612-81.40344-22.75305-81.40344-72.80682c0-52.58435,48.53784-73.82152,93.53784-73.82152
        c53.59668,0,100.11255,21.23717,105.6748,82.92174l-42.4718,6.57214c-1.01221-34.88754-23.76526-53.59413-63.70911-53.59413
        c-24.77502,0-50.55981,11.62836-50.55981,34.88753c0,23.25671,30.3374,29.8313,60.67236,36.40341
        c50.56226,10.61859,102.6405,19.21271,102.6405,78.37164c0,61.68457-53.08801,79.38141-104.15637,79.38141
        C1418.07092,463,1367.00244,436.70657,1367.50854,374.01224z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M412.83435,115.28931v93.3986c0,6.78372-5.50616,12.28929-12.28928,12.28929H218.66359
        c-6.78311,0-12.28929,5.50558-12.28929,12.28929v93.39861c0,6.78372,5.50618,12.28931,12.28929,12.28931h78.65144
        c6.78311,0,12.28931,5.50555,12.28931,12.28928v93.39859c0,6.78372-5.5062,12.28931-12.28931,12.28931h-78.65144
        c-6.78311,0-12.28929-5.50558-12.28929-12.28931v-93.39859c0-6.78372-5.50618-12.28928-12.28929-12.28928h-78.65146
        c-6.78311,0-12.28929-5.50558-12.28929-12.28931v-93.39861c0-6.78371,5.50618-12.28929,12.28929-12.28929h78.65146
        c6.78311,0,12.28929-5.50557,12.28929-12.28929v-93.3986c0-6.78371,5.50618-12.28929,12.28929-12.28929h181.88148
        C407.32819,103.00002,412.83435,108.50561,412.83435,115.28931z"
        />
      </g>
    </g>
  </svg>
);

const Logo: FC<LogoPropsType> = ({ height, width, darkTheme }) => {
  return (
    <LinkComponent href={RouteNames.home} localized>
      <Grid container alignItems="center" ml={-1} width="100%">
        <SvgComponent
          color={darkTheme ? '#FFF' : '#000'}
          height={height || '40px'}
          width={width || 'unset'}
        />
      </Grid>
    </LinkComponent>
  );
};

export default Logo;
