import { Grid } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TestimonialStatementColumn from '../layout/columns/TestimonialStatement.column';
import FadeSlide from '../layout/container/FadeSlide.container';
import Row from '../layout/rows/Row';
import Header from '../text/Header';
import SubHeader from '../text/SubHeader';
import Label from '../text/Label';

interface ReferencesSectionPropsType {
  darkTheme?: boolean;
}

const ReferencesSection: FC<ReferencesSectionPropsType> = ({ darkTheme }) => {
  const { t, i18n } = useTranslation();

  const references = [
    {
      name: 'David Armstrong',
      position_en: 'CEO & President',
      position_de: 'CEO',
      quote_de:
        'Als europaweit agierende Gruppe haben wir mit Fides unsere Prozesse sehr stark vereinfachen können, vor allem bei Umlaufbeschlüssen - von der Erstellung, über den Versand, zur digitalen Unterschrift und automatisierten Ablage. Die schnelle Weiterentwicklung des Produkts hält zudem immer wieder neue und nützliche Features parat.',
      quote_en:
        'As a group operating across Europe, Fides has enabled us to simplify our processes significantly, especially for circular resolutions - from creation, to send out, to digital signature and automated filing. Also, the rapid continued development of the product constantly delivers new and valuable features.',
      person_img: '/assets/images/testimonials/people/1_person.webp',
      company_img: '/assets/images/testimonials/people/1_company.webp',
    },
    {
      name: 'Dirk Sievert',
      position_en: 'Group General Counsel',
      position_de: 'Group General Counsel',
      quote_en:
        "From cap tables to interactive org charts, creating resolutions or holding meetings - all the corporate tasks we're dealing with daily can be executed in Fides.",
      quote_de:
        'Von Cap Tables bis hin zu interaktiven Organigrammen, der Erstellung von Beschlüssen oder dem Durchführen von Sitzungen - alle Corporate Aufgaben, mit denen wir täglich zu tun haben, können mit Fides digital erledigt werden.',
      person_img: '/assets/images/testimonials/people/2_person.webp',
      company_img: '/assets/images/testimonials/people/2_company.webp',
    },
  ];

  return (
    <Row
      sx={{
        mt: { xs: 0, md: 3, lg: 5 },
      }}
      rowSpacing={8}
    >
      <FadeSlide rowSpacing={2} sx={{ textAlign: 'center' }} direction="up" once>
        <Grid item xs={12}>
          <Header text={t('HOME.SECTIONS.REFERENCES.TITLE')} darkTheme={darkTheme} />
        </Grid>
        <Grid item xs={12}>
          <SubHeader text={t('HOME.SECTIONS.REFERENCES.SUBTITLE')} darkTheme={darkTheme} />
        </Grid>
      </FadeSlide>
      <FadeSlide direction="up" once>
        <Grid container item columnSpacing={2} rowSpacing={4} justifyContent="center">
          {references.map((reference) => (
            <TestimonialStatementColumn
              darkTheme={darkTheme}
              name={reference.name}
              position={i18n.language === 'de' ? reference.position_de : reference.position_en}
              quote={i18n.language === 'de' ? reference.quote_de : reference.quote_en}
              personImage={reference.person_img}
              companyImage={reference.company_img}
              key={reference.name}
            />
          ))}
        </Grid>
      </FadeSlide>
    </Row>
  );
};

export default ReferencesSection;
