import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { purple } from '@mui/material/colors';
import { SxProps } from '@mui/material';
import { ThemeColors } from '../../utils/colors';
import LinkComponent from '../Link/Link';

const BlueButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: ThemeColors.light.PRIMARY,
  textTransform: 'none',
  boxShadow: 'none',
  textDecoration: 'none !important',
  '&:hover': {
    backgroundColor: ThemeColors.dark.PRIMARY,
    boxShadow: 'none',
    color: '#000',
  },
  '&:active': {
    backgroundColor: ThemeColors.dark.PRIMARY,
    boxShadow: 'none',
    color: '#000',
  },
}));

const GreenButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: ThemeColors.dark.PRIMARY,
  color: '#000',
  textTransform: 'none',
  boxShadow: 'none',
  textDecoration: 'none !important',
  '&:hover': {
    //backgroundColor: ThemeColors.light.PRIMARY,
    backgroundColor: ThemeColors.dark.PRIMARY_COMPLEMENT,
    boxShadow: 'none',
    color: '#FFF',
  },
  '&:active': {
    //backgroundColor: ThemeColors.light.PRIMARY,
    backgroundColor: ThemeColors.dark.PRIMARY_COMPLEMENT,
    boxShadow: 'none',
    color: '#FFF',
  },
}));

interface HButtonPropsType {
  darkTheme?: boolean;
  size?: 'large' | 'small' | 'medium';
  sx?: SxProps;
  onClick?: () => void;
  fullWidth?: boolean;
  href?: string;
  localized?: boolean;
}
const HButton: React.FC<HButtonPropsType> = ({
  darkTheme,
  fullWidth,
  size,
  onClick,
  href,
  localized = true,
  sx,
  children,
}) => {
  const ButtonComponent = darkTheme ? GreenButton : BlueButton;

  const button = (
    <ButtonComponent
      variant="contained"
      fullWidth={fullWidth}
      size={size}
      sx={{ ...sx }}
      onClick={onClick}
    >
      {children}
    </ButtonComponent>
  );

  return href ? (
    <LinkComponent href={href} localized={localized}>
      {button}
    </LinkComponent>
  ) : (
    button
  );
};

export default HButton;
