import { Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../text/SubHeader';
import BeforeAndAfterSection from './components/BeforeAndAfter.section';

interface TitleOnlyCardPropsType {
  darkTheme: boolean;
  firstTab: number;
  tabs: {
    id: number;
    title: string;
    before_fides: string[];
    after_fides: string[];
  }[];
}

const CustomTabCard: React.FC<TitleOnlyCardPropsType> = ({ tabs, firstTab, darkTheme }) => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<number>(firstTab);

  return (
    <Grid container item justifyContent="center">
      <Grid container item bgcolor="#F3F3F3" borderRadius="10px" xs={12} p={2}>
        <Grid
          container
          item
          xs={12}
          md={3}
          borderRadius="10px"
          alignContent="flex-start"
          spacing={2}
          pr={{
            xs: 0,
            md: 2,
          }}
          pb={{
            xs: 2,
            md: 0,
          }}
        >
          {tabs.map((item) => {
            const itemActive = selectedItem === item.id;

            return (
              <Grid container item xs="auto" md={12} height="fit-content" key={item.id}>
                <Grid
                  container
                  item
                  xs={12}
                  onClick={() => setSelectedItem(item.id)}
                  bgcolor={itemActive ? '#FBFBFB' : '#EBEBEB'}
                  p={2}
                  borderRadius="10px"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <SubHeader
                    text={item.title}
                    darkTheme={darkTheme}
                    opacity
                    sx={{
                      fontSize: {
                        xs: 18,
                        md: 22,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid container item xs={12} md={9}>
          {tabs
            .filter((item) => item.id === selectedItem)
            .map((item) => {
              return (
                <Grid item bgcolor="#FBFBFB" borderRadius="10px" p={3} key={item.id}>
                  <BeforeAndAfterSection darkTheme={darkTheme} item={item} />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomTabCard;
