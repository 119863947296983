import { Layer } from '@fides-jam/front/components/Layer';
import { Grid, SxProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedSlide from '../../layout/container/AnimatedSlide.container';
import EventRow from './event.row';
interface EventsPropsType {
  sx?: SxProps;
  animate?: boolean;
}

const Events: FC<EventsPropsType> = ({ sx, animate }) => {
  const { t, i18n } = useTranslation();
  return (
    <Layer
      sx={{
        overflow: 'visible',
        top: {
          xs: '20px',
          sm: '3px',
          md: '3px',
          lg: '3px',
        },
        bottom: 'unset',
        right: {
          xs: '-110px',
          sm: '-50px',
          md: '-70px',
          lg: '-40px',
          xl: '-40px',
        },
        left: 'unset',
        /*
        left:{
          xs:'-120px',
          sm:'-50px',
          md:'-75px',
          lg:'-45px',
        },
        right:'unset',
        */
      }}
    >
      <AnimatedSlide
        animate={animate}
        //transitionDelay={600}
        direction={'right'}
        sx={{
          //py:3,
          //px:2.5,
          position: 'relative',
          //boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          //background:'#FFF',
          /*borderRadius:{
            xs:'20px',
            md:'25px',
          },*/
          width: '410px',
          height: '350px',
        }}
      >
        <Grid
          container
          sx={{
            /*opacity:{
              xs:0.5,
              sm:0.8,
            },*/
            fontWeight: '600',
            fontSize: '17px',
            mb: 0.8,
            ml: 1,
          }}
        >
          <EventRow
            animate={animate}
            name={t('AUDITTRAILCELL.VOTE')}
            //position={'Whitney Wolfe Herd - Chief Executive Officer'}
            position={'Whitney Wolfe Herd - CEO'}
            day={'03.'}
            month={t('AUDITTRAILCELL.MONTH')}
            time={t('AUDITTRAILCELL.TIME.43')}
            ipAddress={'154.77.55.1'}
            line={false}
            index={0}
          />
          <EventRow
            animate={animate}
            name={t('AUDITTRAILCELL.SIGNING')}
            //position={'Whitney Wolfe Herd - Chief Executive Officer'}
            position={'Whitney Wolfe Herd - CEO'}
            day={'03.'}
            month={t('AUDITTRAILCELL.MONTH')}
            time={t('AUDITTRAILCELL.TIME.51')}
            ipAddress={'152.44.55.1'}
            line={false}
            index={1}
          />
          <EventRow
            animate={animate}
            name={t('AUDITTRAILCELL.SIGNED')}
            //position={'Whitney Wolfe Herd - Chief Executive Officer'}
            position={t('AUDITTRAILCELL.SHAREHOLDER')}
            day={'05.'}
            month={t('AUDITTRAILCELL.MONTH')}
            time={t('AUDITTRAILCELL.TIME.22')}
            ipAddress={'156.11.55.2'}
            line={false}
            index={2}
          />
          <Layer
            sx={{
              right: 60,
              left: 60,
              background: {
                xs: 'unset',
                //sm:'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)',
              },
            }}
          />
        </Grid>
      </AnimatedSlide>
    </Layer>
  );
};

export default Events;
