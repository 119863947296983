import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import Row from '../../components/layout/rows/Row';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import Label from '../../components/text/Label';
import PlainText from '../../components/text/PlainText';
import HButton from '../../components/buttons/Highlight.button';
import { PageWrapper, Section } from '../../components/layout';
import { AppColors } from '@fides-jam/utils/theme/colors';
import { BigCompetitorCompareChecklist } from '../../components/Checklist/Checklist';
import SubTitle from '../../components/text/SubTitle';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';
import QuoteCard from '../../components/views/FidesVsCompetitor/Quote.card';
import List from '../../components/List/List';
import fidesVsData from '../../../assets/content/competitor_content.json';

const FidesVsComeptitor = ({ competitor }: { competitor: string }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const [content, setContent] = useState<{
    name: string;
    logoUrl: string;
    text_de: { title?: string; content: string[] }[];
    text_en: { title?: string; content: string[] }[];
    checklist: {
      FEATURES: {
        MANAGEMENT_OF_ENTITIES: boolean;
        MANAGEMENT_OF_BOARD_MEETINGS: boolean;
        AI_USE: boolean;
        RESOLUTIONS: boolean;
        TASK_MANAGEMENT: boolean;
        DOCUMENT_MANAGEMENT: boolean;
      };
      RELEVANT_CHARACTERISTICS: {
        INTERNATIONAL_LAW: boolean;
        USER_FRIENDLY: boolean;
        DATA_SECURITY: boolean;
        GERMANY_HOSTING: boolean;
        GERMAN_SUPPORT: boolean;
        AUDIT_TRAIL: boolean;
      };
    };
  }>({
    name: '',
    logoUrl: '',
    text_de: [],
    text_en: [],
    checklist: {
      FEATURES: {
        MANAGEMENT_OF_ENTITIES: false,
        MANAGEMENT_OF_BOARD_MEETINGS: false,
        AI_USE: false,
        RESOLUTIONS: false,
        TASK_MANAGEMENT: false,
        DOCUMENT_MANAGEMENT: false,
      },
      RELEVANT_CHARACTERISTICS: {
        INTERNATIONAL_LAW: false,
        USER_FRIENDLY: false,
        DATA_SECURITY: false,
        GERMANY_HOSTING: false,
        GERMAN_SUPPORT: false,
        AUDIT_TRAIL: false,
      },
    },
  });
  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  useEffect(() => {
    setContent(fidesVsData[competitor as keyof typeof fidesVsData]);
  }, [competitor]);

  const summaryList = [
    t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.OVERVIEW.OVERVIEW_1'),
    t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.OVERVIEW.OVERVIEW_2'),
    t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.OVERVIEW.OVERVIEW_3'),
    t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.OVERVIEW.OVERVIEW_4'),
  ];

  if (!content.name) {
    return null;
  }

  const text = language === 'de' ? content.text_de : content.text_en;

  return (
    <PageWrapper>
      <Section bgcolor="#011422" sx={{ justifyContent: 'left' }}>
        <Grid item xs={12} md={10}>
          <Label
            text={t('FIDES_VS_COMPETITORS.SINGLE_COMPETITOR.HERO.LABEL')}
            sx={{
              color: AppColors.PRIMARY,
              mb: 2,
            }}
          />
          <Title
            mainTitle
            text={
              t('FIDES_VS_COMPETITORS.SINGLE_COMPETITOR.HERO.TITLE_START') +
              ' ' +
              content.name +
              ' ' +
              t('FIDES_VS_COMPETITORS.SINGLE_COMPETITOR.HERO.TITLE_END')
            }
            sx={{
              color: 'white',
              fontWeight: 300,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <HButton
            onClick={() => setShowContactPopup(true)}
            darkTheme={true}
            sx={{
              px: 3,
            }}
          >
            {t('GENERAL.BOOK_PERSONAL_MEETING')}
          </HButton>
        </Grid>
      </Section>

      <Section>
        <Grid container item xs={12} spacing={2}>
          <Grid item mb={1} xs={10}>
            <Title
              text={
                t('FIDES_VS_COMPETITORS.SINGLE_COMPETITOR.INTRO.TITLE_START') +
                ' ' +
                content.name +
                ' ' +
                t('FIDES_VS_COMPETITORS.SINGLE_COMPETITOR.INTRO.TITLE_END')
              }
              sx={{
                fontWeight: 300,
                mb: 2,
                fontSize: {
                  xs: 21,
                  sm: 25,
                  lg: 27,
                  xl: 31,
                },
              }}
            />
            <PlainText
              text={t('FIDES_VS_COMPETITORS.SINGLE_COMPETITOR.INTRO.TEXT')}
              sx={{
                fontStyle: 'italic',
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={6}
          sx={{
            textAlign: 'justify',
          }}
        >
          <Grid item container xs={12} md={6} rowSpacing={4}>
            {text &&
              text.slice(0, Math.ceil(text.length / 2)).map((item, index) => (
                <Grid item key={index}>
                  <PlainText text={item?.title} opacity bold sx={{ mb: 1 }} />
                  {item.content.map((text, index) => (
                    <PlainText key={index} text={text} darkTheme={darkTheme} />
                  ))}
                </Grid>
              ))}
          </Grid>
          <Grid item container xs={12} md={6} rowSpacing={4}>
            {text &&
              text.slice(Math.ceil(text.length / 2)).map((item, index) => (
                <Grid item key={index}>
                  <PlainText text={item?.title} opacity bold sx={{ mb: 1 }} />
                  {item.content.map((text, index) => (
                    <PlainText key={index} text={text} darkTheme={darkTheme} />
                  ))}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid item xs={12}>
          <Title
            text={t('FIDES_VS_COMPETITORS.COMPARE.TITLE')}
            sx={{
              fontWeight: 300,
            }}
            center
          />
        </Grid>
        <Grid item xs={12}>
          <SubTitle text={t('FIDES_VS_COMPETITORS.COMPARE.SUBTITLE')} center />
        </Grid>
        <Grid item>
          <HButton
            onClick={() => setShowContactPopup(true)}
            darkTheme={true}
            sx={{
              px: 3,
            }}
          >
            {t('GENERAL.BOOK_CONSULTATION_CALL')}
          </HButton>
        </Grid>
      </Section>

      <Section>
        <Grid item xs={12}>
          <BigCompetitorCompareChecklist
            name={content.name}
            logoUrl={content.logoUrl}
            checklist={content.checklist}
            darkTheme={darkTheme}
          />
        </Grid>
      </Section>

      <Section bgcolor={AppColors.LIGHT_GREEN}>
        <Grid container item xs={12} justifyContent="center">
          <Title
            text={t('FIDES_VS_COMPETITORS.QUOTE.TITLE')}
            darkTheme={darkTheme}
            sx={{
              mb: 8,
              fontWeight: 300,
              fontSize: '2.5rem',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <QuoteCard
            darkTheme={darkTheme}
            quote={{
              name: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.DIRK_SIEVERT.NAME'),
              position: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.DIRK_SIEVERT.POSITION'),
              image: '/assets/images/simon-kucher-success-story/dirk-sievert.webp',
              companyImage: '/assets/images/testimonials/simon-kucher/simon-kucher.webp',
              text: t('FIDES_VS_COMPETITORS.QUOTE.QUOTE'),
            }}
          />
        </Grid>
      </Section>

      <Section spacing="none">
        <Grid item mb={1} xs={12}>
          <Title
            text={t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.TITLE')}
            sx={{
              fontWeight: 300,
            }}
            darkTheme={darkTheme}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="flex-start"
          justifyContent="flex-start"
          textAlign="justify"
          pt={4}
          spacing={2}
        >
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <PlainText
                text={t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.TEXT_1')}
                darkTheme={darkTheme}
                opacity
              />
            </Grid>
            <Grid item xs={12}>
              <PlainText
                text={t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.TEXT_2')}
                darkTheme={darkTheme}
                opacity
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6}>
            <List list={summaryList} darkTheme={darkTheme} />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid item container xs={12} justifyContent="center">
          <Grid
            container
            item
            xs={12}
            md={11}
            bgcolor="#011422"
            borderRadius="20px"
            px={10}
            py={6}
            gap={2}
          >
            <Grid item xs={12}>
              <Title
                sx={{
                  fontWeight: 300,
                  fontSize: '2.5rem',
                }}
                text={t('FIDES_VS_COMPETITORS.CTA_2.TITLE')}
                darkTheme={true}
                center
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <SubTitle text={t('FIDES_VS_COMPETITORS.CTA_2.SUBTITLE')} darkTheme={true} center />
              </Grid>
              <Grid container item xs={12} justifyContent="center" mt={4}>
                <HButton
                  darkTheme={true}
                  onClick={() => setShowContactPopup(true)}
                  sx={{
                    px: 3,
                  }}
                >
                  {t('GENERAL.BOOK_CONSULTATION_CALL')}
                </HButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}

      <Row sx={{ mt: 3 }}>
        <Footer />
      </Row>
    </PageWrapper>
  );
};

export default FidesVsComeptitor;
