import { Layer } from '@fides-jam/front/components/Layer';
import ScrollableModal from '@fides-jam/front/components/modals/ScrollableModal';
import { TrackingSuffix } from '@fides-jam/types/analytics';
import { DocuSignStatus } from '@fides-jam/types/documentSigning';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { Grid, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { RouteNames } from '../../Routes/routeNames';

const SigningSuccessModal: FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const [openSigningModal, setOpenSigningModal] = useState<boolean>(false);

  const isDocuSignCompleted = searchParams?.get('event') === DocuSignStatus.Completed;

  if (isDocuSignCompleted && !openSigningModal && pathname === RouteNames.home) {
    setOpenSigningModal(true);
  }

  const handleModalClose = () => {
    searchParams.delete('event');
    setSearchParams(searchParams);
    setOpenSigningModal(false);
  };

  return (
    <ScrollableModal id={`success modal ${TrackingSuffix.VIEW}`} open={openSigningModal}>
      <Grid
        item
        xs={12}
        sx={{
          py: 2,
          textAlign: 'center',
          background: 'rgba(0,255,0,0.1)',
          fontSize: '100px',
        }}
      >
        <span role="img" aria-label="check-icon">
          ✅
        </span>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        sx={{
          px: 7,
          pt: 4,
          pb: 12,
        }}
      >
        <Grid item sx={{ mt: 2 }}>
          <span>
            <strong>{t('SIGNING_MODAL.TITLE')}</strong>
          </span>
        </Grid>
        <Grid
          item
          sx={{
            mb: 1,
            color: theme.palette.grey[600],
            lineHeight: 1.5,
          }}
        >
          <small>{t('SIGNING_MODAL.SUBTITLE')}</small>
        </Grid>
        <Layer
          sx={{
            top: undefined,
            background: '#FFF',
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
            zIndex: 20,
            px: 7,
          }}
        >
          <Grid item container direction="row" columnSpacing={2} justifyContent="center">
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                variant="contained"
                size="small"
                color="success"
                sx={{
                  textTransform: 'none',
                  width: '100%',
                  boxShadow: 'none !important',
                }}
                onClick={handleModalClose}
              >
                <CheckIcon />
                <Grid item sx={{ flex: 1, pr: 2 }}>
                  <strong>{t('SIGNING_MODAL.CLOSE')}</strong>
                </Grid>
              </LoadingButton>
            </Grid>
          </Grid>
        </Layer>
      </Grid>
    </ScrollableModal>
  );
};

export default SigningSuccessModal;
