import { Grid } from '@mui/material';
import { ReactComponent as TrendingFlat } from '/assets/images/icons/trending_flat.svg';
import { ReactComponent as TrendingDown } from '/assets/images/icons/trending_down.svg';
import { useTranslation } from 'react-i18next';
import Header from '../../../text/Header';
import SubTitle from '../../../text/SubTitle';

interface EfficienySectionPropsType {
  darkTheme: boolean;
  item: {
    time_before_fides: string;
    time_after_fides: string;
    efficiency_gain: string;
  };
}

const EfficienySection: React.FC<EfficienySectionPropsType> = ({ darkTheme, item }) => {
  const { t } = useTranslation();
  return (
    <Grid container item xs={12} justifyContent="space-between" rowSpacing={3}>
      <Grid container item xs={12} md={6.5} lg={7.5} alignContent="center" spacing={1.5}>
        <Grid container item xs={12}>
          <Header
            text={t('EFFICIENCY_TABS.AVERAGE_WORKLOAD')}
            darkTheme={darkTheme}
            sx={{
              fontSize: {
                xs: 18,
              },
            }}
          />
        </Grid>
        <Grid container item xs={12} columnSpacing={1.5}>
          <Grid container item xs="auto" alignContent="center">
            <Grid item>
              <SubTitle
                text={item.time_before_fides + ' '}
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 20,
                    md: 38,
                  },
                  display: 'inline',
                }}
              />
              <SubTitle
                text={t('EFFICIENCY_TABS.HOURS')}
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 16,
                    md: 28,
                  },
                  display: 'inline',
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs="auto" alignContent="center">
            <Grid
              item
              width={{
                xs: '28px',
                md: '40px',
              }}
            >
              <TrendingFlat />
            </Grid>
          </Grid>

          <Grid container item xs="auto" alignContent="center">
            <Grid item>
              <SubTitle
                text={item.time_after_fides + ' '}
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 20,
                    md: 38,
                  },
                  display: 'inline',
                }}
              />
              <SubTitle
                text={t('EFFICIENCY_TABS.HOURS')}
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 16,
                    md: 28,
                  },
                  display: 'inline',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={5.5} lg={4.5}>
        <Grid
          item
          container
          bgcolor="#F5F5F5"
          borderRadius="10px"
          p={{
            xs: 1.5,
            md: 2,
          }}
          xs={12}
          spacing={1}
        >
          <Grid item container xs={12}>
            <Grid item width="30px" pr={1.5}>
              <TrendingDown />
            </Grid>
            <Grid item xs={10}>
              <Header
                text={t('EFFICIENCY_TABS.SAVING')}
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 18,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} ml={1}>
            <SubTitle
              text={item.efficiency_gain}
              darkTheme={darkTheme}
              sx={{
                fontSize: {
                  xs: 32,
                  md: 40,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EfficienySection;
