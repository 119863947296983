import { Layer } from '@fides-jam/front/components/Layer';
import { iOSColors } from '@fides-jam/utils/theme/colors';
import { Grid, SxProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedSlide from '../../layout/container/AnimatedSlide.container';
import UserRow from './user.row';

interface ResolutionsPropsType {
  sx?: SxProps;
  animate?: boolean;
}

const Resolutions: FC<ResolutionsPropsType> = ({ sx, animate }) => {
  const { t, i18n } = useTranslation();
  return (
    <Layer
      sx={{
        overflow: 'visible',
        top: {
          xs: '80px',
          sm: '10px',
          md: '-15px',
          lg: '10px',
          xl: '10px',
        },
        bottom: 'unset',
        right: {
          xs: '-115px',
          sm: '-60px',
          md: '-70px',
          lg: '-40px',
          xl: '-40px',
        },
        left: 'unset',
        width: '450px',
        minWidth: '450px',
      }}
    >
      <AnimatedSlide
        animate={animate}
        direction={'up'}
        sx={{
          width: '100%',
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          background: '#FFF',
          borderRadius: {
            xs: '20px',
            md: '25px',
          },
          px: 4,
          pt: 3,
          pb: 3,
        }}
      >
        <Grid container justifyContent="center" alignItems="center" flexDirection={'column'}>
          <Grid
            container
            sx={{
              fontWeight: '600',
              fontSize: '17px',
              opacity: 0.4,
              mb: 0.8,
              ml: 1,
            }}
          >
            {t('RESOLUTIONCELL.TITLE')}
          </Grid>

          <UserRow
            animate={animate}
            name={'Melanie Rosenberg'}
            position={t('RESOLUTIONCELL.CFO')}
            imageUrl={'/assets/images/product/dummy-users/3.webp'}
            vote={true}
            line={true}
            index={1}
          />
          <UserRow
            animate={animate}
            name={'Alexander Peters'}
            position={t('RESOLUTIONCELL.MD')}
            imageUrl={'/assets/images/product/dummy-users/1.webp'}
            vote={true}
            line={true}
            index={2}
          />
          <UserRow
            animate={animate}
            name={'Maria Meyer'}
            position={t('RESOLUTIONCELL.BOARD')}
            imageUrl={'/assets/images/product/dummy-users/2.webp'}
            vote={false}
            line={false}
            index={3}
          />
        </Grid>
      </AnimatedSlide>

      <AnimatedSlide
        animate={animate}
        direction={'up'}
        transitionDelay={300}
        sx={{
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          background: '#FFF',
          mt: 1,
          borderRadius: {
            xs: '20px',
            md: '25px',
          },
        }}
      >
        <Grid container item columnSpacing={1.3} sx={{ p: 1.5 }}>
          <Grid item xs={4}>
            <AnimatedSlide
              animate={animate}
              transitionDelay={400}
              direction={'left'}
              sx={{
                background: iOSColors.green,
                borderRadius: {
                  xs: '12px',
                  md: '14px',
                },
                color: '#FFF',
                fontWeight: '600',
                fontSize: '16px',
                py: 0.5,
                justifyContent: 'center',
              }}
            >
              {t('RESOLUTIONCELL.APPROVE')}
            </AnimatedSlide>
          </Grid>
          <Grid item xs={4}>
            <AnimatedSlide
              animate={animate}
              transitionDelay={300}
              direction={'left'}
              sx={{
                background: iOSColors.red,
                borderRadius: {
                  xs: '12px',
                  md: '14px',
                },
                color: '#FFF',
                fontWeight: '600',
                fontSize: '16px',
                py: 0.5,
                justifyContent: 'center',
              }}
            >
              {t('RESOLUTIONCELL.DECLINE')}
            </AnimatedSlide>
          </Grid>
          <Grid item xs={4}>
            <AnimatedSlide
              animate={animate}
              transitionDelay={200}
              direction={'left'}
              sx={{
                background: iOSColors.grey,
                borderRadius: {
                  xs: '12px',
                  md: '14px',
                },
                color: '#FFF',
                fontWeight: '600',
                fontSize: '16px',
                py: 0.5,
                justifyContent: 'center',
              }}
            >
              {t('RESOLUTIONCELL.ABSTAIN')}
            </AnimatedSlide>
          </Grid>
        </Grid>
      </AnimatedSlide>
    </Layer>
  );
};

export default Resolutions;
