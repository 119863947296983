import { FC } from 'react';
import { Grid, SxProps } from '@mui/material';
import PlainText from '../../text/PlainText';

interface TestimonialStatementColumnPropsType {
  darkTheme?: boolean;
  name: string;
  position: string;
  quote: string;
  personImage: string;
  companyImage: string;
}

const TestimonialStatementColumn: FC<TestimonialStatementColumnPropsType> = ({
  darkTheme,
  name,
  position,
  quote,
  personImage,
  companyImage,
}) => {
  return (
    <Grid container item xs={12} sm={6} md={4.5} mt={5}>
      <Grid position="relative">
        <Grid
          item
          container
          justifyContent="center"
          alignItems="stretch"
          pt={9}
          pb={5}
          px={5}
          bgcolor={darkTheme ? 'rgba(255,255,255,0.03)' : '#FFFFFF'}
          borderRadius={8}
          border={'1px solid ' + (darkTheme ? '#FFFFFF10' : '#00000010')}
          height="100%"
        >
          <Grid
            position="absolute"
            height="100px"
            width="100px"
            top={-40}
            sx={{
              transition: 'background 700ms ease-in-out',
              WebkitTransition: 'background 700ms ease-in-out',
              MsTransition: 'background 700ms ease-in-out',
              MozTransition: 'background 700ms ease-in-out',
              OTransition: 'background 700ms ease-in-out',
              transitionDuration: '700ms',
              WebkitTransitionDuration: '700ms',
              MsTransitionDuration: '700ms',
              MozTransitionDuration: '700ms',
              OTransitionDuration: '700ms',
            }}
          >
            <img
              src={personImage}
              alt={name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                borderRadius: '50%',
                border: '1px solid ' + (darkTheme ? '#FFFFFF20' : '#00000020'),
              }}
            />
          </Grid>
          <Grid container item justifyContent="center" alignItems="center">
            <PlainText
              darkTheme={darkTheme}
              sx={{
                mt: 2,
                opacity: 0.8,
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              {'“' + quote + '“'}
            </PlainText>
          </Grid>
          <Grid container item justifyContent="space-between" alignItems="flex-end" mt={2.5}>
            <Grid item xs={12} md={8}>
              <PlainText
                darkTheme={darkTheme}
                sx={{
                  fontWeight: 'bold',
                  lineHeight: '1.3',
                }}
              >
                {name}
              </PlainText>
              <PlainText
                darkTheme={darkTheme}
                sx={{
                  lineHeight: '1.3',
                }}
              >
                {position}
              </PlainText>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              p={1.5}
              bgcolor={darkTheme ? '#FFFFFF10' : '#00000010'}
              borderRadius={2}
              mt={{
                xs: 2,
              }}
            >
              <img
                src={companyImage}
                alt={'Company of ' + name}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                  maxHeight: '40px',
                  filter: darkTheme ? 'invert(0)' : 'invert(1)',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestimonialStatementColumn;
