import { Box, Button, Fade, Grid, SxProps, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useEffect, useCallback, useState, useMemo } from 'react';
import { TrackingSuffix } from '@fides-jam/types/analytics';

interface DetailsViewPropsType {
  id?: string;
  onClose?: () => void;
  title?: string;
  sx?: SxProps;
  contentStyleProps?: SxProps;
  variant?: string;
  hiddenGradient?: boolean;
}

const DetailsView: FC<DetailsViewPropsType> = ({
  id,
  onClose,
  title,
  children,
  sx,
  contentStyleProps,
  variant,
  hiddenGradient,
}) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [displayHeader, setDisplayHeader] = useState<boolean>(false);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  const detailsDidMount = useCallback(() => {
    container?.addEventListener('scroll', onScrollDetailsView);
  }, [container]);

  const detailsWillUnmount = useCallback(() => {
    container?.removeEventListener('scroll', onScrollDetailsView);
  }, [container]);

  const onScrollDetailsView = useCallback(
    (event: any) => {
      setScrollPosition(container?.scrollTop as number);
    },
    [container]
  );

  useEffect(() => {
    if (container) detailsDidMount();
    return detailsWillUnmount;
  }, [container, detailsDidMount, detailsWillUnmount]);

  useEffect(() => {
    const container = document.getElementById(customId);
    setContainer(container);
  }, []);

  useEffect(() => {
    if (scrollPosition >= 60.0 && !displayHeader) setDisplayHeader(true);
    else if (scrollPosition < 60.0 && displayHeader) setDisplayHeader(false);
  }, [scrollPosition]);

  const theme = useTheme();
  const customId = `KHContentContainer_${id}`;
  const hasBackAction = useMemo(() => !!onClose && variant !== 'close', [onClose, variant]);
  const hasCloseAction = useMemo(() => !!onClose && variant === 'close', [onClose, variant]);

  return (
    <Box
      sx={{
        background: '#FFF',
        boxShadow: '0px 0px 58px -4px rgba(230,230,242,0.7)',
        borderRadius: theme.spacing(1),
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        ...sx,
      }}
    >
      {(!!title || !!onClose) && (
        <Grid
          item
          container
          style={{
            padding: '10px 10px 8px 10px',
            borderBottom: displayHeader ? '1px solid rgba(0,0,0,0.05)' : '1px solid rgba(0,0,0,0)',
            transition: 'border-color 1s ease',
            display: 'flex',
            alignItems: 'center',
            minHeight: '54px',
          }}
        >
          {hasBackAction && (
            <Button
              id={`${id} back button ${TrackingSuffix.CLICK}`}
              variant="text"
              size="small"
              sx={{ textTransform: 'none', px: 0, minWidth: '40px' }}
              onClick={onClose}
            >
              <ArrowBackIcon />
            </Button>
          )}
          <Fade in={displayHeader}>
            <Box
              sx={{
                flex: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                pl: !hasCloseAction ? 3 : 6,
                pr: !hasBackAction ? 3 : 6,
              }}
            >
              <small style={{ color: theme.palette.grey[600] }}>
                <strong>{title}</strong>
              </small>
            </Box>
          </Fade>
          {hasCloseAction && (
            <Button
              id={`${id} close button ${TrackingSuffix.CLICK}`}
              variant="text"
              size="small"
              sx={{ textTransform: 'none', px: 0, minWidth: '40px' }}
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          )}
        </Grid>
      )}
      <Grid
        id={customId}
        item
        container
        display={'flex'}
        justifyContent="center"
        alignItems="center"
        sx={{
          flexGrow: 1,
          width: '100%',
          flexWrap: 'wrap',
          overflow: 'auto',
          pt: 6,
          pb: 7,
          px: 3,
          ...contentStyleProps,
        }}
      >
        {children}
      </Grid>
      {!hiddenGradient && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '60px',
            backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.0), white)',
          }}
        />
      )}
    </Box>
  );
};

export default DetailsView;
