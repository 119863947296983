import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import LawFirmsColumn from '../layout/columns/LawFirms.column';
import FadeSlide from '../layout/container/FadeSlide.container';
import Row from '../layout/rows/Row';
import Column from '../layout/columns/index.column';
import Label from '../text/Label';
import BGImageSlideContainer from '../layout/container/BGImageSlide.container';
import Header from '../text/Header';
import PlainText from '../text/PlainText';
import * as React from 'react';

interface LawFirmsSectionPropsType {
  darkTheme?: boolean;
}

const LawFirmsSection: FC<LawFirmsSectionPropsType> = ({ darkTheme }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Row columnSpacing={isSmall ? 0 : 10} sx={{ my: 5 }}>
      <Column rowSpacing={3}>
        <FadeSlide direction={'up'} rowSpacing={0} once>
          <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
            <Label text={t('HOME.SECTIONS.LAW_FIRMS.TAG')} highlighted darkTheme={darkTheme} />
          </Grid>
        </FadeSlide>
        <BGImageSlideContainer
          direction={'left'}
          darkTheme={darkTheme}
          imageUrl={'/assets/images/lawFirms/law-firms.jpg'}
          transitionDelay={400}
          once={isSmall}
          sx={{
            pb: '65%',
            border: '5px solid rgba(240,245,255,1)',
          }}
        />
      </Column>
      <Column>
        <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
          <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
            <Label text={t('HOME.SECTIONS.LAW_FIRMS.TAG')} highlighted darkTheme={darkTheme} />
          </Grid>
          <Grid item xs={12}>
            <Header text={t('HOME.SECTIONS.LAW_FIRMS.SUBTITLE')} darkTheme={darkTheme} />
          </Grid>
          <Grid item>
            <PlainText darkTheme={darkTheme} text={t('HOME.SECTIONS.LAW_FIRMS.DESCRIPTION')} />
          </Grid>
        </FadeSlide>
      </Column>
    </Row>
  );
};

export default LawFirmsSection;
