import { WebinarPost } from 'apps/speedy/src/types/builder-io-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Header from '../../../text/Header';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import PlainText from '../../../text/PlainText';
import HubspotForm from 'react-hubspot-form';

const WebinarPostContent: React.FC<{ post: WebinarPost }> = ({ post }) => {
  const { t, i18n } = useTranslation();
  const darkTheme = false;

  return (
    <>
      {post.description.map((section) => (
        <Grid item xs={12} key={section.text}>
          {section.emoji && (
            <PlainText
              darkTheme={darkTheme}
              text={section.emoji + ' '}
              sx={{ display: 'inline' }}
              opacity
            />
          )}
          <PlainText darkTheme={darkTheme} text={section.text} sx={{ display: 'inline' }} opacity />
        </Grid>
      ))}

      <Grid
        item
        xs={12}
        mt={7}
        zIndex={1}
        bgcolor="#F7F7F7"
        border={AppSignUpColors.PRIMARY_LIGHT_BORDER}
        borderRadius="10px"
        boxShadow="0px 0px 34px 5px rgba(0,0,0,0.05)"
        p={{
          xs: 2,
          lg: 5.5,
        }}
        mx={{
          xs: 1,
          lg: 2,
        }}
      >
        <Grid container sx={{ display: 'block' }} mb={5}>
          <Grid item textAlign="center">
            <Header text={t('KNOWLEDGE_HUB.WEBINAR.WATCH_WEBINAR')} />
          </Grid>
          <Grid
            item
            zIndex={1}
            mt={5}
            sx={{
              maxWidth: {
                xl: '1000px',
              },
            }}
          >
            <HubspotForm
              key={`hubspot_${i18n.language}`}
              portalId="25457524"
              formId={post.signUpFormId}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WebinarPostContent;
