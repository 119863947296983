import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavBarButton from '../buttons/NavBar.button';
import HButton from '../buttons/Highlight.button';
import MenuButton from '../buttons/MenuButton';
import RowSeperator from '@fides-jam/front/components/separators/HorizontalSeparator';
import ProductFeaturesView from './ProductFeaturesMenu';
import SolutionsMenu from './SolutionsMenu';
import Logo from '../logos/Logo';
import LanguageMenu from '../menus/Language.menu';
import Banner from '../views/Navbar/Banner.view';
import { RouteNames } from '../../Routes/routeNames';
import { useCookies } from 'react-cookie';
import { addMonths } from 'date-fns';
import FadeSlide from '../layout/container/FadeSlide.container';
import { builder, BuilderContent } from '@builder.io/react';
import { useContext } from 'react';
import { DarkThemeContext } from '../Context/ThemeContext';
import PhoneNumberCard from './PhoneNumber.card';
builder.init('e885ee80a68046cd9e6273567079f107');

interface Banner {
  id: string;
  language: string[];
  text: string;
  linkText?: string;
  linkUrl?: string;
  hideBannerUrls: string[];
}

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [productFeaturesOpen, setProductFeaturesOpen] = useState<boolean>(false);
  const [productFeaturesOpenMobile, setProductFeaturesOpenMobile] = useState<boolean>(false);
  const [solutionsMenuOpen, setSolutionsMenuOpen] = useState<boolean>(false);
  const [solutionsMenuOpenMobile, setSolutionsMenuOpenMobile] = useState<boolean>(false);
  const [{ hideBanner: hideBannerCookie }, setCookie] = useCookies(['hideBanner']);
  const [bannerActive, setBannerActive] = useState<boolean>(false);
  const { darkTheme } = useContext(DarkThemeContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!open) {
      setProductFeaturesOpen(false);
      setProductFeaturesOpenMobile(false);
    }
  }, [open]);

  const hideBanner = (id: string) => {
    const cookieValue = hideBannerCookie ? [...hideBannerCookie, id] : [id];

    setCookie('hideBanner', cookieValue, {
      expires: addMonths(new Date(), 1),
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'sticky',
        top: 0,
        right: 0,
        left: 0,
        background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
        transition: 'background 0.6s ease-in-out',
        zIndex: 1000,
        borderBottom: darkTheme
          ? '1px solid rgba(255,255,255, 0.05)'
          : '1px solid rgba(0,0,0, 0.05)',
        maxWidth: '100vw',
      }}
    >
      <BuilderContent
        model="banner"
        options={{
          locale: i18n.language,
        }}
        key={i18n.language}
      >
        {(banner: Banner, loading, meta) => {
          if (loading) {
            return null;
          }

          banner.id = meta?.id;
          const activeBanner =
            !hideBannerCookie?.includes(banner.id) &&
            banner.language.includes(i18n.language) &&
            !banner.hideBannerUrls.some((url) => pathname.endsWith(url)) &&
            !productFeaturesOpenMobile &&
            !solutionsMenuOpenMobile;
          setBannerActive(activeBanner);

          return (
            activeBanner && (
              <Banner
                banner={banner}
                darkTheme={darkTheme}
                hideBanner={hideBanner}
                key={banner.id}
              />
            )
          );
        }}
      </BuilderContent>

      <Grid
        item
        container
        xs={12}
        xl={11}
        sx={{
          px: {
            xs: 4,
            sm: 5,
            md: 6,
            lg: 7,
          },
          py: 0,
        }}
      >
        <Grid
          item
          container
          wrap="nowrap"
          flexDirection="row"
          alignItems="center"
          columnSpacing={1}
          sx={{
            height: {
              xs: '55px',
              sm: '60px',
              md: '60px',
              lg: '65px',
            },
          }}
        >
          <Logo darkTheme={darkTheme} />
          <Grid item sx={{ flexGrow: 1, flex: 1 }} />
          <Box
            sx={{
              display: {
                xs: 'none',
                lg: 'block',
              },
            }}
          >
            <NavBarButton darkTheme={darkTheme} onMouseEnter={() => setProductFeaturesOpen(true)}>
              {t('NAVIGATION.PRODUCT')}
              {!productFeaturesOpen ? <>&nbsp;&nbsp;&#x25BC;</> : <>&nbsp;&nbsp;&#x25B2;</>}
            </NavBarButton>
            <NavBarButton darkTheme={darkTheme} onMouseEnter={() => setSolutionsMenuOpen(true)}>
              {t('NAVIGATION.SOLUTIONS')}
              {!solutionsMenuOpen ? <>&nbsp;&nbsp;&#x25BC;</> : <>&nbsp;&nbsp;&#x25B2;</>}
            </NavBarButton>
            <NavBarButton darkTheme={darkTheme} href={RouteNames.simonKucherSuccessStory}>
              {t('NAVIGATION.CUSTOMERS')}
            </NavBarButton>
            <NavBarButton darkTheme={darkTheme} href={RouteNames.circleOfCounsels}>
              {t('NAVIGATION.CIRCLE_OF_COUNSELS')}
            </NavBarButton>
            <NavBarButton darkTheme={darkTheme} href={RouteNames.about}>
              {t('NAVIGATION.ABOUT')}
            </NavBarButton>
            <NavBarButton darkTheme={darkTheme} href={RouteNames.login} localized={false}>
              {t('NAVIGATION.LOGIN')}
            </NavBarButton>
          </Box>
          <Grid
            item
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
              },
              ml: 1,
            }}
          >
            <HButton
              size="medium"
              sx={{ px: 5 }}
              darkTheme={darkTheme}
              href={RouteNames.demoBooking}
            >
              {t('GENERAL.BOOK_DEMO')}
            </HButton>
          </Grid>

          <MenuButton
            sx={{
              display: {
                xs: 'flex',
                lg: 'none',
              },
              mr: {
                xs: -2,
                sm: -2,
              },
              ml: 3,
            }}
            darkTheme={darkTheme}
            open={open}
            onClick={() => setOpen(!open)}
          />

          <LanguageMenu
            sx={{
              ml: 3,
              mr: {
                xs: -2,
                sm: -2,
              },
            }}
            darkTheme={darkTheme}
          />
        </Grid>
      </Grid>

      <Grid
        id={'MenuContainer'}
        container
        sx={{
          transition: 'height 0.5s ease-in-out, background 700ms ease-in-out',
          transitionDelay: open ? '0ms' : '300ms',
          height: !open ? 0 : '100%',
          maxHeight: '90svh',
          overflow: 'scroll',
          background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
          display: {
            xs: 'flex',
            lg: 'none',
          },
        }}
      >
        <Grid
          container
          rowSpacing={0}
          sx={{
            display: !open ? 'none' : 'flex',
            px: 4,
            pt: 3,
            pb: 4,
          }}
        >
          <RowSeperator sx={{ width: '100%', mt: 0 }} darkTheme={darkTheme} />
          <Grid item container>
            <NavBarButton
              id="0_NB"
              darkTheme={darkTheme}
              href={RouteNames.home}
              fullWidth
              onClick={() => setOpen(false)}
            >
              {t('NAVIGATION.HOME')}
            </NavBarButton>
          </Grid>
          <RowSeperator sx={{ width: '100%' }} darkTheme={darkTheme} />
          <Grid item container>
            <NavBarButton
              id="1_NB"
              darkTheme={darkTheme}
              fullWidth
              onClick={() => {
                setProductFeaturesOpenMobile(true);
              }}
            >
              {t('NAVIGATION.PRODUCT')}
            </NavBarButton>
          </Grid>
          <RowSeperator sx={{ width: '100%' }} darkTheme={darkTheme} />
          <Grid item container>
            <NavBarButton
              id="1_NB"
              darkTheme={darkTheme}
              fullWidth
              onClick={() => {
                setSolutionsMenuOpenMobile(true);
              }}
            >
              {t('NAVIGATION.SOLUTIONS')}
            </NavBarButton>
          </Grid>
          <RowSeperator sx={{ width: '100%' }} darkTheme={darkTheme} />
          <Grid item container>
            <NavBarButton
              id="4_NB"
              darkTheme={darkTheme}
              href={RouteNames.simonKucherSuccessStory}
              fullWidth
              onClick={() => setOpen(false)}
            >
              {t('NAVIGATION.CUSTOMERS')}
            </NavBarButton>
          </Grid>
          <RowSeperator sx={{ width: '100%' }} darkTheme={darkTheme} />
          <Grid item container>
            <NavBarButton
              darkTheme={darkTheme}
              href={RouteNames.circleOfCounsels}
              fullWidth
              onClick={() => setOpen(false)}
            >
              {t('NAVIGATION.CIRCLE_OF_COUNSELS')}
            </NavBarButton>
          </Grid>
          <RowSeperator sx={{ width: '100%' }} darkTheme={darkTheme} />
          <Grid item container>
            <NavBarButton
              id="2_NB"
              darkTheme={darkTheme}
              href={RouteNames.about}
              fullWidth
              onClick={() => setOpen(false)}
            >
              {t('NAVIGATION.ABOUT')}
            </NavBarButton>
          </Grid>
          <RowSeperator sx={{ width: '100%' }} darkTheme={darkTheme} />
          <Grid item container>
            <NavBarButton
              id="5_NB"
              darkTheme={darkTheme}
              href={RouteNames.login}
              fullWidth
              onClick={() => setOpen(false)}
              localized={false}
            >
              {t('NAVIGATION.LOGIN')}
            </NavBarButton>
          </Grid>
          <Box height="20px" width="100%" />
          <Grid item container justifyContent="center">
            <HButton
              size="medium"
              sx={{ px: 5 }}
              darkTheme={darkTheme}
              href={RouteNames.demoBooking}
            >
              {t('GENERAL.BOOK_DEMO')}
            </HButton>
          </Grid>
        </Grid>
      </Grid>
      <FadeSlide direction="right">
        <PhoneNumberCard darkTheme={darkTheme} />
      </FadeSlide>

      <SolutionsMenu
        darkTheme={darkTheme}
        closeMenu={() => {
          setSolutionsMenuOpen(false);
          setSolutionsMenuOpenMobile(false);
          setOpen(false);
        }}
        open={solutionsMenuOpen || solutionsMenuOpenMobile}
        backdrop={solutionsMenuOpenMobile}
        sx={{
          top: bannerActive ? '119px' : '70px',
          right: solutionsMenuOpenMobile
            ? '10px'
            : {
                lg: i18n.language == 'de' ? '440px' : '400px',
                xl: i18n.language == 'de' ? '580px' : '480px',
              },
          left: solutionsMenuOpenMobile ? '10px' : 'unset',
        }}
      />

      <ProductFeaturesView
        darkTheme={darkTheme}
        closeMenu={() => {
          setProductFeaturesOpen(false);
          setProductFeaturesOpenMobile(false);
          setOpen(false);
        }}
        open={productFeaturesOpen || productFeaturesOpenMobile}
        backdrop={productFeaturesOpenMobile}
        sx={{
          top: bannerActive ? '119px' : '70px',
          right: productFeaturesOpenMobile
            ? '10px'
            : {
                lg: i18n.language == 'de' ? '770px' : '680px',
                xl: i18n.language == 'de' ? '870px' : '750px',
              },
          left: productFeaturesOpenMobile ? '10px' : 'unset',
        }}
      />
    </Grid>
  );
};

export default NavBar;
