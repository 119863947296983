import { Box, Grid } from '@mui/material';

export const ContactFormLoadingSkeleton: React.FC = () => {
  return (
    <Grid
      container
      spacing={5}
      mb={3}
      sx={{ animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite' }}
    >
      <Grid
        container
        item
        spacing={{
          xs: 2,
          md: 0,
        }}
      >
        <Grid item container xs={12} md={6} gap={0.5}>
          <Grid item xs={12}>
            <Box bgcolor="#d1d5db" height="1rem" width="25%" borderRadius="0.375rem"></Box>
          </Grid>
          <Grid item xs={12}>
            <Box bgcolor="#E8E8E8" height="2.25rem" width="90%" borderRadius="0.375rem"></Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6} gap={0.5}>
          <Grid item xs={12}>
            <Box bgcolor="#d1d5db" height="1rem" width="35%" borderRadius="0.375rem"></Box>
          </Grid>
          <Grid item xs={12}>
            <Box bgcolor="#E8E8E8" height="2.25rem" width="100%" borderRadius="0.375rem"></Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={{
          xs: 2,
          md: 0,
        }}
      >
        <Grid item container xs={12} md={6} gap={0.5}>
          <Grid item xs={12}>
            <Box bgcolor="#d1d5db" height="1rem" width="85%" borderRadius="0.375rem"></Box>
          </Grid>
          <Grid item xs={12}>
            <Box bgcolor="#E8E8E8" height="2.25rem" width="90%" borderRadius="0.375rem"></Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6} gap={0.5}>
          <Grid item xs={12}>
            <Box bgcolor="#d1d5db" height="1rem" width="75%" borderRadius="0.375rem"></Box>
          </Grid>
          <Grid item xs={12}>
            <Box bgcolor="#E8E8E8" height="2.25rem" width="100%" borderRadius="0.375rem"></Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} gap={0.5} justifyContent="flex-end">
        <Box bgcolor="#d1d5db" height="2.25rem" width="25%" borderRadius="0.375rem"></Box>
      </Grid>
    </Grid>
  );
};
