import { useEffect } from 'react';
import { trackGtagConversion } from '../utils/gtag-conversion';

export const useHubspotFormConversionTracking = (formId: string, gtagEventId: string) => {
  useEffect(() => {
    const handler = (e: MessageEvent) => {
      if (e.data.type !== 'hsFormCallback' || e.data.eventName !== 'onFormSubmitted') {
        return;
      }
      if (e.data.id === formId) {
        trackGtagConversion(gtagEventId);
      }
    };
    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [formId, gtagEventId]);
};
