import RowSeperator from '@fides-jam/front/components/separators/HorizontalSeparator';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DarkThemeContext } from '../../../components/Context/ThemeContext';
import Column from '../../../components/layout/columns/index.column';
import FadeSlide from '../../../components/layout/container/FadeSlide.container';
import Row from '../../../components/layout/rows/Row';
import Header from '../../../components/text/Header';
import Label from '../../../components/text/Label';
import PlainText from '../../../components/text/PlainText';
import SubTitle from '../../../components/text/SubTitle';
import Title from '../../../components/text/Title';
import Footer from '../../../components/views/Footer';
import ScreenShotSlideContainer from '../../../components/layout/container/ScreenShotSlide.container';
import { PageWrapper } from '../../../components/layout';

const CommitteesPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(true);
  }, [toggleDarkTheme]);
  return (
    <PageWrapper>
      <Grid
        container
        item
        rowSpacing={isSmall ? 8 : 10}
        justifyContent="center"
        alignItems="center"
      >
        <Row
          sx={{
            mt: {
              xs: -10,
              md: 3,
              lg: 5,
            },
            mb: {
              xs: -3,
              sm: 'unset',
            },
          }}
          rowSpacing={0}
        >
          <FadeSlide
            rowSpacing={2}
            sx={{
              textAlign: 'center',
              py: {
                xs: 0,
                sm: 4,
              },
              px: {
                xs: 0,
                sm: 5,
              },
              mt: 20,
              justifyContent: 'center',
            }}
            direction={'up'}
            once
          >
            <Grid item xs={12} lg={10}>
              <Title
                text={t('PRODUCT.COMMITTEES.TITLE')}
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 45,
                    lg: 55,
                    xl: 68,
                  },
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mt: 4 }}>
              <SubTitle
                text={t('PRODUCT.COMMITTEES.SUBTITLE')}
                darkTheme={darkTheme}
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                  opacity: 0.5,
                }}
              />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mt: 2 }}>
              <RowSeperator sx={{ width: '100%', m: 0 }} darkTheme={darkTheme} />
            </Grid>
          </FadeSlide>
        </Row>

        <Row columnSpacing={isSmall ? 0 : 10} flexDirection={'row-reverse'}>
          <Column rowSpacing={3}>
            <FadeSlide direction={'up'} rowSpacing={0} once>
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <Label
                  text={t('PRODUCT.COMMITTEES.SECTIONS.MANAGEMENT.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
            </FadeSlide>
            <ScreenShotSlideContainer
              direction={'right'}
              darkTheme={darkTheme}
              // imageUrl={'/assets/images/product/entity-management/committees.png'}
              imageUrl={`/assets/images/product/entity-management/committees/${i18n.language}.jpg`}
            />
          </Column>
          <Column>
            <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Label
                  text={t('PRODUCT.COMMITTEES.SECTIONS.MANAGEMENT.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item xs={12}>
                <Header
                  text={t('PRODUCT.COMMITTEES.SECTIONS.MANAGEMENT.TITLE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('PRODUCT.COMMITTEES.SECTIONS.MANAGEMENT.TEXT')}
                />
              </Grid>
            </FadeSlide>
          </Column>
        </Row>

        <Row columnSpacing={isSmall ? 0 : 10}>
          <Column rowSpacing={3}>
            <FadeSlide direction={'up'} rowSpacing={0} once>
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <Label
                  text={t('PRODUCT.COMMITTEES.SECTIONS.MEMBER.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
            </FadeSlide>
            <ScreenShotSlideContainer
              direction={'left'}
              darkTheme={darkTheme}
              // imageUrl={'/assets/images/product/entity-management/committee-members.png'}
              imageUrl={`/assets/images/product/entity-management/committee-members/${i18n.language}.jpg`}
            />
          </Column>
          <Column>
            <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Label
                  text={t('PRODUCT.COMMITTEES.SECTIONS.MEMBER.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item xs={12}>
                <Header
                  text={t('PRODUCT.COMMITTEES.SECTIONS.MEMBER.TITLE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('PRODUCT.COMMITTEES.SECTIONS.MEMBER.TEXT')}
                />
              </Grid>
            </FadeSlide>
          </Column>
        </Row>

        <Row columnSpacing={isSmall ? 0 : 10} flexDirection={'row-reverse'}>
          <Column rowSpacing={3}>
            <FadeSlide direction={'up'} rowSpacing={0} once>
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <Label
                  text={t('PRODUCT.COMMITTEES.SECTIONS.COLLABORATION.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
            </FadeSlide>
            <ScreenShotSlideContainer
              direction={'right'}
              darkTheme={darkTheme}
              // imageUrl={'/assets/images/product/entity-management/committee-resolutions.png'}
              imageUrl={`/assets/images/product/entity-management/committee-resolutions/${i18n.language}.jpg`}
            />
          </Column>
          <Column>
            <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Label
                  text={t('PRODUCT.COMMITTEES.SECTIONS.COLLABORATION.LABEL')}
                  highlighted
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item xs={12}>
                <Header
                  text={t('PRODUCT.COMMITTEES.SECTIONS.COLLABORATION.TITLE')}
                  darkTheme={darkTheme}
                />
              </Grid>
              <Grid item>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('PRODUCT.COMMITTEES.SECTIONS.COLLABORATION.TEXT')}
                />
              </Grid>
            </FadeSlide>
          </Column>
        </Row>

        <Row sx={{ mt: 3 }}>
          <Footer />
        </Row>
      </Grid>
    </PageWrapper>
  );
};

export default CommitteesPage;
