import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { useContext } from 'react';
import { RouteNames } from '../../Routes/routeNames';
import { PageWrapper, Section } from '../../components/layout';
import Row from '../../components/layout/rows/Row';
import Column from '../../components/layout/columns/index.column';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import Header from '../../components/text/Header';
import SubHeader from '../../components/text/SubHeader';
import PlainText from '../../components/text/PlainText';
import HButton from '../../components/buttons/Highlight.button';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';
import CompaniesThatUseFides from '../../components/sections/CompaniesThatUseFides.section';
import FeatureOverview from '../../components/sections/FeatureOverview.section';
import CallToAction from '../../components/sections/cta/CallToAction.section';
import TestimonialCard from '../../components/Cards/Testimonial.card';
import Timeline from '../../components/Timeline/Timeline';
import TabCard from '../../components/views/FidesFor/FidesForTab.card';
import { ReactComponent as LowPriority } from '/assets/images/icons/low_priority.svg';
import { ReactComponent as Lock } from '/assets/images/icons/lock.svg';
import { ReactComponent as HowToReg } from '/assets/images/icons/how_to_reg.svg';
import { ReactComponent as Mediation } from '/assets/images/icons/mediation.svg';

const FidesForCounselsPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);

  const testimonials = [
    {
      company: {
        img: '/assets/images/fides-for-counsels/testimonials/simon-kucher.webp',
        industry_en: 'Consulting Firm',
        industry_de: 'Unternehmensberatung',
      },
      person: {
        name: 'Dirk Sievert',
        img: '/assets/images/fides-for-counsels/testimonials/dirk-sievert.webp',
        position_en: 'General Counsel & Head of Compliance',
        position_de: 'General Counsel & Head of Compliance',
      },
      testimonial_en:
        '"Working together has never been easier. Our entire team, including senior members of our company, can access the same up-to-date information in real-time, regardless of their location. This has enhanced our communication, reduced the risk of errors, and improved our governance and transparency."',
      testimonial_de:
        '"Kollaboration war noch nie so einfach. Mit Fides können unser gesamtes Team und alle anderen leitenden Mitarbeiter unserer Firma in Echtzeit auf dieselben aktuellen Informationen zugreifen, egal wo sie sich befinden. Das hat unsere Kommunikation verbessert, das Fehlerrisiko verringert und unsere Governance und Transparenz verbessert.”',
      change: {
        before_fides_en: [
          'Decentralized storage of corporate data and documents',
          'Time-consuming coordination of resolutions with +180 shareholders',
        ],
        before_fides_de: [
          'Dezentrale Ablage von Unternehmensdaten und -Dokumenten',
          'Enormer Zeitaufwand durch die Distribution und Ablage von Informationen und Unterlagen',
        ],
        after_fides_en: [
          'Centralized storage and maintenance of data from global subsidiaries',
          'Efficiently implement and document decisions made by all global partners',
        ],
        after_fides_de: [
          'Zentrale Ablage und Pflege von Daten der globalen Tochtergesellschaften',
          'Effiziente Durchführung & Dokumentation von Entscheidungen aller Partner',
        ],
      },
    },
  ];

  const features = [
    {
      title: t('FIDES_FOR_COUNSELS.FEATURES.TIME_SAVING.TITLE'),
      text: t('FIDES_FOR_COUNSELS.FEATURES.TIME_SAVING.TEXT'),
      icon: <Mediation />,
    },
    {
      title: t('FIDES_FOR_COUNSELS.FEATURES.TRANSPARENCY.TITLE'),
      text: t('FIDES_FOR_COUNSELS.FEATURES.TRANSPARENCY.TEXT'),
      icon: <HowToReg />,
    },
    {
      title: t('FIDES_FOR_COUNSELS.FEATURES.PRIORITIZATION.TITLE'),
      text: t('FIDES_FOR_COUNSELS.FEATURES.PRIORITIZATION.TEXT'),
      icon: <LowPriority />,
    },
    {
      title: t('FIDES_FOR_COUNSELS.FEATURES.COMPLIANCE.TITLE'),
      text: t('FIDES_FOR_COUNSELS.FEATURES.COMPLIANCE.TEXT'),
      icon: <Lock />,
    },
  ];

  const timeline = [
    {
      type: 'process',
      short_line: true,
      emoji: '📥',
      description_de: 'Zustimmungspflichtiges Geschäft',
      description_en: 'Transaction requiring approval',
      position: {
        bottom: 83,
        left: 4,
      },
    },
    {
      type: 'person',
      short_line: false,
      image: '/assets/images/timeline/avatars/Legal-Counsel.webp',
      image_bg_color: '#9FA7EE',
      description_de: 'Legal Counsel',
      description_en: 'Legal Counsel',
      position: {
        bottom: 67,
        left: 21,
      },
    },
    {
      type: 'process',
      short_line: true,
      emoji: '📑',
      description_de: 'Entwurf Umlaufbeschluss',
      description_en: 'Draft cicular resolution',
      position: {
        bottom: 51,
        left: 33,
      },
    },
    {
      type: 'person',
      short_line: false,
      image: '/assets/images/timeline/avatars/General-Counsel.webp',
      image_bg_color: '#9FA7EE',
      description_de: 'General Counsel',
      description_en: 'General Counsel',
      position: {
        bottom: 39.5,
        left: 45,
      },
    },
    {
      type: 'process',
      short_line: true,
      emoji: '✅',
      description_de: 'Finalisieren',
      description_en: 'Review',
      position: {
        bottom: 25.5,
        left: 57,
      },
    },
    {
      type: 'person',
      short_line: false,
      image: '/assets/images/timeline/avatars/CFO.webp',
      image_bg_color: '#9FA7EE',
      description_de: 'Finanzvorstand',
      description_en: 'CFO',
      position: {
        bottom: 15,
        left: 66,
      },
    },
    {
      type: 'process',
      short_line: true,
      emoji: '✍🏼',
      description_de: 'Unterschrift',
      description_en: 'Signature',
      position: {
        bottom: 26,
        left: 78,
      },
    },
    {
      type: 'process',
      short_line: true,
      emoji: '📂',
      description_de: 'Ablage',
      description_en: 'Folder',
      position: {
        bottom: 52,
        left: 85.5,
      },
    },
  ];

  return (
    <PageWrapper>
      <Section topSection fullWidth>
        <Grid
          container
          item
          alignContent="center"
          alignItems="center"
          spacing={7}
          direction="row-reverse"
        >
          <Grid item xs={12} md={6}>
            <img
              src={'/assets/images/fides-for-counsels/hero/' + i18n.language + '.webp'}
              alt="Hero Fides For Counsels"
              style={{
                width: '100%',
                maxHeight: '550px',
                objectFit: 'contain',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item container rowSpacing={3}>
              <Title
                text={t('FIDES_FOR_COUNSELS.HERO.TITLE')}
                mainTitle
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 30,
                    lg: 40,
                    xl: 48,
                  },
                  mb: 2,
                }}
              />
              <PlainText
                text={t('FIDES_FOR_COUNSELS.HERO.TEXT')}
                darkTheme={darkTheme}
                sx={{ pr: 6 }}
              />
              <Grid item xs={12} onClick={() => setShowContactPopup(true)}>
                <HButton size="large" sx={{ px: 5 }} darkTheme={true}>
                  {t('GENERAL.BOOK_PERSONAL_MEETING')}
                </HButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <CompaniesThatUseFides
        type="counsel"
        title={t('FIDES_FOR_COUNSELS.INDUSTRY_LEADERS.TITLE')}
        darkTheme={darkTheme}
      />

      <Grid
        container
        display={{
          xs: 'none',
          md: 'flex',
        }}
      >
        <Section spacing="xl">
          <Grid item>
            <Title text={t('FIDES_FOR_COUNSELS.TIMELINE.TITLE')} darkTheme={darkTheme} center />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <SubHeader
              text={t('FIDES_FOR_COUNSELS.TIMELINE.SUBHEADER_1')}
              darkTheme={darkTheme}
              sx={{
                width: {
                  xs: '100%',
                  md: '60%',
                },
                textAlign: {
                  xs: 'left',
                  md: 'right',
                },
              }}
            />
          </Grid>
        </Section>
        <Timeline timeline={timeline} />
        <Section sx={{ mb: 10 }}>
          <Grid item container justifyContent="flex-start">
            <SubHeader
              text={t('FIDES_FOR_COUNSELS.TIMELINE.SUBHEADER_2')}
              darkTheme={darkTheme}
              sx={{
                width: {
                  xs: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '36%',
                },
                textAlign: {
                  xs: 'left',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} onClick={() => setShowContactPopup(true)}>
            <HButton size="large" sx={{ px: 5 }} darkTheme={true}>
              {t('GENERAL.BOOK_PERSONAL_MEETING')}
            </HButton>
          </Grid>
        </Section>
      </Grid>

      <TestimonialCard
        testimonials={testimonials}
        darkTheme={darkTheme}
        bgcolorWrapper="#F3F3F3"
        bgcolor="white"
      />

      <FeatureOverview
        title={t('FIDES_FOR_COUNSELS.FEATURES.TITLE')}
        features={features}
        size="big"
      />

      <Section>
        <Grid container item justifyContent="center" textAlign="center" rowSpacing={1}>
          <Grid item>
            <Header text={t('FIDES_FOR_COUNSELS.TAB_OVERVIEW.HEADER')} darkTheme={false} />
          </Grid>
          <Grid item>
            <SubHeader text={t('FIDES_FOR_COUNSELS.TAB_OVERVIEW.SUBHEADER')} darkTheme={false} />
          </Grid>
        </Grid>
        <TabCard firstTab={1} darkTheme={darkTheme} />
      </Section>

      <Section
        sx={{
          alignItems: 'flex-start',
        }}
        spacing="lg"
      >
        <Grid item>
          <Title text={t('FIDES_FOR_COUNSELS.CIRCLE_OF_COUNSELS.TITLE')} darkTheme={false} center />
        </Grid>
        <Column>
          <Grid item>
            <Header text={t('FIDES_FOR_COUNSELS.CIRCLE_OF_COUNSELS.HEADER')} darkTheme={false} />
          </Grid>
          <Grid item>
            <SubHeader
              text={t('FIDES_FOR_COUNSELS.CIRCLE_OF_COUNSELS.SUBHEADER')}
              darkTheme={false}
            />
          </Grid>
          <Grid item>
            <PlainText text={t('FIDES_FOR_COUNSELS.CIRCLE_OF_COUNSELS.TEXT')} darkTheme={false} />
          </Grid>
          <Grid item>
            <HButton
              size="large"
              sx={{ px: 5 }}
              darkTheme={true}
              href={RouteNames.circleOfCounsels}
            >
              {t('FIDES_FOR_COUNSELS.CIRCLE_OF_COUNSELS.BUTTON_TEXT')}
            </HButton>
          </Grid>
        </Column>
        <Column>
          <Grid item>
            <img
              src="/assets/images/events/coc/impressions/coc_impressions_1-min.webp"
              alt="Cirlce of Counsels"
              style={{
                width: '100%',
                height: '400px',
                objectFit: 'cover',
                borderRadius: '10px',
                boxShadow: '0px 0px 60px 0px rgba(0,0,0,0.09)',
              }}
            />
          </Grid>
        </Column>
      </Section>

      <CallToAction
        header={t('FIDES_FOR_COUNSELS.CTA.TITLE')}
        subHeader={t('FIDES_FOR_COUNSELS.CTA.SUBTITLE')}
        link={() => setShowContactPopup(true)}
        buttonText={t('GENERAL.BOOK_PERSONAL_MEETING')}
      />

      {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}

      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </PageWrapper>
  );
};

export default FidesForCounselsPage;
