import { FC } from 'react';
import { useState } from 'react';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';

interface PastEventsCarouselPropsType {
  pastEvents: {
    image: string;
    link: string;
  }[];
}

const PastEventsCarousel: FC<PastEventsCarouselPropsType> = ({ pastEvents }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % pastEvents.length);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? pastEvents.length - 1 : prevIndex - 1));
  };

  return (
    <Grid container item justifyContent="center" alignItems="center" spacing={2} xs={11} md={12}>
      <Grid item container xs={1} justifyContent="flex-end">
        <IconButton
          sx={{
            position: 'relative',
            transform: 'rotate(90deg)',
            background: 'rgba(255,255,255,0.2)',
            opacity: currentIndex === 0 ? 0.05 : 0.4,
          }}
          disabled={currentIndex === 0}
          onClick={handlePrevClick}
        >
          <ExpandCircleDownIcon sx={{ color: '#fff', fontSize: '40px' }} />
        </IconButton>
      </Grid>
      <Grid container item xs={10} justifyContent="center" spacing={5}>
        {pastEvents.slice(currentIndex, currentIndex + (isMedium ? 1 : 3)).map((event) => (
          <Grid item xs={12} md={4} key={event.image}>
            <a href={event.link}>
              <img
                src={event.image || ''}
                alt=""
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  borderRadius: '18px',
                  border: '1px solid #fff',
                }}
              />
            </a>
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={1}>
        <IconButton
          sx={{
            position: 'relative',
            transform: 'rotate(-90deg)',
            background: 'rgba(255,255,255,0.2)',
            opacity: currentIndex === pastEvents.length - 1 ? 0.05 : 0.4,
          }}
          onClick={handleNextClick}
          disabled={currentIndex === pastEvents.length - (isMedium ? 1 : 3)}
        >
          <ExpandCircleDownIcon sx={{ color: '#fff', fontSize: '40px' }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default PastEventsCarousel;
