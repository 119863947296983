import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import Header from '../text/Header';
import { HubspotFormWrapperWithTracking } from '../../components/HubspotFormWrapperWithTracking';
interface PflichtenheftPopupPropsType {
  setShowPflichtenheftPopup: (show: boolean) => void;
}

export const PflichtenheftPopup = ({ setShowPflichtenheftPopup }: PflichtenheftPopupPropsType) => {
  const { t, i18n } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.5)',
        zIndex: 1001,
        overflow: 'hidden',
      }}
    >
      <Grid
        container
        m={3}
        justifyContent="center"
        sx={{
          position: 'relative',
          maxWidth: '1100px',
          background: '#E9FEEB',
          borderRadius: '20px',
          overflow: 'scroll',
          height: 'auto',
          maxHeight: '90%',
          border: '1px solid #10182810',
          boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2)',
          zIndex: 1002,
        }}
      >
        <Grid
          item
          onClick={() => setShowPflichtenheftPopup(false)}
          sx={{
            position: 'absolute',
            top: '5px',
            right: '20px',
            fontSize: '30px',
            cursor: 'pointer',
          }}
        >
          &times;
        </Grid>
        <Grid
          item
          container
          pt={8}
          pb={{
            xs: 0,
            md: 4,
          }}
          justifyContent="center"
        >
          <Grid xs={12} md={10.5} px={4} item container justifyContent="center" textAlign="center">
            <Header
              sx={{
                mb: 4,
              }}
              text={t('FIDES_VS_COMPETITORS.INTRO.CHECKLIST.POPUP_HEADER')}
            />
          </Grid>
          <Grid
            xs={12}
            md={10.5}
            px={4}
            item
            container
            justifyContent="center"
            textAlign="center"
            sx={{
              '& > div': {
                width: '100%',
              },
            }}
          >
            <HubspotFormWrapperWithTracking
              key={i18n.language}
              portalId="25457524"
              formId={
                i18n.language === 'de'
                  ? '5674c895-cf94-46ef-b671-d6ca6fcc8453'
                  : '7c9838fb-f16c-44df-bc35-badca4cb8500'
              }
              gtagEventId="AW-10855235169/sVriCJ2HxJ0ZEOH8lrgo"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
