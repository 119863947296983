import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, SxProps } from '@mui/material';

interface AnimatedSlidePropsType {
  rowSpacing?: number;
  columnSpacing?: number;
  darkTheme?: boolean;
  direction?: 'left' | 'right' | 'up' | 'down';
  sx?: SxProps;
  transitionDelay?: number;
  animate?: boolean;
}

const AnimatedSlide: FC<AnimatedSlidePropsType> = ({
  rowSpacing,
  columnSpacing,
  children,
  direction,
  sx,
  transitionDelay,
  animate,
}) => {
  const ref = useRef(null);
  const offset = {
    x: 60,
    y: 40,
  };
  const opacity = useMemo(() => (animate ? 1.0 : 0.0), [animate]);

  const getXOffset = useCallback(() => {
    if (direction === 'left') {
      return !animate ? -offset.x : 0;
    } else if (direction === 'right') {
      return !animate ? offset.x : 0;
    } else return 0.0;
  }, [animate, offset, direction]);

  const getYOffset = useCallback(() => {
    if (direction === 'up') {
      return !animate ? offset.y : 0;
    } else if (direction === 'down') {
      return !animate ? -offset.y : 0;
    } else return 0.0;
  }, [animate, offset, direction]);

  const xOffset = useMemo(() => getXOffset(), [animate]);
  const yOffset = useMemo(() => getYOffset(), [animate]);

  return (
    <Grid
      ref={ref}
      container
      item
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
      sx={{
        transition:
          'opacity 1.0s ease-in-out, transform 1.0s ease-in-out, background 0.6s ease-in-out, filter 0.6s ease-in-out',
        transitionDelay: `${transitionDelay || 0}ms`,
        transform: `translate(${xOffset}px, ${yOffset}px)`,
        opacity: opacity,
        ...sx,
      }}
    >
      {children}
    </Grid>
  );
};

export default AnimatedSlide;
