import { Box, Button, Fade, Grid, SxProps, useTheme } from '@mui/material';
import { useRef } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { TrackingSuffix } from '@fides-jam/types/analytics';
import LinkComponent from '../../Link/Link';
import { RouteNames } from '../../../Routes/routeNames';

interface DetailsViewPropsType {
  id?: string;
  title?: string;
  sx?: SxProps;
  contentStyleProps?: SxProps;
  variant?: string;
  hiddenGradient?: boolean;
}

const DetailsView: React.FC<DetailsViewPropsType> = ({
  id,
  title,
  children,
  sx,
  contentStyleProps,
  variant,
  hiddenGradient,
}) => {
  const [displayHeader, setDisplayHeader] = useState<boolean>(false);
  const theme = useTheme();
  const hasBackAction = useMemo(() => variant !== 'close', [variant]);
  const hasCloseAction = useMemo(() => variant === 'close', [variant]);

  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <Box
      sx={{
        background: '#FFF',
        boxShadow: '0px 0px 58px -4px rgba(230,230,242,0.7)',
        borderRadius: theme.spacing(1),
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Grid
        item
        container
        style={{
          padding: '10px 10px 8px 10px',
          borderBottom: displayHeader ? '1px solid rgba(0,0,0,0.05)' : '1px solid rgba(0,0,0,0)',
          transition: 'border-color 1s ease',
          display: 'sticky',
          alignItems: 'center',
          minHeight: '54px',
        }}
      >
        <LinkComponent href={RouteNames.knowledgeHub} localized={true}>
          <Button
            id={`${id} back button ${TrackingSuffix.CLICK}`}
            variant="text"
            size="small"
            sx={{
              textTransform: 'none',
              px: 0,
              minWidth: '40px',
              color: theme.palette.grey[600],
              textDecoration: 'none',
            }}
          >
            <ArrowBackIcon />
          </Button>
        </LinkComponent>

        <Fade in={displayHeader}>
          <Box
            sx={{
              flex: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'center',
              pl: !hasCloseAction ? 3 : 6,
              pr: !hasBackAction ? 3 : 6,
            }}
          >
            <small style={{ color: theme.palette.grey[600] }}>
              <strong>{title}</strong>
            </small>
          </Box>
        </Fade>
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          flexGrow: 1,
          width: '100%',
          flexWrap: 'wrap',
          overflow: 'auto',
          pt: 6,
          pb: 7,
          px: 3,
          ...contentStyleProps,
        }}
      >
        {children}
      </Grid>
      {!hiddenGradient && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '60px',
            backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.0), white)',
          }}
        />
      )}
    </Box>
  );
};

export default DetailsView;
