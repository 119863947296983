import { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Header from '../../text/Header';
import { AppColors, AppSignUpColors } from '@fides-jam/utils/theme/colors';
import HButton from '../../buttons/Highlight.button';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface FidesOrCardPropsType {
  darkTheme: boolean;
  name: string;
  logo: string;
}

export const FidesOrCard: FC<FidesOrCardPropsType> = ({ name, logo, darkTheme }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectCompetitor = useCallback(
    (id: string) => {
      navigate(id);
    },
    [navigate]
  );

  return (
    <Grid item container xs={12} sm={6}>
      <Grid
        item
        container
        border={AppSignUpColors.PRIMARY_DARKER_BORDER}
        borderRadius="20px"
        width="100%"
        p={4}
        justifyContent="center"
        textAlign="center"
      >
        <Grid item xs={12}>
          <Header
            text={`${t('FIDES_VS_COMPETITORS.COMPARE.CARD_TITLE_START')} ${name}?`}
            darkTheme={darkTheme}
            sx={{
              fontWeight: 500,
            }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          height="60px"
          alignContent="center"
          py={7}
        >
          <Grid item xs={4}>
            <img src={logo} alt={name} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </Grid>
        </Grid>
        <HButton
          darkTheme={true}
          onClick={() => selectCompetitor(name.toLowerCase())}
          sx={{
            backgroundColor: AppColors.LIGHT_GREEN,
          }}
        >
          {t('FIDES_VS_COMPETITORS.COMPARE.BUTTON_TEXT')}
        </HButton>
      </Grid>
    </Grid>
  );
};
