import RowSeperator from '@fides-jam/front/components/separators/HorizontalSeparator';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Row from '../../components/layout/rows/Row';
import ArticlesSection from '../../components/sections/Posts.section';
import Label from '../../components/text/Label';
import PlainText from '../../components/text/PlainText';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';

const DemoBookingConfirmationPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const darkTheme = true;

  useEffect(() => {
    console.log('🏠 [CONFIRMATION PAGE]');
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        transition: 'background 0.6s ease-in-out',
        background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
        maxWidth: '100vw',
        overflow: 'hidden',
      }}
    >
      <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{ mb: -20 }}>
        <Row
          sx={{
            mt: {
              xs: 0,
              md: 3,
              lg: 10,
            },
          }}
          rowSpacing={0}
        >
          <FadeSlide
            rowSpacing={2}
            once={isSmall}
            sx={{
              textAlign: 'center',
              py: 10,
              px: {
                xs: 1,
                sm: 5,
              },
              mt: 10,
              justifyContent: 'center',
            }}
            direction={'up'}
          >
            <Grid item xs={12} lg={10}>
              <Title
                text={t('DEMO_BOOKING.TITLE')}
                mainTitle
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 45,
                    lg: 55,
                    xl: 68,
                  },
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mt: 4 }}>
              <PlainText
                text={t('DEMO_BOOKING.SUBTITLE')}
                darkTheme={darkTheme}
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                  opacity: 1,
                  fontWeight: '600',
                }}
              />
            </Grid>

            <Grid item xs={12} lg={8} sx={{ mt: 3 }}>
              <RowSeperator sx={{ width: '100%', mb: 6 }} darkTheme={darkTheme} />
              <Label
                text={t('DEMO_BOOKING.GOOD_GOVERNANCE')}
                darkTheme={darkTheme}
                highlighted
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                }}
              />
            </Grid>
          </FadeSlide>
        </Row>
      </Grid>
      <ArticlesSection darkTheme={darkTheme} />
      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </Grid>
  );
};

export default DemoBookingConfirmationPage;
