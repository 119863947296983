import { Grid } from '@mui/material';
import PlainText from '../text/PlainText';
import { ReactComponent as CheckIcon } from '/assets/images/icons/check.svg';
import { ReactComponent as CancelIcon } from '/assets/images/icons/cancel.svg';
import { useTranslation } from 'react-i18next';
import Logo from '../logos/Logo';
import Title from '../text/Title';

interface ChecklistArrowsLeftPropsType {
  darkTheme: boolean;
  checklist: string[];
}

interface BigCompetitorCompareChecklistPropsType {
  darkTheme: boolean;
  name: string;
  logoUrl: string;
  checklist: {
    FEATURES: {
      MANAGEMENT_OF_ENTITIES: boolean;
      MANAGEMENT_OF_BOARD_MEETINGS: boolean;
      AI_USE: boolean;
      RESOLUTIONS: boolean;
      TASK_MANAGEMENT: boolean;
      DOCUMENT_MANAGEMENT: boolean;
    };
    RELEVANT_CHARACTERISTICS: {
      INTERNATIONAL_LAW: boolean;
      USER_FRIENDLY: boolean;
      DATA_SECURITY: boolean;
      GERMANY_HOSTING: boolean;
      GERMAN_SUPPORT: boolean;
      AUDIT_TRAIL: boolean;
    };
  };
}

export const ChecklistArrowsLeft: React.FC<ChecklistArrowsLeftPropsType> = ({
  checklist,
  darkTheme,
}) => {
  return (
    <Grid container>
      {checklist.map((item, index) => (
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="center"
          py={2}
          borderBottom={index === checklist.length - 1 ? 0 : 1}
          key={item}
        >
          <Grid item xs={1} justifyContent="center" alignItems="center">
            <CheckIcon fill="#0245C7" height={10} />
          </Grid>
          <Grid item xs={11}>
            <PlainText text={item} darkTheme={darkTheme} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export const BigCompetitorCompareChecklist: React.FC<BigCompetitorCompareChecklistPropsType> = ({
  name,
  logoUrl,
  checklist,
  darkTheme,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid container item borderBottom={1}>
        <Grid item xs={12} md={9} py={2}>
          <Title
            text={'Fides oder ' + name + '?'}
            darkTheme={darkTheme}
            sx={{
              fontWeight: 300,
              fontSize: '2.5rem',
              mt: 3,
            }}
          />
        </Grid>

        <Grid container item xs={6} md={1.5} py={2} justifyContent="center" alignItems="center">
          <Logo />
        </Grid>
        <Grid container item xs={6} md={1.5} py={2} justifyContent="center" alignItems="center">
          <img src={logoUrl} alt={name} style={{ width: '80%', height: 'auto' }} />
        </Grid>
      </Grid>
      <Grid item xs={12} pb={1}>
        <Title
          text={t('FIDES_VS_COMPETITORS.DETAIL_CHECKLIST.FEATURES.TITLE')}
          darkTheme={darkTheme}
          sx={{
            fontWeight: 300,
            fontSize: '1.75rem',
            mt: 3,
          }}
        />
      </Grid>
      {Object.keys(checklist.FEATURES).map((key, index) => (
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="center"
          py={2}
          borderBottom={index === Object.keys(checklist.FEATURES).length - 1 ? 0 : 1}
          key={key}
        >
          <Grid item xs={12} md={9}>
            <PlainText
              text={t('FIDES_VS_COMPETITORS.DETAIL_CHECKLIST.FEATURES.' + key + '.TITLE')}
              darkTheme={darkTheme}
              opacity
              bold
              sx={{
                mb: 1,
              }}
            />
            <PlainText
              text={t('FIDES_VS_COMPETITORS.DETAIL_CHECKLIST.FEATURES.' + key + '.TEXT')}
              darkTheme={darkTheme}
            />
          </Grid>
          <Grid item xs={6} md={1.5} justifyContent="center" alignItems="center">
            <CheckIcon fill="#0245C7" height={10} />
          </Grid>
          <Grid
            item
            xs={6}
            md={1.5}
            justifyContent="center"
            alignItems="center"
            mt={{
              xs: 5,
              md: 0,
            }}
          >
            {checklist.FEATURES[key as keyof typeof checklist.FEATURES] ? (
              <CheckIcon fill="#0245C7" height={10} />
            ) : (
              <CancelIcon fill="#D03A3A" height={10} />
            )}
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12} pb={1}>
        <Title
          text={t('FIDES_VS_COMPETITORS.DETAIL_CHECKLIST.RELEVANT_CHARACTERISTICS.TITLE')}
          darkTheme={darkTheme}
          sx={{
            fontWeight: 300,
            fontSize: '1.75rem',
            mt: 3,
          }}
        />
      </Grid>
      {Object.keys(checklist.RELEVANT_CHARACTERISTICS).map((key, index) => (
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="center"
          py={2}
          borderBottom={
            index === Object.keys(checklist.RELEVANT_CHARACTERISTICS).length - 1 ? 0 : 1
          }
          key={key}
        >
          <Grid item xs={12} md={9}>
            <PlainText
              text={t(
                'FIDES_VS_COMPETITORS.DETAIL_CHECKLIST.RELEVANT_CHARACTERISTICS.' + key + '.TITLE'
              )}
              darkTheme={darkTheme}
              opacity
              bold
              sx={{
                mb: 1,
              }}
            />
            <PlainText
              text={t(
                'FIDES_VS_COMPETITORS.DETAIL_CHECKLIST.RELEVANT_CHARACTERISTICS.' + key + '.TEXT'
              )}
              darkTheme={darkTheme}
            />
          </Grid>
          <Grid item xs={6} md={1.5} justifyContent="center" alignItems="center">
            <CheckIcon fill="#0245C7" height={10} />
          </Grid>
          <Grid
            item
            xs={6}
            md={1.5}
            justifyContent="center"
            alignItems="center"
            mt={{
              xs: 5,
              md: 0,
            }}
          >
            {checklist.RELEVANT_CHARACTERISTICS[
              key as keyof typeof checklist.RELEVANT_CHARACTERISTICS
            ] ? (
              <CheckIcon fill="#0245C7" height={10} />
            ) : (
              <CancelIcon fill="#D03A3A" height={10} />
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
