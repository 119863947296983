import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';
import { Section } from '../layout';
import Header from '../../components/text/Header';
import SubHeader from '../../components/text/SubHeader';
import PlainText from '../../components/text/PlainText';
import { ReactComponent as Gesture } from '/assets/images/icons/gesture.svg';
import { ReactComponent as AutoFix } from '/assets/images/icons/auto-fix.svg';

interface CompaniesThatUseFidesPropsType {
  darkTheme?: boolean;
  testimonials: Testimonial[];
  bgcolorWrapper: string;
  bgcolor: string;
}

interface Testimonial {
  person: {
    name: string;
    position_de: string;
    position_en: string;
    img: string;
  };
  company: {
    industry_de: string;
    industry_en: string;
    img: string;
  };
  testimonial_de: string;
  testimonial_en: string;
  change: {
    before_fides_de: string[];
    before_fides_en: string[];
    after_fides_de: string[];
    after_fides_en: string[];
  };
}

const CompaniesThatUseFides: React.FC<CompaniesThatUseFidesPropsType> = ({
  darkTheme,
  testimonials,
  bgcolor,
  bgcolorWrapper,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Section bgcolor={bgcolorWrapper}>
      <Grid item container zIndex={1} gap={3} overflow="scroll" flexWrap="nowrap">
        {testimonials.map((testimonial: Testimonial) => (
          <Box
            bgcolor={bgcolor}
            key={testimonial.person.name}
            borderRadius="20px"
            p={{
              xs: 4,
              sm: 6,
            }}
            width="fit-content"
            minWidth="85%"
            maxWidth="1400px"
            mb={2}
          >
            <Grid container justifyContent="center" columnSpacing={6} rowSpacing={8}>
              <Grid container item xs={12} md={6} rowSpacing={4}>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="flex-start"
                  xs={12}
                  rowSpacing={3}
                >
                  <Grid item sm={8} xs={12}>
                    <Header text={t('TESTIMONIALS.CARD_TITLE')} />
                  </Grid>
                  <Grid
                    container
                    item
                    sm={4}
                    xs={12}
                    justifyContent={{
                      xs: 'flex-start',
                      sm: 'flex-end',
                    }}
                  >
                    <Grid
                      item
                      container
                      flexDirection="row"
                      justifyContent="flex-end"
                      width="auto"
                      textAlign={{
                        xs: 'left',
                        sm: 'right',
                      }}
                    >
                      <Grid item>
                        <PlainText
                          text={t('TESTIMONIALS.INDUSTRY')}
                          opacity
                          bold
                          darkTheme={darkTheme}
                        />
                        <PlainText
                          text={
                            i18n.language === 'de'
                              ? testimonial.company.industry_de
                              : testimonial.company.industry_en
                          }
                          darkTheme={darkTheme}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <PlainText
                    text={
                      i18n.language === 'de'
                        ? testimonial.testimonial_de
                        : testimonial.testimonial_en
                    }
                    darkTheme={darkTheme}
                    opacity
                    sx={{
                      lineHeight: '170%',
                    }}
                  />
                </Grid>
                <Grid item container xs={12} justifyContent="space-between" flexWrap="nowrap">
                  <Grid container item xs={10}>
                    <Grid item pr={2} xs={4} sm={2}>
                      <img
                        src={testimonial.person.img}
                        alt={testimonial.person.name}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          maxHeight: '60px',
                          borderRadius: '50%',
                        }}
                      />
                    </Grid>
                    <Grid item container xs={6} md={10} alignItems="center">
                      <Grid item xs={12}>
                        <PlainText
                          text={testimonial.person.name}
                          darkTheme={darkTheme}
                          opacity
                          bold
                          sx={{
                            paddingBottom: '5px',
                          }}
                        />
                        <PlainText
                          text={
                            i18n.language === 'de'
                              ? testimonial.person.position_de
                              : testimonial.person.position_en
                          }
                          darkTheme={darkTheme}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <img
                      src={testimonial.company.img}
                      alt="Company"
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        maxHeight: '50px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  alignItems="center"
                  height="fit-content"
                  rowSpacing={3}
                >
                  <Grid item width="30px" pr={1.5}>
                    <Gesture color="#17496A" />
                  </Grid>
                  <Grid item>
                    <SubHeader
                      text={t('TESTIMONIALS.BEFORE_FIDES')}
                      opacity
                      darkTheme={darkTheme}
                    />
                  </Grid>
                  <Grid item>
                    <ul>
                      {i18n.language === 'de'
                        ? testimonial.change.before_fides_de.map((item) => (
                            <li key={item} style={{ paddingBottom: '15px' }}>
                              {item}
                            </li>
                          ))
                        : testimonial.change.before_fides_en.map((item) => (
                            <li key={item} style={{ paddingBottom: '15px' }}>
                              {item}
                            </li>
                          ))}
                    </ul>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  alignItems="center"
                  height="fit-content"
                  rowSpacing={3}
                >
                  <Grid item width="30px" pr={1.5}>
                    <AutoFix color="#17496A" />
                  </Grid>
                  <Grid item>
                    <SubHeader text={t('TESTIMONIALS.AFTER_FIDES')} opacity darkTheme={darkTheme} />
                  </Grid>
                  <Grid item>
                    <ul>
                      {i18n.language === 'de'
                        ? testimonial.change.after_fides_de.map((item) => (
                            <li key={item} style={{ paddingBottom: '15px' }}>
                              {item}
                            </li>
                          ))
                        : testimonial.change.after_fides_en.map((item) => (
                            <li key={item} style={{ paddingBottom: '15px' }}>
                              {item}
                            </li>
                          ))}
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
    </Section>
  );
};

export default CompaniesThatUseFides;
