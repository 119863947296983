import { useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { AppColors } from '@fides-jam/utils/theme/colors';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { useNavigate } from 'react-router-dom';
import Row from '../../components/layout/rows/Row';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Footer from '../../components/views/Footer';
import Header from '../../components/text/Header';
import Label from '../../components/text/Label';
import PlainText from '../../components/text/PlainText';
import CircleOfCounselsSection from '../../components/sections/CircleOfCounsels.section';
import PostCard from '../../components/views/KnowledgeHub/Post.card';
import Title from '../../components/text/Title';
import { BuilderContent } from '@builder.io/react';
import { PageWrapper, Section } from '../../components/layout';
import { HubspotFormWrapperWithTracking } from '../../components/HubspotFormWrapperWithTracking';
import SubHeader from '../../components/text/SubHeader';
import { Spinner } from '../../components/Spinner';
import { builder, Builder } from '@builder.io/react';
import {
  getAllContentInPreview,
  getAllContentInLive,
} from '../../components/views/KnowledgeHub/utils/utils';
import { WebinarPost } from '../../../types/builder-io-types';

const WebinarSignUpPage: React.FC = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const [posts, setPosts] = useState<WebinarPost[]>([]);

  const navigate = useNavigate();
  const selectPost = useCallback(
    (id: string) => {
      navigate(`/${language}/knowledge-hub/${id}`);
    },
    [navigate, language]
  );

  useEffect(() => {
    async function fetchBuilderKnowledgeHub() {
      try {
        const kh = await builder.get('knowledge-hub', {
          options: {
            locale: language,
          },
          userAttributes: {
            locale: language,
          },
        });

        const [_, allContentFilteredAndSorted] =
          Builder.isPreviewing || Builder.isEditing
            ? await getAllContentInPreview(kh.data)
            : await getAllContentInLive(kh.data);

        setPosts(allContentFilteredAndSorted);
      } catch (e) {
        console.error(e);
      }
    }

    fetchBuilderKnowledgeHub();
  }, [language]);

  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  return (
    <PageWrapper>
      <BuilderContent
        model="webinar"
        options={{
          locale: language,
        }}
        key={language}
      >
        {(webinar: WebinarPost, loading) => {
          if (loading) {
            return null;
          }

          return (
            <Section fullWidth spacing="lg">
              <Grid item xs={12} md={5}>
                <Grid item xs={12} pb={1}>
                  <Label text={webinar.label} highlighted darkTheme={darkTheme} />
                </Grid>
                <Grid item xs={12} pb={1}>
                  <Header text={webinar.title} darkTheme={darkTheme} />
                </Grid>
                <Grid item xs={12}>
                  <SubHeader text={webinar.subtitle} darkTheme={darkTheme} />
                </Grid>
                <Grid item xs={12} pt={2}>
                  <PlainText
                    text={webinar.dateTimeText}
                    opacity
                    sx={{
                      fontWeight: 'bold',
                    }}
                  />
                </Grid>
                {webinar.description.map((section) => (
                  <Grid
                    item
                    xs={12}
                    key={section.text}
                    sx={{
                      mt: 4,
                    }}
                  >
                    {section.emoji && (
                      <PlainText
                        darkTheme={darkTheme}
                        text={section.emoji + ' '}
                        sx={{ display: 'inline' }}
                        opacity
                      />
                    )}
                    <PlainText
                      darkTheme={darkTheme}
                      text={section.text}
                      sx={{ display: 'inline' }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} md={7}>
                <FadeSlide direction="up" rowSpacing={0} once={isSmall}>
                  <Grid
                    item
                    container
                    sx={{
                      backgroundColor: 'rgb(240,240,240)',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      boxShadow: '0px 0px 60px 0px rgba(0,0,0,0.09)',
                    }}
                    justifyContent="center"
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        m: 1.5,
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={webinar.thumbnail}
                        alt={webinar.title}
                        style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                      />
                    </Grid>
                    <Grid container item xs={12} sx={{ p: 3 }}>
                      <Grid item xs={12}>
                        <HubspotFormWrapperWithTracking
                          key={webinar.signUpFormId}
                          portalId="25457524"
                          formId={webinar.signUpFormId}
                          gtagEventId="AW-10855235169/sVriCJ2HxJ0ZEOH8lrgo"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </FadeSlide>
              </Grid>
            </Section>
          );
        }}
      </BuilderContent>

      {language !== 'en' && (
        <Section>
          <Grid item textAlign="center">
            <Title text={t('WEBINAR.PAST_WEBINARS')} mainTitle darkTheme={darkTheme} />
          </Grid>
          <Grid container item xs={12} justifyContent="center" spacing={6}>
            {!posts && (
              <Grid item xs={12}>
                <Spinner darkTheme={!darkTheme} />
              </Grid>
            )}
            {posts
              ?.filter((post) => post.type === 'webinar')
              .map((post) => {
                return (
                  <Grid
                    key={post.slug}
                    item
                    container
                    xs={12}
                    sm={10}
                    md={11}
                    lg={6}
                    xl={4}
                    sx={{
                      minWidth: {
                        xs: 'unset',
                        sm: '400px',
                      },
                    }}
                  >
                    <PostCard post={post} onClick={() => selectPost(post.slug)} />
                  </Grid>
                );
              })}
          </Grid>
        </Section>
      )}

      <Section bgcolor={AppSignUpColors.PRIMARY_DARKER}>
        <Grid item textAlign="center">
          <Title text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.INTRO.TITLE_1')} darkTheme={true} large />
          <Title
            text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.INTRO.TITLE_2')}
            sx={{
              color: AppColors.PRIMARY,
            }}
            large
          />
        </Grid>
      </Section>

      <CircleOfCounselsSection />

      <Row sx={{ mt: 3 }}>
        <Footer />
      </Row>
    </PageWrapper>
  );
};

export default WebinarSignUpPage;
