import { Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../../components/text/SubHeader';
import BeforeAndAfterSection from './components/BeforeAndAfter.section';
import EfficienySection from './components/Efficiency.section';

interface TitleOnlyCardPropsType {
  darkTheme: boolean;
  firstTab: number;
}

const TabCard: React.FC<TitleOnlyCardPropsType> = ({ firstTab, darkTheme }) => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<number>(firstTab);

  const tabs = [
    {
      id: 1,
      title: t('EFFICIENCY_TABS.RESOLUTIONS.TITLE'),
      before_fides: [t('EFFICIENCY_TABS.RESOLUTIONS.BEFORE_FIDES_1')],
      after_fides: [
        t('EFFICIENCY_TABS.RESOLUTIONS.AFTER_FIDES_1'),
        t('EFFICIENCY_TABS.RESOLUTIONS.AFTER_FIDES_2'),
      ],
      time_before_fides: '225',
      time_after_fides: '46',
      efficiency_gain: '-69,3%',
    },
    {
      id: 2,
      title: t('EFFICIENCY_TABS.MEETINGS.TITLE'),
      before_fides: [
        t('EFFICIENCY_TABS.MEETINGS.BEFORE_FIDES_1'),
        t('EFFICIENCY_TABS.MEETINGS.BEFORE_FIDES_2'),
      ],
      after_fides: [
        t('EFFICIENCY_TABS.MEETINGS.AFTER_FIDES_1'),
        t('EFFICIENCY_TABS.MEETINGS.AFTER_FIDES_2'),
      ],
      time_before_fides: '430',
      time_after_fides: '176',
      efficiency_gain: '-59,1%',
    },
    {
      id: 3,
      title: t('EFFICIENCY_TABS.ENTITY_MANAGEMENT.TITLE'),
      before_fides: [
        t('EFFICIENCY_TABS.ENTITY_MANAGEMENT.BEFORE_FIDES_1'),
        t('EFFICIENCY_TABS.ENTITY_MANAGEMENT.BEFORE_FIDES_2'),
      ],
      after_fides: [
        t('EFFICIENCY_TABS.ENTITY_MANAGEMENT.AFTER_FIDES_1'),
        t('EFFICIENCY_TABS.ENTITY_MANAGEMENT.AFTER_FIDES_2'),
      ],
      time_before_fides: '12',
      time_after_fides: '134',
      efficiency_gain: '-12,4%',
    },
  ];

  return (
    <Grid container item justifyContent="center">
      <Grid container item bgcolor="#F3F3F3" borderRadius="10px" xs={12} p={2}>
        <Grid
          container
          item
          xs={12}
          md={3}
          borderRadius="10px"
          alignContent="flex-start"
          spacing={2}
          pr={{
            xs: 0,
            md: 2,
          }}
          pb={{
            xs: 2,
            md: 0,
          }}
        >
          {tabs.map((item) => {
            const itemActive = selectedItem === item.id;

            return (
              <Grid container item xs="auto" md={12} height="fit-content" key={item.id}>
                <Grid
                  container
                  item
                  xs={12}
                  onClick={() => setSelectedItem(item.id)}
                  bgcolor={itemActive ? '#FBFBFB' : '#EBEBEB'}
                  p={2}
                  borderRadius="10px"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <SubHeader
                    text={item.title}
                    darkTheme={darkTheme}
                    opacity
                    sx={{
                      fontSize: {
                        xs: 18,
                        md: 22,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid container item xs={12} md={9}>
          {tabs
            .filter((item) => item.id === selectedItem)
            .map((item) => {
              return (
                <Grid item bgcolor="#FBFBFB" borderRadius="10px" p={3} key={item.id}>
                  <BeforeAndAfterSection darkTheme={darkTheme} item={item} />

                  <EfficienySection darkTheme={darkTheme} item={item} />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TabCard;
