import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Grid, SxProps } from '@mui/material';
import { ThemeColors } from '../../utils/colors';
import LinkComponent from '../Link/Link';

interface RichContentButtonPropsType {
  darkTheme?: boolean;
  href?: string;
  sx?: SxProps;
  fullWidth?: boolean;
  icon?: any;
  title?: string;
  description?: string;
  dim?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  shrink?: boolean;
  onHover?: (hover: boolean) => void;
  localized?: boolean;
}

const RichContentButton: FC<RichContentButtonPropsType> = ({
  darkTheme,
  href,
  fullWidth,
  sx,
  children,
  localized = true,
  icon,
  title,
  description,
  dim,
  onMouseEnter,
  onMouseLeave,
  onClick,
  shrink,
  onHover,
}) => {
  const [hover, setHover] = useState<boolean>(false);

  const titleColor = useMemo(
    () =>
      darkTheme
        ? hover
          ? ThemeColors.dark.PRIMARY_COMPLEMENT
          : ThemeColors.dark.PRIMARY
        : ThemeColors.light.PRIMARY,
    [darkTheme, hover]
  );

  const descriptionColor = useMemo(() => (darkTheme ? '#FFF' : '#000'), [darkTheme, hover]);

  useEffect(() => onHover?.(hover), [hover, onHover]);

  return (
    <LinkComponent
      href={href}
      localized={localized}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
    >
      <Box
        sx={{
          backgroundColor: hover ? 'rgba(0,60,150,0.1)' : 'transparent',
          width: fullWidth ? '100%' : 'unset',
          px: 4,
          py: shrink ? 2 : 3,
          borderRadius: '15px',
          cursor: 'pointer',
          opacity: dim ? 0.5 : 1,
          ...sx,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Grid container>
          {icon && (
            <Grid
              item
              sx={{
                width: '30px',
                height: '30px',
                px: 0.4,
                mr: 1,
                transition: 'color 300ms ease-in-out',
                color: titleColor,
              }}
            >
              {icon}
            </Grid>
          )}
          <Grid
            item
            container
            flexDirection={'column'}
            sx={{
              flex: 1,
            }}
          >
            <Grid
              item
              sx={{
                transition: 'color 300ms ease-in-out',
                color: titleColor,
              }}
            >
              <strong>{title}</strong>
            </Grid>
            <Grid
              item
              sx={{
                lineHeight: 1.1,
                mt: 0.2,
                opacity: darkTheme ? 0.6 : 0.4,
                transition: 'color 300ms ease-in-out',
                color: descriptionColor,
              }}
            >
              <small>{description}</small>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LinkComponent>
  );
};

export default RichContentButton;
