import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { Box, Grid, SxProps } from '@mui/material';
import { ThemeColors } from '../../utils/colors';

interface MenuButtonPropsType {
  darkTheme?: boolean;
  sx?: SxProps;
  open?: boolean;
  onClick: (e: any) => void;
}

const MenuButton: FC<MenuButtonPropsType> = ({ darkTheme, sx, open, onClick }) => {
  const color = darkTheme ? ThemeColors.dark.PRIMARY : ThemeColors.light.PRIMARY; //'#000';
  const [cross, setCross] = useState<boolean>(false);
  const [transformTopLine, setTransformTopLine] = useState<string>('');
  const [transformBottomLine, setTransformBottomLine] = useState<string>('');
  const [opacityMiddleLine, setOpacityMiddleLine] = useState<number>(1);

  const tStates = [
    ['', ''],
    ['translate(0px, 6.5px)', 'translate(0px, -6.5px)'],
    ['translate(0px, 6.5px) rotate(45deg)', 'translate(0px, -6.5px) rotate(-45deg)'],
  ];

  useEffect(() => {
    setTransformTopLine(tStates[cross ? 1 : 0][0]);
    setTransformBottomLine(tStates[cross ? 1 : 0][1]);
    setOpacityMiddleLine(cross ? 0 : 1);
    if (cross)
      setTimeout(() => {
        setTransformTopLine(tStates[2][0]);
        setTransformBottomLine(tStates[2][1]);
      }, 350);
  }, [cross]);

  useEffect(() => {
    setCross(!!open);
  }, [open]);

  useEffect(() => {
    const preventPropagation = (e: any) => {
      e.stopPropagation();
      return false;
    };
    const element = document.getElementById('MenuButton');
    element?.removeEventListener('touchstart', preventPropagation);
    element?.addEventListener('touchstart', preventPropagation, { passive: false });
    return () => element?.removeEventListener('touchstart', preventPropagation);
  }, []);

  return (
    <Grid
      id={'MenuButton'}
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: 'transparent !important',
        background: 'transparent !important',
        width: '35px',
        height: '35px',
        py: 1,
        px: 0.8,
        borderRadius: '4px',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          background: color,
          width: '100%',
          height: '2px',
          borderRadius: '1px',
          transform: transformTopLine,
          transition: 'transform 0.4s ease-in-out',
        }}
      />
      <Box
        sx={{
          background: color,
          width: '100%',
          height: '2px',
          borderRadius: '1px',
          opacity: opacityMiddleLine,
          transition: 'opacity 0.5s ease-in-out',
        }}
      />
      <Box
        sx={{
          background: color,
          width: '100%',
          height: '2px',
          borderRadius: '1px',
          transform: transformBottomLine,
          transition: 'transform 0.4s ease-in-out',
        }}
      />
    </Grid>
  );
};

export default MenuButton;
