import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import Row from '../../components/layout/rows/Row';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import RowSeperator from '@fides-jam/front/components/separators/HorizontalSeparator';
import Label from '../../components/text/Label';
import PlainText from '../../components/text/PlainText';

const MediaPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        transition: 'background 0.6s ease-in-out',
        background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
        maxWidth: '100vw',
        overflow: 'hidden',
      }}
    >
      <Grid container item xs={12} rowSpacing={5} justifyContent="center" alignItems="center">
        <Row
          sx={{
            mt: {
              xs: 0,
              md: 3,
              lg: 10,
            },
          }}
          rowSpacing={0}
        >
          <FadeSlide
            rowSpacing={0}
            once={isSmall}
            sx={{
              textAlign: 'center',
              py: 5,
              px: {
                xs: 1,
                sm: 5,
              },
              mt: 10,
              justifyContent: 'center',
            }}
            direction={'up'}
          >
            <Grid item xs={12} lg={10}>
              <Title
                text={t('MEDIA.TITLE')}
                mainTitle
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 45,
                    lg: 55,
                    xl: 68,
                  },
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mt: 4 }}>
              <PlainText
                text={t('MEDIA.SUBTITLE')}
                darkTheme={darkTheme}
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                  opacity: 0.5,
                }}
              />
            </Grid>
            <Grid item xs={12} lg={8} sx={{ mt: 3 }}>
              <RowSeperator sx={{ width: '100%', mb: 5 }} />
              <Label
                text={t('MEDIA.SUBHEADER')}
                darkTheme={darkTheme}
                highlighted
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                }}
              />
            </Grid>
          </FadeSlide>
        </Row>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={11}
        md={11}
        lg={10}
        xl={10}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={5}
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2, height: '200px' }}
        >
          <img
            src={'/assets/images/media/logo.webp'}
            height="100%"
            style={{
              display: 'block',
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={11}
        md={11}
        lg={10}
        xl={10}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container item xs={11} sm={12} md={12} lg={12} xl={12} sx={{ py: 6 }}>
          <RowSeperator sx={{ width: '100%' }} />
        </Grid>
        <Grid
          item
          container
          xs={10}
          sm={10}
          md={6}
          lg={5}
          xl={4}
          justifyContent="center"
          alignItems="center"
          sx={{ p: 4 }}
        >
          <img
            src={'/assets/images/media/logo_title_dark.webp'}
            width="100%"
            style={{
              display: 'block',
            }}
          />
        </Grid>

        <Grid
          item
          container
          xs={10}
          sm={10}
          md={6}
          lg={5}
          xl={4}
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 4,
            background: AppSignUpColors.PRIMARY,
            color: '#FFF',
          }}
        >
          <img
            src={'/assets/images/media/logo_title_light.webp'}
            width="100%"
            style={{
              display: 'block',
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={11}
        md={11}
        lg={10}
        xl={10}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container item xs={11} sm={12} md={12} lg={12} xl={12} sx={{ py: 6 }}>
          <RowSeperator sx={{ width: '100%' }} />
        </Grid>
        <Grid
          item
          container
          xs={10}
          sm={10}
          md={6}
          lg={5}
          xl={4}
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 1,
            background: AppSignUpColors.PRIMARY,
          }}
        >
          <img
            src={'/assets/images/media/fides_fifth_element_light.png'}
            width="100%"
            style={{
              display: 'block',
            }}
          />
        </Grid>

        <Grid
          item
          container
          xs={10}
          sm={10}
          md={6}
          lg={5}
          xl={4}
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 1,
          }}
        >
          <img
            src={'/assets/images/media/fides_fifth_element_light.png'}
            width="100%"
            style={{
              display: 'block',
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={11}
        md={11}
        lg={10}
        xl={10}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container item xs={11} sm={12} md={12} lg={12} xl={12} sx={{ py: 6 }}>
          <RowSeperator sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={11} sm={5} md={3} lg={3} xl={3} sx={{ p: 2 }}>
          <img
            src={'/assets/images/media/founders.webp'}
            width="100%"
            style={{
              display: 'block',
            }}
          />
          <small>Founders Team</small>
        </Grid>
        <Grid item xs={11} sm={5} md={3} lg={3} xl={3} sx={{ p: 2 }}>
          <img
            src={'/assets/images/media/philippa.webp'}
            width="100%"
            style={{
              display: 'block',
            }}
          />
          <small>Founder Philippa Peters</small>
        </Grid>

        <Grid item xs={11} sm={5} md={3} lg={3} xl={3} sx={{ p: 2 }}>
          <img
            src={'/assets/images/media/vincent.webp'}
            width="100%"
            style={{
              display: 'block',
            }}
          />
          <small>Founder Vincent Bobinski</small>
        </Grid>
        <Grid item xs={11} sm={5} md={3} lg={3} xl={3} sx={{ p: 2 }}>
          <img
            src={'/assets/images/media/lisa.webp'}
            width="100%"
            style={{
              display: 'block',
            }}
          />
          <small>Founder Lisa Gradow</small>
        </Grid>
      </Grid>
      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </Grid>
  );
};

export default MediaPage;
