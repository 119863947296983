import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { useTranslation } from 'react-i18next';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Row from '../../components/layout/rows/Row';
import NavBar from '../../components/Navbar';
import PlainText from '../../components/text/PlainText';
import SubTitle from '../../components/text/SubTitle';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import { PageWrapper } from '../../components/layout';
const PressReleasePage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  return (
    <PageWrapper>
      <Grid container item rowSpacing={10} justifyContent="center" alignItems="center">
        <Row
          sx={{
            mt: {
              xs: 0,
              md: 3,
              lg: 5,
            },
          }}
          rowSpacing={0}
        >
          <FadeSlide
            rowSpacing={2}
            sx={{
              textAlign: 'center',
              py: 10,
              px: {
                xs: 2,
                sm: 5,
              },
              mt: 10,
              justifyContent: 'center',
            }}
            direction={'up'}
          >
            <Grid item xs={12} lg={10}>
              <Title
                text={t('PRESS.TITLE')}
                mainTitle
                darkTheme={false}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 45,
                    lg: 55,
                    xl: 68,
                  },
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mt: 4 }}>
              <SubTitle
                text={t('PRESS.SUBTITLE')}
                darkTheme={false}
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                  opacity: 0.5,
                }}
              />
            </Grid>
          </FadeSlide>
        </Row>
        <Row columnSpacing={isSmall ? 0 : 10}>
          <FadeSlide direction={'up'} rowSpacing={3} sx={{ maxWidth: '900px' }}>
            <Grid>
              <PlainText>
                <strong>
                  <i>
                    <p>
                      Fides is disrupting the dusty space of Corporate Governance through automation
                    </p>
                    <p>
                      Currently, legal departments of companies are still heavily relying on manual
                      and repetitive workflows, which lead to errors and increased liability for
                      managers.
                    </p>
                    <p>
                      Fides took part in the first European cohort of Arc, Sequoia’s seed stage
                      catalyst.
                    </p>
                    <p>
                      Prestigious female investors back the women-led company, and their seed
                      funding round was led by La Famiglia, accompanied by angels Cristina Stenbeck,
                      Christa Davies, and Katharina Jünger.
                    </p>
                  </i>
                </strong>
              </PlainText>
            </Grid>
            <Grid>
              <PlainText darkTheme={darkTheme}>
                <p>
                  <strong>Munich</strong> - Fides today announced they had raised a total of $4.3M
                  seed funding led by La Famiglia, with participation from prominent investors after
                  completing its participation in Arc, Sequoia’s seed stage catalyst.
                </p>
                <p>
                  Fides is on a mission to transform the way corporate governance duties are
                  fulfilled through digitizing, streamlining, and automating global entity
                  management and governance processes. As repetitive and manual work is still the
                  default mode for most companies regarding governance processes, especially if they
                  have multiple subsidiaries across various jurisdictions. This often results in
                  missed deadlines, costly mistakes, and ultimately non-compliance and associated
                  legal liability.{' '}
                </p>
                <p>
                  The bigger picture consequences for companies are not only a slow-down of deal-
                  and M&A readiness but, more importantly, an uncontrolled liability of
                  non-compliance for management and board members. As European countries and many
                  other jurisdictions in the world step up their assessment of company governance as
                  part of ESG mandates, this issue is expected to increase in the future. A{' '}
                  <a href="http://clp.law.harvard.edu/assets/ey-entity-compliance-and-governance-report-2021.pdf">
                    study
                  </a>{' '}
                  by Harvard Law School showed that over 75% of legal processes are done manually.
                  This demonstrates that in-house legal departments have a huge demand for a tool
                  that eases their operative duties while ensuring quality and guaranteeing
                  compliance across global entities and boards.{' '}
                </p>
                <p>
                  “A majority of global companies lack a digital approach that provides a single
                  source of truth for their global governance processes. Currently, this is mostly
                  done without tools other than email and word, ” says Lisa Gradow, Founder and CEO
                  of Fides. “With Fides, we want to be the G in ESG and make governance easy,
                  affordable, and secure.”
                </p>
                <p>
                  The Fides software unifies legal administrative processes and delivers a one-stop
                  shop for corporate governance. Fides can be used by companies in any sector and
                  already counts Chrono24, Pitch, and Venture Capital fund Headline among its
                  customers.
                </p>
                <p>
                  The company was founded by serial entrepreneurs Lisa Gradow and Vincent Bobinski,
                  as well as lawyer Philippa Peters in September 2021 and was one of the companies
                  to take part in the first Sequoia Arc Europe cohort.{' '}
                </p>
                <p>
                  Fides will use the funding to accelerate product development, implement legal
                  guidance for global jurisdictions, and ramp up sales and growth. The round was
                  joined by well-known investors and industry experts like Cristina Stenbeck, lead
                  shareholder of Kinnevik, chairperson of the Zalando Supervisory Board and
                  non-executive Board director of Spotify, Christa Davies, who is the Group CFO of
                  AON and non-executive Board director of Stripe and Workday, Russ Heddleston,
                  founder of DocSend recently exited to DropBox, Auxxo Female Catalyst Fund,
                  early-stage VC AngelInvest, and Marlon Braumann, Partner at Peter Thiel's Elevat3
                  VC and Founder of AMES Foundation.
                </p>
                <p>
                  Fides is proud to have two female founders and is excited to continue to demand
                  diversity from and within their investor base: Sequoia’s Luciana Lixandru, La
                  Famiglia’s Judith Dada and Jeanette zu Fürstenberg, Gesa Miczaika and Bettine
                  Schmitz from the Auxxo Female Catalyst Fund and investors Cristina Stenbeck,
                  Christa Davies, and Katharina Jünger all comprise Fides current cap table.
                </p>
                <p>
                  “We see almost every company at any stage struggling to establish governance
                  practices and a tool like Fides that enables them to streamline their processes is
                  long overdue,” said Luciana Lixandru, partner at Sequoia. “Most importantly, using
                  Fides will give management teams and boards peace of mind, knowing that the
                  chances of governance-related mistakes are dramatically reduced.”
                </p>
                <p>
                  “Today, many companies are global from day one, but looking at all the different
                  European jurisdictions, it is tough to comply with all governance requirements as
                  they expand. We are convinced that Fides can build the one-stop shop every company
                  needs to be compliant without sacrificing efficiency,” said Judith Dada, General
                  Partner at La Famiglia.
                </p>
                <p>
                  “The struggle of keeping everything up-to-date, in one place while ensuring
                  everything is done right by every managing director or any other manager in charge
                  of communication with external stakeholders across entities is real. Fides is the
                  solution that everyone needs, but apparently, no one has built it yet.” Philipp
                  Takjas, Chief Legal Officer of McMakler.
                </p>
              </PlainText>
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRESS.ABOUT')} darkTheme={darkTheme} />
            </Grid>
            <PlainText darkTheme={darkTheme}>
              <p>
                Fides offers a one-stop shop for corporate governance workflows by digitizing,
                streamlining, and automating global entity and board management processes helping
                companies of all sizes and industries to work more efficiently, save resources on
                legal expenses, and create an audit trail to prove they fulfilled their duties.
                Fides was founded in 2021 by Lisa Gradow, Philippa Peters, and Vincent Bobinski and
                is based in Munich, Germany.
              </p>
            </PlainText>
            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
              <PlainText text={t('PRESS.CONTACT')} darkTheme={darkTheme} />
            </Grid>
            <PlainText darkTheme={darkTheme}>
              <p> Lisa Gradow</p>
              <p> Founder & CEO</p>
              <p> lisa.gradow@fides.technology</p>
            </PlainText>
          </FadeSlide>
        </Row>

        <Row sx={{ mt: 3 }}>
          <Footer />
        </Row>
      </Grid>
    </PageWrapper>
  );
};

export default PressReleasePage;
