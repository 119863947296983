import { FC } from 'react';
import { SxProps } from '@mui/material';
import { Layer } from '@fides-jam/front/components/Layer';
import Events from './events.view';
import Details from './details.view';

interface AusditTrailViewPropsType {
  sx?: SxProps;
  animate?: boolean;
}

const AusditTrailView: FC<AusditTrailViewPropsType> = ({ sx, animate }) => {
  return (
    <Layer
      sx={{
        overflow: 'visible',
        transform: {
          xs: 'scale(0.72)',
          sm: 'scale(1.0)',
          md: 'scale(0.9)',
          lg: 'scale(1.0)',
          xl: 'scale(1.0)',
        },
      }}
    >
      <Details animate={animate} />
      <Events animate={animate} />
    </Layer>
  );
};

export default AusditTrailView;
