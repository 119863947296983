import CSS from 'csstype';

const containerStyle: CSS.Properties = {
  width: '100%',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 10000,
  paddingLeft: '20px',
  paddingRight: '20px',
  overflow: 'hidden',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  display:'flex',
  flexDirection:'row',
};

export default {
  containerStyle,
};
