export const ThemeColors = {
  dark: {
    PRIMARY: '#33FF99',
    PRIMARY_COMPLEMENT: '#ff3399',
    TITLE: '#FFF',
  },
  light: {
    PRIMARY: '#006DA5',
    TITLE: '#17486b',
  },

  backgroundBox: {
    grey: 'rgba(255,255,255,0.03)',
    blue: 'rgba(0,100,255,0.04)',
  },
  backgroundCircle: {
    grey: 'rgba(255,255,255,0.03)',
    blue: 'rgba(0,100,255,0.04)',
  },
};
