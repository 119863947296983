import { FC } from 'react';
import { Grid } from '@mui/material';
import { Section } from '../layout';
import Title from '../../components/text/Title';
import PlainText from '../text/PlainText';

interface FeatureListPropsType {
  title: string;
  features: { title: string; text: string }[];
}

const FeatureList: FC<FeatureListPropsType> = ({ title, features }) => {
  return (
    <Section>
      <Grid item xs={12}>
        <Title text={title} />
      </Grid>
      <Grid container item xs={12} justifyContent="center" spacing={3}>
        {features.map((item) => (
          <Grid item container xs={12} justifyContent="center" key={item.title}>
            <Grid item xs={12} md={3}>
              <PlainText text={item.title} opacity bold />
            </Grid>
            <Grid item xs={12} md={9}>
              <PlainText text={item.text} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default FeatureList;
