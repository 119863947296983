import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import {Box, CircularProgress, SxProps, useTheme} from '@mui/material';
import { FC } from 'react';

interface AppLoaderPropTypes {
  size?:number;
  sx?: SxProps;
}

const AppLoader: FC<AppLoaderPropTypes> = ({
  size,
  sx,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        zIndex: 9999,
        transition: '0.4s all ease',
        ...sx
      }}
    >
      <CircularProgress
        size={size||theme.spacing(10)}
        style={{ color: AppSignUpColors.PRIMARY_DARKER }}
      />
    </Box>
  );
};

export default AppLoader;
