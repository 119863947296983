import { FC } from 'react';
import { Grid, SxProps } from '@mui/material';
import { Layer } from '@fides-jam/front/components/Layer';
import CircularSegment from '@fides-jam/front/components/CircularSegment';
import { iOSColors } from '@fides-jam/utils/theme/colors';
import AnimatedSlide from '../../layout/container/AnimatedSlide.container';

interface ResolutionsPropsType {
  sx?: SxProps;
  animate?: boolean;
}

const Resolutions: FC<ResolutionsPropsType> = ({ sx, animate }) => {
  //const [isVisible, setVisible] = useState<boolean>(false);
  return (
    <Layer
      sx={{
        transform: {
          xs: 'scale(0.85)',
          sm: 'scale(0.85)',
          md: 'scale(0.7)',
          lg: 'scale(0.9)',
          xl: 'scale(0.95)',
        },
        overflow: 'visible',
        top: 'unset',
        bottom: {
          xs: '230px',
          sm: '-50px',
          md: '-80px',
          lg: '-80px',
          xl: '-70px',
        },
        right: {
          xs: '50%',
          sm: '-53px',
          md: '-85px',
          lg: '-80px',
          xl: '-80px',
        },
        left: 'unset',
        marginRight: {
          xs: '-140px',
          sm: 'unset',
        },
      }}
    >
      <AnimatedSlide
        animate={animate}
        direction={'up'}
        transitionDelay={500}
        sx={{
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          background: '#FFF',
          //borderRadius:'50%',
          borderRadius: {
            xs: '20px',
            md: '25px',
          },
          px: 4,
          //pt:3,
          pb: 3,
          width: '250px',
          //height:'300px',
          height: '280px',
        }}
      >
        <div style={{ opacity: 0.8 }}>
          <Grid
            container
            sx={{
              fontWeight: '600',
              fontSize: '17px',
              opacity: 0.4,
              mb: 0.8,
              ml: 0.1,
              py: 2,
            }}
          >
            Captable
          </Grid>
          <Layer sx={{ top: '35px', overflow: 'visible' }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}
            >
              <Grid
                item
                sx={{
                  //transform:`rotate(${isVisible?340:0}deg)`,
                  transform: `rotate(${animate ? 340 : 0}deg)`,
                  //transition:'transform 1.5s ease-in-out',
                  transition: 'transform 1500ms ease-in-out',
                  WebkitTransition: 'transform 1500ms ease-in-out',
                  MsTransition: 'transform 1500ms ease-in-out',
                  MozTransition: 'transform 1500ms ease-in-out',
                  OTransition: 'transform 1500ms ease-in-out',

                  transitionDuration: '1500ms',
                  WebkitTransitionDuration: '1500ms',
                  MsTransitionDuration: '1500ms',
                  MozTransitionDuration: '1500ms',
                  OTransitionDuration: '1500ms',
                }}
              >
                <CircularSegment
                  size={170}
                  percentage={100}
                  color={'blue'}
                  //color={'#FAC898'}
                  //color={'#F8C8DC'}
                  //color={'#fcaf45'}
                  //color={iOSColors.red}
                  //color={'#173750'}
                  rotation={0}
                />
              </Grid>
            </Grid>
          </Layer>

          <Layer sx={{ top: '35px', overflow: 'visible' }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}
            >
              <Grid
                item
                sx={{
                  //transform:`rotate(${isVisible?340:0}deg)`,
                  transform: `rotate(${animate ? 340 : 0}deg)`,
                  //transition:'transform 1.5s ease-in-out',
                  transition: 'transform 1500ms ease-in-out',
                  WebkitTransition: 'transform 1500ms ease-in-out',
                  MsTransition: 'transform 1500ms ease-in-out',
                  MozTransition: 'transform 1500ms ease-in-out',
                  OTransition: 'transform 1500ms ease-in-out',

                  transitionDuration: '1500ms',
                  WebkitTransitionDuration: '1500ms',
                  MsTransitionDuration: '1500ms',
                  MozTransitionDuration: '1500ms',
                  OTransitionDuration: '1500ms',
                }}
              >
                <CircularSegment
                  size={180}
                  percentage={32}
                  color={iOSColors.blue}
                  //color={'#A7C7E7'}
                  //color={'#e1306c'}
                  //color={ThemeColors.light.PRIMARY}
                  //color={'#17486b'}
                  rotation={0}
                />
              </Grid>
            </Grid>
          </Layer>

          <Layer sx={{ top: '35px', overflow: 'visible' }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}
            >
              <Grid
                item
                sx={{
                  //transform:`rotate(${isVisible?310:0}deg)`,
                  transform: `rotate(${animate ? 310 : 0}deg)`,
                  //transition:'transform 2.0s ease-in-out',
                  transition: 'transform 2000ms ease-in-out',
                  WebkitTransition: 'transform 2000ms ease-in-out',
                  MsTransition: 'transform 2000ms ease-in-out',
                  MozTransition: 'transform 2000ms ease-in-out',
                  OTransition: 'transform 2000ms ease-in-out',

                  transitionDuration: '2000ms',
                  WebkitTransitionDuration: '2000ms',
                  MsTransitionDuration: '2000ms',
                  MozTransitionDuration: '2000ms',
                  OTransitionDuration: '2000ms',
                }}
              >
                <CircularSegment
                  size={190}
                  percentage={32}
                  color={iOSColors.red}
                  //color={'#A7C7E7'}
                  //color={'#FAC898'}
                  //color={'#833ab4'}
                  //color={ThemeColors.dark.PRIMARY}
                  //color={'#125a87'}
                  rotation={0}
                />
              </Grid>
            </Grid>
          </Layer>

          <Layer sx={{ top: '35px', overflow: 'visible' }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}
            >
              <Grid
                item
                sx={{
                  //transform:`rotate(${isVisible?-252:0}deg)`,
                  transform: `rotate(${animate ? -252 : 0}deg)`,
                  //transition:'transform 2.0s ease-in-out',
                  transition: 'transform 2000ms ease-in-out',
                  WebkitTransition: 'transform 2000ms ease-in-out',
                  MsTransition: 'transform 2000ms ease-in-out',
                  MozTransition: 'transform 2000ms ease-in-out',
                  OTransition: 'transform 2000ms ease-in-out',

                  transitionDuration: '2000ms',
                  WebkitTransitionDuration: '2000ms',
                  MsTransitionDuration: '2000ms',
                  MozTransitionDuration: '2000ms',
                  OTransitionDuration: '2000ms',
                }}
              >
                <CircularSegment
                  size={200}
                  percentage={70}
                  color={iOSColors.green}
                  //color={ThemeColors.dark.PRIMARY}
                  //color={'#405de6'}
                  //color={AppSignUpColors.PRIMARY}
                  //color={'#006da5'}
                  rotation={0}
                />
              </Grid>
            </Grid>
          </Layer>
        </div>
      </AnimatedSlide>
    </Layer>
  );
};

export default Resolutions;
