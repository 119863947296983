import { Grid } from '@mui/material';
import Row from '../../../components/layout/rows/Row';
import FadeSlide from '../../../components/layout/container/FadeSlide.container';
import Header from '../../../components/text/Header';
import HButton from '../../../components/buttons/Highlight.button';
import SubHeader from '../../../components/text/SubHeader';
import PlainText from '../../text/PlainText';

interface CallToActionPropsType {
  header: string;
  subHeader?: string;
  text?: string;
  link?: string | (() => void);
  buttonText?: string;
}

const CallToAction = ({ header, subHeader, text, link, buttonText }: CallToActionPropsType) => {
  return (
    <Grid container bgcolor="#E9FEEB" justifyContent="center" alignItems="center" mt={8}>
      <Row>
        <FadeSlide direction="up" transitionDelay={400} once>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            mb={6}
            spacing={2}
            sx={{ textAlign: 'center' }}
          >
            <Grid item xs={12}>
              <Header text={header} darkTheme={false} />
            </Grid>
            {subHeader && (
              <Grid item xs={12} md={10}>
                <SubHeader text={subHeader} darkTheme={false} />
              </Grid>
            )}
            {text && (
              <Grid item xs={12}>
                <PlainText text={text} darkTheme={false} />
              </Grid>
            )}
            {link && (
              <Grid
                item
                xs={12}
                mt={2}
                onClick={() => {
                  if (link) {
                    if (typeof link === 'string') {
                      window.open(link, '_self');
                    } else {
                      link();
                    }
                  }
                }}
              >
                <HButton size="large" sx={{ px: 5 }} darkTheme={true}>
                  {buttonText}
                </HButton>
              </Grid>
            )}
          </Grid>
        </FadeSlide>
      </Row>
    </Grid>
  );
};

export default CallToAction;
