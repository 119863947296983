import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import Header from '../text/Header';
import Label from '../text/Label';
import BookDemoHubspot from '../Hubspot/BookDemo.hubspot';
interface BookDemoPopupPropsType {
  setShowContactPopup: (show: boolean) => void;
}

export const BookDemoPopup = ({ setShowContactPopup }: BookDemoPopupPropsType) => {
  const { t } = useTranslation();
  const darkTheme = false;

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script?.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head?.appendChild(script);
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.5)',
        zIndex: 1001,
        overflow: 'hidden',
      }}
    >
      <Grid
        container
        m={3}
        justifyContent="center"
        sx={{
          position: 'relative',
          maxWidth: '1100px',
          background: '#E9FEEB',
          borderRadius: '20px',
          overflow: 'scroll',
          height: 'auto',
          maxHeight: '90%',
          border: '1px solid #10182810',
          boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2)',
          zIndex: 1002,
        }}
      >
        <Grid
          item
          onClick={() => setShowContactPopup(false)}
          sx={{
            position: 'absolute',
            top: '5px',
            right: '20px',
            fontSize: '30px',
            cursor: 'pointer',
          }}
        >
          &times;
        </Grid>
        <Grid
          item
          container
          pt={8}
          pb={{
            xs: 0,
            md: 4,
          }}
          justifyContent="center"
        >
          <Grid item xs={12} md={10.5} px={4} container justifyContent="center" textAlign="center">
            <Label
              text={t('MITTELSTAND.CONTACT_FORM.LABEL')}
              darkTheme={darkTheme}
              highlighted
              sx={{
                mb: '10px',
              }}
            />
            <Header
              sx={{
                mb: '20px',
              }}
              text={t('MITTELSTAND.CONTACT_FORM.TITLE')}
            />
          </Grid>
          <BookDemoHubspot />
        </Grid>
      </Grid>
    </Grid>
  );
};
