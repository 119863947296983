import { Box, SxProps } from '@mui/material';
import { FC } from 'react';

interface PlainTextPropsType {
  text?: string;
  darkTheme?: boolean;
  sx?: SxProps;
  opacity?: boolean;
  bold?: boolean;
  italic?: boolean;
}

const PlainText: FC<PlainTextPropsType> = ({
  text,
  darkTheme,
  children,
  sx,
  opacity,
  bold,
  italic,
}) => {
  return (
    <Box
      component="p"
      sx={{
        opacity: !opacity ? 0.5 : null,
        transition: 'color 500ms ease-in-out',
        WebkitTransition: 'color 500ms ease-in-out',
        MsTransition: 'color 500ms ease-in-out',
        MozTransition: 'color 500ms ease-in-out',
        OTransition: 'color 500ms ease-in-out',
        transitionDuration: '500ms',
        WebkitTransitionDuration: '500ms',
        MsTransitionDuration: '500ms',
        MozTransitionDuration: '500ms',
        OTransitionDuration: '500ms',
        color: !darkTheme ? '#000' : '#FFF',
        fontWeight: !bold ? null : 500,
        fontStyle: !italic ? null : 'italic',
        ...sx,
      }}
    >
      {text}
      {children}
    </Box>
  );
};

export default PlainText;
