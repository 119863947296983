import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { PageWrapper, Section } from '../../components/layout';
import Row from '../../components/layout/rows/Row';
import FeatureHighlight from '../../components/sections/FeatureHighlight.section';
import CallToAction from '../../components/sections/cta/CallToAction.section';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import SubTitle from '../../components/text/SubTitle';
import HButton from '../../components/buttons/Highlight.button';
import FeatureOverview from '../../components/sections/FeatureOverview.section';
import FeatureList from '../../components/sections/FeatureList.section';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';
import { ReactComponent as Lock } from '/assets/images/icons/lock1.svg';
import { ReactComponent as DisconnectedIcon } from '/assets/images/icons/disconnected.svg';
import { ReactComponent as CertificateIcon } from '/assets/images/icons/certificate.svg';
import { ReactComponent as BucketIcon } from '/assets/images/icons/bucket.svg';
import { ReactComponent as ContractIcon } from '/assets/images/icons/contract.svg';
import { ReactComponent as EventIcon } from '/assets/images/icons/event.svg';
import { ReactComponent as KeyIcon } from '/assets/images/icons/key.svg';
import { ReactComponent as PolicyIcon } from '/assets/images/icons/policy.svg';
import { ReactComponent as TimeIcon } from '/assets/images/icons/time.svg';
import { ReactComponent as VisibilityIcon } from '/assets/images/icons/visibility.svg';
import { ReactComponent as EuropeIcon } from '/assets/images/icons/europe.svg';
import { ReactComponent as GavelIcon } from '/assets/images/icons/gavel.svg';

const Security: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);

  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const privacyFeatures = [
    {
      title: t('SECURITY.PRIVACY_FEATURES.SERVERS.LABEL'),
      text: t('SECURITY.PRIVACY_FEATURES.SERVERS.TEXT'),
      icon: <EuropeIcon />,
    },
    {
      title: t('SECURITY.PRIVACY_FEATURES.ENCRYPTION.LABEL'),
      text: t('SECURITY.PRIVACY_FEATURES.ENCRYPTION.TEXT'),
      icon: <Lock />,
    },
    {
      title: t('SECURITY.PRIVACY_FEATURES.GDPR.LABEL'),
      text: t('SECURITY.PRIVACY_FEATURES.GDPR.TEXT'),
      icon: <GavelIcon />,
    },
  ];

  const featureOverview = [
    {
      title: t('SECURITY.SECURITY_MADE_IN_EUROPE.GRANULAR_ACCESS_PERMISSIONS.TITLE'),
      text: t('SECURITY.SECURITY_MADE_IN_EUROPE.GRANULAR_ACCESS_PERMISSIONS.TEXT'),
      icon: <KeyIcon />,
    },
    {
      title: t('SECURITY.SECURITY_MADE_IN_EUROPE.ISO_27001_CERTIFIED.TITLE'),
      text: t('SECURITY.SECURITY_MADE_IN_EUROPE.ISO_27001_CERTIFIED.TEXT'),
      icon: <CertificateIcon />,
    },
    {
      title: t('SECURITY.SECURITY_MADE_IN_EUROPE.SECURE_CONFIDENTIALITY.TITLE'),
      text: t('SECURITY.SECURITY_MADE_IN_EUROPE.SECURE_CONFIDENTIALITY.TEXT'),
      icon: <VisibilityIcon />,
    },
    {
      title: t('SECURITY.SECURITY_MADE_IN_EUROPE.ANNUAL_PENTEST.TITLE'),
      text: t('SECURITY.SECURITY_MADE_IN_EUROPE.ANNUAL_PENTEST.TEXT'),
      icon: <PolicyIcon />,
    },
    {
      title: t('SECURITY.SECURITY_MADE_IN_EUROPE.SELF_HOSTING_LICENSE.TITLE'),
      text: t('SECURITY.SECURITY_MADE_IN_EUROPE.SELF_HOSTING_LICENSE.TEXT'),
      icon: <DisconnectedIcon />,
    },
    {
      title: t('SECURITY.SECURITY_MADE_IN_EUROPE.ISOLATED_S3_BUCKET.TITLE'),
      text: t('SECURITY.SECURITY_MADE_IN_EUROPE.ISOLATED_S3_BUCKET.TEXT'),
      icon: <BucketIcon />,
    },
    {
      title: t('SECURITY.SECURITY_MADE_IN_EUROPE.CONFIDENTIALITY.TITLE'),
      text: t('SECURITY.SECURITY_MADE_IN_EUROPE.CONFIDENTIALITY.TEXT'),
      icon: <ContractIcon />,
    },
    {
      title: t('SECURITY.SECURITY_MADE_IN_EUROPE.SOC_2.TITLE'),
      text: t('SECURITY.SECURITY_MADE_IN_EUROPE.SOC_2.TEXT'),
      icon: <EventIcon />,
    },
    {
      title: t('SECURITY.SECURITY_MADE_IN_EUROPE.AUTOSCALING.TITLE'),
      text: t('SECURITY.SECURITY_MADE_IN_EUROPE.AUTOSCALING.TEXT'),
      icon: <TimeIcon />,
    },
  ];

  const featureList = [
    {
      title: t('SECURITY.FEATURE_LIST.CUSTOMIZED_SSO.TITLE'),
      text: t('SECURITY.FEATURE_LIST.CUSTOMIZED_SSO.TEXT'),
    },
    {
      title: t('SECURITY.FEATURE_LIST.MFA.TITLE'),
      text: t('SECURITY.FEATURE_LIST.MFA.TEXT'),
    },
    {
      title: t('SECURITY.FEATURE_LIST.CUSTOM_EMAIL_ADDRESS.TITLE'),
      text: t('SECURITY.FEATURE_LIST.CUSTOM_EMAIL_ADDRESS.TEXT'),
    },
    {
      title: t('SECURITY.FEATURE_LIST.ACTIVE_DIRECTORY.TITLE'),
      text: t('SECURITY.FEATURE_LIST.ACTIVE_DIRECTORY.TEXT'),
    },
  ];

  return (
    <PageWrapper>
      <Section flexDirection="row-reverse" expanded>
        <Grid item xs={12} md={6}>
          <img
            src={'/assets/images/security/hero/' + i18n.language + '.webp'}
            alt="Hero Security Page"
            style={{
              width: '100%',
              maxHeight: '250px',
              objectFit: 'contain',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item container rowSpacing={3}>
            <Title
              text={t('SECURITY.HERO.TITLE')}
              mainTitle
              darkTheme={darkTheme}
              sx={{
                mb: 2,
              }}
            />
            <SubTitle
              text={t('SECURITY.HERO.SUBTITLE')}
              sx={{
                fontSize: {
                  xs: 30,
                  sm: 30,
                  lg: 30,
                  xl: 38,
                },
                mb: 1,
                pr: 2,
              }}
              darkTheme={darkTheme}
            />
          </Grid>
        </Grid>
      </Section>

      <FeatureHighlight
        title={t('SECURITY.PRIVACY_FEATURES.TITLE')}
        features={privacyFeatures}
        darkTheme={darkTheme}
        small
      />

      <Section spacing="none">
        <Grid item container justifyContent="center">
          <Grid item onClick={() => setShowContactPopup(true)}>
            <HButton size="large" sx={{ px: 10 }} darkTheme={true}>
              {t('GENERAL.BOOK_PERSONAL_MEETING')}
            </HButton>
          </Grid>
        </Grid>
      </Section>

      <FeatureOverview
        title={t('SECURITY.SECURITY_MADE_IN_EUROPE.TITLE')}
        features={featureOverview}
      />

      <FeatureList title={t('SECURITY.FEATURE_LIST.TITLE')} features={featureList} />

      <CallToAction
        header={t('SECURITY.CTA.TITLE')}
        subHeader={t('SECURITY.CTA.SUBTITLE')}
        link={() => setShowContactPopup(true)}
        buttonText={t('GENERAL.BOOK_MEETING')}
      />

      {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}

      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </PageWrapper>
  );
};

export default Security;
