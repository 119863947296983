import { useCalendlyEventListener } from 'react-calendly';
import { trackGtagConversion } from '../utils/gtag-conversion';

export const useCalendlyConversionTracking = () => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      trackGtagConversion('AW-10855235169/kYfSCK-Htp0ZEOH8lrgo');
    },
  });
};
