import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { AppColors, AppSignUpColors } from '@fides-jam/utils/theme/colors';
import Row from '../../components/layout/rows/Row';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Header from '../../components/text/Header';
import PlainText from '../../components/text/PlainText';
import SubHeader from '../../components/text/SubHeader';
import HButton from '../buttons/Highlight.button';
import { RouteNames } from '../../Routes/routeNames';

const CircleOfCounselsSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const darkTheme = false;

  return (
    <Grid
      container
      bgcolor={AppColors.LIGHT_GREEN}
      justifyContent="center"
      alignItems="center"
      py={6}
    >
      <Row>
        <FadeSlide direction="up" transitionDelay={400} once>
          <Grid container item spacing={8} pt={8}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} mb={2}>
                <Header darkTheme={darkTheme} text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.TITLE')} />
              </Grid>
              <Grid item xs={12} md={10} mb={4}>
                <SubHeader
                  darkTheme={darkTheme}
                  text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.SUBTITLE')}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.TEXT_1')}
                />
              </Grid>
              <Grid item xs={12} mb={4}>
                <PlainText
                  darkTheme={darkTheme}
                  text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.TEXT_2')}
                />
              </Grid>
              <Grid item xs={12}>
                <HButton
                  size="large"
                  sx={{ px: 5 }}
                  darkTheme={true}
                  href={RouteNames.circleOfCounsels}
                >
                  {t('CIRCLE_OF_COUNSELS.CTO_SECTION.BUTTON_TEXT')}
                </HButton>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6}>
              <Grid
                container
                item
                sx={{
                  borderRadius: '10px',
                  minHeight: '300px',
                  backgroundImage: `url("/assets/images/events/coc/impressions/coc_impressions_1-min.webp")`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                }}
              ></Grid>
            </Grid>
          </Grid>
        </FadeSlide>
      </Row>
    </Grid>
  );
};

export default CircleOfCounselsSection;
