import * as React from 'react';
import { FC } from 'react';
import { Box } from '@mui/material';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';

interface MenuBorderCoverProps {
  darkTheme?: boolean;
}

export const MenuBorderCover: FC<MenuBorderCoverProps> = ({ darkTheme }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 100,
        top: '-1px',
        bottom: '-1px',
        right: '0px',
        width: '30px',
        background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
        borderTop: darkTheme ? '1px solid rgba(255,255,255,0.05)' : '1px solid rgba(0,0,0,0.05)',
        borderBottom: darkTheme ? '1px solid rgba(255,255,255,0.05)' : '1px solid rgba(0,0,0,0.05)',
      }}
    />
  );
};
