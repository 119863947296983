import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Row from '../../components/layout/rows/Row';
import PlainText from '../../components/text/PlainText';
import SubTitle from '../../components/text/SubTitle';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { PageWrapper, Section } from '../../components/layout';

const DataInformationPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  return (
    <PageWrapper>
      <Section>
        <Grid item xs={12} lg={10}>
          <Title
            text={t('DATAINFO.TITLE')}
            mainTitle
            darkTheme={false}
            sx={{
              fontSize: {
                xs: 30,
                sm: 45,
                lg: 55,
                xl: 68,
              },
              textAlign: {
                xs: 'left',
                sm: 'center',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={10} sx={{ mt: 4 }}>
          <SubTitle
            text={t('DATAINFO.SECTIONS.INTRO')}
            darkTheme={false}
            sx={{
              textAlign: {
                xs: 'left',
                sm: 'center',
              },
              opacity: 0.5,
            }}
          />
        </Grid>
      </Section>
      <Row columnSpacing={isSmall ? 0 : 10}>
        <FadeSlide direction={'up'} rowSpacing={3}>
          <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SubTitle text={t('DATAINFO.SECTIONS.CONTROLLER')} darkTheme={darkTheme} />
          </Grid>
          <Grid>
            <PlainText darkTheme={darkTheme} text={t('PRIVACY.SECTIONS.NAME.TEXT')} />

            <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.DETAILS.ADDRESS')} />
            <PlainText darkTheme={darkTheme} text={t('IMPRINT.SECTIONS.DETAILS.PHONE')}>
              <p>
                {t('IMPRINT.SECTIONS.DETAILS.CONTACT')}{' '}
                <a href="mailto:hello@fides.technology">hello@fides.technology</a>
                <a href="https://www.fides.technology/">
                  <br />
                  www.fides.technology
                </a>
              </p>
            </PlainText>
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SubTitle text={t('PRIVACY.SECTIONS.DPO.TITLE')} darkTheme={darkTheme} />
          </Grid>
          <Grid>
            <PlainText darkTheme={darkTheme} text={t('PRIVACY.SECTIONS.DPO.TEXT')} />
            <PlainText darkTheme={darkTheme} text={t('PRIVACY.SECTIONS.DPO.CONTACT')} />
            <PlainText darkTheme={darkTheme} text={t('PRIVACY.SECTIONS.DPO.PHONE')} />
            <PlainText darkTheme={darkTheme}>
              <p>
                <a href="https://www.isico-datenschutz.de" target="_blank">
                  www.isico-datenschutz.de
                </a>
              </p>
            </PlainText>
          </Grid>

          <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SubTitle text={t('DATAINFO.SECTIONS.GENERALINFORMATION')} darkTheme={darkTheme} />
          </Grid>
          <Grid>
            <PlainText>
              <p>
                <strong style={{ color: 'black' }}>a. Personal data that is processed by us</strong>
              </p>
              <p>
                In the context of contract negotiations, we process the following data about you:
              </p>
              <ul>
                <li>Your e-mail address</li>
                <li>First name</li>
                <li>Last name</li>
              </ul>
              <p>
                Within the scope of existing customer relations, we process the following personal
                data:{' '}
              </p>
              <ul>
                <li>First name</li>
                <li>Last name</li>
                <li>E-mail address</li>
                <li>Mobile number</li>
                <li>IP address</li>
                <li>Role in the company</li>
                <li>Date of birth (optional)</li>
                <li>Address (optional)</li>
                <li>Gender (optional)</li>
                <li>User photo (optional)</li>
              </ul>
            </PlainText>
            <PlainText>
              <p>
                <strong style={{ color: 'black' }}>b. Purposes of the data processing</strong>
              </p>
              <p>
                In the context of contractual negotiations and existing customer relationships, your
                personal data will be processed for the following purposes:{' '}
              </p>
              <ul>
                <li>
                  To process your inquiry as an interested party. For this purpose, we use your
                  contact information to respond to your inquiry{' '}
                </li>
                <li>
                  To prepare and carry out pre-contractual measures - this includes, for example,
                  the preparation and sending of an individual offer or individual agreement and
                  transmission of contractual conditions with the aim of concluding a contract{' '}
                </li>
                <li>To add your contact information to our customer database</li>
                <li>To fulfil our contractual obligations with you </li>
                <li>
                  To comply with our legal obligations. This includes, for example, the transmission
                  of your personal data to the tax office{' '}
                </li>
                <li>
                  In order to provide the best service to you as our customer. This includes in
                  particular the communication with you by e-mail and/or mobile phone
                </li>
                <li>
                  For the purpose of sending newsletters, insofar as you have registered for our
                  newsletter{' '}
                </li>
                <li>To fulfil post-contractual measures.</li>
                <li>For the assertion, exercise or defense of legal claims</li>
                <li>
                  In order to be able to inform you about our services and new offers within the
                  scope of direct advertising{' '}
                </li>
              </ul>
            </PlainText>
            <PlainText>
              <p>
                <strong style={{ color: 'black' }}>c. Legal basis for data processing </strong>
              </p>
              <p>
                The legal basis for the processing of data under the aforementioned purposes are
                Art. 6 (1) p. 1 lit. a, b, c and f GDRP.
              </p>
              <p>
                <strong>Processing of your personal data on the basis of consent </strong>
                <br />
                Insofar as we obtain your consent for the processing of your personal data, the
                processing of your personal data is based on Art. 6 (1) p. 1 lit. a GDRP in
                conjunction with Art. 5, 7 GDRP.
              </p>
              <p>
                <strong>Processing for the purpose of executing the contract with you</strong>
                <br />
                Insofar as we process your personal data for the purpose of fulfilling a contract
                obligation, Art. 6 (1) p. 1 lit. b GDRP serves as our legal basis. This also applies
                to processing operations that are necessary for the implementation of pre- and
                post-contractual measures.{' '}
              </p>
              <p>
                <strong>Processing for compliance with a legal obligation</strong>
                <br />
                Insofar as the processing of your personal data is necessary for the fulfilment of a
                legal obligation to which our company is subject, Art. 6 (1) p. 1 lit. c GDRP serves
                as the legal basis for us. Our legal obligation to process data results from
                retention obligations under tax and commercial law.{' '}
              </p>
              <p>
                <strong>Processing on the basis of legitimate interest </strong>
                <br />
                Insofar as we process your personal data on the basis of a legitimate interest, the
                legitimate interests pursued by us in this context - in addition to the purposes
                listed under b. - are:
              </p>
              <ul>
                <li>
                  the ability to inform you about our products, offers and services by means of
                  direct marketing;
                </li>
                <li>
                  communicate with you, in particular to answer your requests by e-mail and
                  telephone;
                </li>
              </ul>
              <p>
                The legal basis for processing activities in connection with the assertion, exercise
                or defense of legal claims is also our legitimate interest pursuant to Art. 6 (1)
                sentence 1 lit. f GDRP
              </p>
            </PlainText>
            <PlainText>
              <p>
                <strong style={{ color: 'black' }}>
                  d. Source from which your personal data originates
                </strong>
              </p>
              <p>We may not receive the following personal information directly from you: </p>
              <ul>
                <li>Name</li>
                <li>First name</li>
                <li>E-mail address</li>
              </ul>
              <p>These come from the following sources:</p>
              <ul>
                <li>
                  companies that are customers of ours and have indicated you as an investor or
                  contact person of an investor
                </li>
                <li>our customer who have granted you access to their company's Fides account</li>
              </ul>
            </PlainText>
          </Grid>

          <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SubTitle text={t('DATAINFO.SECTIONS.RECIPIENTS')} darkTheme={darkTheme} />
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 'bold' }}></Grid>
          <Grid>
            <PlainText darkTheme={darkTheme}>
              <p>
                In the course of processing your personal data, we may disclose the personal data
                concerning you to the following recipients. We only transfer your personal data to
                external recipients if you have consented or if this is permitted by law. External
                recipients of your personal data are in particular:{' '}
              </p>
              <p> Processors</p>
              <p>
                In addition, your personal data may be transferred to the following service
                providers located in a country outside the EU/EEA:
                <ul>
                  <li>Auth0</li>
                </ul>
              </p>
            </PlainText>
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SubTitle text={t('DATAINFO.SECTIONS.TRANSFER')} darkTheme={darkTheme} />
          </Grid>
          <PlainText darkTheme={darkTheme}>
            <p>
              In principle, the personal data collected and generated during the provision of our
              services is stored on our servers in the European Union. Since the providers of our
              software solutions, among others, offer their products and/or services on a global
              basis based on available resources and servers, your personal data may be transferred
              to or accessed from other jurisdictions outside the European Union and the European
              Economic Area. In particular, personal data will be transferred to the third country
              USA within the meaning of Article 15 (2) GDRP. In order to ensure the continuation of
              the necessary level of protection in the event of data transfer to a third country,
              contractual measures are agreed for this purpose. The software provider has its
              registered office in the United States of America, which has not been recognized as
              providing an adequate level of data protection. In order to ensure appropriate
              guarantees for the protection of the transfer and processing of personal data outside
              the EU, the transfer of data to and processing of data by our service providers is
              carried out on the basis of appropriate guarantees pursuant to Art. 46 ff GDRP, in
              particular by concluding so-called standard data protection clauses pursuant to Art.
              46 (2) lit. c GDRP.
            </p>
          </PlainText>
          <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SubTitle text={t('DATAINFO.SECTIONS.STORAGE')} darkTheme={darkTheme} />
          </Grid>
          <PlainText darkTheme={darkTheme}>
            <p>
              We do not store your personal data for longer than necessary for the purpose for which
              it was collected. This means that data in our systems is destroyed or deleted as soon
              as it is no longer needed. Reasonable measures are taken by us to ensure that your
              personal data is only processed under the following conditions:
            </p>
            <ol>
              <li>For the duration that the data is used to provide you with a service.</li>
              <li>As required by applicable law, contract or in view of our legal obligations.</li>
              <li>
                Only for as long as necessary for the purpose for which the data was collected, or
                longer if required by contract, applicable law, applying appropriate safeguards.
              </li>
            </ol>
            <p>
              {' '}
              A requirement may exist in particular if the data is still needed in order to fulfil
              contractual services, to check and grant or ward off warranty and, if applicable,
              guarantee claims. If the data is no longer required for the fulfilment of contractual
              or legal obligations, it is regularly deleted, unless its - temporary - retention is
              still necessary, in particular for the fulfilment of legal retention periods of up to
              ten years (including from the German Commercial Code, the German Fiscal Code and the
              German Anti Money Laundering Act). In the case of statutory retention obligations,
              deletion is only considered after the expiry of the respective retention obligation.
            </p>
          </PlainText>
          <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SubTitle text={t('DATAINFO.SECTIONS.RIGHTSOFSUBJECT')} darkTheme={darkTheme} />
          </Grid>

          <PlainText darkTheme={darkTheme}>
            <p>Under the General Data Protection Regulation, you have the following rights:</p>
            <ul>
              <li>
                If your personal data is processed, you have the right to obtain information from
                the controller about the data stored about you (Art. 15 GDRP).
              </li>
              <li>
                If inaccurate personal data is processed, you have a right to rectification (Art. 16
                GDRP).
              </li>
              <li>
                If the legal requirements are met, you may request the deletion or restriction of
                processing (Art. 17 and 18 GDRP).
              </li>
              <li>
                If you have consented to the data processing or if a contract for data processing
                exists and the data processing is carried out by automated means, you may have a
                right to data portability (Art. 20 GDRP).
              </li>
              <li>
                If the personal data concerning you is processed for the purpose of direct
                marketing, you have the right to object at any time to the processing of personal
                data concerning you for the purpose of such marketing. If you object to the
                processing for direct marketing purposes, the personal data concerning you will no
                longer be processed for these purposes.
              </li>
              <li>
                If you object to processing for direct marketing purposes, the personal data
                concerning you will no longer be processed for these purposes.
              </li>
              <li>
                Furthermore, there is a right of appeal to a supervisory authority (Art. 77 GDRP).
                The supervisory authority responsible for us is the Bavarian State Office for Data
                Protection Supervision (BayLDA). You can reach this authority under
              </li>
            </ul>
            <p style={{ textAlign: 'center' }}>
              Bayerische Landesamt für Datenschutzaufsicht (BayLDA)
              <br />
              Promenade 18
              <br />
              91522 Ansbach
              <br />
              Tel: +49 (0) 981 180093-0
              <br />
              <a href="https://www.lda.bayern.de/de/index.html" target="_blank">
                {' '}
                Website: https://www.lda.bayern.de/de/index.html{' '}
              </a>
            </p>
          </PlainText>

          <table
            style={{
              border: '3px solid grey',
              borderCollapse: 'collapse',
              textAlign: 'center',
              width: '100%',
              tableLayout: 'fixed',
            }}
          >
            <tr>
              <td style={{ border: '1px solid grey' }}>
                <PlainText>
                  Provided the legal requirements are met, you may object to the processing of
                  personal data which is carried out on the basis of Art. 6 (1) lit. e or f GDRP
                  (Art. 21 GDRP) at any time on grounds relating to your particular situation .{' '}
                </PlainText>
              </td>
            </tr>
          </table>
          <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SubTitle text={t('DATAINFO.SECTIONS.WITHDRAWAL')} darkTheme={darkTheme} />
          </Grid>

          <PlainText darkTheme={darkTheme}>
            <p>
              If you have consented to processing by the data controller by making a corresponding
              declaration, you can revoke your consent at any time for the future. The legality of
              the data processing carried out on the basis of the consent until the revocation is
              not affected by this.{' '}
            </p>
          </PlainText>
        </FadeSlide>
      </Row>

      <Row sx={{ mt: 3 }}>
        <Footer />
      </Row>
    </PageWrapper>
  );
};

export default DataInformationPage;
