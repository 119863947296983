import './i18n';
import './main.css';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import _$ from 'jquery';

import App from './app/app';

declare global {
  interface Window {
    $: JQueryStatic;
  }
}
window.$ = _$;

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);
