import * as React from 'react';
import { FC } from 'react';
import { Box } from '@mui/material';

interface SubMenuArrowProps {
  darkTheme?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const SubMenuArrow: FC<SubMenuArrowProps> = ({ darkTheme, onMouseEnter, onMouseLeave }) => {
  return (
    <Box
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      display={'flex'}
      justifyContent={'end'}
      alignItems="center"
      sx={{
        position: 'absolute',
        zIndex: 1000,
        top: 0,
        bottom: 0,
        right: '-40px',
        width: '50px',
      }}
    >
      <div
        style={{
          width: 0,
          height: 0,
          borderTop: '15px solid transparent',
          borderBottom: '15px solid transparent',
          borderLeft: darkTheme
            ? '15px solid rgba(255,255,255,0.05)'
            : '15px solid rgba(0,0,0,0.05)',
        }}
      ></div>
    </Box>
  );
};
