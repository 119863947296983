export enum TrackingSuffix {
  CLICK = '- track click',
  LINK_CLICK = '- track link click',
  VIEW = '- track view',
}

export enum Event {
  CLICK = 'click',
  CLICK_TOUCH = 'click/touch',
  VIEW = 'view',
  OPEN = 'open',
  START_CAPACITY_SESSION = 'start capacity session',
  SELECT_KEY_ACCOUNT = 'select key account',
  SELECT_COMPANY = 'select company',
}

export enum PointerType {
  MOUSE = 'mouse',
  TOUCH = 'touch',
  PEN = 'pen',
}

export interface AnalyticsView {
  target: HTMLDivElement;
  mutationObserver?: MutationObserver;
  visible: boolean;
}
