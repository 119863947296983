import FileGraphic from '@fides-jam/front/components/FileGraphic';
import { Layer } from '@fides-jam/front/components/Layer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Grid, SxProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedSlide from '../../layout/container/AnimatedSlide.container';
import FadeSlide from '../../layout/container/FadeSlide.container';

interface DetailsPropsType {
  sx?: SxProps;
  animate?: boolean;
}

const Details: FC<DetailsPropsType> = ({ sx, animate }) => {
  const { t, i18n } = useTranslation();
  return (
    <Layer
      sx={{
        overflow: 'visible',
        top: {
          xs: '-95px',
          sm: '28px',
          md: '28px',
          lg: '28px',
          xl: '28px',
        },
        bottom: 'unset',
        /*
        right:{
          xs:'-110px',
          md:'-70px',
          lg:'-40px',
          xl:'-40px',
        },
        left:'unset',
        */
        left: {
          xs: '-110px',
          sm: '-50px',
          md: '-75px',
          lg: '-45px',
        },
        right: 'unset',

        width: '450px',
        minWidth: '450px',
      }}
    >
      <AnimatedSlide
        animate={animate}
        direction={'left'}
        transitionDelay={600}
        sx={{
          width: '100%',
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          background: '#FFF',
          overflow: 'hidden',
          borderRadius: {
            xs: '20px',
            md: '25px',
          },
          px: 4,
          pt: 4,
          pb: 4,
        }}
      >
        <Grid container justifyContent="center" alignItems="center" flexDirection={'column'}>
          <Grid
            container
            sx={{
              fontWeight: '600',
              fontSize: '17px',
              mb: 0.8,
              ml: 1,
            }}
          >
            <span style={{ opacity: 0.4 }}> {t('AUDITTRAILCELL.DISCHARGE')}</span>
            <div
              style={{
                lineHeight: 1.0,
                opacity: 1.5,
              }}
            >
              <small>
                <span style={{ opacity: 0.4 }}>{t('AUDITTRAILCELL.SIGNATURE')}</span>{' '}
                <strong>DocuSign</strong>
              </small>
            </div>
          </Grid>

          <Grid container item flexDirection={'row'} alignItems="center" sx={{ py: 5 }}>
            <Grid
              item
              sx={{
                width: '70px',
                mr: 1.5,
                position: 'relative',
              }}
            >
              <FileGraphic fileName={'dummy.pdf'} />
              <Box
                sx={{
                  position: 'absolute',
                  left: '-4px',
                  bottom: '-4px',
                  background: '#FFF',
                  borderRadius: '50%',
                }}
              >
                <CheckCircleIcon
                  fontSize={'medium'}
                  color={'success'}
                  sx={{
                    display: 'block',
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item
              flexDirection={'column'}
              sx={{
                flex: 1,
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  flex: 1,
                  height: '90px',
                  mb: '-15px',
                  backgroundImage: 'url("/assets/images/features/signature.webp")',
                  backgroundPosition: 'left center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
              />

              <Grid
                item
                sx={{
                  flex: 1,
                  borderTop: '2px solid rgba(0,0,0,0.1)',
                  color: 'rgba(0,0,0,0.2)',
                }}
              >
                <strong>
                  <small>Berlin, 05.01.2022</small>
                </strong>
              </Grid>
            </Grid>
          </Grid>

          <Layer
            sx={{
              left: 80,
            }}
          >
            <FadeSlide
              transitionDelay={800}
              sx={{
                background: {
                  xs: 'unset',
                  sm: 'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)',
                },
                width: '100%',
                height: '100%',
              }}
            />
          </Layer>
        </Grid>
      </AnimatedSlide>

      {/*
            <FadeSlide
        direction={'up'}
        transitionDelay={300}
        sx={{
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          background:'#FFF',
          mt:1,
          borderRadius:{
            xs:'20px',
            md:'25px',
          },
        }}
      >
        <Grid container item sx={{p:1.5}}>
          <Grid item sx={{mr:0.7}}>
            <FadeSlide
              transitionDelay={200}
              direction={'right'}
            >
              <CountryBubble jurisdiction={'Europe'}/>
            </FadeSlide>
          </Grid>
          <Grid item sx={{mr:0.7}}>
            <FadeSlide
              transitionDelay={300}
              direction={'right'}
            >
              <CountryBubble jurisdiction={'Germany'} />
            </FadeSlide>
          </Grid>
          <Grid item sx={{flex:1}}>
            <FadeSlide
              transitionDelay={400}
              direction={'right'}
              sx={{
                fontWeight:'600',
                fontSize:'16px',
                py:0.5,
                justifyContent:'center',
              }}
            >Europe Hosted Servers (Germany)
            </FadeSlide>
          </Grid>
        </Grid>
      </FadeSlide>
      */}
    </Layer>
  );
};

export default Details;
