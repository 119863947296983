import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useNavigateToDummyConfirmation = () => {
  const location = window?.location;
  const { pathname, search } = location;
  const navigate = useNavigate();

  useEffect(() => {
    if (!window) return;

    const demoConfirmationEventHandler = (event: MessageEvent) => {
      if (event.data?.meetingBookSucceeded) {
        navigate(`${pathname}${search}#confirmation`);
      }
    };

    window.addEventListener('message', demoConfirmationEventHandler);

    return () => {
      window.removeEventListener('message', demoConfirmationEventHandler);
    };
  }, [location, navigate, pathname, search]);
};
