import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, SxProps } from '@mui/material';

interface FadeSlidePropsType {
  rowSpacing?: number;
  columnSpacing?: number;
  darkTheme?: boolean;
  direction?: 'left' | 'right' | 'up' | 'down';
  sx?: SxProps;
  transitionDelay?: number;
  onFade?: (isInViewport: boolean) => void;
  once?: boolean;
}

const FadeSlide: FC<FadeSlidePropsType> = ({
  rowSpacing,
  columnSpacing,
  children,
  direction,
  sx,
  transitionDelay,
  onFade,
  once,
}) => {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref, !!once);
  const offset = {
    x: 60,
    y: 40,
  };
  const opacity = useMemo(() => (isInViewport ? 1.0 : 0.0), [isInViewport]);

  useEffect(() => {
    onFade?.(isInViewport);
  }, [isInViewport]);

  const getXOffset = useCallback(() => {
    if (direction === 'left') {
      return !isInViewport ? -offset.x : 0;
    } else if (direction === 'right') {
      return !isInViewport ? offset.x : 0;
    } else return 0.0;
  }, [isInViewport, offset, direction]);

  const getYOffset = useCallback(() => {
    if (direction === 'up') {
      return !isInViewport ? offset.y : 0;
    } else if (direction === 'down') {
      return !isInViewport ? -offset.y : 0;
    } else return 0.0;
  }, [isInViewport, offset, direction]);

  const xOffset = useMemo(() => getXOffset(), [isInViewport]);
  const yOffset = useMemo(() => getYOffset(), [isInViewport]);

  return (
    <Grid
      ref={ref}
      container
      item
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
      sx={{
        /*
        transition:'opacity 1000ms ease-in-out, transform 1000ms ease-in-out, background 600ms ease-in-out',
        WebkitTransition:'opacity 1000ms ease-in-out, transform 1000ms ease-in-out, background 600ms ease-in-out',
        MsTransition:'opacity 1000ms ease-in-out, transform 1000ms ease-in-out, background 600ms ease-in-out',
        MozTransition:'opacity 1000ms ease-in-out, transform 1000ms ease-in-out, background 600ms ease-in-out',
        OTransition:'opacity 1000ms ease-in-out, transform 1000ms ease-in-out, background 600ms ease-in-out',
        */

        transition: 'all 700ms ease-in-out',
        WebkitTransition: 'all 700ms ease-in-out',
        MsTransition: 'all 700ms ease-in-out',
        MozTransition: 'all 700ms ease-in-out',
        OTransition: 'all 700ms ease-in-out',

        transitionDuration: '700ms',
        WebkitTransitionDuration: '700ms',
        MsTransitionDuration: '700ms',
        MozTransitionDuration: '700ms',
        OTransitionDuration: '700ms',
        transitionDelay: `${transitionDelay || 0}ms`,
        WebkitTransitionDelay: `${transitionDelay || 0}ms`,
        MsTransitionDelay: `${transitionDelay || 0}ms`,
        MozTransitionDelay: `${transitionDelay || 0}ms`,
        OTransitionDelay: `${transitionDelay || 0}ms`,
        transform: `translate(${xOffset}px, ${yOffset}px)`,
        opacity: opacity,
        overflow: 'visible',
        ...sx,
      }}
    >
      {children}
    </Grid>
  );
};

const useIsInViewport = (ref: any, once: boolean) => {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const observer = useMemo(
    () => new IntersectionObserver(async ([entry]) => setIsIntersecting(entry.isIntersecting)),
    []
  );
  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  useEffect(() => {
    if (isIntersecting && once) observer.disconnect();
  }, [isIntersecting]);

  return isIntersecting;
};

export default FadeSlide;
