import { FC } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Fade, Grid, Slide, useMediaQuery, useTheme } from '@mui/material';
import Title from '../text/Title';
import SubTitle from '../text/SubTitle';
import HButton from '../buttons/Highlight.button';
import { ThemeColors } from '../../utils/colors';
import { Layer } from '@fides-jam/front/components/Layer';
import { RouteNames } from '../../Routes/routeNames';
import { Section } from '../layout';

interface CentralBannerPropsType {
  darkTheme?: boolean;
}

const CentralBanner: FC<CentralBannerPropsType> = ({ darkTheme }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Section spacing="sm">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        paddingTop="100px"
        height="70%"
        width="80%"
        position="absolute"
        maxWidth="1200px"
        maxHeight="800px"
      >
        <div
          style={{
            background: ThemeColors.light.PRIMARY,
            borderRadius: '50%',
            WebkitFilter: 'blur(60px)',
            filter: 'blur(60px)',
            opacity: 0.1,
            overflow: 'visible',
            width: '100%',
            height: '100%',
          }}
        />
      </Grid>

      <Grid item xs={12} md={10} lg={10} justifyContent="center" textAlign="center">
        <Title
          darkTheme={darkTheme}
          mainTitle
          sx={{
            fontSize: {
              xs: '45px',
              sm: '60px',
              md: '65px',
              lg: '75px',
              xl: '85px',
            },
            px: 0,
            my: {
              xs: 5,
              sm: 5,
            },
            zIndex: 1,
          }}
        >
          {t('HOME.TITLE.1')}{' '}
          <span style={{ color: ThemeColors.dark.PRIMARY }}>{t('HOME.TITLE.2')}</span>{' '}
          {t('HOME.TITLE.3')}
        </Title>
      </Grid>

      <Grid item xs={12} md={10} lg={9} xl={8} justifyContent="center" textAlign="center">
        <SubTitle text={t('HOME.SUBTITLE')} darkTheme={darkTheme} sx={{ opacity: 0.45, mt: 0 }} />
      </Grid>

      <Grid container item xs={12} justifyContent="center" textAlign="center">
        <Grid
          item
          width={{
            xs: '100%',
            sm: 'auto',
          }}
          px={4}
          mt={6}
        >
          <HButton
            size="large"
            fullWidth
            sx={{ px: 5 }}
            darkTheme={darkTheme}
            href={RouteNames.demoBooking}
          >
            {t('GENERAL.GET_STARTED')}
          </HButton>
        </Grid>
      </Grid>
    </Section>
  );
};

export default CentralBanner;
