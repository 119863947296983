import { AppSignUpColors, iOSColors } from '@fides-jam/utils/theme/colors';
import { Box, SxProps } from '@mui/material';
import { CSSProperties, FC } from 'react';

import { ReactComponent as Austria } from '@fides-jam/assets/images/country-flags/at.svg';
import { ReactComponent as Belgium } from '@fides-jam/assets/images/country-flags/be.svg';
import { ReactComponent as Brazil } from '@fides-jam/assets/images/country-flags/br.svg';
import { ReactComponent as Switzerland } from '@fides-jam/assets/images/country-flags/ch.svg';
import { ReactComponent as China } from '@fides-jam/assets/images/country-flags/cn.svg';
import { ReactComponent as Cyprus } from '@fides-jam/assets/images/country-flags/cy.svg';
import { ReactComponent as Czechia } from '@fides-jam/assets/images/country-flags/cz.svg';
import { ReactComponent as Germany } from '@fides-jam/assets/images/country-flags/de.svg';
import { ReactComponent as Denmark } from '@fides-jam/assets/images/country-flags/dk.svg';
import { ReactComponent as Spain } from '@fides-jam/assets/images/country-flags/ea.svg';
import { ReactComponent as Estonia } from '@fides-jam/assets/images/country-flags/ee.svg';
import { ReactComponent as Finland } from '@fides-jam/assets/images/country-flags/fi.svg';
import { ReactComponent as France } from '@fides-jam/assets/images/country-flags/fr.svg';
import { ReactComponent as Greece } from '@fides-jam/assets/images/country-flags/gr.svg';
import { ReactComponent as HongKong } from '@fides-jam/assets/images/country-flags/hk.svg';
import { ReactComponent as Italy } from '@fides-jam/assets/images/country-flags/it.svg';
import { ReactComponent as Japan } from '@fides-jam/assets/images/country-flags/jp.svg';
import { ReactComponent as SouthKorea } from '@fides-jam/assets/images/country-flags/kr.svg';
import { ReactComponent as CaymanIslands } from '@fides-jam/assets/images/country-flags/ky.svg';
import { ReactComponent as Liechtenstein } from '@fides-jam/assets/images/country-flags/li.svg';
import { ReactComponent as Lithunia } from '@fides-jam/assets/images/country-flags/lt.svg';
import { ReactComponent as Luxembourg } from '@fides-jam/assets/images/country-flags/lu.svg';
import { ReactComponent as Latvia } from '@fides-jam/assets/images/country-flags/lv.svg';
import { ReactComponent as Malta } from '@fides-jam/assets/images/country-flags/mt.svg';
import { ReactComponent as Netherlands } from '@fides-jam/assets/images/country-flags/nl.svg';
import { ReactComponent as Poland } from '@fides-jam/assets/images/country-flags/pl.svg';
import { ReactComponent as Portugal } from '@fides-jam/assets/images/country-flags/pt.svg';
import { ReactComponent as Russia } from '@fides-jam/assets/images/country-flags/ru.svg';
import { ReactComponent as Sweden } from '@fides-jam/assets/images/country-flags/se.svg';
import { ReactComponent as Norway } from '@fides-jam/assets/images/country-flags/sj.svg';
import { ReactComponent as Slovakia } from '@fides-jam/assets/images/country-flags/sk.svg';
import { ReactComponent as UK } from '@fides-jam/assets/images/country-flags/uk.svg';
import { ReactComponent as USA } from '@fides-jam/assets/images/country-flags/us.svg';
import { ReactComponent as VirginIslands } from '@fides-jam/assets/images/country-flags/vg.svg';
import { ReactComponent as Europe } from '@fides-jam/assets/images/country-flags/eu.svg';
import { ReactComponent as Unknown } from '@fides-jam/assets/images/country-flags/xx.svg';
import { allCountriesList } from '@fides-jam/utils/countries';

interface RowProps {
  jurisdiction: string;
  sx?: SxProps;
  size?: number;
}

const CountryBubble: FC<RowProps> = ({ jurisdiction, sx, size }) => {
  const code = jurisdiction?.toUpperCase();
  const country = allCountriesList[code] || jurisdiction;
  const getCountryFlagSVG = (country: string, style: CSSProperties) => {
    switch (country) {
      case 'Austria':
        return <Austria style={style} />;
      case 'Belgium':
        return <Belgium style={style} />;
      case 'Brazil':
        return <Brazil style={style} />;
      case 'Cayman Islands':
        return <CaymanIslands style={style} />;
      case 'Cyprus':
        return <Cyprus style={style} />;
      case 'China':
        return <China style={style} />;
      case 'Czechia':
        return <Czechia style={style} />;
      case 'Denmark':
        return <Denmark style={style} />;
      case 'Estonia':
        return <Estonia style={style} />;
      case 'Finland':
        return <Finland style={style} />;
      case 'France':
        return <France style={style} />;
      case 'Germany':
        return <Germany style={style} />;
      case 'Greece':
        return <Greece style={style} />;
      case 'Hong Kong':
        return <HongKong style={style} />;
      case 'Italy':
        return <Italy style={style} />;
      case 'Japan':
        return <Japan style={style} />;
      case 'Jersey':
        return <Japan style={style} />;
      case 'Liechtenstein':
        return <Liechtenstein style={style} />;
      case 'Lithunia':
        return <Lithunia style={style} />;
      case 'Luxembourg':
        return <Luxembourg style={style} />;
      case 'Latvia':
        return <Latvia style={style} />;
      case 'Malta':
        return <Malta style={style} />;
      case 'Netherlands':
        return <Netherlands style={style} />;
      case 'Norway':
        return <Norway style={style} />;
      case 'Poland':
        return <Poland style={style} />;
      case 'Portugal':
        return <Portugal style={style} />;
      case 'Russia':
        return <Russia style={style} />;
      case 'Spain':
        return <Spain style={style} />;
      case 'South Korea':
        return <SouthKorea style={style} />;
      case 'Slovakia':
        return <Slovakia style={style} />;
      case 'Sweden':
        return <Sweden style={style} />;
      case 'Switzerland':
        return <Switzerland style={style} />;
      case 'UK':
        return <UK style={style} />;
      case 'United States':
        return <USA style={style} />;
      case 'British Virgin Islands':
        return <VirginIslands style={style} />;
      case 'Europe':
        return <Europe style={style} />;
      default:
        return <Unknown style={style} />;
    }
  };

  return (
    <Box
      sx={{
        width: `${size || 30}px`,
        height: `${size || 30}px`,
        borderRadius: '50%',
        background: iOSColors.lightGrey,
        overflow: 'hidden',
        lineHeight: '0.1em',
        border: jurisdiction
          ? 'unset'
          : `1.3px solid ${AppSignUpColors.PRIMARY_LIGHTER}`,
        ...sx,
      }}
    >
      {getCountryFlagSVG(country, {
        height: `${size || 30}px`,
        minHeight: '100%',
        minWidth: '100%',
        margin: 'auto',
        display: 'block',
      })}
    </Box>
  );
};

export default CountryBubble;
