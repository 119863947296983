import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState, useContext } from 'react';
import CentralBanner from '../../components/banner/Central.banner';
import SigningSuccessModal from '../../components/modals/SigningSuccessModal';
import AuditTrailSection from '../../components/sections/AuditTrail.section';
import BenefitsSection from '../../components/sections/Benefits.section';
import DecisionsSection from '../../components/sections/Decisions.section';
import EntityManagementSection from '../../components/sections/EntityManagement.section';
import FooterSection from '../../components/sections/Footer.section';
import LawFirmsSection from '../../components/sections/LawFirms.section';
import TestimonialsSection from '../../components/sections/Testimonials.section';
import WhoIsItForSection from '../../components/sections/WhoIsItFor.section';
import ReferencesSection from '../../components/sections/References.section';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { PageWrapper } from '../../components/layout';

const Home: React.FC = () => {
  const [offset, setOffset] = useState<number>(0);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const offsetSection = 600;
  const footerSection = isSmall ? 3100 : 2700;

  useMemo(() => {
    if (offset < offsetSection || offset > footerSection) {
      toggleDarkTheme(true);
    } else {
      toggleDarkTheme(false);
    }
  }, [offset]);

  useEffect(() => {
    const debounce = (func: (...args: any[]) => void, delay: number): (() => void) => {
      let debounceTimer: NodeJS.Timeout | null = null;

      return function (...args: any[]) {
        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => func(...args), delay);
      };
    };

    const onScroll = debounce(() => {
      setOffset(window.scrollY);
    }, 50);

    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <PageWrapper>
      <Grid container minHeight={{ xs: '900px', md: '100vh' }}>
        <CentralBanner darkTheme={darkTheme} />
      </Grid>
      <TestimonialsSection darkTheme={darkTheme} />
      <EntityManagementSection darkTheme={darkTheme} />
      <DecisionsSection darkTheme={darkTheme} />
      <AuditTrailSection darkTheme={darkTheme} />
      <WhoIsItForSection darkTheme={darkTheme} />
      <ReferencesSection darkTheme={darkTheme} />
      <BenefitsSection darkTheme={darkTheme} />
      <LawFirmsSection darkTheme={darkTheme} />
      <FooterSection showPostsSection />
      <SigningSuccessModal />
    </PageWrapper>
  );
};

export default Home;
