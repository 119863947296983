import { environment } from '@fides-jam/environments/environment';
import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const DETECTION_OPTIONS = {
  order: ['path', 'localStorage', 'navigator'],
  caches: ['localStorage'],
  lookupFromPathIndex: 0,
  checkWhitelist: true,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(XHR)
  .init({
    debug: !environment.production,
    detection: DETECTION_OPTIONS,
    fallbackLng: 'en',
    supportedLngs: ['de', 'en'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: 'locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
