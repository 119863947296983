import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LanguageRedirect = ({ children }: any) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const language = i18n.language || 'en';
    const newPath = `/${language}${location.pathname}`;

    window.scrollTo(0, 0);

    if (
      location.pathname !== newPath &&
      !location.pathname.startsWith('/de/') &&
      !location.pathname.startsWith('/en/') &&
      !location.pathname.endsWith('/en') &&
      !location.pathname.endsWith('/de')
    ) {
      navigate(newPath, { replace: true });
    }
  }, [i18n.language, location.pathname, navigate]);

  return <>{children}</>;
};

export default LanguageRedirect;
