import { FC } from 'react';
import { Grid, SxProps } from '@mui/material';
import TruncatedText from '@fides-jam/front/components/textWrappers/TruncatedText';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import AnimatedSlide from '../../layout/container/AnimatedSlide.container';

interface MeetingRowPropsType {
  sx?: SxProps;
  name: string;
  position: string;
  day: string;
  month: string;
  line: boolean;
  index: number;
  animate?: boolean;
}

const MeetingRow: FC<MeetingRowPropsType> = ({
  sx,
  name,
  position,
  day,
  month,
  line,
  index,
  animate,
}) => {
  return (
    <AnimatedSlide animate={animate} direction={'up'} transitionDelay={100 * index + 500}>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={{
          pl: 0,
          px: 0,
          py: 0.8,
          borderBottom: line ? '2px solid rgba(0,0,0,0.05)' : 'unset',
        }}
      >
        <Grid
          container
          sx={{
            height: '50px',
            width: '67px',
            mr: 1,
            position: 'relative',
            textAlign: 'right',
          }}
        >
          <Grid
            item
            container
            sx={{
              flex: 1,
              fontSize: '22px',
              fontWeight: '700',
              pr: 1,
            }}
          >
            <Grid
              item
              sx={{
                flex: 2,
              }}
            >
              {day}
            </Grid>
            <Grid
              item
              sx={{
                flex: 1,
                mt: -1.3,
                opacity: 0.4,
                fontSize: '12px',
                lineHeight: 1,
              }}
            >
              {month}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: AppSignUpColors.PRIMARY_LIGHTER,
              height: '100%',
              width: '4px',
              borderRadius: '2px',
            }}
          />
        </Grid>
        <Grid
          container
          item
          direction={'column'}
          sx={{
            flex: 1,
            px: 1,
            py: 1,
          }}
        >
          <Grid
            item
            sx={{
              fontSize: '16px',
              fontWeight: '700',
              mb: -0.5,
            }}
          >
            <TruncatedText maxLines={1}>{name}</TruncatedText>
          </Grid>
          <Grid
            item
            sx={{
              fontSize: '12px',
              opacity: 0.4,
            }}
          >
            <TruncatedText maxLines={1}>{position}</TruncatedText>
          </Grid>
        </Grid>
      </Grid>
    </AnimatedSlide>
  );
};

export default MeetingRow;
