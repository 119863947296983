const userAgent = window.navigator.userAgent;

const useDeviceType = () => {
  return {
    iOS:userAgent.match(/iPad/i) || userAgent.match(/iPhone/i),
    iPhone:userAgent.match(/iPhone/i),
    iPad:userAgent.match(/iPad/i),
    //android: ... TBD
  };
}

export default useDeviceType;
