import { FC } from 'react';
import { Grid, SxProps } from '@mui/material';
import FadeSlide from '../container/FadeSlide.container';

interface SectionPropsType {
  sx?: SxProps;
  left?: boolean;
  fadeIn?: boolean;
  spacing?: 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'none';
  fullWidth?: boolean;
  id?: string;
  expanded?: boolean;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  topSection?: boolean;
  bgcolor?: string;
}

const Section: FC<SectionPropsType> = ({
  left,
  fadeIn = true,
  spacing,
  fullWidth,
  expanded,
  id,
  flexDirection = 'row',
  children,
  bgcolor,
  topSection,
  sx,
}) => {
  const Section = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      bgcolor={bgcolor}
      py={bgcolor || expanded ? 6 : 0}
      id={id}
      sx={{
        mt: id && -10,
      }}
    >
      <Grid
        container
        item
        justifyContent={left ? 'flex-start' : 'center'}
        alignItems={left ? 'flex-start' : 'center'}
        flexDirection={flexDirection}
        xs={12}
        xl={fullWidth ? 12 : 10}
        maxWidth="1500px !important"
        spacing={
          spacing === 'xl'
            ? 10
            : spacing === 'lg'
            ? 8
            : spacing === 'md'
            ? 6
            : spacing === 'sm'
            ? 4
            : spacing === 'xs'
            ? 2
            : spacing === 'none'
            ? 0
            : 6
        }
        sx={{
          p: {
            xs: 4,
            sm: 5,
            md: 6,
            lg: 7,
          },
          overflow: 'visible',
          mt: id ? 10 : topSection ? 5 : 0,
          ...sx,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );

  if (fadeIn) {
    return (
      <FadeSlide direction="up" once>
        {Section}
      </FadeSlide>
    );
  }

  return Section;
};

export default Section;
