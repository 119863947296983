import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteNames } from '../../Routes/routeNames';
import AnimationBGLayer from '../layers/AnimationBG.layer';
import Column from '../layout/columns/index.column';
import FadeSlide from '../layout/container/FadeSlide.container';
import Row from '../layout/rows/Row';
import Header from '../text/Header';
import Label from '../text/Label';
import LinkText from '../text/Link';
import PlainText from '../text/PlainText';
import AusditTrailView from '../views/AuditTrail';

interface AuditTrailSectionPropsType {
  darkTheme?: boolean;
}

const AuditTrailSection: FC<AuditTrailSectionPropsType> = ({ darkTheme }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <Row columnSpacing={isSmall ? 0 : 10} sx={{ position: 'relative', overflow: 'visible' }}>
      <Column rowSpacing={3} sx={{ position: 'relative', overflow: 'visible' }}>
        <FadeSlide direction={'up'} rowSpacing={0} once>
          <Grid item sx={{ display: { xs: 'block', md: 'none' }, mb: 4 }}>
            <Label text={t('HOME.SECTIONS.AUDIT_TRAIL.LABEL')} highlighted darkTheme={darkTheme} />
          </Grid>
        </FadeSlide>
        <AnimationBGLayer />
        <FadeSlide
          direction={'left'}
          darkTheme={darkTheme}
          once={isSmall}
          onFade={setVisible}
          sx={{
            //background:darkTheme?'rgba(255,255,255,0.03)':'rgba(0,10,100,0.03)',
            borderRadius: '10px',
            position: 'relative',
            height: {
              xs: '300px',
              sm: '380px',
            },
            paddingBottom: 'unset',
            mb: {
              xs: 3,
              md: 'unset',
            },
          }}
        >
          <AusditTrailView animate={visible} />
        </FadeSlide>
      </Column>
      <Column sx={{ zIndex: 1 }}>
        <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
          <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
            <Label text={t('HOME.SECTIONS.AUDIT_TRAIL.LABEL')} highlighted darkTheme={darkTheme} />
          </Grid>
          <Grid item>
            <Header text={t('HOME.SECTIONS.AUDIT_TRAIL.TITLE')} darkTheme={darkTheme} />
          </Grid>
          <Grid item>
            <PlainText darkTheme={darkTheme}>{t('HOME.SECTIONS.AUDIT_TRAIL.TEXT')}</PlainText>
          </Grid>
          <Grid item>
            <LinkText
              text={t('GENERAL.LEARN_MORE')}
              darkTheme={darkTheme}
              href={RouteNames.productAuditTrail}
            />
          </Grid>
        </FadeSlide>
      </Column>
    </Row>
  );
};

export default AuditTrailSection;
