import { FC } from 'react';
import { Box, Grid, SxProps } from '@mui/material';
import Tag from '@fides-jam/front/components/Tag';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';

interface RowSeperatorProps {
  text?: string;
  tag?: string;
  darkTheme?: boolean;
  sx?: SxProps;
  fullWidth?: boolean;
}

const RowSeperator: FC<RowSeperatorProps> = ({ text, tag, darkTheme, sx, fullWidth }) => {
  const lightBG = 'rgba(0, 34, 187, 0.03)';
  const darkBG = 'rgba(255, 255, 255, 0.03)';
  const lightCL = 'rgba(0, 34, 187, 0.16)';
  const darkCL = 'rgba(255, 255, 255, 0.3)';
  return (
    <Box
      sx={{
        position: 'relative',
        marginTop: '14px',
        marginBottom: !!text ? '10px' : !!tag ? '14px' : '10px',
        lineHeight: 1,
        width: fullWidth ? '100%' : 'unset',
        ...sx,
      }}
    >
      <div
        style={{
          height: '3px',
          borderRadius: '1.5px',
          background: darkTheme ? darkBG : lightBG,
          width: '100%',
          marginBottom: '2px',
        }}
      />
      {!!text && (
        <small>
          <small>
            <strong>
              <span
                style={{
                  color: darkTheme ? darkCL : lightCL,
                }}
              >
                &nbsp;&nbsp;{text}
              </span>
            </strong>
          </small>
        </small>
      )}
      {!!tag && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            top: -8,
          }}
        >
          <Tag
            text={tag}
            color={darkTheme ? darkBG : AppSignUpColors.PRIMARY_LIGHTER}
            textColor={darkTheme ? darkCL : AppSignUpColors.PRIMARY}
          />
        </Grid>
      )}
    </Box>
  );
};

export default RowSeperator;
