import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { RouteNames } from '../../Routes/routeNames';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { PageWrapper, Section } from '../../components/layout';
import Row from '../../components/layout/rows/Row';
import FeatureHighlight from '../../components/sections/FeatureHighlight.section';
import SimpleCallToAction from '../../components/sections/cta/SimpleCTA.section';
import CallToAction from '../../components/sections/cta/CallToAction.section';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import SubTitle from '../../components/text/SubTitle';
import PlainText from '../../components/text/PlainText';
import HButton from '../../components/buttons/Highlight.button';
import FeatureOverview from '../../components/sections/FeatureOverview.section';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';
import { ReactComponent as IncreaseLegalCertainty } from '/assets/images/icons/increase-legal-certainty.svg';
import { ReactComponent as Accelerate } from '/assets/images/icons/accelerate-processes.svg';
import { ReactComponent as Cybersecurity } from '/assets/images/icons/cybersecurity.svg';
import { ReactComponent as Gathering } from '/assets/images/icons/gathering.svg';
import { ReactComponent as Flag } from '/assets/images/icons/flag.svg';
import { ReactComponent as Route } from '/assets/images/icons/route.svg';
import { ReactComponent as CheckmarkPerson } from '/assets/images/icons/checkmark-person.svg';
import { ReactComponent as FindInPage } from '/assets/images/icons/find-in-page.svg';
import { ReactComponent as TabletMac } from '/assets/images/icons/tablet-mac.svg';
import FeatureScreenshotsSection from '../../components/sections/feature-screenshots/FeatureScreenshots.section';

const EnterprisePage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);

  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const featureScreenshots = [
    {
      id: 'INFORMATION_MANAGEMENT',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.INFORMATION_MANAGEMENT.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.INFORMATION_MANAGEMENT.DESCRIPTION'),
      active: true,
      img:
        '/assets/images/mittelstand/feature-screenshots/information-management/' +
        i18n.language +
        '.webp',
    },
    {
      id: 'BOARD_COMMITTEE_WORK',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.BOARD_COMMITTEE_WORK.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.BOARD_COMMITTEE_WORK.DESCRIPTION'),
      active: false,
      img:
        '/assets/images/mittelstand/feature-screenshots/board-committee-work/' +
        i18n.language +
        '.webp',
    },
    {
      id: 'LEGAL_CERTAINTY',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.LEGAL_CERTAINTY.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.LEGAL_CERTAINTY.DESCRIPTION'),
      active: false,
      img:
        '/assets/images/mittelstand/feature-screenshots/legal-certainty/' + i18n.language + '.webp',
    },
    {
      id: 'COLLABORATION',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.COLLABORATION.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.COLLABORATION.DESCRIPTION'),
      active: false,
      img:
        '/assets/images/mittelstand/feature-screenshots/collaboration/' + i18n.language + '.webp',
    },
    {
      id: 'AUDIT_TRAIL_FOR_DOCUMENTATION',
      title: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.AUDIT_TRAIL_FOR_DOCUMENTATION.TITLE'),
      text: t('MITTELSTAND.FEATURE_SCREENSHOTS.FEATURES.AUDIT_TRAIL_FOR_DOCUMENTATION.DESCRIPTION'),
      active: false,
      img:
        '/assets/images/mittelstand/feature-screenshots/audit-trail-for-documentation/' +
        i18n.language +
        '.webp',
    },
  ];

  const masterChallanges = [
    {
      title: t('ENTERPRISE.MASTER_CHALLANGES.ACCELERATE_PROCESSES.LABEL'),
      text: t('ENTERPRISE.MASTER_CHALLANGES.ACCELERATE_PROCESSES.TEXT'),
      icon: <Accelerate />,
    },
    {
      title: t('ENTERPRISE.MASTER_CHALLANGES.INCREASE_LEGAL_CERTAINTY.LABEL'),
      text: t('ENTERPRISE.MASTER_CHALLANGES.INCREASE_LEGAL_CERTAINTY.TEXT'),
      icon: <IncreaseLegalCertainty />,
    },
    {
      title: t('ENTERPRISE.MASTER_CHALLANGES.BUILT_IN_CYBERSECURITY.LABEL'),
      text: t('ENTERPRISE.MASTER_CHALLANGES.BUILT_IN_CYBERSECURITY.TEXT'),
      icon: <Cybersecurity />,
    },
  ];

  const features = [
    {
      title: t('ENTERPRISE.FEATURES.SUBSIDIARY_INFORMATION.TITLE'),
      text: t('ENTERPRISE.FEATURES.SUBSIDIARY_INFORMATION.TEXT'),
      icon: <FindInPage />,
    },
    {
      title: t('ENTERPRISE.FEATURES.BOARD_MANAGEMENT.TITLE'),
      text: t('ENTERPRISE.FEATURES.BOARD_MANAGEMENT.TEXT'),
      icon: <TabletMac />,
    },
    {
      title: t('ENTERPRISE.FEATURES.POAS_AND_PROCURATION.TITLE'),
      text: t('ENTERPRISE.FEATURES.POAS_AND_PROCURATION.TEXT'),
      icon: <CheckmarkPerson />,
    },
    {
      title: t('ENTERPRISE.FEATURES.HOLD_MEETINGS.TITLE'),
      text: t('ENTERPRISE.FEATURES.HOLD_MEETINGS.TEXT'),
      icon: <Gathering />,
    },
    {
      title: t('ENTERPRISE.FEATURES.PASS_RESOLUTIONS_DIGITALLY.TITLE'),
      text: t('ENTERPRISE.FEATURES.PASS_RESOLUTIONS_DIGITALLY.TEXT'),
      icon: <Flag />,
    },
    {
      title: t('ENTERPRISE.FEATURES.GLOBALLY_APPLICABLE.TITLE'),
      text: t('ENTERPRISE.FEATURES.GLOBALLY_APPLICABLE.TEXT'),
      icon: <Route />,
    },
  ];

  return (
    <PageWrapper>
      <Section flexDirection="row-reverse" expanded spacing="sm">
        <Grid item xs={12} md={6}>
          <img
            src={'/assets/images/enterprise/hero/' + i18n.language + '.webp'}
            alt="Hero Enterprise Page"
            style={{
              width: '100%',
              maxHeight: '700px',
              objectFit: 'contain',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item container rowSpacing={3}>
            <Title
              text={t('ENTERPRISE.HERO.TITLE')}
              mainTitle
              darkTheme={darkTheme}
              sx={{
                mb: 2,
              }}
            />
            <PlainText text={t('ENTERPRISE.HERO.TEXT')} darkTheme={darkTheme} sx={{ pr: 6 }} />
            <Grid item xs={12} onClick={() => setShowContactPopup(true)}>
              <HButton size="large" sx={{ px: 5 }} darkTheme={true}>
                {t('GENERAL.BOOK_PERSONAL_MEETING')}
              </HButton>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <FeatureHighlight
        title={t('ENTERPRISE.MASTER_CHALLANGES.TITLE')}
        features={masterChallanges}
        darkTheme={darkTheme}
      />

      <SimpleCallToAction
        header={t('ENTERPRISE.CTA_1.TITLE')}
        link={RouteNames.security}
        buttonText={t('ENTERPRISE.CTA_1.BUTTON_TEXT')}
      />

      <FeatureOverview title={t('ENTERPRISE.FEATURES.TITLE')} features={features} />

      <SimpleCallToAction
        header={t('ENTERPRISE.CTA_2.TITLE')}
        link={RouteNames.ai}
        buttonText={t('ENTERPRISE.CTA_2.BUTTON_TEXT')}
      />

      <Grid container justifyContent="center" alignItems="center" overflow="hidden" zIndex={1}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          paddingTop="100px"
          height="70%"
          width="80%"
          position="absolute"
          maxWidth="1200px"
          maxHeight="800px"
        >
          <div
            style={{
              background: 'rgb(0, 109, 165)',
              borderRadius: '50%',
              WebkitFilter: 'blur(60px)',
              filter: 'blur(60px)',
              opacity: 0.05,
              overflow: 'visible',
              width: '100%',
              height: '100%',
            }}
          />
        </Grid>

        <Grid container item xs={7} justifyContent="center" mb={10}>
          <Grid item>
            <Title
              text={t('MITTELSTAND.FEATURE_SCREENSHOTS.TITLE')}
              darkTheme={false}
              sx={{
                fontSize: {
                  xs: 45,
                },
                textAlign: {
                  xs: 'center',
                  sm: 'center',
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} justifyContent="center" py={3} px={1}>
          <Grid container display={{ xs: 'none', md: 'flex' }} maxWidth="1400px">
            <FeatureScreenshotsSection featureScreenshots={featureScreenshots} type="desktop" />
          </Grid>
          <Grid container display={{ xs: 'flex', md: 'none' }} justifyContent="center">
            <FeatureScreenshotsSection featureScreenshots={featureScreenshots} type="mobile" />
          </Grid>
        </Grid>
      </Grid>

      <CallToAction
        header={t('ENTERPRISE.CTA.TITLE')}
        subHeader={t('ENTERPRISE.CTA.SUBTITLE')}
        link={() => setShowContactPopup(true)}
        buttonText={t('GENERAL.BOOK_PERSONAL_MEETING')}
      />

      {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}

      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </PageWrapper>
  );
};

export default EnterprisePage;
