import { FC, useMemo } from 'react';
import { Box, SxProps } from '@mui/material';
import { ThemeColors } from '../../utils/colors';

interface LabelPropsType {
  text: string;
  darkTheme?: boolean;
  highlighted?: boolean;
  sx?: SxProps;
}

const Label: FC<LabelPropsType> = ({ text, darkTheme, highlighted, sx }) => {
  const lightThemeColor = useMemo(
    () => (highlighted ? ThemeColors.light.PRIMARY : '#000'),
    [highlighted]
  );
  const darkThemeColor = useMemo(
    () => (highlighted ? ThemeColors.dark.PRIMARY : '#FFF'),
    [highlighted]
  );
  return (
    <Box
      component="h5"
      sx={{
        fontSize: {
          xs: 16,
          sm: 18,
          lg: 20,
        },
        lineHeight: 1.2,
        fontWeight: 700,
        opacity: 1.0,
        transition: 'color 500ms ease-in-out',
        WebkitTransition: 'color 500ms ease-in-out',
        MsTransition: 'color 500ms ease-in-out',
        MozTransition: 'color 500ms ease-in-out',
        OTransition: 'color 500ms ease-in-out',
        transitionDuration: '500ms',
        WebkitTransitionDuration: '500ms',
        MsTransitionDuration: '500ms',
        MozTransitionDuration: '500ms',
        OTransitionDuration: '500ms',
        color: darkTheme ? darkThemeColor : lightThemeColor,
        ...sx,
      }}
    >
      {text}
    </Box>
  );
};

export default Label;
