import { ReactComponent as DocGraphic } from '@fides-jam/assets/images/file-graphics/docs.svg';
import { ReactComponent as JPGGraphic } from '@fides-jam/assets/images/file-graphics/jpg.svg';
import { ReactComponent as PDFGraphic } from '@fides-jam/assets/images/file-graphics/pdf.svg';
import { ReactComponent as PNGGraphic } from '@fides-jam/assets/images/file-graphics/png.svg';
import { ReactComponent as SheetGraphic } from '@fides-jam/assets/images/file-graphics/sheets.svg';
import { ReactComponent as SlideGraphic } from '@fides-jam/assets/images/file-graphics/slides.svg';
import { ReactComponent as TIFGraphic } from '@fides-jam/assets/images/file-graphics/tif.svg';
import { FC, useCallback } from 'react';

interface FileGraphicPropsType {
  fileName?: string;
  fileType?: string;
  fileUrl?: string;
}

const FileGraphic: FC<FileGraphicPropsType> = ({
  fileName,
  fileType,
  fileUrl,
}) => {
  const getFileExtension = useCallback(() => {
    const FNExtension = fileName?.split('.')?.pop()?.toLowerCase();
    const FTExtension = fileType;
    const FUExtension = fileUrl?.split('.')?.pop()?.toLowerCase();
    return FUExtension || FNExtension || FTExtension;
  }, [fileName, fileType, fileUrl]);

  const getFileGraphic = (f: string | undefined) => {
    const extension = getFileExtension();
    switch (extension) {
      case 'doc':
        return <DocGraphic />;
      case 'docx':
        return <DocGraphic />;
      case 'pages':
        return <DocGraphic />;
      case 'gdoc':
        return <DocGraphic />;
      case 'xls':
        return <SheetGraphic />;
      case 'xlsx':
        return <SheetGraphic />;
      case 'numbers':
        return <SheetGraphic />;
      case 'gsheet':
        return <SheetGraphic />;
      case 'ppt':
        return <SlideGraphic />;
      case 'pptx':
        return <SlideGraphic />;
      case 'key':
        return <SlideGraphic />;
      case 'gslides':
        return <SlideGraphic />;
      case 'pdf':
        return <PDFGraphic />;
      case 'png':
        return <PNGGraphic />;
      case 'jpg':
        return <JPGGraphic />;
      case 'jpeg':
        return <JPGGraphic />;
      case 'tif':
        return <TIFGraphic />;
      default:
        return <DocGraphic />;
    }
  };

  return <>{getFileGraphic(fileName)}</>;
};

export default FileGraphic;
