import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import { Grid } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Row from '../../components/layout/rows/Row';
import BenefitsSection from '../../components/sections/Benefits.section';
import Header from '../../components/text/Header';
import SubTitle from '../../components/text/SubTitle';
import Footer from '../../components/views/Footer';
import { DarkThemeContext } from '../../components/Context/ThemeContext';

const DemoPage: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);

  useEffect(() => {
    toggleDarkTheme(true);
  }, [toggleDarkTheme]);

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script?.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head?.appendChild(script);
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        display: 'block',
        transition: 'background 700ms ease-in-out',
        WebkitTransition: 'background 700ms ease-in-out',
        MsTransition: 'background 700ms ease-in-out',
        MozTransition: 'background 700ms ease-in-out',
        OTransition: 'background 700ms ease-in-out',
        transitionDuration: '700ms',
        WebkitTransitionDuration: '700ms',
        MsTransitionDuration: '700ms',
        MozTransitionDuration: '700ms',
        OTransitionDuration: '700ms',
        background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
        maxWidth: '100vw',
        overflow: 'hidden',
      }}
    >
      <Grid container item rowSpacing={10} justifyContent="center" alignItems="center">
        <Row
          sx={{
            marginBottom: '-2rem',
            mt: { xs: 0, md: 3, lg: 5 },
          }}
          rowSpacing={8}
        >
          <FadeSlide
            rowSpacing={2}
            sx={{ textAlign: 'center', py: 10, mt: 8, mb: -3 }}
            direction="up"
            once
          >
            <Grid item xs={12}>
              <Header text={t('DEMO.TITLE')} darkTheme={darkTheme} />
            </Grid>
            <Grid item xs={12}>
              <SubTitle
                sx={{ fontWeight: 'bold' }}
                text={t('DEMO.SUBTITLE_1')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '3rem' }}>
              <SubTitle
                sx={{ fontWeight: 'bold' }}
                text={t('DEMO.SUBTITLE_2')}
                darkTheme={darkTheme}
              />
            </Grid>
            <video
              controls
              playsInline
              width="100%"
              style={{
                borderRadius: '20px',
                border: AppSignUpColors.PRIMARY_DARKER_BORDER,
              }}
              key={language}
            >
              <source src={`assets/videos/fides-demo-2024-${language}.mp4`} type="video/mp4" />
            </video>
          </FadeSlide>
        </Row>
        <BenefitsSection darkTheme={darkTheme} />

        <FadeSlide
          rowSpacing={2}
          sx={{ textAlign: 'center', marginBottom: '6rem', marginTop: 10 }}
          direction="up"
          once
        >
          <Grid item xs={12}>
            <Header
              sx={{ opacity: 1 }}
              text={t('DEMO.LETS_GET_STARTED.TITLE')}
              darkTheme={darkTheme}
            />
          </Grid>
          <Grid item xs={12}>
            <SubTitle
              sx={{ fontWeight: 'bold' }}
              text={t('DEMO.LETS_GET_STARTED.SUBTITLE_1')}
              darkTheme={darkTheme}
            />
          </Grid>
          <Grid item xs={12}>
            <SubTitle
              sx={{ fontWeight: 'bold' }}
              text={t('DEMO.LETS_GET_STARTED.SUBTITLE_2')}
              darkTheme={darkTheme}
            />
          </Grid>
        </FadeSlide>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default DemoPage;
