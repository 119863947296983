import TruncatedText from '@fides-jam/front/components/textWrappers/TruncatedText';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Box, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FadeSlide from '../layout/container/FadeSlide.container';
import Row from '../layout/rows/Row';
import Header from '../text/Header';
import LinkText from '../text/Link';
import PlainText from '../text/PlainText';
import SubHeader from '../text/SubHeader';
import { builder, Builder } from '@builder.io/react';
import { getAllContentInPreview, getAllContentInLive } from '../views/KnowledgeHub/utils/utils';
import { RouteNames } from '../../Routes/routeNames';
import { BlogPost } from '../../../types/builder-io-types';

interface PostsSlidePropsType {
  darkTheme?: boolean;
}

const PostsSection: FC<PostsSlidePropsType> = ({ darkTheme }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const theme = useTheme();
  const [index, setIndex] = useState<number>(0);
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState<BlogPost>();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    async function fetchBuilderKnowledgehub() {
      try {
        const kh = await builder.get('knowledge-hub', {
          options: {
            locale: language,
          },
          userAttributes: {
            locale: language,
          },
        });

        const [_, allContentFilteredAndSorted] =
          Builder.isPreviewing || Builder.isEditing
            ? await getAllContentInPreview(kh.data)
            : await getAllContentInLive(kh.data);

        setPosts(
          allContentFilteredAndSorted.filter(
            (post: BlogPost) => post.type === 'blog-article' && !post.invisible
          )
        );
      } catch (e) {
        console.error(e);
      }
    }

    fetchBuilderKnowledgehub();
  }, [language]);

  useEffect(() => {
    if (posts?.length) setPost(posts[index]);
  }, [posts, index]);

  return (
    <Row
      sx={{
        mt: { xs: 0, md: 3, lg: 5 },
        mb: '2rem',
        overflow: 'visible',
        p: { xs: 0, sm: 5, md: 6, lg: 7 },
      }}
      rowSpacing={8}
    >
      <FadeSlide
        direction="up"
        once
        sx={{
          overflow: 'visible',
          position: 'relative',
          p: {
            xs: 2,
            sm: 5,
          },
          background: {
            xs: 'unset',
            sm: 'radial-gradient(circle, rgba(29,50,93,1) 0%, rgba(16,24,40,1) 100%)',
          },
          borderTopLeftRadius: {
            xs: '0px',
            sm: '20px',
          },
          borderTopRightRadius: {
            xs: '0px',
            sm: '20px',
          },
          borderBottomLeftRadius: {
            xs: '0px',
            sm: '20px',
          },
          borderBottomRightRadius: {
            xs: '0px',
            sm: '20px',
          },
          flexDirection: 'column',
          boxShadow: {
            xs: 'unset',
            sm: '0px 0px 58px 12px rgba(0,0,0,0.2)',
          },
        }}
      >
        <FadeSlide sx={{ textAlign: 'center' }} direction="up" rowSpacing={1} once>
          <Grid item xs={12}>
            <Header text={t('POSTS.TITLE')} darkTheme={darkTheme || !isSmall} />
          </Grid>
          <Grid item xs={12} sx={{ px: 1 }}>
            <SubHeader text={t('POSTS.SUBTITLE')} darkTheme={darkTheme || !isSmall} />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <LinkText
              text={t('POSTS.VIEW')}
              darkTheme={darkTheme || !isSmall}
              href={RouteNames.knowledgeHub}
            />
          </Grid>
        </FadeSlide>
        <FadeSlide
          direction="up"
          once
          sx={{
            maxWidth: '700px',
            borderRadius: '20px',
            background: '#FFF',
            mx: 'auto',
            mt: 5,
            mb: {
              xs: '-50px',
              sm: '-70px',
            },
            opacity: '1 !important',
            boxShadow: '0px 0px 58px 6px rgba(255,255,255,0.1)',
            overflow: 'visible',
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              backgroundColor: 'black',
              backgroundImage: `url(${post?.thumbnail || ''})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              borderTopLeftRadius: '20px',
              borderBottomLeftRadius: {
                xs: 0,
                md: '20px',
              },
              borderTopRightRadius: {
                xs: '20px',
                md: 0,
              },
            }}
          />
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            rowSpacing={2}
            sx={{
              p: 5,
            }}
          >
            <Grid item>
              <small>
                <strong>
                  <TruncatedText maxLines={3} noWordBreak>
                    <PlainText text={post?.title} sx={{ opacity: 1 }} />
                  </TruncatedText>
                </strong>
              </small>
            </Grid>
            <Grid item>
              <small>
                <TruncatedText maxLines={8} noWordBreak>
                  <PlainText text={post?.excerpt} />
                </TruncatedText>
              </small>
            </Grid>
            <Box sx={{ transform: 'scale(0.78) translate(-13px, 0px)', mt: 1 }}>
              <LinkText text={t('POSTS.READING')} href={`/knowledge-hub/${post?.slug}`} />
            </Box>
          </Grid>

          <IconButton
            sx={{
              position: 'absolute',
              left: '-80px',
              top: '35%',
              transform: 'rotate(90deg)',
              opacity: index == 0 ? 0.05 : 0.4,
              background: 'rgba(255,255,255,0.2)',
            }}
            disabled={index == 0}
            onClick={() => setIndex(Math.max(0, index - 1))}
          >
            <ExpandCircleDownIcon sx={{ color: '#fff', fontSize: '40px' }} />
          </IconButton>

          <IconButton
            sx={{
              position: 'absolute',
              right: '-80px',
              top: '35%',
              transform: 'rotate(-90deg)',
              opacity: 0.4,
              background: 'rgba(255,255,255,0.2)',
            }}
            onClick={() => setIndex(Math.min(posts?.length - 1, index + 1))}
          >
            <ExpandCircleDownIcon sx={{ color: '#fff', fontSize: '40px' }} />
          </IconButton>
        </FadeSlide>
      </FadeSlide>
    </Row>
  );
};

export default PostsSection;
