import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface TextProps {
  children?: ReactNode;
  maxLines?: number;
  display?: string;
  noWordBreak?: boolean;
}

const Text: FC<TextProps> = ({ children, maxLines, display, noWordBreak }) => {
  const truncateClass = {
    display: display || '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    minWidth: 0,
    wordBreak: 'break-all',
    //overflowWrap: 'break-word',
  };

  return (
    <Box
      sx={{
        ...truncateClass,
        WebkitLineClamp: maxLines || 1000,
        wordBreak: noWordBreak ? 'unset' : 'break-all',
      }}
    >
      {children}
    </Box>
  );
};

export default Text;
