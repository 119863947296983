import { FC, useMemo } from 'react';
import { Grid, SxProps } from '@mui/material';
import FadeSlide from './FadeSlide.container';

interface FadeSlidePropsType {
  rowSpacing?: number;
  darkTheme?: boolean;
  flexDirection?: 'row' | 'row-reverse';
  direction?: 'left' | 'right' | 'up' | 'down';
  sx?: SxProps;
  transitionDelay?: number;
  imageUrl?: string;
  once?: boolean;
  noBackground?: boolean;
  contain?: boolean;
}

const BGImageSlideContainer: FC<FadeSlidePropsType> = ({
  rowSpacing,
  darkTheme,
  children,
  flexDirection,
  direction,
  sx,
  transitionDelay,
  imageUrl,
  once,
  noBackground,
  contain,
}) => {
  const isSmall = useMemo(() => window.innerWidth <= 600, [window.innerWidth]);
  return (
    <FadeSlide
      darkTheme={darkTheme}
      direction={isSmall ? 'up' : direction}
      transitionDelay={transitionDelay}
      once={once}
      sx={{
        position: 'relative',
      }}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        alignSelf={direction === 'right' ? 'flex-end' : 'flex-start'}
        flexDirection={flexDirection || 'row'}
        sx={{
          background: noBackground
            ? 'none'
            : darkTheme
            ? 'rgba(255,255,255,0.03)'
            : 'rgba(0,10,100,0.03)',
          transition:
            'opacity 1.5s ease-in-out, transform 1.3s ease-in-out, background 0.5s ease-in-out, filter 0.7s ease-in-out',
          borderRadius: '10px',
          paddingBottom: '60%',
          backgroundImage: `url("${imageUrl}")`,
          backgroundPosition: {
            xs: 'center',
            sm: 'center',
          },
          backgroundSize: contain ? 'contain' : 'cover',
          backgroundRepeat: 'no-repeat',
          ...sx,
        }}
      >
        {children}
      </Grid>
    </FadeSlide>
  );
};

export default BGImageSlideContainer;
