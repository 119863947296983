import { use } from 'i18next';
import { useState, createContext, useEffect } from 'react';

export const DarkThemeContext = createContext({
  darkTheme: false,
  toggleDarkTheme: (mode: boolean) => {},
});

export const DarkThemeProvider = ({ children }: { children: any }) => {
  const [darkTheme, setDarkTheme] = useState(true);

  const toggleDarkTheme = (mode: boolean) => {
    setDarkTheme(mode);
  };

  return (
    <DarkThemeContext.Provider value={{ darkTheme, toggleDarkTheme }}>
      {children}
    </DarkThemeContext.Provider>
  );
};
