import { ComponentProps, FC } from 'react';
import HubSpotForm from 'react-hubspot-form';
import { useHubspotFormConversionTracking } from '../hooks/useHubspotConversionTracking';
import { ContactFormLoadingSkeleton } from './skeletons/ContactForm.skeleton';

type HubspotFormWrapperWithTrackingProps = ComponentProps<typeof HubSpotForm> & {
  gtagEventId: string;
};
export const HubspotFormWrapperWithTracking: FC<HubspotFormWrapperWithTrackingProps> = ({
  portalId,
  formId,
  gtagEventId,
}) => {
  useHubspotFormConversionTracking(formId, gtagEventId);
  return (
    <HubSpotForm portalId={portalId} formId={formId} loading={<ContactFormLoadingSkeleton />} />
  );
};
