import { FC, useEffect, useMemo, useState } from 'react';
import { Grid, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { Layer } from '@fides-jam/front/components/Layer';
import { useTranslation } from 'react-i18next';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import RichContentButton from '../../buttons/RichContent.button';
import { ReactComponent as ApprovalsIcon } from '/assets/images/icons/approvals.svg';
import { ReactComponent as OrganisationIcon } from '/assets/images/icons/organization.svg';
import { ReactComponent as CapTableIcon } from '/assets/images/icons/cap-table.svg';
import { RouteNames } from '../../../Routes/routeNames';
import { WorkflowFeaturesMenu } from './components/WorkflowsFeatures.menu';
import { SubMenuArrow } from './components/SubMenu.arrow';
import { MenuBorderCover } from './components/MenuBorder.cover';
import { ReactComponent as TasksIcon } from '/assets/images/icons/tasks.svg';
import { ReactComponent as ResolutionsIcon } from '/assets/images/icons/resolutions.svg';
import { ReactComponent as MeetingsIcon } from '/assets/images/icons/meetings.svg';
import { ReactComponent as DocumentManagementIcon } from '/assets/images/icons/document-management.svg';
import { ReactComponent as AiIcon } from '/assets/images/icons/auto-fix.svg';
import { BoardManagementMenu } from './components/BoardManagement.menu';
import { ReactComponent as BoardManagementGroup } from '/assets/images/icons/board-management-group.svg';

interface ProductFeaturesViewPropsType {
  darkTheme?: boolean;
  sx?: SxProps;
  open?: boolean;
  closeMenu: () => void;
  backdrop?: boolean;
}

const ProductFeaturesView: FC<ProductFeaturesViewPropsType> = ({
  darkTheme,
  open,
  sx,
  closeMenu,
  backdrop,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobileVersion = useMediaQuery(theme.breakpoints.down('lg'));
  const [isWorkflowSubMenuHovered, setIsWorkflowSubMenuHovered] = useState<boolean>(false);
  const [isBoardManagementSubMenuHovered, setIsBoardManagementSubMenuHovered] =
    useState<boolean>(true);

  const [isFirstLayerHovered, setIsFirstLayerHovered] = useState<boolean>(false);
  const [isSecondLayerHovered, setIsSecondLayerHovered] = useState<boolean>(false);
  const [isWorkflowSubSubMenuHovered, setIsWorkflowSubSubMenuHovered] = useState<boolean>(false);
  const [isWorkflowSubSubMenuArrowHovered, setIsWorkflowSubSubMenuArrowHovered] =
    useState<boolean>(false);

  const [isBoardManagementSubSubMenuHovered, setIsBoardManagementSubSubMenuHovered] =
    useState<boolean>(false);
  const [isBoardManagementSubSubMenuArrowHovered, setIsBoardManagementSubSubMenuArrowHovered] =
    useState<boolean>(false);

  const isAnyLayerHovered = useMemo(
    () => isFirstLayerHovered || isSecondLayerHovered || isWorkflowSubSubMenuHovered,
    [isFirstLayerHovered, isSecondLayerHovered, isWorkflowSubSubMenuHovered]
  );

  const shouldShowWorkflowSubMenu =
    (isWorkflowSubMenuHovered || isWorkflowSubSubMenuHovered || isWorkflowSubSubMenuArrowHovered) &&
    !isMobileVersion;
  const shouldShowBoardManagementSubMenu =
    (isBoardManagementSubMenuHovered ||
      isBoardManagementSubSubMenuHovered ||
      isBoardManagementSubSubMenuArrowHovered) &&
    !isMobileVersion;

  useEffect(() => {
    if (!isAnyLayerHovered) closeMenu();
  }, [isAnyLayerHovered]);

  return (
    <>
      {backdrop && (
        <Layer
          sx={{
            display: {
              xs: open ? 'block' : 'none',
              lg: open ? 'block' : 'none',
            },
            transition: 'background 700ms ease-in-out',
            background: darkTheme ? 'rgba(16, 24, 40, 0.7)' : 'rgba(255, 255, 255, 0.7)',
            top: '60px',
            backdropFilter: 'blur(10px)',
          }}
        />
      )}
      <Layer
        sx={{
          display: {
            xs: open ? 'block' : 'none',
            lg: open ? 'block' : 'none',
          },
          background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
          transition: 'background 700ms ease-in-out',
          top: '70px',
          left: 'unset',
          bottom: 'unset',
          minWidth: '200px',
          minHeight: '20px',
          borderRadius: '20px',
          p: 0,
          border: `1px solid ${darkTheme ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'}`,
          overflow: 'visible',
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          ...sx,
        }}
        onMouseEnter={() => setIsSecondLayerHovered(true)}
        onMouseLeave={() => setIsSecondLayerHovered(false)}
      >
        <Layer
          sx={{
            top: '-75px',
            height: '75px',
            bottom: '100px',
            right: '140px',
            left: '140px',
            maxWidth: '120px',
            cursor: 'pointer',
            flexDirection: 'row',
            display: 'flex',
          }}
          onMouseEnter={() => setIsFirstLayerHovered(true)}
          onMouseLeave={() => setIsFirstLayerHovered(false)}
        />
        <Grid
          container
          flexDirection="row"
          maxHeight="calc(100svh - 70px)"
          p={3}
          width="100%"
          maxWidth={{
            xs: 'unset',
            lg: `430px`,
          }}
          overflow={{
            xs: 'auto',
            md: 'unset',
          }}
        >
          <Grid container item flex={1}>
            <Grid item xs={12}>
              <RichContentButton
                title={t('NAVBAR.FEATURE_GROUPS.ENTITY_MANAGEMENT.TITLE')}
                description={t('NAVBAR.FEATURE_GROUPS.ENTITY_MANAGEMENT.TEXT')}
                icon={<CapTableIcon />}
                href={RouteNames.productEntityManagement}
                darkTheme={darkTheme}
                sx={{
                  opacity:
                    shouldShowWorkflowSubMenu || shouldShowBoardManagementSubMenu ? 0.2 : 1.0,
                }}
                onClick={closeMenu}
              />
            </Grid>
            <Grid item xs={12} position="relative">
              <RichContentButton
                title={t('NAVBAR.FEATURE_GROUPS.WORKFLOW_MANAGEMENT.TITLE')}
                description={t('NAVBAR.FEATURE_GROUPS.WORKFLOW_MANAGEMENT.TEXT')}
                icon={<OrganisationIcon />}
                href={RouteNames.productWorkflows}
                darkTheme={darkTheme}
                onHover={setIsWorkflowSubMenuHovered}
                sx={{ opacity: shouldShowBoardManagementSubMenu ? 0.2 : 1.0 }}
                onClick={closeMenu}
              />
              {shouldShowWorkflowSubMenu && (
                <SubMenuArrow
                  darkTheme={darkTheme}
                  onMouseEnter={() => setIsWorkflowSubSubMenuArrowHovered(true)}
                  onMouseLeave={() => setIsWorkflowSubSubMenuArrowHovered(false)}
                />
              )}
            </Grid>

            <Grid item xs={12} position="relative">
              <RichContentButton
                title={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.TITLE')}
                description={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.TEXT')}
                icon={<BoardManagementGroup />}
                href={RouteNames.boardManagement}
                darkTheme={darkTheme}
                onHover={setIsBoardManagementSubMenuHovered}
                sx={{ opacity: shouldShowWorkflowSubMenu ? 0.2 : 1.0 }}
                onClick={closeMenu}
              />
              {shouldShowBoardManagementSubMenu && (
                <SubMenuArrow
                  darkTheme={darkTheme}
                  onMouseEnter={() => setIsBoardManagementSubSubMenuArrowHovered(true)}
                  onMouseLeave={() => setIsBoardManagementSubSubMenuArrowHovered(false)}
                />
              )}
            </Grid>
            <Grid item xs={12} position="relative">
              <RichContentButton
                title={t('NAVBAR.FEATURE_GROUPS.AI_FEATURES.TITLE')}
                description={t('NAVBAR.FEATURE_GROUPS.AI_FEATURES.TEXT')}
                icon={<AiIcon />}
                href={RouteNames.ai}
                darkTheme={darkTheme}
                sx={{
                  opacity:
                    shouldShowWorkflowSubMenu || shouldShowBoardManagementSubMenu ? 0.2 : 1.0,
                }}
                onClick={closeMenu}
              />
            </Grid>

            {isMobileVersion && (
              <>
                <Grid item xs={12}>
                  <RichContentButton
                    title={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.MEETINGS.TITLE')}
                    description={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.MEETINGS.TEXT')}
                    icon={<MeetingsIcon />}
                    href={RouteNames.meetings}
                    darkTheme={darkTheme}
                    onClick={closeMenu}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichContentButton
                    title={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.RESOLUTIONS.TITLE')}
                    description={t(
                      'NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.RESOLUTIONS.TEXT'
                    )}
                    icon={<ResolutionsIcon />}
                    href={RouteNames.resolutions}
                    darkTheme={darkTheme}
                    onClick={closeMenu}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichContentButton
                    title={t('NAVBAR.FEATURE_GROUPS.WORKFLOW_MANAGEMENT.SUBMENU.APPROVALS.TITLE')}
                    description={t(
                      'NAVBAR.FEATURE_GROUPS.WORKFLOW_MANAGEMENT.SUBMENU.APPROVALS.TEXT'
                    )}
                    icon={<ApprovalsIcon />}
                    href={RouteNames.approvals}
                    darkTheme={darkTheme}
                    onClick={closeMenu}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichContentButton
                    title={t(
                      'NAVBAR.FEATURE_GROUPS.WORKFLOW_MANAGEMENT.SUBMENU.TASK_MANAGEMENT.TITLE'
                    )}
                    description={t(
                      'NAVBAR.FEATURE_GROUPS.WORKFLOW_MANAGEMENT.SUBMENU.TASK_MANAGEMENT.TEXT'
                    )}
                    icon={<TasksIcon />}
                    href={RouteNames.tasks}
                    darkTheme={darkTheme}
                    onClick={closeMenu}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichContentButton
                    title={t(
                      'NAVBAR.FEATURE_GROUPS.WORKFLOW_MANAGEMENT.SUBMENU.DOCUMENT_MANAGEMENT.TITLE'
                    )}
                    description={t(
                      'NAVBAR.FEATURE_GROUPS.WORKFLOW_MANAGEMENT.SUBMENU.DOCUMENT_MANAGEMENT.TEXT'
                    )}
                    icon={<DocumentManagementIcon />}
                    href={RouteNames.documentManagement}
                    darkTheme={darkTheme}
                    onClick={closeMenu}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichContentButton
                    title={t('NAVBAR.FEATURE_GROUPS.AI_FEATURES.TITLE')}
                    description={t('NAVBAR.FEATURE_GROUPS.AI_FEATURES.TEXT')}
                    icon={<AiIcon />}
                    href={RouteNames.ai}
                    darkTheme={darkTheme}
                    onClick={closeMenu}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {shouldShowWorkflowSubMenu && (
            <>
              <WorkflowFeaturesMenu
                darkTheme={darkTheme}
                onMouseEnter={() => setIsWorkflowSubSubMenuHovered(true)}
                onMouseLeave={() => setIsWorkflowSubSubMenuHovered(false)}
                closeMenu={closeMenu}
                sx={{
                  position: 'absolute',
                  top: '-1px',
                  right: '-400px',
                  width: '400px',
                }}
              />
              <MenuBorderCover darkTheme={darkTheme} />
            </>
          )}

          {shouldShowBoardManagementSubMenu && (
            <>
              <BoardManagementMenu
                darkTheme={darkTheme}
                onMouseEnter={() => setIsBoardManagementSubSubMenuHovered(true)}
                onMouseLeave={() => setIsBoardManagementSubSubMenuHovered(false)}
                closeMenu={closeMenu}
                sx={{
                  position: 'absolute',
                  top: '-1px',
                  right: '-400px',
                  width: '400px',
                }}
              />
              <MenuBorderCover darkTheme={darkTheme} />
            </>
          )}
        </Grid>
      </Layer>
    </>
  );
};

export default ProductFeaturesView;
