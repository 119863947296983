import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import FadeSlide from '../layout/container/FadeSlide.container';
import Row from '../layout/rows/Row';
import Header from '../text/Header';
import SubHeader from '../text/SubHeader';
import Column from '../layout/columns/index.column';
import Label from '../text/Label';
import PlainText from '../text/PlainText';
import * as React from 'react';
import BGImageSlideContainer from '../layout/container/BGImageSlide.container';

interface WhoIsItForSectionPropsType {
  darkTheme?: boolean;
}

const WhoIsItForSection: FC<WhoIsItForSectionPropsType> = ({ darkTheme }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Row
        sx={{
          mt: { xs: 0, md: 3, lg: 5 },
          mb: '-1rem',
        }}
        rowSpacing={8}
      >
        <>
          <FadeSlide rowSpacing={1} direction={'up'} once>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Header
                sx={{ opacity: null }}
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <SubHeader text={t('HOME.SECTIONS.WHO_IS_IT_FOR.SUBTITLE')} darkTheme={darkTheme} />
            </Grid>
          </FadeSlide>
        </>
      </Row>

      <Row columnSpacing={isSmall ? 0 : 10}>
        <Column rowSpacing={3}>
          <FadeSlide direction={'up'} rowSpacing={0} once>
            <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
              <Label
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.CORPORATES.LABEL')}
                highlighted
                darkTheme={darkTheme}
              />
            </Grid>
          </FadeSlide>
          <BGImageSlideContainer
            direction={'left'}
            darkTheme={darkTheme}
            imageUrl={'/assets/images/users/corporate.jpg'}
            transitionDelay={400}
            once={isSmall}
            sx={{
              pb: '65%',
              border: '5px solid rgba(240,245,255,1)',
            }}
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
            <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
              <Label
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.CORPORATES.LABEL')}
                highlighted
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item xs={12}>
              <Header
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.CORPORATES.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.CORPORATES.DESCRIPTION')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Row>

      <Row columnSpacing={isSmall ? 0 : 10} flexDirection={'row-reverse'}>
        <Column rowSpacing={3}>
          <FadeSlide direction={'up'} rowSpacing={0} once>
            <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
              <Label
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.VC_PE_FUNDS.LABEL')}
                highlighted
                darkTheme={darkTheme}
              />
            </Grid>
          </FadeSlide>
          <BGImageSlideContainer
            direction={'right'}
            darkTheme={darkTheme}
            imageUrl={'/assets/images/users/vc-pe-funds.jpg'}
            transitionDelay={400}
            once={isSmall}
            sx={{
              pb: '65%',
              border: '5px solid rgba(240,245,255,1)',
            }}
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
            <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
              <Label
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.VC_PE_FUNDS.LABEL')}
                highlighted
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item xs={12}>
              <Header
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.VC_PE_FUNDS.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.VC_PE_FUNDS.DESCRIPTION')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Row>

      <Row columnSpacing={isSmall ? 0 : 10}>
        <Column rowSpacing={3}>
          <FadeSlide direction={'up'} rowSpacing={0} once>
            <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
              <Label
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.VENTURE_COMPANIES.LABEL')}
                highlighted
                darkTheme={darkTheme}
              />
            </Grid>
          </FadeSlide>
          <BGImageSlideContainer
            direction={'left'}
            darkTheme={darkTheme}
            imageUrl={'/assets/images/users/venture-companies.jpg'}
            transitionDelay={400}
            once={isSmall}
            sx={{
              pb: '65%',
              border: '5px solid rgba(240,245,255,1)',
            }}
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
            <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
              <Label
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.VENTURE_COMPANIES.LABEL')}
                highlighted
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item xs={12}>
              <Header
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.VENTURE_COMPANIES.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('HOME.SECTIONS.WHO_IS_IT_FOR.LIST_USERS.VENTURE_COMPANIES.DESCRIPTION')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Row>
    </>
  );
};

export default WhoIsItForSection;
