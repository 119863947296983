import { FC, useMemo } from 'react';
import { Link } from '@mui/material';
import { ThemeColors } from '../../utils/colors';
import LinkComponent from '../Link/Link';
interface LinkTextPropsType {
  text: string;
  darkTheme?: boolean;
  href?: string;
  localized?: boolean;
  external?: boolean;
}

const LinkText: FC<LinkTextPropsType> = ({ text, href, localized = true, external, darkTheme }) => {
  const color = useMemo(
    () => (darkTheme ? ThemeColors.dark.PRIMARY : ThemeColors.light.PRIMARY),
    [darkTheme]
  );

  const LinkElement = external ? 'a' : Link;

  return (
    <LinkComponent href={href} localized={localized}>
      <LinkElement
        underline="hover"
        sx={{
          transition: 'color 500ms ease-in-out',
          WebkitTransition: 'color 500ms ease-in-out',
          MsTransition: 'color 500ms ease-in-out',
          MozTransition: 'color 500ms ease-in-out',
          OTransition: 'color 500ms ease-in-out',
          transitionDuration: '500ms',
          WebkitTransitionDuration: '500ms',
          MsTransitionDuration: '500ms',
          MozTransitionDuration: '500ms',
          OTransitionDuration: '500ms',
          color: color,
          fontWeight: 600,
          cursor: 'pointer',
        }}
      >
        {text}
        <i
          style={{
            borderStyle: 'solid',
            borderColor: color,
            transition: 'border 0.5s ease-in-out',
            borderRadius: '0px 1px 0px 1px',
            borderWidth: '0 2px 2px 0',
            display: 'inline-block',
            padding: '4px',
            marginLeft: darkTheme ? '2px' : '1px',
            transform: 'rotate(-45deg)',
          }}
        />
      </LinkElement>
    </LinkComponent>
  );
};

export default LinkText;
