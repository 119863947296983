import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { AppColors } from '@fides-jam/utils/theme/colors';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import Row from '../../components/layout/rows/Row';
import Footer from '../../components/views/Footer';
import Header from '../../components/text/Header';
import Label from '../../components/text/Label';
import PlainText from '../../components/text/PlainText';
import CircleOfCounselsSection from '../../components/sections/CircleOfCounsels.section';
import Title from '../../components/text/Title';
import { BuilderContent } from '@builder.io/react';
import { PageWrapper, Section } from '../../components/layout';
import { HubspotFormWrapperWithTracking } from '../../components/HubspotFormWrapperWithTracking';
import SubHeader from '../../components/text/SubHeader';
import { WhitepaperPost } from '../../../types/builder-io-types';

const WhitepaperPage: React.FC = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);

  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  return (
    <PageWrapper>
      <BuilderContent
        model="whitepaper"
        options={{
          locale: language,
        }}
        key={language}
      >
        {(whitepaper: WhitepaperPost, loading, meta) => {
          if (loading) {
            return null;
          }

          return (
            <Section fullWidth spacing="lg" fadeIn>
              <Grid item container xs={12} md={5} rowSpacing={1}>
                <Grid item xs={12}>
                  <Label text={whitepaper.label} highlighted darkTheme={darkTheme} />
                </Grid>
                <Grid item xs={12}>
                  <Header text={whitepaper.title} darkTheme={darkTheme} />
                </Grid>
                <Grid item xs={12}>
                  <SubHeader text={whitepaper.subtitle} darkTheme={darkTheme} />
                </Grid>

                {whitepaper.description.map(({ emoji, text, list }) => (
                  <Grid
                    item
                    xs={12}
                    key={text}
                    sx={{
                      mt: 3,
                    }}
                  >
                    {emoji && (
                      <PlainText
                        darkTheme={darkTheme}
                        text={emoji + ' '}
                        sx={{ display: 'inline' }}
                        opacity
                      />
                    )}
                    <PlainText darkTheme={darkTheme} text={text} sx={{ display: 'inline' }} />
                    {list && (
                      <ul style={{ marginTop: 3 }}>
                        {list.map(({ item: listItem }) => (
                          <li key={listItem}>
                            <PlainText darkTheme={darkTheme} text={listItem} />
                          </li>
                        ))}
                      </ul>
                    )}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid
                  item
                  container
                  sx={{
                    background: '#F7F7F7',
                    p: 5,
                  }}
                  justifyContent="center"
                  borderRadius="10px"
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      px: {
                        xs: 0,
                        md: 1.5,
                        lg: 5,
                        xl: 6,
                      },
                      py: {
                        xs: 0,
                        md: 1,
                        lg: 2,
                        xl: 3,
                      },
                    }}
                  >
                    <img
                      src={whitepaper.image}
                      style={{
                        display: 'block',
                        width: '100%',
                        filter: 'drop-shadow(5px 5px 5px #00000061)',
                      }}
                      alt={whitepaper.title}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <HubspotFormWrapperWithTracking
                      key={whitepaper.signUpFormId}
                      portalId="25457524"
                      formId={whitepaper.signUpFormId}
                      gtagEventId="AW-10855235169/sVriCJ2HxJ0ZEOH8lrgo"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Section>
          );
        }}
      </BuilderContent>

      <Section bgcolor={AppSignUpColors.PRIMARY_DARKER}>
        <Grid item textAlign="center">
          <Title text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.INTRO.TITLE_1')} darkTheme={true} large />
          <Title
            text={t('CIRCLE_OF_COUNSELS.CTO_SECTION.INTRO.TITLE_2')}
            sx={{
              color: AppColors.PRIMARY,
            }}
            large
          />
        </Grid>
      </Section>

      <CircleOfCounselsSection />

      <Row sx={{ mt: 3 }}>
        <Footer />
      </Row>
    </PageWrapper>
  );
};

export default WhitepaperPage;
