import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlainText from '../../text/PlainText';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import LinkComponent from '../../Link/Link';
import HButton from '../../buttons/Highlight.button';

interface FilterCardPropsType {
  darkTheme?: boolean;
  setFilter: (filter: string) => void;
  filter: string;
}

const FilterCard: React.FC<FilterCardPropsType> = ({ setFilter, filter, darkTheme }) => {
  const { t } = useTranslation();

  const allFilter = [
    {
      id: 'all',
      name: t('KNOWLEDGE_HUB.FILTER.ALL'),
    },
    {
      id: 'blog-article',
      name: t('KNOWLEDGE_HUB.FILTER.BLOG'),
    },
    {
      id: 'webinar',
      name: t('KNOWLEDGE_HUB.FILTER.WEBINARS'),
    },
    {
      id: 'video',
      name: t('KNOWLEDGE_HUB.FILTER.VIDEOS'),
    },
    {
      id: 'whitepaper',
      name: t('KNOWLEDGE_HUB.FILTER.WHITEPAPERS'),
    },
  ];

  return (
    <Grid
      container
      item
      justifyContent={{
        xs: 'center',
        sm: 'flex-end',
      }}
    >
      <Grid container bgcolor="#F7F7F7" maxWidth="100%" xs="auto" borderRadius="10px" gap={1} p={1}>
        {allFilter.map((item) => {
          const isActive = filter === item.id;
          return (
            <LinkComponent onClick={() => setFilter(item.id)} localized={false} key={item.id}>
              <HButton
                darkTheme={true}
                sx={{
                  opacity: isActive ? 1 : 0.8,
                  backgroundColor: isActive ? '#4BFF9D' : '#FFFFFF50',
                }}
              >
                <Grid item xs={12} justifyContent="center" onClick={() => setFilter(item.id)}>
                  <PlainText text={item.name} darkTheme={darkTheme} opacity bold />
                </Grid>
              </HButton>
            </LinkComponent>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FilterCard;
