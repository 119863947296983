import { FC } from 'react';
import { Grid, SxProps } from '@mui/material';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import RichContentButton from '../../../buttons/RichContent.button';
import { RouteNames } from '../../../../Routes/routeNames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ResolutionsIcon } from '/assets/images/icons/resolutions.svg';
import { ReactComponent as MeetingsIcon } from '/assets/images/icons/meetings.svg';

interface BoardManagementMenuProps {
  darkTheme?: boolean;
  sx?: SxProps;
  closeMenu?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const BoardManagementMenu: FC<BoardManagementMenuProps> = ({
  darkTheme,
  sx,
  onMouseEnter,
  onMouseLeave,
  closeMenu,
}) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      flex={1}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        p: 3,
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        background: darkTheme ? AppSignUpColors.PRIMARY_DARKER : '#FFF',
        transition: 'background 700ms ease-in-out',
        border: `1px solid ${darkTheme ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'}`,
        ...sx,
      }}
    >
      <Grid container item flex={1}>
        <Grid item xs={12}>
          <RichContentButton
            title={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.COMMITTEE_MANAGEMENT.TITLE')}
            description={t(
              'NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.COMMITTEE_MANAGEMENT.TEXT'
            )}
            icon={<MeetingsIcon />}
            href={RouteNames.committees}
            darkTheme={darkTheme}
            onClick={closeMenu}
          />
        </Grid>
        <Grid item xs={12}>
          <RichContentButton
            title={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.MEETINGS.TITLE')}
            description={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.MEETINGS.TEXT')}
            icon={<ResolutionsIcon />}
            href={RouteNames.meetings}
            darkTheme={darkTheme}
            onClick={closeMenu}
          />
        </Grid>
        <Grid item xs={12}>
          <RichContentButton
            title={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.RESOLUTIONS.TITLE')}
            description={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.RESOLUTIONS.TEXT')}
            icon={<ResolutionsIcon />}
            href={RouteNames.resolutions}
            darkTheme={darkTheme}
            onClick={closeMenu}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <RichContentButton
            title={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.BOARD_APP.TITLE')}
            description={t('NAVBAR.FEATURE_GROUPS.BOARD_MANAGEMENT.SUBMENU.BOARD_APP.TEXT')}
            icon={<TasksIcon />}
            href={RouteNames.tasks}
            darkTheme={darkTheme}
            onClick={closeMenu}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};
