import { ThemeProvider } from '@emotion/react';
import { themeSignUp } from '@fides-jam/utils/theme';
import { CssBaseline } from '@mui/material';
import { FC, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './Routes';
import AppLoader from '@fides-jam/front/components/AppLoader';
import WhitepaperPopup from './components/popup/WhitepaperPopup';
import NavBar from './components/Navbar';
import { DarkThemeProvider } from './components/Context/ThemeContext';
import { useCalendlyConversionTracking } from './hooks/useCalendlyConversionTracking';

const App: FC = () => {
  useCalendlyConversionTracking();
  return (
    <HelmetProvider>
      <CssBaseline enableColorScheme />
      <ThemeProvider theme={themeSignUp}>
        <DarkThemeProvider>
          <BrowserRouter>
            <Suspense fallback={<AppLoader />}>
              <WhitepaperPopup />
              <NavBar />
              <AppRoutes />
            </Suspense>
          </BrowserRouter>
        </DarkThemeProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
