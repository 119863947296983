import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlainText from '../text/PlainText';

const LogoWall = ({ logos }: { logos: string[] }) => {
  const gap = '70px';

  const ImageGroup = styled(Box)({
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: gap,
    minWidth: '100%',
    animation: 'scroll-x 60s linear infinite',

    '@keyframes scroll-x': {
      from: {
        transform: 'translateX(0)',
      },
      to: {
        transform: 'translateX(calc(-100% - calc(400px / 14)))',
      },
    },
  });

  const Image = styled('img')({
    height: '60px',
    width: '100%',
    objectFit: 'contain',
    display: 'grid',
    placeItems: 'center',
  });

  function ImageMap() {
    return (
      <>
        {logos.map((image, index: number) => (
          <Grid
            container
            width="200px"
            height="100%"
            spacing={0}
            justifyContent="flex-end"
            alignContent="center"
            key={image}
          >
            <Image key={index} src={image} loading="lazy" alt="" />
          </Grid>
        ))}
      </>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          maskImage:
            'linear-gradient(to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 20%, hsl(0 0% 0% / 1) 80%, hsl(0 0% 0% / 0))',
          gap: gap,
        }}
      >
        <ImageGroup>
          <ImageMap />
        </ImageGroup>
        <ImageGroup aria-hidden="true">
          <ImageMap />
        </ImageGroup>
      </Box>
    </Box>
  );
};

export default LogoWall;
