import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { Grid } from '@mui/material';
import { addWeeks } from 'date-fns';
import PlainText from '../text/PlainText';
import Header from '../text/Header';
import HButton from '../buttons/Highlight.button';
import FadeSlide from '../layout/container/FadeSlide.container';
import { BuilderContent } from '@builder.io/react';

const WhitepaperPopup: FC = () => {
  const { i18n, t } = useTranslation();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [{ hidePopup: hidePopupCookie }, setCookie] = useCookies<string>(['hidePopup']);

  useEffect(() => {
    const timeoutReference = setTimeout(() => {
      setShowPopup(true);
    }, 20000);
    return () => clearTimeout(timeoutReference);
  }, []);

  const hidePopup = () => {
    setCookie('hidePopup', true, {
      expires: addWeeks(new Date(), 1),
    });
    setShowPopup(false);
  };

  if (!(i18n.language === 'de') || hidePopupCookie || !showPopup) {
    return null;
  }

  return (
    <BuilderContent model="popup">
      {(popup, loading, meta) => {
        if (loading) {
          return null;
        }
        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              position: 'fixed',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.5)',
              zIndex: 1001,
              overflow: 'hidden',
            }}
          >
            <FadeSlide rowSpacing={1} direction="up" once sx={{ width: 'unset' }}>
              <Grid
                container
                m={3}
                pt={5}
                pb={3}
                justifyContent="center"
                sx={{
                  position: 'relative',
                  maxWidth: '800px',
                  background: '#fff',
                  borderRadius: '20px',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  item
                  onClick={hidePopup}
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '20px',
                    fontSize: '30px',
                    cursor: 'pointer',
                  }}
                >
                  &times;
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="flex-start"
                  flexDirection="row-reverse"
                  p={6}
                  spacing={6}
                  sx={{
                    overflow: 'scroll',
                    maxHeight: '90svh',
                  }}
                >
                  <Grid item container xs={12} md={6} justifyContent="center">
                    <img
                      src={popup.image}
                      alt={popup.title}
                      style={{
                        display: 'block',
                        width: '100%',
                        filter: 'drop-shadow(5px 5px 5px #00000061)',
                        maxHeight: '38vh',
                        maxWidth: 'max-content',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid item mb={1}>
                      <Header text={popup.title} />
                    </Grid>
                    <Grid item mb={4}>
                      <PlainText text={popup.description} />
                    </Grid>
                    <Grid item>
                      <a
                        href={popup.buttonLink}
                        onClick={hidePopup}
                        style={{
                          textDecoration: 'none',
                          textTransform: 'none',
                        }}
                      >
                        <HButton size="small" sx={{ px: 5 }} darkTheme={true}>
                          {popup.buttonText}
                        </HButton>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FadeSlide>
          </Grid>
        );
      }}
    </BuilderContent>
  );
};

export default WhitepaperPopup;
