import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import Row from '../../components/layout/rows/Row';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import Header from '../../components/text/Header';
import Label from '../../components/text/Label';
import PlainText from '../../components/text/PlainText';
import QuoteCard from '../../components/Cards/Quote.card';
import { TitleOnlyCard } from '../../components/Cards/Feature.card';
import { ReactComponent as Entities } from '/assets/images/icons/entities.svg';
import { ReactComponent as Resolutions } from '/assets/images/icons/resolutions-2.svg';
import Column from '../../components/layout/columns/index.column';
import BGImageSlideContainer from '../../components/layout/container/BGImageSlide.container';
import CallToAction from '../../components/sections/cta/CallToAction.section';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';
import { PageWrapper, Section } from '../../components/layout';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';

const SimonKucherSucessStoryPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);

  const features = [
    {
      title: t('SIMON_KUCHER_SUCCESS_STORY.MAIN_USE_CASES.FEATURES.MANAGING_ENTITIES'),
      icon: <Entities />,
    },
    {
      title: t('SIMON_KUCHER_SUCCESS_STORY.MAIN_USE_CASES.FEATURES.RESOLUTIONS'),
      icon: <Resolutions />,
    },
  ];

  return (
    <PageWrapper>
      <Section bgcolor="#E9FEEB">
        <Grid item xs={12} md={6}>
          <Grid container item spacing={2} xs={12} md={12}>
            <Grid item xs={12}>
              <Label text={t('SIMON_KUCHER_SUCCESS_STORY.HERO.LABEL')} darkTheme={false} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Header
                text={t('SIMON_KUCHER_SUCCESS_STORY.HERO.TITLE')}
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 30,
                    lg: 40,
                    xl: 48,
                  },
                  mb: 2,
                }}
              />
            </Grid>
            <Grid container item spacing={4} xs={12} md={11.5} textAlign="justify">
              <Grid item>
                <PlainText opacity text={t('SIMON_KUCHER_SUCCESS_STORY.HERO.TEXT_1')} />
              </Grid>

              <Grid item>
                <PlainText
                  opacity
                  text={t('SIMON_KUCHER_SUCCESS_STORY.HERO.TEXT_2')}
                  sx={{ display: 'inline' }}
                />
              </Grid>
              <Grid item>
                <PlainText opacity text={t('SIMON_KUCHER_SUCCESS_STORY.HERO.TEXT_3')} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src="/assets/images/simon-kucher-success-story/1.webp"
            alt="Walking"
            style={{
              width: '100%',
              height: isSmall ? '400px' : '700px',
              maxHeight: '500px',
              objectFit: 'cover',
              borderRadius: '10px',
              boxShadow: '0px 0px 60px 0px rgba(0,0,0,0.09)',
            }}
          />
        </Grid>
      </Section>

      <Section>
        <Grid item textAlign="center" xs={12} md={11}>
          <Title
            text={t('SIMON_KUCHER_SUCCESS_STORY.STORY.TITLE_1') + ' '}
            mainTitle
            sx={{
              fontSize: {
                xs: 30,
                sm: 30,
                lg: 40,
                xl: 48,
              },
              display: 'inline',
            }}
          />
          <Title
            text={t('SIMON_KUCHER_SUCCESS_STORY.STORY.TITLE_2')}
            sx={{
              fontSize: {
                xs: 30,
                sm: 30,
                lg: 40,
                xl: 48,
              },
              display: 'inline',
              fontWeight: 400,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            item
            sx={{
              height: {
                xs: '250px',
                md: '680px',
              },
            }}
            minWidth="300px"
          >
            <video
              controls
              autoPlay
              playsInline
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'relative',
                borderRadius: '20px',
                border: AppSignUpColors.PRIMARY_DARKER_BORDER,
              }}
            >
              <source src="/assets/videos/simon-kucher.webm" type="video/webm" />
              <source src="/assets/videos/simon-kucher.mp4" type="video/mp4" />
            </video>
          </Grid>
        </Grid>
      </Section>

      <Section fullWidth>
        <Grid item textAlign="center">
          <Title
            text={t('SIMON_KUCHER_SUCCESS_STORY.MAIN_USE_CASES.TITLE')}
            sx={{
              fontSize: {
                xs: 30,
                sm: 30,
                lg: 40,
                xl: 48,
              },
            }}
          />
        </Grid>
        <Grid container item justifyContent="center" alignItems="flex-start" spacing={3}>
          {features.map((feature) => (
            <TitleOnlyCard feature={feature} darkTheme={true} key={feature.title} />
          ))}
        </Grid>
      </Section>

      <Section>
        <Grid item mt={6}>
          <QuoteCard
            darkTheme={true}
            quote={{
              name: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.DIRK_SIEVERT.NAME'),
              position: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.DIRK_SIEVERT.POSITION'),
              image: '/assets/images/simon-kucher-success-story/dirk-sievert.webp',
              text: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.DIRK_SIEVERT.TEXT'),
            }}
          />
        </Grid>
      </Section>

      <Section
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="right"
            darkTheme={darkTheme}
            imageUrl="/assets/images/simon-kucher-success-story/2.webp"
            transitionDelay={400}
            once={true}
            sx={{
              pb: '65%',
              border: '5px solid rgba(240,245,255,1)',
            }}
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('SIMON_KUCHER_SUCCESS_STORY.MANAGING_COMPLEXITY.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('SIMON_KUCHER_SUCCESS_STORY.MANAGING_COMPLEXITY.TEXT_1')}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('SIMON_KUCHER_SUCCESS_STORY.MANAGING_COMPLEXITY.TEXT_2')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <Section>
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="left"
            darkTheme={darkTheme}
            imageUrl="/assets/images/simon-kucher-success-story/3.webp"
            transitionDelay={400}
            once={true}
            sx={{
              pb: '65%',
              border: '5px solid rgba(240,245,255,1)',
            }}
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction={'up'} rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('SIMON_KUCHER_SUCCESS_STORY.EFFICIENCY_AND_COLLABORATION.TITLE_1')}
                darkTheme={darkTheme}
              />
              <Header
                text={t('SIMON_KUCHER_SUCCESS_STORY.EFFICIENCY_AND_COLLABORATION.TITLE_2')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('SIMON_KUCHER_SUCCESS_STORY.EFFICIENCY_AND_COLLABORATION.TEXT_1')}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('SIMON_KUCHER_SUCCESS_STORY.EFFICIENCY_AND_COLLABORATION.TEXT_2')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <Section>
        <Grid item>
          <QuoteCard
            darkTheme={true}
            quote={{
              name: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.LUCA_FLAHERTY.NAME'),
              position: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.LUCA_FLAHERTY.POSITION'),
              image: '/assets/images/simon-kucher-success-story/luca-flaherty.webp',
              text: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.LUCA_FLAHERTY.TEXT'),
            }}
          />
        </Grid>
      </Section>

      <Section
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="right"
            darkTheme={darkTheme}
            imageUrl="/assets/images/simon-kucher-success-story/4.webp"
            transitionDelay={400}
            once={true}
            sx={{
              pb: '65%',
              border: '5px solid rgba(240,245,255,1)',
            }}
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('SIMON_KUCHER_SUCCESS_STORY.SEAMLESS_TRANSITION.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('SIMON_KUCHER_SUCCESS_STORY.SEAMLESS_TRANSITION.TEXT_1')}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('SIMON_KUCHER_SUCCESS_STORY.SEAMLESS_TRANSITION.TEXT_2')}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('SIMON_KUCHER_SUCCESS_STORY.SEAMLESS_TRANSITION.TEXT_3')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <CallToAction
        header={t('CTA.EXPERIENCE.HEADER')}
        subHeader={t('CTA.EXPERIENCE.SUBHEADER')}
        link={() => setShowContactPopup(true)}
        buttonText={t('GENERAL.BOOK_MEETING')}
      />

      {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}

      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </PageWrapper>
  );
};

export default SimonKucherSucessStoryPage;
