import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlainText from '../../text/PlainText';

interface ImagesScrollPropsType {
  images: { src: string; description?: string }[];
}

const ImageScroll = ({ images }: ImagesScrollPropsType) => {
  const gap = '30px';

  const ImageGroup = styled(Box)({
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: gap,
    minWidth: '100%',
    animation: 'scroll-x 60s linear infinite',

    '@keyframes scroll-x': {
      from: {
        transform: 'translateX(0)',
      },
      to: {
        transform: 'translateX(calc(-100% - calc(400px / 14)))',
      },
    },
  });

  const Image = styled('img')({
    height: '300px',
    width: '300px',
    objectFit: 'cover',
    display: 'grid',
    placeItems: 'center',
    border: '1px solid white',
    borderRadius: '0.5rem',
  });

  function ImageMap() {
    return (
      <>
        {images.map((image: { src: string; description?: string }, index: number) => (
          <Grid
            container
            width="300px"
            height="100%"
            spacing={0}
            justifyContent="flex-end"
            alignContent="center"
            sx={{
              marginBottom: index % 2 ? '150px' : '0px',
            }}
            key={image.src}
          >
            <Image key={index} src={image.src} loading="lazy" alt="" />
            {image.description && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                bgcolor="#4BFF9D"
                borderRadius="10px"
                width="fit-content"
                px={2}
                py={0.5}
                mt={0.5}
              >
                <Grid item>
                  <PlainText
                    text={image.description}
                    opacity
                    sx={{
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          maskImage:
            'linear-gradient(to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 20%, hsl(0 0% 0% / 1) 80%, hsl(0 0% 0% / 0))',
          gap: gap,
        }}
      >
        <ImageGroup>
          <ImageMap />
        </ImageGroup>
        <ImageGroup aria-hidden="true">
          <ImageMap />
        </ImageGroup>
      </Box>
    </Box>
  );
};

export default ImageScroll;
