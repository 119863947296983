import { FC } from 'react';
import { Grid, SxProps } from '@mui/material';

interface SectionPropsType {
  sx?: SxProps;
  rowSpacing?: number;
  columnSpacing?: number;
  flexDirection?: 'row' | 'row-reverse';
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  id?: string;
}

const Row: FC<SectionPropsType> = ({
  sx,
  rowSpacing,
  columnSpacing,
  children,
  flexDirection,
  alignItems,
  id,
}) => {
  return (
    <Grid
      item
      container
      xs={12}
      xl={10}
      sx={{
        p: {
          xs: 4,
          sm: 5,
          md: 6,
          lg: 7,
        },
        maxWidth: '100vw',
        overflow: 'visible',
        ...sx,
      }}
      justifyContent="center"
      alignItems={alignItems || 'center'}
      flexDirection={flexDirection || 'row'}
      rowSpacing={rowSpacing || 4}
      columnSpacing={columnSpacing || 0}
      id={id}
    >
      {children}
    </Grid>
  );
};

export default Row;
