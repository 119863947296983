import { FC } from 'react';
import { SxProps } from '@mui/material';
import { Layer } from '@fides-jam/front/components/Layer';
import Meetings from './meetings.view';
import Resolutions from './resolutions.view';

interface DecisionViewPropsType {
  sx?: SxProps;
  animate?: boolean;
}

const DecisionView: FC<DecisionViewPropsType> = ({ sx, animate }) => {
  return (
    <Layer
      sx={{
        overflow: 'visible',
        transform: {
          xs: 'scale(0.72)',
          sm: 'scale(1.0)',
          md: 'scale(0.9)',
          lg: 'scale(1.0)',
          xl: 'scale(1.0)',
        },
      }}
    >
      <Meetings animate={animate} />
      <Resolutions animate={animate} />
    </Layer>
  );
};

export default DecisionView;
