import { FC } from 'react';
import { SxProps } from '@mui/material';
import { Layer } from '@fides-jam/front/components/Layer';
import OrgChart from './orgChart.view';
import CapTable from './capTable.view';

interface EntityViewPropsType {
  sx?: SxProps;
  darkTheme?: boolean;
  animate?: boolean;
}

const EntityView: FC<EntityViewPropsType> = ({ sx, darkTheme, animate }) => {
  return (
    <Layer
      sx={{
        overflow: 'visible',
        transform: {
          xs: 'scale(0.7)',
          sm: 'scale(0.9)',
          md: 'scale(0.8)',
          lg: 'scale(0.9)',
          xl: 'scale(0.9)',
        },
      }}
    >
      <OrgChart darkTheme={darkTheme} animate={animate} />
      <CapTable animate={animate} />
    </Layer>
  );
};

export default EntityView;
