import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { RouteNames } from '../../Routes/routeNames';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { PageWrapper, Section } from '../../components/layout';
import Row from '../../components/layout/rows/Row';
import FeatureHighlight from '../../components/sections/FeatureHighlight.section';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import BGImageSlideContainer from '../../components/layout/container/BGImageSlide.container';
import CallToAction from '../../components/sections/cta/CallToAction.section';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import SubTitle from '../../components/text/SubTitle';
import Label from '../../components/text/Label';
import Header from '../../components/text/Header';
import PlainText from '../../components/text/PlainText';
import HButton from '../../components/buttons/Highlight.button';
import Column from '../../components/layout/columns/index.column';
import FeatureOverview from '../../components/sections/FeatureOverview.section';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';
import { ReactComponent as Hourglass } from '/assets/images/icons/hourglass.svg';
import { ReactComponent as Lightbulb } from '/assets/images/icons/lightbulb.svg';
import { ReactComponent as Security } from '/assets/images/icons/security.svg';
import { ReactComponent as Lock } from '/assets/images/icons/lock1.svg';
import { ReactComponent as Disconnected } from '/assets/images/icons/disconnected.svg';
import { ReactComponent as Certificate } from '/assets/images/icons/certificate.svg';

const Ai: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);

  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const masterChallanges = [
    {
      title: t('FIDES_AI.SECURE_FEATURES.SELF_HOSTING_LICENSE.LABEL'),
      text: t('FIDES_AI.SECURE_FEATURES.SELF_HOSTING_LICENSE.TEXT'),
      icon: <Disconnected />,
    },
    {
      title: t('FIDES_AI.SECURE_FEATURES.DATA_STORED_IN_EUROPE.LABEL'),
      text: t('FIDES_AI.SECURE_FEATURES.DATA_STORED_IN_EUROPE.TEXT'),
      icon: <Lock />,
    },
    {
      title: t('FIDES_AI.SECURE_FEATURES.ISO_27001_CERTIFIED.LABEL'),
      text: t('FIDES_AI.SECURE_FEATURES.ISO_27001_CERTIFIED.TEXT'),
      icon: <Certificate />,
    },
  ];

  const features = [
    {
      title: t('FIDES_AI.WHAT_FIDES_CAN_DO.EFFICIENT_TASK_HANDLING.TITLE'),
      text: t('FIDES_AI.WHAT_FIDES_CAN_DO.EFFICIENT_TASK_HANDLING.TEXT'),
      icon: <Hourglass />,
    },
    {
      title: t('FIDES_AI.WHAT_FIDES_CAN_DO.QUICK_ANSWERS.TITLE'),
      text: t('FIDES_AI.WHAT_FIDES_CAN_DO.QUICK_ANSWERS.TEXT'),
      icon: <Lightbulb />,
    },
    {
      title: t('FIDES_AI.WHAT_FIDES_CAN_DO.SECURE_CONFIDENTIALITY.TITLE'),
      text: t('FIDES_AI.WHAT_FIDES_CAN_DO.SECURE_CONFIDENTIALITY.TEXT'),
      icon: <Security />,
    },
  ];

  return (
    <PageWrapper>
      <Section flexDirection="row-reverse" expanded>
        <Grid item xs={12} md={6}>
          <img
            src={'/assets/images/ai/hero/' + i18n.language + '.webp'}
            alt="Hero AI Page"
            style={{
              width: '100%',
              maxHeight: '300px',
              objectFit: 'contain',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item container rowSpacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                mb: 2,
              }}
            >
              <Label text={t('FIDES_AI.HERO.LABEL')} darkTheme={darkTheme} />
            </Grid>
            <Title
              mainTitle
              text={t('FIDES_AI.HERO.TITLE')}
              darkTheme={darkTheme}
              sx={{
                mb: 2,
              }}
            />
            <SubTitle
              text={t('FIDES_AI.HERO.SUBTITLE')}
              sx={{
                fontSize: {
                  xs: 30,
                  sm: 30,
                  lg: 30,
                  xl: 38,
                },
                mb: 1,
                pr: 2,
              }}
              darkTheme={darkTheme}
            />
            <Grid item xs={12} onClick={() => setShowContactPopup(true)}>
              <HButton size="large" sx={{ px: 5 }} darkTheme={true}>
                {t('GENERAL.BOOK_DEMO')}
              </HButton>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <FeatureOverview title={t('FIDES_AI.WHAT_FIDES_CAN_DO.TITLE')} features={features} />

      <Section>
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="left"
            darkTheme={darkTheme}
            imageUrl={`/assets/images/ai/feature-sections/ask-anything/${i18n.language}.webp`}
            transitionDelay={400}
            once={true}
            noBackground
            contain
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('FIDES_AI.FEATURE_SECTIONS.ASK_ANYTHING.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.ASK_ANYTHING.TEXT_1')}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.ASK_ANYTHING.TEXT_2')}
                sx={{
                  display: 'inline',
                }}
                bold
              />
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.ASK_ANYTHING.TEXT_3')}
                sx={{
                  display: 'inline',
                }}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <Section
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="right"
            darkTheme={darkTheme}
            imageUrl={`/assets/images/ai/feature-sections/find-documents-immediately/${i18n.language}.webp`}
            transitionDelay={400}
            once={true}
            noBackground
            contain
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('FIDES_AI.FEATURE_SECTIONS.FIND_DOCUMENTS_IMMEDIATELY.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.FIND_DOCUMENTS_IMMEDIATELY.TEXT')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <Section
        sx={{
          mb: 10,
        }}
      >
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="left"
            darkTheme={darkTheme}
            imageUrl={`/assets/images/ai/feature-sections/overcome-language-barriers/${i18n.language}.webp`}
            transitionDelay={400}
            once={true}
            noBackground
            contain
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('FIDES_AI.FEATURE_SECTIONS.OVERCOME_LANGUAGE_BARRIERS.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.OVERCOME_LANGUAGE_BARRIERS.TEXT')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <FeatureHighlight
        title={t('FIDES_AI.SECURE_FEATURES.TITLE')}
        features={masterChallanges}
        buttonText={t('FIDES_AI.SECURE_FEATURES.BUTTON_TEXT')}
        buttonLink={RouteNames.security}
        darkTheme={darkTheme}
        small
      />

      <Section
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="right"
            darkTheme={darkTheme}
            imageUrl={`/assets/images/ai/feature-sections/gen-ai-in-every-feature/${i18n.language}.webp`}
            transitionDelay={400}
            once={true}
            noBackground
            contain
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('FIDES_AI.FEATURE_SECTIONS.GEN_AI_IN_EVERY_FEATURE.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.GEN_AI_IN_EVERY_FEATURE.TEXT_1')}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.GEN_AI_IN_EVERY_FEATURE.TEXT_2')}
                sx={{
                  display: 'inline',
                }}
                bold
              />
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.GEN_AI_IN_EVERY_FEATURE.TEXT_3')}
                sx={{
                  display: 'inline',
                }}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <Section>
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="left"
            darkTheme={darkTheme}
            imageUrl={`/assets/images/ai/feature-sections/translations-with-a-single-click/${i18n.language}.webp`}
            transitionDelay={400}
            once={true}
            noBackground
            contain
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('FIDES_AI.FEATURE_SECTIONS.TRANSLATIONS_WITH_A_SINGLE_CLICK.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.TRANSLATIONS_WITH_A_SINGLE_CLICK.TEXT')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <Section
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="right"
            darkTheme={darkTheme}
            imageUrl={`/assets/images/ai/feature-sections/ai-for-legal-documents/${i18n.language}.webp`}
            transitionDelay={400}
            once={true}
            noBackground
            contain
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('FIDES_AI.FEATURE_SECTIONS.AI_FOR_LEGAL_DOCUMENTS.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.AI_FOR_LEGAL_DOCUMENTS.TEXT')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <CallToAction
        header={t('FIDES_AI.CTA.TITLE')}
        subHeader={t('FIDES_AI.CTA.SUBTITLE')}
        link={() => setShowContactPopup(true)}
        buttonText={t('GENERAL.BOOK_MEETING')}
      />

      {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}

      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </PageWrapper>
  );
};

export default Ai;
