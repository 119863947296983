export const trackGtagConversion = (eventId: string, callback?: () => void) => {
  const windowWithGtag: WindowWithGtag = window;
  if (!windowWithGtag || !windowWithGtag.gtag) {
    return;
  }
  if (!eventId) {
    console.warn('No event ID provided for gtag conversion tracking');
    return;
  }
  windowWithGtag.gtag('event', 'conversion', {
    send_to: eventId,
    event_callback: callback,
  });
};
