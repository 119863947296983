import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import Row from '../../components/layout/rows/Row';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import Label from '../../components/text/Label';
import PlainText from '../../components/text/PlainText';
import HButton from '../../components/buttons/Highlight.button';
import { PageWrapper, Section } from '../../components/layout';
import { AppColors } from '@fides-jam/utils/theme/colors';
import SubHeader from '../../components/text/SubHeader';
import { ChecklistArrowsLeft } from '../../components/Checklist/Checklist';
import SubTitle from '../../components/text/SubTitle';
import { FidesOrCard } from '../../components/views/FidesVsCompetitor/FidesOr.card';
import QuoteCard from '../../components/views/FidesVsCompetitor/Quote.card';
import List from '../../components/List/List';
import { useParams } from 'react-router-dom';
import FidesVsComeptitor from './FidesVsComeptitor';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';
import { PflichtenheftPopup } from '../../components/popup/Pflichtenheft.popup';
import { RouteNames } from '../../Routes/routeNames';

const FidesVsComeptitors = () => {
  const { t } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showPflichtenheftPopup, setShowPflichtenheftPopup] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const { competitorId } = useParams();

  const checklistItems = [
    t('FIDES_VS_COMPETITORS.INTRO.CHECKLIST.CHECKLIST_1'),
    t('FIDES_VS_COMPETITORS.INTRO.CHECKLIST.CHECKLIST_2'),
    t('FIDES_VS_COMPETITORS.INTRO.CHECKLIST.CHECKLIST_3'),
    t('FIDES_VS_COMPETITORS.INTRO.CHECKLIST.CHECKLIST_4'),
  ];

  const summaryList = [
    t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.OVERVIEW.OVERVIEW_1'),
    t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.OVERVIEW.OVERVIEW_2'),
    t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.OVERVIEW.OVERVIEW_3'),
    t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.OVERVIEW.OVERVIEW_4'),
  ];

  const fidesOrCompetitor = [
    {
      name: 'Corporify',
      logo: '/assets/images/fidesVs/corporify.webp',
    },
    {
      name: 'Sherpany',
      logo: '/assets/images/fidesVs/sherpany.webp',
    },
  ];

  if (competitorId) {
    if (fidesOrCompetitor.find((item) => item.name.toLowerCase() === competitorId)) {
      return <FidesVsComeptitor competitor={competitorId} />;
    } else {
      navigate(RouteNames.FidesVsComeptitors);
    }
  }

  return (
    <PageWrapper>
      <Section bgcolor="#011422" sx={{ justifyContent: 'left' }}>
        <Grid item xs={12} md={10}>
          <Label
            text={t('FIDES_VS_COMPETITORS.HERO.LABEL')}
            sx={{
              color: AppColors.PRIMARY,
              mb: 2,
            }}
          />
          <Title
            mainTitle
            text={t('FIDES_VS_COMPETITORS.HERO.TITLE')}
            sx={{
              color: 'white',
              fontWeight: 300,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <HButton
            onClick={() => setShowContactPopup(true)}
            darkTheme={true}
            sx={{
              px: 3,
            }}
          >
            {t('GENERAL.BOOK_PERSONAL_MEETING')}
          </HButton>
        </Grid>
      </Section>

      <Section
        bgcolor="#E9FEEB"
        sx={{ justifyContent: 'left', alignItems: 'flex-start' }}
        spacing="lg"
      >
        <Grid item container md={6} xs={12} spacing={2}>
          <Grid item mb={1} xs={12}>
            <SubHeader text={t('FIDES_VS_COMPETITORS.INTRO.TEXT.HEADER')} opacity />
          </Grid>
          <Grid item xs={12}>
            <PlainText text={t('FIDES_VS_COMPETITORS.INTRO.TEXT.TEXT_1')} darkTheme={darkTheme} />
          </Grid>
          <Grid item xs={12}>
            <PlainText text={t('FIDES_VS_COMPETITORS.INTRO.TEXT.TEXT_2')} darkTheme={darkTheme} />
          </Grid>
        </Grid>
        <Grid item container md={6} xs={12} spacing={2}>
          <Grid item>
            <SubHeader text={t('FIDES_VS_COMPETITORS.INTRO.CHECKLIST.HEADER')} opacity />
          </Grid>
          <Grid item>
            <ChecklistArrowsLeft checklist={checklistItems} darkTheme={darkTheme} />
          </Grid>
          <Grid item xs={12} mt={1}>
            <Grid item mb={3} xs={12}>
              <SubHeader
                text={t('FIDES_VS_COMPETITORS.INTRO.CHECKLIST.SAMPLE_SHEET_CTA')}
                opacity
              />
            </Grid>
            <Grid item mb={1} xs={12}>
              <HButton
                localized={false}
                darkTheme={true}
                onClick={() => setShowPflichtenheftPopup(true)}
              >
                {t('FIDES_VS_COMPETITORS.INTRO.CHECKLIST.SAMPLE_SHEET_CTA_BUTTON')}
              </HButton>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid item xs={12}>
          <Title
            text={t('FIDES_VS_COMPETITORS.COMPARE.TITLE')}
            sx={{
              fontWeight: 300,
            }}
            darkTheme={darkTheme}
            center
          />
        </Grid>
        <Grid item xs={12}>
          <SubTitle
            text={t('FIDES_VS_COMPETITORS.COMPARE.SUBTITLE')}
            center
            darkTheme={darkTheme}
          />
        </Grid>
        <Grid item container spacing={2} xs={12} md={10} justifyContent="center">
          {fidesOrCompetitor.map((item) => (
            <FidesOrCard name={item.name} logo={item.logo} key={item.name} darkTheme={darkTheme} />
          ))}
        </Grid>
      </Section>

      <Section>
        <Grid item xs={12}>
          <Title
            text={t('FIDES_VS_COMPETITORS.CTA.TITLE')}
            sx={{
              fontWeight: 300,
            }}
            center
            darkTheme={darkTheme}
          />
        </Grid>
        <Grid item xs={12}>
          <SubTitle text={t('FIDES_VS_COMPETITORS.CTA.SUBTITLE')} darkTheme={darkTheme} center />
        </Grid>
        <Grid item>
          <HButton
            onClick={() => setShowContactPopup(true)}
            darkTheme={true}
            sx={{
              px: 3,
            }}
          >
            {t('GENERAL.BOOK_CONSULTATION_CALL')}
          </HButton>
        </Grid>
      </Section>

      <Section bgcolor={AppColors.LIGHT_GREEN}>
        <Grid container item xs={12} justifyContent="center">
          <Title
            text={t('FIDES_VS_COMPETITORS.QUOTE.TITLE')}
            darkTheme={darkTheme}
            sx={{
              mb: 8,
              fontWeight: 300,
              fontSize: '2.5rem',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <QuoteCard
            darkTheme={darkTheme}
            quote={{
              name: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.DIRK_SIEVERT.NAME'),
              position: t('SIMON_KUCHER_SUCCESS_STORY.TESTIMONIALS.DIRK_SIEVERT.POSITION'),
              image: '/assets/images/simon-kucher-success-story/dirk-sievert.webp',
              companyImage: '/assets/images/testimonials/simon-kucher/simon-kucher.webp',
              text: t('FIDES_VS_COMPETITORS.QUOTE.QUOTE'),
            }}
          />
        </Grid>
      </Section>

      <Section spacing="none">
        <Grid item mb={1} xs={12}>
          <Title
            text={t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.TITLE')}
            sx={{
              fontWeight: 300,
            }}
            darkTheme={darkTheme}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="flex-start"
          justifyContent="flex-start"
          textAlign="justify"
          pt={4}
          spacing={2}
        >
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <PlainText
                text={t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.TEXT_1')}
                darkTheme={darkTheme}
                opacity
              />
            </Grid>
            <Grid item xs={12}>
              <PlainText
                text={t('FIDES_VS_COMPETITORS.BENEFITS_SUMMARY.TEXT_2')}
                darkTheme={darkTheme}
                opacity
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6}>
            <List list={summaryList} darkTheme={darkTheme} />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid item container xs={12} justifyContent="center">
          <Grid
            container
            item
            xs={12}
            md={11}
            bgcolor="#011422"
            borderRadius="20px"
            px={10}
            py={6}
            gap={2}
          >
            <Grid item xs={12}>
              <Title
                sx={{
                  fontWeight: 300,
                  fontSize: '2.5rem',
                }}
                text={t('FIDES_VS_COMPETITORS.CTA_2.TITLE')}
                darkTheme={true}
                center
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <SubTitle text={t('FIDES_VS_COMPETITORS.CTA_2.SUBTITLE')} darkTheme={true} center />
              </Grid>
              <Grid container item xs={12} justifyContent="center" mt={4}>
                <HButton
                  darkTheme={true}
                  onClick={() => setShowContactPopup(true)}
                  sx={{
                    px: 3,
                  }}
                >
                  {t('GENERAL.BOOK_CONSULTATION_CALL')}
                </HButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      {showPflichtenheftPopup && (
        <PflichtenheftPopup setShowPflichtenheftPopup={setShowPflichtenheftPopup} />
      )}
      {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}

      <Row sx={{ mt: 3 }}>
        <Footer />
      </Row>
    </PageWrapper>
  );
};

export default FidesVsComeptitors;
