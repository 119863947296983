import { WhitepaperPost } from '../../../../../types/builder-io-types';
import HubspotForm from 'react-hubspot-form';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Header from '../../../text/Header';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import PlainText from '../../../text/PlainText';

const WhitepaperPostContent: React.FC<{ post: WhitepaperPost }> = ({ post }) => {
  const { i18n } = useTranslation();

  return (
    <>
      {post.description.map(({ emoji, text, list }) => (
        <Grid
          item
          xs={12}
          key={text}
          sx={{
            mt: 3,
          }}
        >
          {emoji && (
            <PlainText darkTheme={false} opacity text={emoji + ' '} sx={{ display: 'inline' }} />
          )}
          <PlainText darkTheme={false} opacity text={text} sx={{ display: 'inline' }} />
          {list && (
            <ul style={{ marginTop: 3 }}>
              {list.map(({ item: listItem }) => (
                <li key={listItem}>
                  <PlainText opacity darkTheme={false} text={listItem} />
                </li>
              ))}
            </ul>
          )}
        </Grid>
      ))}

      <Box
        sx={{
          filter: 'blur(10px)',
          opacity: 0.75,
          width: '100%',
          mt: 5,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
          }}
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce semper ac odio et
            sodales. Aenean at rutrum quam. Vestibulum magna nibh, cursus ac ullamcorper quis,
            ullamcorper a erat. Duis ut eleifend nulla. Integer ac metus turpis. Nunc congue
            malesuada metus, sed posuere tortor interdum eu. Orci varius natoque penatibus et magnis
            dis parturient montes, nascetur ridiculus mus. Suspendisse sed mollis sem, et porttitor
            orci. Mauris vitae nibh vitae tellus auctor vestibulum. Ut quis quam tortor. Aenean
            magna odio, bibendum sed lorem pellentesque, lobortis malesuada mi.
          </p>

          <p>
            Ut ex nisi, venenatis a nisi non, vulputate tempor mauris. Curabitur porttitor magna at
            placerat fringilla. Mauris fringilla nibh id metus sagittis, at aliquam elit tempor.
            Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Aliquam ipsum dui, elementum viverra porta id, lobortis ut ipsum. Vivamus sed massa eget
            erat aliquam sodales quis vitae erat. Sed consequat sapien sapien, et dictum felis
            eleifend vel.
          </p>
          <p>
            Ut ex nisi, venenatis a nisi non, vulputate tempor mauris. Curabitur porttitor magna at
            placerat fringilla. Mauris fringilla nibh id metus sagittis, at aliquam elit tempor.
            Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Aliquam ipsum dui, elementum viverra porta id, lobortis ut ipsum. Vivamus sed massa eget
            erat aliquam sodales quis vitae erat. Sed consequat sapien sapien, et dictum felis
            eleifend vel.
          </p>
        </Box>
      </Box>

      <Grid
        item
        xs={12}
        mt={7}
        zIndex={1}
        bgcolor="#F7F7F7"
        border={AppSignUpColors.PRIMARY_LIGHT_BORDER}
        borderRadius="10px"
        boxShadow="0px 0px 34px 5px rgba(0,0,0,0.05)"
        p={{
          xs: 2,
          lg: 5.5,
        }}
        mx={{
          xs: 1,
          lg: 5,
        }}
      >
        <Grid container sx={{ display: 'block' }} mb={5}>
          <Grid item textAlign="center">
            <Header text="Whitepaper per E-Mail erhalten" />
          </Grid>
          <Grid
            item
            zIndex={1}
            mt={5}
            sx={{
              maxWidth: {
                xl: '1000px',
              },
            }}
          >
            <HubspotForm
              key={`hubspot_${i18n.language}`}
              portalId="25457524"
              formId={post.signUpFormId}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WhitepaperPostContent;
