// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  urls: {
    api: '/api',
  },
  googleMapsKey: 'AIzaSyCwO0nFapv6U9U1USnxIPLaO68WZY-1T20',
  auth: {
    domain: 'fides-development.eu.auth0.com',
    clientId: 'ecSao0SYwOncOwcsjaWRGKgrLicuZBQI',
  },
  segment: {
    writeKey: '9jC5fHfYN2AMYMGiGJ1ng8YlRSZHAYP5',
  },
};
