import { Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import LinkComponent from '../../../Link/Link';
import { ThemeColors } from '../../../../utils/colors';

interface SolutionsMenuItemPropsType {
  title: string;
  Icon: any;
  href: string;
  darkTheme: boolean;
  onClick?: () => void;
}

const SolutionsMenuItem: FC<SolutionsMenuItemPropsType> = ({
  title,
  Icon,
  href,
  darkTheme,
  onClick,
}) => {
  const color = useMemo(() => (darkTheme ? '#ffffffcf' : ThemeColors.light.PRIMARY), [darkTheme]);

  return (
    <LinkComponent href={href} localized onClick={onClick}>
      <Grid
        item
        container
        spacing={1}
        alignItems="stretch"
        xs={12}
        px={1}
        pt={1}
        pb={1.75}
        borderRadius="10px"
        sx={{
          transition: 'color 300ms ease-in-out',
          color: color,
          '&:hover': {
            opacity: 0.8,
            backgroundColor: 'rgba(0,60,150,0.1)',
          },
        }}
      >
        <Grid item>
          <Icon style={{ width: '1rem', fill: color }} />
        </Grid>
        <Grid item>
          <span style={{ fontWeight: 600 }}>{title}</span>
        </Grid>
      </Grid>
    </LinkComponent>
  );
};

export default SolutionsMenuItem;
