import { builder } from '@builder.io/react';
import {
  BlogPost,
  ModelLink,
  VideoPost,
  WebinarPost,
  WhitepaperPost,
} from 'apps/speedy/src/types/builder-io-types';
import slugify from 'slugify';

function formatData(contentType: string, value: any) {
  try {
    return {
      ...value.data,
      slug:
        contentType +
        '-' +
        slugify(value.data.title, {
          replacement: '-',
          remove: /ä|ö|ü/g,
          lower: true,
          strict: true,
          locale: 'de',
          trim: true,
        }).toLowerCase(),
      type: contentType,
      id: value.id,
      typeLabel: firstLetterToUpperCase(contentType),
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}

function formatBlogPost(contentType: string, value: BlogPost) {
  try {
    return {
      ...value,
      ...value.data,
      slug:
        contentType +
        '-' +
        slugify(value.data.title, {
          replacement: '-',
          remove: /ä|ö|ü/g,
          lower: true,
          strict: true,
          locale: 'de',
          trim: true,
        }).toLowerCase(),
      type: contentType,
      id: value.id,
      typeLabel: 'Blog',
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}

function firstLetterToUpperCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

async function fetchData(contentType: string, data: ModelLink[]) {
  try {
    if (contentType === 'blogArticles') {
      return Promise.all(
        data
          .map((item: ModelLink) => {
            return builder.get('blog-article', {
              query: {
                id: item['article'].id,
              },
            });
          })
          .map(async (content) => {
            const ct = await content;
            return formatBlogPost('blog-article', ct as BlogPost);
          })
      );
    }

    return Promise.all(
      data
        .map((item: ModelLink) => {
          console.log(item[contentType].id);
          return builder.get(contentType, {
            query: {
              id: item[contentType].id,
            },
            locale: 'de',
          });
        })
        .map(async (content) => {
          const ct = await content;

          return formatData(
            contentType,
            ct as {
              id: string;
              lastUpdated: number;
              data: VideoPost | WebinarPost | WhitepaperPost;
            }
          );
        })
    );
  } catch (e) {
    console.error(e);
    return [];
  }
}

async function fetchHighlightData(contentType: string, id: string) {
  try {
    return builder
      .get(contentType, {
        query: {
          id: id,
        },
        locale: 'de',
      })
      .toPromise()
      .then(formatData.bind(null, contentType)) as Promise<
      VideoPost | WebinarPost | WhitepaperPost
    >;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getAllContentInPreview(khData: {
  webinars: ModelLink[];
  videos: ModelLink[];
  blogArticles: ModelLink[];
  whitepapers: ModelLink[];
  highlight: {
    value: VideoPost | WebinarPost | WhitepaperPost;
    model: string;
    id: string;
  };
}) {
  const webinars = await fetchData('webinar', khData.webinars);
  const videos = await fetchData('video', khData.videos);
  const whitepapers = await fetchData('whitepaper', khData.whitepapers);
  const blogArticles = await fetchData('blogArticles', khData.blogArticles);
  const highlightContent = await fetchHighlightData(khData.highlight.model, khData.highlight.id);
  const allContent = [...webinars, ...videos, ...whitepapers, ...blogArticles];
  const allContentFilteredAndSorted = allContent
    .sort((a, b) => {
      if (b && a) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return 0;
    })
    .filter((item) => item !== null);

  return [highlightContent, allContentFilteredAndSorted];
}

export async function getAllContentInLive(khData: {
  webinars: ModelLink[];
  videos: ModelLink[];
  whitepapers: ModelLink[];
  blogArticles: ModelLink[];
  highlight: {
    value: VideoPost | WebinarPost | WhitepaperPost;
    model: string;
    id: string;
  };
}) {
  const webinars = khData.webinars.map((webinar: ModelLink) => {
    return formatData('webinar', webinar.webinar.value as WebinarPost);
  });
  const videos = khData.videos.map((video: ModelLink) => {
    return formatData('video', video.video.value as VideoPost);
  });
  const whitepapers = khData.whitepapers.map((whitepaper: ModelLink) => {
    return formatData('whitepaper', whitepaper.whitepaper.value as WhitepaperPost);
  });
  const blogArticles = khData.blogArticles.map((article: ModelLink) => {
    return formatBlogPost('blog-article', article.article.value as BlogPost);
  });
  const highlightContent = formatData(khData.highlight.model, khData.highlight.value);

  const allContent = [...webinars, ...videos, ...whitepapers, ...blogArticles];

  const allContentFilteredAndSorted = allContent
    .sort((a, b) => {
      if (b && a) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return 0;
    })
    .filter((item) => item !== null);

  return [highlightContent, allContentFilteredAndSorted];
}
