import { FC } from 'react';
import { Grid, SxProps } from '@mui/material';

interface ColumnPropsType {
  rowSpacing?: number;
  sx?: SxProps;
}

const Column: FC<ColumnPropsType> = ({ rowSpacing, children, sx }) => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={10}
      md={6}
      flexDirection={'column'}
      rowSpacing={rowSpacing || 2}
      sx={{
        ...sx,
      }}
      maxWidth={'750px !important'}
    >
      {children}
    </Grid>
  );
};

export default Column;
