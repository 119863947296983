import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { useContext } from 'react';
import { RouteNames } from '../../Routes/routeNames';
import { PageWrapper, Section } from '../../components/layout';
import Row from '../../components/layout/rows/Row';
import Column from '../../components/layout/columns/index.column';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import Header from '../../components/text/Header';
import SubHeader from '../../components/text/SubHeader';
import FeatureHighlight from '../../components/sections/FeatureHighlight.section';
import PlainText from '../../components/text/PlainText';
import HButton from '../../components/buttons/Highlight.button';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';
import FadeSlide from '../../components/layout/container/FadeSlide.container';
import FeatureOverview from '../../components/sections/FeatureOverview.section';
import CallToAction from '../../components/sections/cta/CallToAction.section';
import TestimonialCard from '../../components/Cards/Testimonial.card';
import BGImageSlideContainer from '../../components/layout/container/BGImageSlide.container';
import Timeline from '../../components/Timeline/Timeline';
import CustomTabcard from '../../components/views/FidesFor/CustomFidesForTab.card';
import { ReactComponent as AuditProofDocumentation } from '/assets/images/icons/audit-proof-documentation.svg';
import { ReactComponent as BusinessJudgementRule } from '/assets/images/icons/business-judgement-rule.svg';
import { ReactComponent as SaveOnDOInsurance } from '/assets/images/icons/save-on-d-and-o-insurance.svg';
import { ReactComponent as Folder } from '/assets/images/icons/folder.svg';
import { ReactComponent as Mail } from '/assets/images/icons/mail.svg';
import { ReactComponent as Tablet } from '/assets/images/icons/tablet-mac.svg';
import { ReactComponent as Gathering } from '/assets/images/icons/gathering.svg';
import { ReactComponent as Flag } from '/assets/images/icons/flag.svg';
import { ReactComponent as Robot } from '/assets/images/icons/robot.svg';
import SimpleCallToAction from '../../components/sections/cta/SimpleCTA.section';

const SupervisoryBoardPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  useEffect(() => {
    toggleDarkTheme(false);
  }, [toggleDarkTheme]);

  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);

  const testimonials = [];

  const features = [
    {
      title: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.SECURE_DATA_ROOM.TITLE'),
      text: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.SECURE_DATA_ROOM.TEXT'),
      icon: <Folder />,
    },
    {
      title: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.ENCRYPTED_COMMUNICATION.TITLE'),
      text: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.ENCRYPTED_COMMUNICATION.TEXT'),
      icon: <Mail />,
    },
    {
      title: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.IPAD_APP.TITLE'),
      text: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.IPAD_APP.TEXT'),
      icon: <Tablet />,
    },
    {
      title: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.EFFICIENT_MEETINGS.TITLE'),
      text: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.EFFICIENT_MEETINGS.TEXT'),
      icon: <Gathering />,
    },

    {
      title: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.SIMPLE_AND_DIGITAL_RESOLUTIONS.TITLE'),
      text: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.SIMPLE_AND_DIGITAL_RESOLUTIONS.TEXT'),
      icon: <Flag />,
    },

    {
      title: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.AI_MANAGES_INFORMATION_OVERLOAD.TITLE'),
      text: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.AI_MANAGES_INFORMATION_OVERLOAD.TEXT'),
      icon: <Robot />,
    },
  ];

  const timeline = [
    {
      type: 'person',
      short_line: false,
      image: '/assets/images/timeline/avatars/CFO.webp',
      image_bg_color: '#9FA7EE',
      description_de: 'Aussichtratsvorsitzender',
      description_en: 'Chairman of the Supervisory Board',
      position: {
        bottom: 83,
        left: 4,
      },
    },
    {
      type: 'process',
      short_line: true,
      emoji: '📩',
      description_de: 'Abstimmung zur Agenda',
      description_en: 'Agreement on agenda and invitation',
      position: {
        bottom: 67,
        left: 18,
      },
    },
    {
      type: 'person',
      short_line: false,
      image: '/assets/images/timeline/avatars/board-member.webp',
      image_bg_color: '#B8CDDD',
      description_de: 'Aufsichtsratsmitglieder',
      description_en: 'Supervisory Board Members',
      position: {
        bottom: 56,
        left: 30,
      },
    },
    {
      type: 'process',
      short_line: true,
      emoji: '📄',
      description_de: 'Review und Kommentierung der Unterlagen',
      description_en: 'Review and comments on the documents',
      position: {
        bottom: 37.5,
        left: 40,
      },
    },
    {
      type: 'person',
      short_line: false,
      extend_line: true,
      image: '/assets/images/timeline/avatars/group.webp',
      image_bg_color: 'transparent',
      description_de: 'Sitzung findet statt',
      description_en: 'Meeting takes place',
      position: {
        bottom: 21,
        left: 59,
      },
    },
    {
      type: 'person',
      short_line: false,
      image: '/assets/images/timeline/avatars/Legal-Counsel.webp',
      image_bg_color: '#9FA7EE',
      description_de: 'Corporate Office',
      description_en: 'Corporate Office',
      position: {
        bottom: 20,
        left: 75,
      },
    },
    {
      type: 'process',
      short_line: true,
      emoji: '✅',
      description_de: 'Protokoll Draft und Abstimmung',
      description_en: 'Draft minutes and approval',
      position: {
        bottom: 52,
        left: 80,
      },
    },
    {
      type: 'process',
      short_line: true,
      emoji: '📂',
      description_de: 'Revisionssichere Ablage in Fides',
      description_en: 'Secure and compliant filing in Fides',
      position: {
        bottom: 75,
        left: 88.5,
      },
    },
  ];

  const masterChallanges = [
    {
      title: t(
        'FIDES_FOR_SUPERVISORY_BOARD.FEATURE_DETAILS.FEATURES.AUDIT_PROOF_DOCUMENTATION.LABEL'
      ),
      text: t(
        'FIDES_FOR_SUPERVISORY_BOARD.FEATURE_DETAILS.FEATURES.AUDIT_PROOF_DOCUMENTATION.TEXT'
      ),
      icon: <AuditProofDocumentation />,
    },
    {
      title: t(
        'FIDES_FOR_SUPERVISORY_BOARD.FEATURE_DETAILS.FEATURES.BUSINESS_JUDGEMENT_RULE.LABEL'
      ),
      text: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURE_DETAILS.FEATURES.BUSINESS_JUDGEMENT_RULE.TEXT'),
      icon: <BusinessJudgementRule />,
    },
    {
      title: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURE_DETAILS.FEATURES.SAVE_ON_DO_INSURANCE.LABEL'),
      text: t('FIDES_FOR_SUPERVISORY_BOARD.FEATURE_DETAILS.FEATURES.SAVE_ON_DO_INSURANCE.TEXT'),
      icon: <SaveOnDOInsurance />,
    },
  ];

  const tabs = [
    {
      id: 1,
      title: t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.PREPARATION_TAB.TITLE'),
      before_fides: [
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.PREPARATION_TAB.BEFORE_FIDES_1'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.PREPARATION_TAB.BEFORE_FIDES_2'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.PREPARATION_TAB.BEFORE_FIDES_3'),
      ],
      after_fides: [
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.PREPARATION_TAB.AFTER_FIDES_1'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.PREPARATION_TAB.AFTER_FIDES_2'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.PREPARATION_TAB.AFTER_FIDES_3'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.PREPARATION_TAB.AFTER_FIDES_4'),
      ],
    },
    {
      id: 2,
      title: t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.TITLE'),
      before_fides: [
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.BEFORE_FIDES_1'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.BEFORE_FIDES_2'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.BEFORE_FIDES_3'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.BEFORE_FIDES_4'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.BEFORE_FIDES_5'),
      ],
      after_fides: [
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.AFTER_FIDES_1'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.AFTER_FIDES_2'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.AFTER_FIDES_3'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.AFTER_FIDES_4'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.MINUTES_TAB.AFTER_FIDES_5'),
      ],
    },
    {
      id: 3,
      title: t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.DOCUMENTS_TAB.TITLE'),
      before_fides: [
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.DOCUMENTS_TAB.BEFORE_FIDES_1'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.DOCUMENTS_TAB.BEFORE_FIDES_2'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.DOCUMENTS_TAB.BEFORE_FIDES_3'),
      ],
      after_fides: [
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.DOCUMENTS_TAB.AFTER_FIDES_1'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.DOCUMENTS_TAB.AFTER_FIDES_2'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.DOCUMENTS_TAB.AFTER_FIDES_3'),
        t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.DOCUMENTS_TAB.AFTER_FIDES_4'),
      ],
    },
  ];

  return (
    <PageWrapper>
      <Section topSection fullWidth>
        <Grid
          container
          item
          alignContent="center"
          alignItems="center"
          spacing={7}
          direction="row-reverse"
        >
          <Grid item xs={12} md={6}>
            <img
              src={`/assets/images/fides-for-supervisory-board/hero/${i18n.language}.webp`}
              alt="Hero Fides For Counsels"
              style={{
                width: '100%',
                maxHeight: '550px',
                objectFit: 'contain',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item container rowSpacing={3}>
              <Title
                text={t('FIDES_FOR_SUPERVISORY_BOARD.HERO.TITLE')}
                mainTitle
                darkTheme={darkTheme}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 30,
                    lg: 40,
                    xl: 48,
                  },
                  mb: 2,
                }}
              />
              <PlainText
                text={t('FIDES_FOR_SUPERVISORY_BOARD.HERO.TEXT')}
                darkTheme={darkTheme}
                sx={{ pr: 6 }}
              />
              <Grid item xs={12} onClick={() => setShowContactPopup(true)}>
                <HButton size="large" sx={{ px: 5 }} darkTheme={true}>
                  {t('GENERAL.BOOK_PERSONAL_MEETING')}
                </HButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <Grid
        container
        display={{
          xs: 'none',
          md: 'flex',
        }}
      >
        <Section spacing="xl">
          <Grid item>
            <Title
              text={t('FIDES_FOR_SUPERVISORY_BOARD.TIMELINE.TITLE')}
              darkTheme={darkTheme}
              center
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <SubHeader
              text={t('FIDES_FOR_SUPERVISORY_BOARD.TIMELINE.SUBHEADER_1')}
              darkTheme={darkTheme}
              sx={{
                width: {
                  xs: '100%',
                  md: '60%',
                },
                textAlign: {
                  xs: 'left',
                  md: 'right',
                },
              }}
            />
          </Grid>
        </Section>
        <Timeline timeline={timeline} />
        <Section sx={{ mb: 10 }}>
          <Grid item container justifyContent="flex-start">
            <SubHeader
              text={t('FIDES_FOR_SUPERVISORY_BOARD.TIMELINE.SUBHEADER_2')}
              darkTheme={darkTheme}
              sx={{
                width: {
                  xs: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '36%',
                },
                textAlign: {
                  xs: 'left',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} onClick={() => setShowContactPopup(true)}>
            <HButton size="large" sx={{ px: 5 }} darkTheme={true}>
              {t('GENERAL.BOOK_PERSONAL_MEETING')}
            </HButton>
          </Grid>
        </Section>
      </Grid>

      {/* <TestimonialCard
        testimonials={testimonials}
        darkTheme={darkTheme}
        bgcolorWrapper="#F3F3F3"
        bgcolor="white"
      /> */}

      <Section>
        <Grid container item justifyContent="center" textAlign="center" rowSpacing={1}>
          <Grid item>
            <Header text={t('FIDES_FOR_SUPERVISORY_BOARD.TAB_CARD.TITLE')} darkTheme={false} />
          </Grid>
        </Grid>
        <CustomTabcard tabs={tabs} firstTab={1} darkTheme={darkTheme} />
      </Section>

      <FeatureOverview
        title={t('FIDES_FOR_SUPERVISORY_BOARD.FEATURES.TITLE')}
        features={features}
      />

      <Section
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="right"
            darkTheme={darkTheme}
            imageUrl={
              '/assets/images/fides-for-supervisory-board/ai-features/' + i18n.language + '.webp'
            }
            transitionDelay={400}
            once={true}
            noBackground
            contain
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header
                text={t('FIDES_AI.FEATURE_SECTIONS.FIND_DOCUMENTS_IMMEDIATELY.TITLE')}
                darkTheme={darkTheme}
              />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_AI.FEATURE_SECTIONS.FIND_DOCUMENTS_IMMEDIATELY.TEXT')}
              />
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <Section>
        <Column rowSpacing={3}>
          <BGImageSlideContainer
            direction="left"
            darkTheme={darkTheme}
            imageUrl="/assets/images/fides-for-supervisory-board/work-anywhere.webp"
            transitionDelay={400}
            once={true}
            noBackground
          />
        </Column>
        <Column>
          <FadeSlide sx={{ px: { xs: 'unset', lg: 4 } }} direction="up" rowSpacing={3} once>
            <Grid item xs={12}>
              <Header text={t('FIDES_FOR_SUPERVISORY_BOARD.AI.TITLE')} darkTheme={darkTheme} />
            </Grid>
            <Grid item>
              <PlainText
                darkTheme={darkTheme}
                text={t('FIDES_FOR_SUPERVISORY_BOARD.AI.SUBTITLE')}
              />
            </Grid>
            <Grid item>
              <HButton size="large" sx={{ px: 5 }} darkTheme={true} href={RouteNames.ai}>
                {t('FIDES_FOR_SUPERVISORY_BOARD.AI.BUTTON_TEXT')}
              </HButton>
            </Grid>
          </FadeSlide>
        </Column>
      </Section>

      <FeatureHighlight
        title={t('FIDES_AI.SECURE_FEATURES.TITLE')}
        features={masterChallanges}
        darkTheme={darkTheme}
      />

      {/* <TestimonialCard
        testimonials={testimonials}
        darkTheme={darkTheme}
        bgcolorWrapper="#F3F3F3"
        bgcolor="white"
      /> */}

      <SimpleCallToAction
        header={t('FIDES_FOR_SUPERVISORY_BOARD.SECURITY_CTA.TITLE')}
        link={RouteNames.security}
        buttonText={t('FIDES_FOR_SUPERVISORY_BOARD.SECURITY_CTA.BUTTON_TEXT')}
      />

      <CallToAction
        header={t('FIDES_FOR_COUNSELS.CTA.TITLE')}
        subHeader={t('FIDES_FOR_COUNSELS.CTA.SUBTITLE')}
        link={() => setShowContactPopup(true)}
        buttonText={t('GENERAL.BOOK_PERSONAL_MEETING')}
      />

      {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}

      <Row sx={{ mt: 3 }}>
        <Footer hideCalendar />
      </Row>
    </PageWrapper>
  );
};

export default SupervisoryBoardPage;
