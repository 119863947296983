import { Box } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigateToDummyConfirmation } from './useNavigateToDummyConfirmation';

const BookDemoHubspot = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const breakpointHeights = {
    xs: '900px',
    sm: '1100px',
    md: '1100px',
    lg: '700px',
  };

  useNavigateToDummyConfirmation();

  return (
    <Box
      id="schedule_form"
      className="meetings-iframe-container"
      sx={{
        height: breakpointHeights,
        maxHeight: breakpointHeights,
        minHeight: breakpointHeights,
        width: '100%',
        overflow: 'hidden !important',
      }}
    >
      <iframe
        key={language}
        title="hubspotMeeting"
        style={{
          border: 'none',
          display: 'inline',
          width: '100%',
          height: '100%',
        }}
        src={
          language === 'de'
            ? 'https://meetings-eu1.hubspot.com/meetings/fides-technology/website-meeting?embed=true'
            : 'https://meetings-eu1.hubspot.com/meetings/fides-technology/website-meeting-en?embed=true'
        }
      />
    </Box>
  );
};

export default memo(BookDemoHubspot);
