import useDeviceType from '@fides-jam/utils/device-type';

import { FC } from 'react';
import { SxProps } from '@mui/material';
import { Layer } from '@fides-jam/front/components/Layer';
import { ThemeColors } from '../../utils/colors';
import * as React from 'react';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';

interface AnimationBGLayerPropsType {
  sx?: SxProps;
  rowSpacing?: number;
  columnSpacing?: number;
  flexDirection?: 'row' | 'row-reverse';
}

const AnimationBGLayer: FC<AnimationBGLayerPropsType> = ({ sx, children }) => {
  const device = useDeviceType();
  return (
    <Layer
      sx={{
        top: '-200px',
        right: '-200px',
        bottom: '-200px',
        left: '-200px',
        padding: '200px',
        zIndex: 0,
      }}
    >
      <div
        style={{
          background: device.iPhone ? AppSignUpColors.PRIMARY : ThemeColors.light.PRIMARY,
          borderRadius: '50%',
          WebkitFilter: 'blur(60px)',
          filter: 'blur(60px)',
          opacity: device.iPhone ? 0.4 : 0.2,
          overflow: 'visible',
          width: '100%',
          height: '100%',
        }}
      />
    </Layer>
  );
};

export default AnimationBGLayer;
