import { FC } from 'react';
import { Grid } from '@mui/material';
import { ThemeColors } from '../../../utils/colors';
import PlainText from '../../text/PlainText';
import LinkText from '../../text/Link';

interface BannerType {
  id: string;
  language: string[];
  text: string;
  linkText?: string;
  linkUrl?: string;
}

interface BannerViewPropsType {
  darkTheme?: boolean;
  banner: BannerType;
  hideBanner: (bannerId: string) => void;
}

const Banner: FC<BannerViewPropsType> = ({ darkTheme, banner, hideBanner }) => {
  return (
    <Grid
      container
      sx={{
        background: ThemeColors.dark.PRIMARY,
        py: 1.5,
        px: 4,
        position: 'relative',
        textAlign: 'center',
        borderBottom: '1px solid ' + (darkTheme ? 'rgba(0,0,0, 0.2)' : 'rgba(0,0,0, 0.05)'),
      }}
      width="100vw"
      justifyContent="center"
      gap={1}
    >
      <Grid item>
        <PlainText text={banner.text} opacity />
      </Grid>
      {banner.linkText && (
        <>
          <Grid item>
            <PlainText text="-" opacity />
          </Grid>
          <Grid item>
            <LinkText text={banner.linkText} href={banner.linkUrl} />
          </Grid>
        </>
      )}

      <Grid
        item
        sx={{
          position: 'absolute',
          right: '20px',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={() => hideBanner(banner.id)}
      >
        &times;
      </Grid>
    </Grid>
  );
};

export default Banner;
