import {AppColors, AppSignUpColors, iOSColors} from './colors';
import { createTheme } from '@mui/material';

const themeTypography = {
  fontFamily: [
    'Inter',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: 16,
  h3: {
    fontWeight: 700,
    fontSize: '2.2rem',
  },
  h4: {
    fontWeight: 700,
    fontSize: '1.75rem',
  },
  h5: {
    fontWeight: 500,
  },
  h6: {
    fontWeight: 500,
  },
};

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    tiny: true;
  }
}

export const themeSignUp = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: AppSignUpColors.PRIMARY,
      dark: AppSignUpColors.PRIMARY_DARK,
      light: AppSignUpColors.PRIMARY_LIGHTER,
    },
    secondary: {
      main: AppSignUpColors.SECONDARY,
      light: AppSignUpColors.SECONDARY_LIGHT,
    },
    success: {
      main: iOSColors.green,
      contrastText:'#FFF',
    },
    error: {
      main: iOSColors.red,
    },
    warning: {
      main: iOSColors.red,
    },
    info: {
      main: '#fff',
      light: AppSignUpColors.PRIMARY_LIGHTEST,
      dark: AppSignUpColors.PRIMARY_LIGHTEST,
    },
    text: {
      primary: AppSignUpColors.PRIMARY,
      secondary: AppSignUpColors.SECONDARY,
    },
    background: {
      default: AppSignUpColors.BACKGROUND_DEFAULT,
      paper: AppSignUpColors.BACKGROUND_PAPER,
    },
  },
  typography: {
    ...themeTypography,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'unset',
        }
      },
      variants: [
        {
          props: { size: "tiny" },
          style: {
            fontSize: 12,
            padding: "4px 2px",
            fontWeight: '700',
          }
        }
      ]
    },
  },
});

export const themeMain = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: AppColors.PRIMARY,
      dark: AppColors.PRIMARY_DARK,
    },
    secondary: {
      main: AppColors.SECONDARY,
      light: AppColors.SECONDARY_LIGHT,
    },
    background: {
      default: AppColors.BACKGROUND_DEFAULT,
      paper: AppColors.BACKGROUND_PAPER,
    },

    text: {
      primary: AppColors.TEXT_PRIMARY,
      secondary: AppColors.TEXT_SECONDARY,
    },
    success: {
      main: AppColors.SUCCESS,
    },
    error: {
      main: AppColors.ERROR,
    },
    info: {
      main: AppColors.INFO,
    },
  },
  typography: {
    ...themeTypography,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'black',
          color: 'white',
        },
      },
    },
  },
});
