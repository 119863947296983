import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { FC, useEffect } from 'react';
import { SxProps } from '@mui/material';
import { ThemeColors } from '../../utils/colors';
import LinkComponent from '../Link/Link';

const BlueButton = styled(Button)<ButtonProps>(({ theme, fullWidth }) => ({
  backgroundColor: 'transparent !important',
  background: 'transparent !important',
  color: ThemeColors.light.PRIMARY,
  textDecoration: 'none !important',
  textTransform: 'none',
  boxShadow: 'none',
  fontWeight: '600',
  width: fullWidth ? '100%' : 'unset',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: '#000',
  },
  '&:active': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: '#000',
  },
}));

const GreenButton = styled(Button)<ButtonProps>(({ theme, fullWidth }) => ({
  backgroundColor: 'transparent !important',
  background: 'transparent !important',
  color: ThemeColors.dark.PRIMARY,
  textDecoration: 'none !important',
  textTransform: 'none',
  boxShadow: 'none',
  fontWeight: '600',
  width: fullWidth ? '100%' : 'unset',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    //color:ThemeColors.dark.PRIMARY,
    color: ThemeColors.dark.PRIMARY_COMPLEMENT,
  },
  '&:active': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    //color:ThemeColors.dark.PRIMARY,
    color: ThemeColors.dark.PRIMARY_COMPLEMENT,
  },
}));

interface HButtonPropsType {
  id?: string;
  darkTheme?: boolean;
  size?: 'large' | 'small' | 'medium';
  href?: string;
  localized?: boolean;
  sx?: SxProps;
  fullWidth?: boolean;
  onMouseEnter?: () => void;
  onClick?: () => void;
}

const HButton: FC<HButtonPropsType> = ({
  id,
  darkTheme,
  href,
  fullWidth,
  size,
  localized = true,
  sx,
  children,
  onMouseEnter,
  onClick,
}) => {
  useEffect(() => {
    const preventPropagation = (e: any) => {
      e.stopPropagation();
      return false;
    };
    const element = document.getElementById(`NavBarButtonAnchor_${id}`);
    element?.removeEventListener('touchstart', preventPropagation);
    element?.addEventListener('touchstart', preventPropagation, { passive: false });
    return () => element?.removeEventListener('touchstart', preventPropagation);
  }, [id]);

  const ButtonComponent = darkTheme ? GreenButton : BlueButton;

  const button = (
    <ButtonComponent
      variant="contained"
      fullWidth={fullWidth}
      size={size}
      sx={{ ...sx, px: '19px' }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </ButtonComponent>
  );

  return href ? (
    <LinkComponent
      href={href}
      onClick={onClick}
      id={`NavBarButtonAnchor_${id}`}
      localized={localized}
      fullWidth={fullWidth}
    >
      {button}
    </LinkComponent>
  ) : (
    button
  );
};

export default HButton;
