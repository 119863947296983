import { FC } from 'react';
import { Grid } from '@mui/material';
import { AppColors } from '@fides-jam/utils/theme/colors';
import { Section } from '../layout';
import Title from '../../components/text/Title';
import PlainText from '../text/PlainText';
import HButton from '../buttons/Highlight.button';
import { ReactComponent as ArrowTop } from '/assets/images/icons/arrow-top.svg';

interface FeatureHighlightPropsType {
  title: string;
  features: { title: string; text: string; icon: JSX.Element }[];
  buttonText?: string;
  buttonLink?: string;
  darkTheme?: boolean;
  small?: boolean;
}

const FeatureHighlight: FC<FeatureHighlightPropsType> = ({
  title,
  features,
  buttonText,
  buttonLink,
  darkTheme,
  small,
}) => {
  return (
    <Section bgcolor={AppColors.LIGHT_GREEN} spacing="none">
      <Grid container item xs={12} justifyContent="center" mb={10} md={11}>
        <Title text={title} darkTheme={darkTheme} center />
      </Grid>
      <Grid container item xs={12} md={10} rowGap={8} justifyContent="center">
        {features.map((item) => (
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={4}
            justifyContent="center"
            alignContent="flex-start"
            flexDirection="row"
            rowGap={3}
            key={item.title}
          >
            <Grid container item xs={12} justifyContent="center">
              <Grid
                container
                item
                bgcolor="#9561A910"
                alignItems="center"
                px={3.5}
                py={0.5}
                width="auto"
                borderRadius="25px"
                mx={1}
                textAlign="center"
                minHeight={35}
                mb={1}
                wrap="nowrap"
              >
                <Grid item width="35px" pr={2} flexShrink={0}>
                  <ArrowTop />
                </Grid>
                <Grid item>
                  <PlainText
                    text={item.title}
                    opacity
                    darkTheme={darkTheme}
                    bold
                    sx={{
                      fontSize: '0.8rem',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              height="90px"
              color="#17496A"
            >
              <Grid item xs={6} height={small ? '70px' : '100%'}>
                {item.icon}
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Grid item xs={11}>
                <PlainText
                  text={item.text}
                  sx={{
                    textAlign: 'center',
                  }}
                  darkTheme={darkTheme}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {buttonText && buttonLink && (
        <Grid container item xs={12} justifyContent="center" mt={6}>
          <HButton size="large" sx={{ px: 5 }} darkTheme={true} href={buttonLink}>
            {buttonText}
          </HButton>
        </Grid>
      )}
    </Section>
  );
};

export default FeatureHighlight;
