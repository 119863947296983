import { Box, Grid } from '@mui/material';
import { FC } from 'react';

interface TestimonialColumnPropsType {
  darkTheme?: boolean;
  backgroundImage: string;
  index: number;
}

const TestimonialColumn: FC<TestimonialColumnPropsType> = ({
  darkTheme,
  backgroundImage,
  index,
  children,
}) => {
  return (
    <Grid container item xs={4} sm={4} md={3} lg={2}>
      <Box
        sx={{
          width: '100%',
          paddingBottom: '40%',
          background: darkTheme ? 'rgba(0,0,0,0.02)' : 'rgba(255,255,255,0.02)',
          border: `2px solid ${darkTheme ? 'rgba(0,0,0,0.02)' : 'rgba(255,255,255,0.01)'}`,
          borderRadius: '10px',
          backgroundImage: backgroundImage,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '80%',
          backgroundPosition: 'center',
          filter: darkTheme ? 'brightness(0) invert(1)' : 'unset',
          transition: 'filter 0.5s ease-in-out',
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

export default TestimonialColumn;
