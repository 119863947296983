import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Row from '../../components/layout/rows/Row';
import Title from '../../components/text/Title';
import Footer from '../../components/views/Footer';
import SubTitle from '../../components/text/SubTitle';
import Header from '../../components/text/Header';
import PlainText from '../../components/text/PlainText';
import Logo from '../../components/logos/Logo';
import HubspotForm from 'react-hubspot-form';
import { DarkThemeContext } from '../../components/Context/ThemeContext';
import { PageWrapper, Section } from '../../components/layout';
import { UpcomingCocEvent } from '../../../types/builder-io-types';
import CallToAction from '../../components/sections/cta/CallToAction.section';
import { BookDemoPopup } from '../../components/popup/BookDemo.popup';

interface CircleOfCounselsDetailPagePropsType {
  cocEvent: UpcomingCocEvent;
}

const CircleOfCounselsDetailPage: FC<CircleOfCounselsDetailPagePropsType> = ({ cocEvent }) => {
  const { t, i18n } = useTranslation();
  const { darkTheme, toggleDarkTheme } = useContext(DarkThemeContext);
  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);

  useEffect(() => {
    toggleDarkTheme(true);
  }, [toggleDarkTheme]);

  return (
    <>
      <Helmet>
        <title>{`Circle of Counsels - ${cocEvent.cityName} Edition`}</title>
        <meta
          data-rh="true"
          data-react-helmet="true"
          property="og:title"
          content={`Circle of Counsels - ${cocEvent.cityName} Edition`}
        />
        <meta
          data-rh="true"
          data-react-helmet="true"
          name="title"
          content={`Circle of Counsels - ${cocEvent.cityName} Edition`}
        />

        <meta
          data-rh="true"
          data-react-helmet="true"
          name="description"
          content={cocEvent.detailedDescription?.[0]?.text ?? ''}
        />
        <meta
          data-rh="true"
          data-react-helmet="true"
          property="og:description"
          content={cocEvent.detailedDescription?.[0]?.text ?? ''}
        />

        <meta data-rh="true" data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-rh="true"
          data-react-helmet="true"
          property="og:image"
          content={cocEvent.detailPageImage}
        />

        {/* <meta property="og:url" content="https://fides.technology/" /> */}
      </Helmet>
      <PageWrapper>
        <Section>
          <Grid item xs={12} md={6}>
            <Grid item>
              <Title
                text={t('CIRCLE_OF_COUNSELS.JOIN.TITLE')}
                darkTheme={darkTheme}
                mainTitle
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 30,
                    lg: 40,
                    xl: 48,
                  },
                  mb: 2,
                }}
              />
              <SubTitle
                text={t('CIRCLE_OF_COUNSELS.JOIN.SUBTITLE')}
                sx={{
                  fontSize: {
                    xs: 30,
                    sm: 30,
                    lg: 30,
                    xl: 38,
                  },
                  mb: 1,
                }}
                darkTheme={darkTheme}
              />

              <Grid container alignItems="center" gap={3} sx={{ opacity: '0.6' }} mt={1}>
                <SubTitle
                  text={t('CIRCLE_OF_COUNSELS.JOIN.HOSTED_BY')}
                  sx={{
                    fontSize: {
                      xs: 30,
                      sm: 30,
                      lg: 30,
                      xl: 38,
                    },
                  }}
                  darkTheme={darkTheme}
                />
                <Logo darkTheme={darkTheme} height="50px" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <img
              src={cocEvent.detailPageImage}
              alt="Cirlce of Counsels"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '2rem',
              }}
            />
          </Grid>
        </Section>
        <Section>
          <Grid
            item
            md={9}
            sx={{
              textAlign: 'center',
            }}
          >
            <Header
              text={
                t('CIRCLE_OF_COUNSELS.DETAIL_PAGE.MIDDLE_SECTION_TITLE_1') +
                ' ' +
                cocEvent.cityName +
                ' ' +
                t('CIRCLE_OF_COUNSELS.DETAIL_PAGE.MIDDLE_SECTION_TITLE_2')
              }
              darkTheme={darkTheme}
            />
          </Grid>
          <Grid container item spacing={5}>
            <Grid item container xs={12} md={6}>
              <Grid item>
                <img
                  src={cocEvent.image}
                  alt="Cirlce of Counsels"
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '2rem',
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} md={6} alignContent="flex-start" rowSpacing={3}>
              {cocEvent.detailedDescription &&
                cocEvent.detailedDescription?.map((description) => (
                  <Grid item key={description.text}>
                    <PlainText darkTheme={darkTheme} text={description.text} />
                  </Grid>
                ))}
              <Grid item>
                <PlainText
                  darkTheme={darkTheme}
                  italic
                  text={t('CIRCLE_OF_COUNSELS.DETAIL_PAGE.VIDEO_INFO')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Section>
        <Section id="signup">
          <Grid container item justifyContent="center">
            <Grid
              container
              item
              bgcolor="white"
              borderRadius={4}
              border="1px solid #EDEDED"
              xs={12}
              sx={{
                px: {
                  xs: 5,
                  md: 8,
                },
                py: {
                  xs: 5,
                  md: 8,
                },
              }}
            >
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Header
                    sx={{
                      mb: '20px',
                    }}
                    text={
                      t('CIRCLE_OF_COUNSELS.DETAIL_PAGE.FORM_TITLE_1') +
                      ' ' +
                      cocEvent.cityName +
                      ' ' +
                      t('CIRCLE_OF_COUNSELS.DETAIL_PAGE.FORM_TITLE_2')
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <HubspotForm
                    key={`hubspot_${i18n.language}`}
                    portalId="25457524"
                    formId={cocEvent.signUpFormId}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Section>
        <CallToAction
          header={t('CIRCLE_OF_COUNSELS.DETAIL_PAGE.CTA.TITLE')}
          subHeader={t('CIRCLE_OF_COUNSELS.DETAIL_PAGE.CTA.SUBTITLE')}
          link={() => setShowContactPopup(true)}
          buttonText={t('GENERAL.BOOK_MEETING')}
        />
        {showContactPopup && <BookDemoPopup setShowContactPopup={setShowContactPopup} />}9
        <Row sx={{ mt: 3 }}>
          <Footer hideCalendar />
        </Row>
      </PageWrapper>
    </>
  );
};

export default CircleOfCounselsDetailPage;
