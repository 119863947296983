import { Grid, Box } from '@mui/material';
import FadeSlide from '../layout/container/FadeSlide.container';
import { useTranslation } from 'react-i18next';

interface TimelineItem {
  type: string;
  image?: string;
  extend_line?: boolean;
  image_bg_color?: string;
  short_line: boolean;
  emoji?: string;
  description_de: string;
  description_en: string;
  position: {
    bottom: number;
    left: number;
  };
}

const Timeline = ({ timeline }: { timeline: TimelineItem[] }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Grid container item height="210px">
      <Box
        style={{
          position: 'relative',
          maxWidth: '1800px',
          width: '100vw',
          margin: 'auto',
        }}
      >
        <img
          src="/assets/images/timeline/background.svg"
          alt="Timeline Background"
          width="100%"
          style={{
            transform: 'scale(1.05)',
          }}
        />
        {timeline.map((item, index) => {
          if (item.type === 'person') {
            return (
              <Box
                style={{
                  left: item.position.left + '%',
                  bottom: item.position.bottom + '%',
                  position: 'absolute',
                }}
                key={item.description_en}
              >
                <FadeSlide
                  direction="up"
                  transitionDelay={index * 125}
                  once
                  sx={{ flexDirection: 'column' }}
                >
                  <Box
                    style={{
                      display: 'inline-block',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.description_en}
                      style={
                        item.extend_line
                          ? {
                              width: '100%',
                              height: '120px',
                              paddingBottom: '20px',
                              backgroundColor: item.image_bg_color,
                            }
                          : {
                              width: '98px',
                              height: '98px',
                              objectFit: 'cover',
                              backgroundColor: item.image_bg_color,
                              borderRadius: '50%',
                            }
                      }
                    />

                    <Box
                      style={{
                        height: '44px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        borderRadius: '40px',
                        whiteSpace: 'nowrap',
                        color: '#4b4a66',
                        background: '#fff',
                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 19px 5px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '12px',
                        fontWeight: '800',
                        lineHeight: '24px',
                        display: 'flex',
                        position: 'absolute',
                        bottom: '0',
                        left: '50px',
                        textAlign: 'center',
                      }}
                    >
                      <Box>{language === 'de' ? item.description_de : item.description_en}</Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100px"
                    style={
                      item.short_line
                        ? {
                            marginTop: '6px',
                            paddingTop: '10px',
                            height: '65px',
                            overflow: 'hidden',
                          }
                        : {}
                    }
                  >
                    <img src="/assets/images/timeline/long-line.svg" alt="Line" />
                  </Box>
                </FadeSlide>
              </Box>
            );
          } else {
            return (
              <Box
                style={{
                  left: item.position.left + '%',
                  bottom: item.position.bottom + '%',
                  position: 'absolute',
                }}
                key={item.description_en}
              >
                <FadeSlide
                  direction="up"
                  transitionDelay={index * 125}
                  once
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box
                    style={{
                      background: 'white',
                      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 19px 5px',
                      width: '78px',
                      height: '78px',
                      borderRadius: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '2px',
                      display: 'flex',
                      position: 'relative',
                    }}
                  >
                    <Box
                      style={{
                        fontSize: '35px',
                      }}
                    >
                      {item.emoji}
                    </Box>
                  </Box>
                  <Box
                    style={{
                      paddingBottom: '4px',
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '22px',
                      textAlign: 'center',
                    }}
                  >
                    {language === 'de' ? item.description_de : item.description_en}
                  </Box>
                  <Box
                    style={
                      item.short_line
                        ? {
                            height: '60px',
                          }
                        : {}
                    }
                  >
                    <img src="/assets/images/timeline/short-line.svg" loading="eager" alt="Line" />
                  </Box>
                </FadeSlide>
              </Box>
            );
          }
        })}
      </Box>
    </Grid>
  );
};

export default Timeline;
