import { FC } from 'react';
import { Box, Grid, SxProps, useTheme } from '@mui/material';
import { dark, light } from '@fides-jam/front/components/widgets/Widget/themes';
import Tag from '@fides-jam/front/components/Tag';
import TruncatedText from '@fides-jam/front/components/textWrappers/TruncatedText';
import SubHeader from '../../text/SubHeader';
import { PostType } from '../../../../types/builder-io-types';

interface PostCardPropsType {
  post: PostType;
  sx?: SxProps;
  darkTheme?: boolean;
  onClick?: () => void;
}

const PostCard: FC<PostCardPropsType> = ({ post, sx, darkTheme, onClick }) => {
  const theme = useTheme();
  const styles = darkTheme ? dark : light;
  const { containerStyle, hoverStyle } = styles;
  const rowStyles = onClick ? { ...containerStyle, ...hoverStyle } : containerStyle;
  return (
    <Grid
      container
      direction="column"
      onClick={onClick}
      sx={{
        boxShadow: '0px 0px 58px -4px rgba(230,230,242,0.0)',
        borderRadius: '10px',
        background: '#F7F7F7',
        alignItems: 'stretch',
        zIndex: 1,
        cursor: onClick ? 'pointer' : 'auto',
        ...rowStyles,
        ...sx,
      }}
    >
      <Grid
        sx={{
          paddingBottom: '48%',
          background: 'rgba(230,230,242,0.2)',
          backgroundImage: `url(${post.previewThumbnail || post.thumbnail})`,
          backgroundSize: 'cover',
          borderRadius: '10px 10px 0 0',
          backgroundPosition: 'center center',
        }}
      />

      <Grid container p={2}>
        <Grid
          container
          xs={12}
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs="auto">
            <SubHeader
              text={post.typeLabel}
              sx={{
                fontSize: '.9rem',
                opacity: 0.8,
              }}
            />
          </Grid>
          <Grid container item xs="auto" py={0.5}>
            {post.tags?.map((t: string) => (
              <Tag text={t} color="#DEE4F7" textColor="#000" key={t} />
            ))}
          </Grid>
        </Grid>

        <Box sx={{ mt: 1 }}>
          <TruncatedText maxLines={3} noWordBreak>
            <strong>{post.title}</strong>
          </TruncatedText>
        </Box>
        <Box sx={{ mt: 1, whiteSpace: 'wrap' }}>
          <TruncatedText maxLines={5} noWordBreak>
            <small style={{ color: theme.palette.grey[600] }}>{post.excerpt}</small>
          </TruncatedText>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PostCard;
