import { FC, useMemo } from 'react';
import { Link } from '@mui/material';
import * as React from 'react';
import { ThemeColors } from '../../../utils/colors';
import LinkComponent from '../../Link/Link';

interface FooterAnchorPropsType {
  text: string;
  darkTheme?: boolean;
  href?: string;
  localized?: boolean;
}

const FooterAnchor: FC<FooterAnchorPropsType> = ({ text, href, localized = true, darkTheme }) => {
  const color = useMemo(() => (darkTheme ? '#fff' : '#000'), [darkTheme]);
  const highlightColor = useMemo(
    () => (darkTheme ? ThemeColors.dark.PRIMARY : ThemeColors.light.PRIMARY),
    [darkTheme]
  );

  return (
    <LinkComponent href={href} localized={localized}>
      <Link
        underline="hover"
        sx={{
          transition: 'color 0.3s ease-in-out',
          cursor: 'pointer',
          color: color,
          '&:hover': {
            color: highlightColor,
          },
        }}
      >
        {text}
      </Link>
    </LinkComponent>
  );
};

export default FooterAnchor;
