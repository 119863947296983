import { Grid } from '@mui/material';
import { useState } from 'react';
import PlainText from '../../../components/text/PlainText';
import SubHeader from '../../../components/text/SubHeader';

interface FeatureScreenshotsSectionPropsType {
  featureScreenshots: {
    id: string;
    title: string;
    text: string;
    active: boolean;
    img: string;
  }[];
  type: string;
}

const FeatureScreenshotsSection = ({
  featureScreenshots,
  type,
}: FeatureScreenshotsSectionPropsType) => {
  const [selectedItem, setSelectedItem] = useState<string>('INFORMATION_MANAGEMENT');

  if (type === 'mobile') {
    return (
      <>
        <Grid item container zIndex={1} gap={1} overflow="scroll" flexWrap="nowrap" mb={1}>
          {featureScreenshots.map((item) => {
            const itemActive = selectedItem === item.id;

            return (
              <Grid
                item
                container
                sx={{
                  cursor: 'pointer',
                  transition: 'background 0.1s ease-in-out',
                  background: itemActive ? '#10182810' : 'transparent',
                  '&:hover': {
                    background: '#10182810',
                  },
                }}
                key={item.id}
                onClick={() => {
                  setSelectedItem(item.id);
                }}
                boxShadow={itemActive ? 'inset 0 0 0 1px #10182820' : 'unset'}
                px={4}
                py={3.1}
                mb={1.5}
                borderRadius="0.75rem"
                width="fit-content"
              >
                <Grid item>
                  <SubHeader
                    text={item.title}
                    darkTheme={false}
                    sx={{ opacity: 0.95, whiteSpace: 'nowrap' }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} px={3} pb={3}>
          <PlainText
            darkTheme={false}
            opacity
            text={featureScreenshots.find((item) => item.id === selectedItem)?.text}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container zIndex={1} px={2}>
            <img
              src={featureScreenshots.find((item) => item.id === selectedItem)?.img}
              alt="feature"
              width="100%"
              height="100%"
              style={{
                minWidth: '400px',
                borderRadius: '0.75rem',
                border: '1px solid #10182810',
                zIndex: 100,
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid
          item
          container
          xs={12}
          width="100%"
          justifyContent="flex-end"
          zIndex={1}
          rowGap={0.75}
          md={5.35}
          alignItems="center"
        >
          {featureScreenshots.map((item) => {
            const itemActive = selectedItem === item.id;

            return (
              <Grid
                item
                container
                sx={{
                  cursor: 'pointer',
                  transition: 'background 0.1s ease-in-out',
                  background: itemActive ? '#10182810' : 'transparent',
                  '&:hover': {
                    background: '#10182810',
                  },
                }}
                key={item.id}
                onClick={() => {
                  setSelectedItem(item.id);
                }}
                flexDirection="column"
                maxWidth="530px"
                boxShadow={itemActive ? 'inset 0 0 0 1px #10182820' : 'unset'}
                mr={-1}
                px={4}
                py={3.1}
                borderRadius="0.75rem 0rem 0rem 0.75rem"
                height="fit-content"
              >
                <Grid item mb={1}>
                  <SubHeader text={item.title} darkTheme={false} sx={{ opacity: 0.95 }} />
                </Grid>
                {itemActive && (
                  <Grid item>
                    <PlainText darkTheme={false} text={item.text} sx={{ opacity: 0.6 }} />
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} md={6.65} width="100%" flexShrink={0} position="relative">
          <Grid container zIndex={1}>
            <img
              src={featureScreenshots.find((item) => item.id === selectedItem)?.img}
              alt="feature"
              width="100%"
              height="100%"
              style={{
                minWidth: '74rem',
                borderRadius: '0.75rem',
                border: '1px solid rgb(201 201 201)',
                zIndex: 100,
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
};

export default FeatureScreenshotsSection;
