import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

interface SubTitlePropsType {
  text: string;
  darkTheme?: boolean;
  center?: boolean;
  sx?: SxProps;
}

const SubTitle: FC<SubTitlePropsType> = ({ text, darkTheme, center, sx }) => {
  return (
    <Box
      component="h2"
      sx={{
        fontSize: {
          xs: 16,
          sm: 20,
          lg: 22,
        },
        fontWeight: '500',
        lineHeight: 1.4,
        opacity: 0.7,
        transition: 'color 500ms ease-in-out',
        WebkitTransition: 'color 500ms ease-in-out',
        MsTransition: 'color 500ms ease-in-out',
        MozTransition: 'color 500ms ease-in-out',
        OTransition: 'color 500ms ease-in-out',
        transitionDuration: '500ms',
        WebkitTransitionDuration: '500ms',
        MsTransitionDuration: '500ms',
        MozTransitionDuration: '500ms',
        OTransitionDuration: '500ms',
        color: darkTheme ? '#FFF' : '#000',
        textAlign: center ? 'center' : 'inherit',
        //color:darkTheme?'#FFF':ThemeColors.light.TITLE,
        ...sx,
      }}
    >
      {text}
    </Box>
  );
};

export default SubTitle;
