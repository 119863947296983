import { FC, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import FadeSlide from './FadeSlide.container';
import { AppSignUpColors, iOSColors } from '@fides-jam/utils/theme/colors';

interface FadeSlidePropsType {
  darkTheme?: boolean;
  direction?: 'left' | 'right' | 'up' | 'down';
  transitionDelay?: number;
  imageUrl?: string;
  once?: boolean;
}

const ScreenShotSlideContainer: FC<FadeSlidePropsType> = ({
  darkTheme,
  direction,
  transitionDelay,
  imageUrl,
  once,
}) => {
  const isSmall = useMemo(() => window.innerWidth <= 600, [window.innerWidth]);
  return (
    <FadeSlide
      darkTheme={darkTheme}
      direction={isSmall ? 'up' : direction}
      transitionDelay={transitionDelay}
      once={true}
      sx={{ position: 'relative' }}
    >
      <Box
        sx={{
          borderRadius: {
            xs: '12px',
            sm: '16px',
          },
          overflow: 'hidden',
          border: `3px solid ${AppSignUpColors.PRIMARY}`,
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          mx: {
            xs: -1.5,
            sm: 'unset',
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          flexDirection={'row'}
          sx={{
            pl: 1.3,
            height: {
              xs: '20px',
              sm: '30px',
            },
            pb: 0.15,
            width: '100%',
            background: AppSignUpColors.PRIMARY,
          }}
        >
          <Grid
            sx={{
              width: {
                xs: '8px',
                sm: '10px',
              },
              height: {
                xs: '8px',
                sm: '10px',
              },
              borderRadius: '50%',
              background: iOSColors.red,
            }}
          />
          <Grid
            sx={{
              ml: 0.5,
              width: {
                xs: '8px',
                sm: '10px',
              },
              height: {
                xs: '8px',
                sm: '10px',
              },
              borderRadius: '50%',
              background: iOSColors.orange,
            }}
          />
          <Grid
            sx={{
              ml: 0.5,
              width: {
                xs: '8px',
                sm: '10px',
              },
              height: {
                xs: '8px',
                sm: '10px',
              },
              borderRadius: '50%',
              background: iOSColors.green,
            }}
          />
        </Grid>
        <img
          src={imageUrl}
          width="100%"
          style={{
            display: 'block',
          }}
        />
      </Box>
    </FadeSlide>
  );
};

export default ScreenShotSlideContainer;
