import CountryBubble from '@fides-jam/front/components/CountryBubble';
import { Layer } from '@fides-jam/front/components/Layer';
import TruncatedText from '@fides-jam/front/components/textWrappers/TruncatedText';
import { Box, Grid, SxProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedSlide from '../../layout/container/AnimatedSlide.container';

interface EntityCellPropsType {
  sx?: SxProps;
  name: string;
  legalForm: string;
  address: string;
  representative: string;
  registration: string;
  imageUrl: string;
  bg?: string;
  index: number;
  jurisdiction: string;
  direction: 'left' | 'right' | 'up' | 'down' | undefined;
  animate?: boolean;
}

const EntityCell: FC<EntityCellPropsType> = ({
  sx,
  name,
  legalForm,
  address,
  representative,
  registration,
  imageUrl,
  bg,
  index,
  jurisdiction,
  direction,
  animate,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Layer
      sx={{
        width: '265px',
        overflow: 'visible',
        ...sx,
      }}
    >
      <AnimatedSlide
        animate={animate}
        direction={direction}
        //transitionDelay={150*index+200}
        sx={{
          boxShadow: '0px 0px 34px 5px rgba(0,0,0,0.05)',
          background: '#FFF',
          borderRadius: {
            xs: '12px',
            md: '14px',
          },
          py: 1,
          px: 2,
        }}
      >
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          sx={{
            borderBottom: '2px solid rgba(0,0,0,0.05)',
          }}
        >
          <Box
            sx={{
              background: bg || 'rgba(0,0,0,0.05)',
              height: '36px',
              width: '36px',
              borderRadius: '50%',
              mr: 1.5,
              position: 'relative',
              backgroundSize: 'cover',
              backgroundPositionX: 'center',
              opacity: 0.8,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                right: '-8px',
                bottom: '-8px',
                background: '#FFF',
                borderRadius: '50%',
                p: 0.3,
              }}
            >
              <CountryBubble jurisdiction={jurisdiction} size={18} />
            </Box>
          </Box>
          <Grid
            container
            item
            direction={'column'}
            sx={{
              flex: 1,
              px: 1,
              py: 1,
            }}
          >
            <Grid
              item
              sx={{
                fontSize: '14px',
                fontWeight: '700',
                mb: -0.5,
              }}
            >
              <TruncatedText maxLines={1}>{name}</TruncatedText>
            </Grid>
            <Grid
              item
              sx={{
                fontSize: '12px',
                opacity: 0.4,
              }}
            >
              <small>
                <TruncatedText maxLines={1}>{legalForm}</TruncatedText>
              </small>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          rowSpacing={1}
          direction="column"
          sx={{
            mt: -0.5,
            px: 0.2,
            py: 1,
          }}
        >
          <Grid
            item
            container
            sx={{
              fontSize: '12px',
              opacity: 0.4,
              lineHeight: 1.3,
            }}
          >
            <small>
              <TruncatedText maxLines={1}>{t('ENTITYCELL.ADDRESS')}</TruncatedText>
              <strong>{address}</strong>
            </small>
          </Grid>
          <Grid
            item
            sx={{
              fontSize: '12px',
              opacity: 0.4,
            }}
          >
            <small>
              <TruncatedText maxLines={1}>{t('ENTITYCELL.REPRESENTATIVE')}</TruncatedText>
              <strong>{representative}</strong>
            </small>
          </Grid>
          <Grid
            item
            sx={{
              fontSize: '12px',
              opacity: 0.4,
            }}
          >
            <small>
              <TruncatedText maxLines={1}>{t('ENTITYCELL.REGISTRATION')}</TruncatedText>
              <strong>{registration}</strong>
            </small>
          </Grid>
        </Grid>
      </AnimatedSlide>
    </Layer>
  );
};

export default EntityCell;
