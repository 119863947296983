import { FC } from 'react';
import Row from '../layout/rows/Row';
import Footer from '../views/Footer';

interface FooterSectionPropsType {
  showPostsSection?: boolean;
}

const FooterSection: FC<FooterSectionPropsType> = ({ showPostsSection }) => {
  return (
    <Row
      sx={{
        mt: { xs: 0, md: 3, lg: 5 },
        p: { xs: 2, sm: 5, md: 6, lg: 7 },
      }}
    >
      <Footer showPostsSection={showPostsSection} />
    </Row>
  );
};

export default FooterSection;
