import { FC } from 'react';
import { PostType, WebinarPost } from '../../../../types/builder-io-types';
import { Grid } from '@mui/material';
import PlainText from '../../text/PlainText';
import { AppSignUpColors } from '@fides-jam/utils/theme/colors';
import Header from '../../text/Header';
import HButton from '../../buttons/Highlight.button';
import { useTranslation } from 'react-i18next';

interface HighlightCardPropsType {
  darkTheme?: boolean;
  post: PostType;
  onClick?: () => void;
}

const HighlightCard: FC<HighlightCardPropsType> = ({ post, darkTheme, onClick }) => {
  const { t } = useTranslation();

  if (!post.title) return null;

  function isWebinarPost(post: PostType): post is WebinarPost {
    return 'dateTimeText' in post && 'eventDuration' in post;
  }

  return (
    <Grid container item>
      <Grid
        container
        item
        bgcolor="#F7F7F7"
        borderRadius="20px"
        border={AppSignUpColors.PRIMARY_LIGHT_BORDER}
        rowSpacing={8}
        p={2}
        boxShadow="0px 0px 34px 5px rgba(0,0,0,0.1)"
      >
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid container item xs={12} md={6} lg={4}>
            <img
              src={post.thumbnail}
              alt="Knowledge Hub Highlight"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                borderRadius: '20px',
              }}
            />
          </Grid>
          <Grid container item xs={12} md={6} lg={8}>
            <Grid container alignContent="flex-start" position="relative" spacing={1}>
              <Grid item xs={12}>
                <PlainText text={post.typeLabel} darkTheme={darkTheme} opacity />
              </Grid>
              <Grid item xs={12}>
                <Header text={post.title} darkTheme={darkTheme} />
              </Grid>
              {isWebinarPost(post) &&
                (() => {
                  return (
                    <Grid item xs={12}>
                      <PlainText
                        text={post.dateTimeText}
                        opacity
                        sx={{
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  );
                })()}
              <Grid item xs={12} mb={2}>
                <PlainText text={post.excerpt} darkTheme={darkTheme} />
              </Grid>
              <Grid item xs={12}>
                <HButton onClick={onClick} sx={{ px: 5 }} darkTheme={true}>
                  {t('GENERAL.SEE_MORE')}
                </HButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HighlightCard;
