import { FC } from 'react';
import { BlogPost } from 'apps/speedy/src/types/builder-io-types';
import { BuilderComponent } from '@builder.io/react';
import { Grid } from '@mui/material';

const BlogPostContent: FC<{ post: BlogPost }> = ({ post }) => {
  return (
    <Grid container>
      <BuilderComponent model="blog-article" content={post} />
    </Grid>
  );
};

export default BlogPostContent;
