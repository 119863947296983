export class RouteNames {
  // main
  static home = '/';
  static product = '/product';
  static knowledgeHub = '/knowledge-hub';
  static knowledgeHubArticles = '/knowledge-hub/:id';
  static about = '/about-us';
  static demoBooking = '/demo-booking';
  static demoBookingConfirmation = '/booking-confirmation';
  static demoBookingDummyConfirmation = '/booking-confirmation-demo';
  static legalNotice = '/legal-notice';
  static privacyPolicy = '/privacypolicy';
  static media = '/media';
  static jobs = 'https://fides-technology-gmbh.jobs.personio.de/';
  static dataInformation = '/gdprinformation';
  static demo = '/demo';
  static login = 'https://app.prod.fides.technology/';
  static circleOfCounsels = '/circleofcounsels';
  static circleOfCounselsDetail = '/circleofcounsels/:location';
  static mittelstand = '/mittelstand';
  static webinar = '/webinar';
  static whitepaper = '/whitepaper';
  // product feature groups
  static productEntityManagement = '/entity-management';
  static productWorkflows = '/product-workflows';
  static productAuditTrail = '/product-audittrail';
  // product features
  static approvals = '/product/details/approvals';
  static capTable = '/product/details/capTable';
  static committees = '/product/details/committees';
  static entityManagement = '/product/details/entity-management';
  static resolutions = '/product/details/resolutions';
  static tasks = '/product/details/tasks';
  static documentManagement = '/product/details/document-management';
  static dataVault = '/product/details/data-vault';
  static pressRelease = '/press';
  static meetings = '/product/details/meetings';
  static boardManagement = '/board-management';
  static simonKucherSuccessStory = '/simon-kucher-success-story';
  static reportWrongdoing = 'https://fides-technology-gmbh.personiowhistleblowing.com/';
  static fidesForCounsels = '/fides-for-counsels';
  static FidesVsComeptitors = '/fides-vs-competitors';
  static FidesVsComeptitor = '/fides-vs-competitors/:competitorId';
  static ai = '/ai';
  static security = '/security';
  static buyersGuide = '/buyers-guide';
  static enterprise = '/enterprise';
  static merch = 'https://fides-merch.myshopify.com';
  static supervisoryBoard = '/supervisory-board';
}
